module.exports = _.merge({
    methods: {
        makeChart(base, instant) {
            let n = this.dims.length
            let m = this.vals.length
            m += this.cols.length

            let grp = this.ndx.groupAll().reduce(
                (p,v) => { for (let i = 0; i < m; ++i) { p[i] += v[n+i] }; return p },
                (p,v) => { for (let i = 0; i < m; ++i) { p[i] -= v[n+i] }; return p },
                () => _.fill(new Array(m), 0))

            let chart = dc.numberDisplay(base)

            chart.group(grp)

            return chart
        },
    },
    computed: {
        computedStyle() {
            let style = this.styleFunction
            if (_.isString(style)) {
                try {
                    style = eval(style)
                }
                catch (ex) {
                    console.warn(ex)
                    style = () => {}
                }
            }
            if (_.isFunction(style)) {
                let value = _.get(this.report, "rows[0][0]")
                if (value === undefined)
                    value = null
                return style(value)
            }
            else if (_.isPlainObject(style))
                return style
            else
                return null
        },
    }
}, require("./props").chartProps({
    renderLegend:   { type: Boolean, default: false },
    valueAccessor:  { type: [Function, String], default: (d) => d[0] },
    formatNumber:   { type: [Function, String], default: d3.format(".3s") },
    // html:           { type: Object },
    styleFunction:  { type: [Function, String] },
}))
