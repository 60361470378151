import { render, staticRenderFns } from "./gp-kpis3.vue?vue&type=template&id=3110d8ae&"
import script from "./gp-kpis3.vue?vue&type=script&lang=js&"
export * from "./gp-kpis3.vue?vue&type=script&lang=js&"
import style0 from "./gp-kpis3.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\aazat\\OneDrive\\Documents\\Repositories\\Goalprofit\\gloriajeans_frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3110d8ae')) {
      api.createRecord('3110d8ae', component.options)
    } else {
      api.reload('3110d8ae', component.options)
    }
    module.hot.accept("./gp-kpis3.vue?vue&type=template&id=3110d8ae&", function () {
      api.rerender('3110d8ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "public/gp/gp-kpis3.vue"
export default component.exports