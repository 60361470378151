<template>
  <div :class="{ 'ao-chart': true, 'ao-chart-compact': compact }">
    <date-filter
      :stream="stream"
      function="date"
      :notBefore="notBefore"
      :notAfter="notAfter"
      :groups="['timerange']"
    />
    <div class="multiselect-container">
      <my-multiselect
        :options="suppliers"
        :selectedOption="suppliers[0]"
        searchable
        placeholder="Выберите поставщика"
        style="width: 300px;"
      />
      <my-multiselect
        :options="products"
        :selectedOption="products[0]"
        searchable
        placeholder="Выберите товар"
        style="width: 535px;"
      />
    </div>
    <my-tooltip
      ref="tooltip"
      :meta="meta"
      :chart="chart"
      :accessor="tooltipAccessor"
      :actions="actions"
    />
    <div class="my-chart" ref="base">
      <gp-data
        id="gp-charts"
        :controls="false"
        :stream="stream"
        :source="source"
        :groups="groups"
        :dims="dims"
        :vals="vals"
        :cols="cols"
        :filter1="filter1"
        :filter2="filter2"
        :instant="instant"
        :throttled="throttled"
        @report="report = $event"
        @reportId="reportId = $event"
        ref="data"
      />
      <div ref="chart" :style="{ opacity: reportId ? 0.3 : 1 }" />
    </div>
  </div>
</template>
<script>
const utils = require('../my-utils');
const MyMultiSelect = require('../my-multiselect.vue').default;

module.exports = {
  mixins: [
    utils.configHelpers,
    require('../dc/base.js'),
    require('../dc/margin.js'),
    require('../dc/coordinate-grid.js'),
    require('../dc/composite-chart.js'),
  ],

  components: {
    'my-multiselect': MyMultiSelect,
  },

  data() {
    const gpndx = crossfilter([]);
    const dim = gpndx.dimension((row) => row[0]);

    return {
      l10n: utils.l10n,
      gpndx,
      dim,
      meta: null,
      rows: [],
      checked: {},
      report: {},
      reportId: null,
      metricsOpened: false,
      nestedCharts: {},
      metricsSearchString: '',
      selectedProduct: '',
      products: [
        'Сосиски ЛАМА Мега Хит Маленькая г/уп 340г/1',
        'Сосиски СКТ Mini и/о г/уп 350г/1',
        'Сосиски Дымов Сливочные 300г в/уп/10',
        'Колбаски ГРАЙСИНГЕР Братвурские для гриля 250г в/у/24',
      ],
      suppliers: [
        'Черкизово', ' Зелёная Линия', 'Индилайт',
      ],
      rows2: [
        [
          "2021-08-01T19:00:00.000Z",
          30,
          4.031579494476318,
          19.75806427001953,
          15.550819396972656,
          23.878564834594727,
          20,
          7.715555667877197,
          0,
        ],
        [
          "2021-08-02T19:00:00.000Z",
          25,
          4.031579494476318,
          21.564516067504883,
          15.550819396972656,
          24.81728172302246,
          20,
          0.8895764350891113,
          0
        ],
        [
          "2021-08-03T19:00:00.000Z",
          5,
          4.031579494476318,
          16.032257080078125,
          15.550819396972656,
          23.679990768432617,
          20,
          5.93445348739624,
          0
        ],
        [
          "2021-08-04T19:00:00.000Z",
          6,
          4.031579494476318,
          19.83333396911621,
          15.550819396972656,
          24.062955856323242,
          20,
          5.772295951843262,
          0
        ],
        [
          "2021-08-05T19:00:00.000Z",
          3,
          4.031579494476318,
          21.489248275756836,
          15.550819396972656,
          24.104442596435547,
          20,
          2.6645357608795166,
          0
        ],
        [
          "2021-08-06T19:00:00.000Z",
          7,
          4.031579494476318,
          22.69354820251465,
          15.550819396972656,
          24.42902374267578,
          20,
          5.900936603546143,
          0
        ],
        [
          "2021-08-07T19:00:00.000Z",
          3,
          4.031579494476318,
          18.629032135009766,
          15.550819396972656,
          25.856300354003906,
          20,
          3.1120235919952393,
          0
        ],
        [
          "2021-08-08T19:00:00.000Z",
          2,
          4.031579494476318,
          19.75806427001953,
          14.665573120117188,
          25.515031814575195,
          20,
          2.069221019744873,
          0
        ],
        [
          "2021-08-09T19:00:00.000Z",
          5,
          4.031579494476318,
          21.564516067504883,
          14.665573120117188,
          24.89271354675293,
          20,
          4.7188897132873535,
          0
        ],
        [
          "2021-08-10T19:00:00.000Z",
          9,
          4.031579494476318,
          16.032257080078125,
          14.665573120117188,
          23.8445987701416,
          20,
          11.340137481689453,
          0
        ],
        [
          "2021-08-11T19:00:00.000Z",
          1,
          4.031579494476318,
          19.83333396911621,
          14.665573120117188,
          23.990802764892578,
          20,
          1.0195958614349365,
          0
        ],
        [
          "2021-08-12T19:00:00.000Z",
          2,
          4.031579494476318,
          21.489248275756836,
          14.665573120117188,
          23.878564834594727,
          20,
          1.8805489540100098,
          0
        ],
        [
          "2021-08-13T19:00:00.000Z",
          8,
          4.031579494476318,
          22.69354820251465,
          14.665573120117188,
          23.394540786743164,
          0,
          0,
          0
        ],
        [
          "2021-08-14T19:00:00.000Z",
          8,
          4.031579494476318,
          18.629032135009766,
          14.665573120117188,
          23.233198165893555,
          0,
          0,
          0
        ],
        [
          "2021-08-15T19:00:00.000Z",
          14,
          4.031579494476318,
          19.75806427001953,
          20.213115692138672,
          23.71722412109375,
          20,
          10.375873565673828,
          0
        ],
        [
          "2021-08-16T19:00:00.000Z",
          5,
          4.031579494476318,
          21.564516067504883,
          20.213115692138672,
          24.062955856323242,
          20,
          3.4036381244659424,
          0
        ],
        [
          "2021-08-17T19:00:00.000Z",
          4,
          4.031579494476318,
          16.032257080078125,
          20.213115692138672,
          23.233198165893555,
          20,
          3.6408348083496094,
          0
        ],
        [
          "2021-08-18T19:00:00.000Z",
          7,
          4.031579494476318,
          19.83333396911621,
          20.213115692138672,
          23.41470718383789,
          20,
          5.157071113586426,
          0
        ],
        [
          "2021-08-19T19:00:00.000Z",
          2,
          4.031579494476318,
          21.489248275756836,
          20.213115692138672,
          23.6480770111084,
          20,
          1.3621817827224731,
          0
        ],
        [
          "2021-08-20T19:00:00.000Z",
          5,
          4.031579494476318,
          22.69354820251465,
          20.213115692138672,
          23.233198165893555,
          20,
          3.21516489982605,
          0
        ],
        [
          "2021-08-21T19:00:00.000Z",
          9,
          4.031579494476318,
          18.629032135009766,
          20.213115692138672,
          23.53106117248535,
          20,
          7.065043926239014,
          0
        ],
        [
          "2021-08-22T19:00:00.000Z",
          1,
          4.031579494476318,
          19.75806427001953,
          16.849180221557617,
          23.41470718383789,
          20,
          0.8871782422065735,
          0
        ],
        [
          "2021-08-23T19:00:00.000Z",
          4,
          4.031579494476318,
          21.564516067504883,
          16.849180221557617,
          24.062955856323242,
          20,
          3.2665393352508545,
          0
        ],
        [
          "2021-08-24T19:00:00.000Z",
          0,
          4.031579494476318,
          16.032257080078125,
          16.849180221557617,
          24.685274124145508,
          20,
          0,
          0
        ],
        [
          "2021-08-25T19:00:00.000Z",
          4,
          4.031579494476318,
          19.83333396911621,
          16.849180221557617,
          24.104442596435547,
          20,
          3.5527145862579346,
          0
        ],
        [
          "2021-08-26T19:00:00.000Z",
          4,
          4.031579494476318,
          21.489248275756836,
          16.849180221557617,
          23.456594467163086,
          20,
          3.263806104660034,
          0
        ],
        [
          "2021-08-27T19:00:00.000Z",
          3,
          4.031579494476318,
          22.69354820251465,
          16.849180221557617,
          23.8445987701416,
          20,
          2.3243935108184814,
          0
        ],
        [
          "2021-08-28T19:00:00.000Z",
          2,
          4.031579494476318,
          18.629032135009766,
          16.849180221557617,
          24.322254180908203,
          20,
          1.8941291570663452,
          0
        ],
        [
          "2021-08-29T19:00:00.000Z",
          8,
          4.031579494476318,
          19.75806427001953,
          21.747539520263672,
          9.293279647827148,
          20,
          4.942502021789551,
          0
        ],
        [
          "2021-08-30T19:00:00.000Z",
          3,
          4.031579494476318,
          21.564516067504883,
          21.747539520263672,
          9.293279647827148,
          20,
          1.6981765031814575,
          0
        ],
        [
          "2021-08-31T19:00:00.000Z",
          15,
          4.031579494476318,
          16.032257080078125,
          21.747539520263672,
          9.465376853942871,
          20,
          11.436501502990723,
          0
        ],
        [
          "2021-09-01T19:00:00.000Z",
          1,
          4.031579494476318,
          19.83333396911621,
          21.747539520263672,
          9.453508377075195,
          20,
          0.6162541508674622,
          0
        ],
        [
          "2021-09-02T19:00:00.000Z",
          11,
          4.031579494476318,
          21.489248275756836,
          21.747539520263672,
          9.434086799621582,
          20,
          6.25546932220459,
          0
        ],
        [
          "2021-09-03T19:00:00.000Z",
          6,
          4.031579494476318,
          22.69354820251465,
          21.747539520263672,
          9.50448989868164,
          20,
          3.2328133583068848,
          0
        ],
        [
          "2021-09-04T19:00:00.000Z",
          10,
          4.031579494476318,
          18.629032135009766,
          21.747539520263672,
          6.0406317710876465,
          20,
          6.382678508758545,
          3.7246649265289307
        ],
        [
          "2021-09-05T19:00:00.000Z",
          29,
          4.031579494476318,
          19.75806427001953,
          20.7442626953125,
          3.0228140354156494,
          0,
          0,
          5.094363689422607
        ],
        [
          "2021-09-06T19:00:00.000Z",
          25,
          4.031579494476318,
          21.564516067504883,
          20.7442626953125,
          3.7173118591308594,
          0,
          0,
          5.501387596130371
        ],
        [
          "2021-09-07T19:00:00.000Z",
          5,
          4.031579494476318,
          16.032257080078125,
          20.7442626953125,
          9.293279647827148,
          20,
          3.9910643100738525,
          4.76611852645874
        ],
        [
          "2021-09-08T19:00:00.000Z",
          2,
          4.031579494476318,
          19.83333396911621,
          20.7442626953125,
          20.57797622680664,
          20,
          1.406543254852295,
          5.081233501434326
        ],
        [
          "2021-09-09T19:00:00.000Z",
          4,
          4.031579494476318,
          21.489248275756836,
          20.7442626953125,
          28.59470558166504,
          20,
          2.7485275268554688,
          6.118488311767578
        ],
        [
          "2021-09-10T19:00:00.000Z",
          4,
          4.031579494476318,
          22.69354820251465,
          20.7442626953125,
          0,
          0,
          0,
          6.551771640777588
        ],
        [
          "2021-09-11T19:00:00.000Z",
          0,
          4.031579494476318,
          18.629032135009766,
          20.7442626953125,
          0,
          0,
          0,
          5.070675849914551
        ],
        [
          "2021-09-12T19:00:00.000Z",
          0,
          4.031579494476318,
          19.75806427001953,
          22.691802978515625,
          0,
          0,
          0,
          5.8828935623168945
        ],
        [
          "2021-09-13T19:00:00.000Z",
          0,
          4.031579494476318,
          21.564516067504883,
          22.691802978515625,
          0,
          0,
          0,
          6.42075777053833
        ],
        [
          "2021-09-14T19:00:00.000Z",
          0,
          4.031579494476318,
          16.032257080078125,
          22.691802978515625,
          0,
          0,
          0,
          4.773547649383545
        ],
        [
          "2021-09-15T19:00:00.000Z",
          0,
          4.031579494476318,
          19.83333396911621,
          22.691802978515625,
          0,
          0,
          0,
          5.905304908752441
        ],
        [
          "2021-09-16T19:00:00.000Z",
          0,
          4.031579494476318,
          21.489248275756836,
          22.691802978515625,
          0,
          0,
          0,
          6.398346900939941
        ],
        [
          "2021-09-17T19:00:00.000Z",
          0,
          4.031579494476318,
          22.69354820251465,
          22.691802978515625,
          0,
          0,
          0,
          6.756922721862793
        ],
        [
          "2021-09-18T19:00:00.000Z",
          0,
          4.031579494476318,
          18.629032135009766,
          22.691802978515625,
          0,
          0,
          0,
          5.546727657318115
        ],
        [
          "2021-09-19T19:00:00.000Z",
          0,
          4.031579494476318,
          19.75806427001953,
          23.163936614990234,
          0,
          0,
          0,
          6.005294322967529
        ],
        [
          "2021-09-20T19:00:00.000Z",
          0,
          4.031579494476318,
          21.564516067504883,
          23.163936614990234,
          0,
          0,
          0,
          6.554349899291992
        ],
        [
          "2021-09-21T19:00:00.000Z",
          0,
          4.031579494476318,
          16.032257080078125,
          23.163936614990234,
          0,
          0,
          0,
          4.872867584228516
        ],
        [
          "2021-09-22T19:00:00.000Z",
          0,
          4.031579494476318,
          19.83333396911621,
          23.163936614990234,
          0,
          0,
          0,
          6.028172016143799
        ],
        [
          "2021-09-23T19:00:00.000Z",
          0,
          4.031579494476318,
          21.489248275756836,
          23.163936614990234,
          0,
          0,
          0,
          6.531472682952881
        ],
        [
          "2021-09-24T19:00:00.000Z",
          0,
          4.031579494476318,
          22.69354820251465,
          23.163936614990234,
          0,
          0,
          0,
          6.897509574890137
        ],
        [
          "2021-09-25T19:00:00.000Z",
          0,
          4.031579494476318,
          18.629032135009766,
          23.163936614990234,
          0,
          0,
          0,
          5.662134647369385
        ],
        [
          "2021-09-26T19:00:00.000Z",
          0,
          4.031579494476318,
          19.75806427001953,
          24.37377166748047,
          0,
          0,
          0,
          6.318946838378906
        ],
        [
          "2021-09-27T19:00:00.000Z",
          0,
          4.031579494476318,
          21.564516067504883,
          24.37377166748047,
          0,
          0,
          0,
          6.896678447723389
        ],
        [
          "2021-09-28T19:00:00.000Z",
          0,
          4.031579494476318,
          16.032257080078125,
          24.37377166748047,
          0,
          0,
          0,
          5.127373695373535
        ],
        [
          "2021-09-29T19:00:00.000Z",
          0,
          4.031579494476318,
          19.83333396911621,
          24.37377166748047,
          0,
          0,
          0,
          6.343019008636475
        ],
        [
          "2021-09-30T19:00:00.000Z",
          0,
          4.031579494476318,
          21.489248275756836,
          24.37377166748047,
          0,
          0,
          0,
          6.872607231140137
        ],
        [
          "2021-10-01T19:00:00.000Z",
          0,
          4.031579494476318,
          22.69354820251465,
          24.37377166748047,
          0,
          0,
          0,
          7.257761478424072
        ],
        [
          "2021-10-02T19:00:00.000Z",
          0,
          4.031579494476318,
          18.629032135009766,
          24.37377166748047,
          0,
          0,
          0,
          5.957863807678223
        ]
      ]
    };
  },

  props: {
    stream: {
      type: String,
      default: 'default',
      required: false,
    },
    source: { type: Object, default: null },
    groups: { type: Array, default: () => [] },
    notBefore: { type: String, default: '2019-01-01' },
    notAfter: { type: String, default: '2020-12-31' },
    filter1: { type: String },
    filter2: { type: String },
    sections: { type: Array, default: () => [] },
    metrics: { type: Array, default: () => [] },
    formats: { type: Object, default: () => ({}) },
    formulas: { type: Object, default: () => ({}) },
    timeframes: { type: Object, default: () => ({}) },
    attributes: { type: Array, default: () => [] },
    instant: { type: Boolean, default: false },
    throttled: { type: Boolean, default: true },
    compact: { type: Boolean, default: false },
    username: { type: String },
    actions: { type: Array },
    defaultMetrics: { type: Array, default: () => [] },
    rightYAxisFormats: { type: Array, default: () => ['value'] },
    selectedCharts: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  created() {
    if (this.selectedCharts) {
      this.selectedCharts.forEach((chart) => {
        this.checked[chart] = true;
      });
    }
  },

  computed: {
    vals() {
      const vals = {};

      for (const metric of this.checkedMetrics) {
        const timeframe = 'date';

        const resolveSubstitutes = (calc, depth = 0) => {
          if (depth == 10) { return calc; }
          return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
            const formula = this.formulas[symbol];
            if (formula !== undefined && !this.isAggregationFormula(formula)) { return `(${resolveSubstitutes(formula, depth + 1)})`; }
            return symbol;
          });
        };

        const registerFormula = (symbol) => {
          const formula = this.formulas[symbol];

          if (formula !== undefined) {
            if (this.isAggregationFormula(formula)) {
              vals[`${symbol}_${timeframe}`] = this.resolveDateConditions(
                resolveSubstitutes(formula),
              );
            } else {
              for (const [symbol] of formula.matchAll(/[a-zA-Z_][a-zA-Z_0-9]*/g)) {
                registerFormula(symbol);
              }
            }
          }
        };

        registerFormula(metric.formula.split(/[\s,]+/g)[0]);
      }

      return _(vals)
        .toPairs()
        .map(([name, calc]) => ({
          name,
          calc: `${calc} as ${name}`,
          show: false,
        }))
        .value();
    },

    dims() {
      return [{
        calc: 'date',
        name: utils.l10n('date'),
      }];
    },

    cols() {
      cols = [];
      for (const metric of this.checkedMetrics) {
        let calc;
        const symbol = metric.formula.split(/[\s,]+/g)[0];
        const formula = this.formulas[symbol];
        const timeframe = 'date';
        if (formula !== undefined) {
          if (this.isAggregationFormula(formula)) { calc = `${symbol}_${timeframe}`; } else {
            const resolveSubstitutes = (calc, depth = 0) => {
              if (depth == 10) { return calc; }
              return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
                const formula = this.formulas[symbol];
                if (formula !== undefined) {
                  if (this.isAggregationFormula(formula)) return `${symbol}_${timeframe}`;
                  return `(${resolveSubstitutes(formula, depth + 1)})`;
                }
                return symbol;
              });
            };
            calc = formula.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) =>
              // console.log(2,symbol)
              resolveSubstitutes(symbol));
          }
        }

        if (calc !== undefined) {
          cols.push({ calc, name: metric.name, metric });
        }
      }
      return cols;
    },

    checkedMetrics() {
      return this.metrics.filter(({ formula }) => this.checked[formula]);
    },
    nestedColors() {
      try {
        return eval(this.colors);
      } catch {
        return undefined;
      }
    },
  },

  watch: {
    selectedProduct(product) {
      if (product === 'Сосиски СКТ Mini и/о г/уп 350г/1') {
        this.report.rows = this.rows2.map((row) => [
          new Date(row[0]),
          row[1],
          row[2],
          row[3],
          row[4],
          row[5],
          row[6],
          row[7],
          row[8],
        ]);
      }
    },

    report: {
      handler: function (report) {
        if (report) {
          this.gpndx.remove(() => true);
          this.gpndx.add(report.rows);

          // for tooltip
          this.rows = report.rows;
          this.meta = report.meta;

          const knownKeys = new Set();
          let needCompose = false;

          const series = {};

          _.forEach(report.meta.columns, ({ calc, metric }, i) => {
            if (metric) {
              const { name, format } = metric;
              const key = JSON.stringify({ calc, name, format });
              let min = 0;
              let max = 0;
              let sum = 0;
              let cnt = 0;
              const useRightYAxis = _.includes(this.rightYAxisFormats, format);

              // eslint-disable-next-line no-restricted-syntax
              for (const row of report.rows) {
                min = Math.min(min, row[i]);
                max = Math.max(max, row[i]);
                sum += row[i];
                cnt += 1;
              }

              series[key] = {
                min, max, sum, cnt, useRightYAxis,
              };
            }
          });

          const groups = _.keys(series).map((key) => [key]);

          // eslint-disable-next-line no-restricted-syntax
          for (const useRightYAxis of [false, true]) {
            let axisScale;
            // eslint-disable-next-line no-restricted-syntax
            for (const group of groups) {
              if (series[group[0]].useRightYAxis === useRightYAxis) {
                const max = _(group).map((x) => series[x].max).max();
                let scale = 1;

                if (max > 0) {
                  while (max * scale > 100) { scale /= 10; }
                  while (max * scale < 10) { scale *= 10; }
                }

                if (axisScale === undefined) {
                  axisScale = scale;
                  scale = 1;
                } else {
                  scale = axisScale;
                }

                // eslint-disable-next-line no-restricted-syntax
                for (const x of group) {
                  series[x].scale = scale;
                }
              }
            }
          }

          _.forEach(report.meta.columns, ({ calc, metric }, i) => {
            if (metric) {
              const { name, format } = metric;
              const key = JSON.stringify({ calc, name, format });
              const { useRightYAxis, scale } = series[key];
              let grp = this.dim.group().reduce(
                (p, v) => p + v[i] * scale,
                (p, v) => p - v[i] * scale,
                () => 0,
              );
              grp = this.removeEmptyBins(grp);

              if (!this.nestedCharts[key]) {
                if (name === '[MX-CHART] Поступления итог'
              || name === '[MX-CHART] Списания итог'
              || name === '[MX] Списания итог'
              || name === '[MX] Списания прогноз'
              || name === '[MX] Поступления итог'
              || name === '[MX] Поступления плановые'
              || name === '[MX] Поступления план а'
              || name === '[AZ] Продажи'
              || name === '[AZ] Сумма по полю FactResult'
                ) {
                  const chart = dc.barChart(this.chart)
                    .dimension(this.dim)
                    .x(d3.scaleTime())
                    .colors(this.metricColor)
                    .barPadding(2)
                    .useRightYAxis(useRightYAxis);
                  this.nestedCharts[key] = chart;
                  needCompose = true;
                } else {
                  const chart = dc.lineChart(this.chart)
                    .defined((d) => {
                      if (d.y !== null || d.y !== 0) {
                        return d.y;
                      }
                      console.log(d);
                      return d;
                    })
                    .dimension(this.dim)
                    .x(d3.scaleTime())
                    .colors(this.metricColor)
                    .useRightYAxis(useRightYAxis);
                  this.nestedCharts[key] = chart;
                  needCompose = true;

                  if (name === '[MX-CHART] Остатки прогноз'
                || name === '[MX] Продажи прогноз'
                || name === '[MX] Остатки плановые'
                || name === '[AZ] Сумма по полю fr1'
                || name === '[AZ] Сумма по полю fr2'
                || name === '[AZ] Сумма по полю fr3'
                || name === '[AZ] Сумма по полю fr4'
                  ) {
                    chart.dashStyle([2, 3]);
                  }

                  if (name === '[AZ] Сумма по полю ClearedResult') {
                    chart.renderArea(true);
                    chart.defined((d) => {
                      if (d.y !== null || d.y !== 0) {
                        return d.y;
                      }
                      console.log(d);
                      return d;
                    });
                  }
                }
              }

              const chart = this.nestedCharts[key]
                .group(grp, name, (d) => d.value)
                .colors(this.metricColor);
              knownKeys.add(key);
            }
          });

          for (const key of _.keys(this.nestedCharts)) {
            if (!knownKeys.has(key)) {
              delete this.nestedCharts[key];
              needCompose = true;
            }
          }

          if (needCompose) {
            this.chart.compose(_.values(this.nestedCharts));
          }

          this.chart.render();
        }
      },
      deep: true,
    }
  },

  methods: {
    tooltipAccessor(data) {
      const row = this.rows.find((row) => row[0] == data.key);

      if (!row) {
        return [];
      }

      return _.map(this.meta.columns, ({ metric }, i) => {
        if (metric) {
          let value = row[i];
          try {
            value = eval(this.formats[metric.format])(value);
          } catch {}
          return {
            name: metric.name,
            value,
            color: this.metricColor(metric.name),
          };
        }
      }).filter(_.identity);
    },

    metricColor(formula) {
      return this.nestedColors ? this.nestedColors(formula) : null;
    },

    createChart(instant) {
      if (!this.chart) {
        this.chart = dc.compositeChart(this.$refs.chart);
        dc.chartRegistry.deregister(this.chart);
        this.setupChart(this.chart);
      }
      this.renderChart(instant);
    },

    isAggregationFormula(formula) {
      return formula.match(/(sum|min|max|one|and|any|cnt|avg|first|last)\s*\(.*\)/);
    },

    removeEmptyBins(sourceGroup) {
      return {
        all() {
          return sourceGroup.all().filter((d) => d.value !== 0);
        },
      };
    },
    onProductSelect(product) {
      this.selectedProduct = product.value;
    },
  },

};
</script>

<style scoped>
  .ao-chart {
    display: flex;
    flex-direction: row;
    height: 80%;
  }
  .ao-chart .my-date-filter {
    position: absolute;
    left: 10px;
    top: 2px;
  }
  .ao-chart .my-chart {
    flex-basis: 1px;
    flex-grow: 1;
    margin-top: 0!important;
  }
  .ao-chart > * {
    transition: opacity 0.3s ease;
  }
  .ao-chart .gp-data {
    position: absolute;
    top: 4px;
    right: 10px;
    z-index: 1;
  }
  .ao-chart .my-date-filter {
    top: 4px;
  }
  .ao-chart .gp-data {
    position: absolute;
    top: 4px;
    right: 10px;
    z-index: 1;
  }
  .ao-chart .my-date-filter {
    top: 4px;
  }
  .ao-chart .dc-chart svg {
    position: absolute;
  }
  .ao-chart .gp-date .feather-icon {
    display: none;
  }
  .multiselect-container {
    position: absolute;
    top: 0px;
    left: 100px;
    display: flex;
    gap: 10px;
    transform: scale(0.7);
    z-index: 1000;
  }
</style>
