var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "node-btn",
      attrs: { type: "button" },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.clickHandle.apply(null, arguments)
        }
      }
    },
    [_vm.isOpen ? _c("minus-icon") : _c("plus-icon")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }