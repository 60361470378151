<template>
    <span class="my-popper" ref="base">
        <span
            key="reference"
            ref="reference"
            class="my-popper-reference"><slot/></span>
        <div
            key="element"
            ref="element"
            :class="`my-popper-element-${strategy}`">
            <slot name="popper"></slot>
        </div>
    </span>
</template>
<script>
let { createPopper } = require('@popperjs/core')
module.exports = {
    props: {
        reference: { type: Function },
        element:   { type: Function },
        placement: { type: [String, Array], default: "bottom" },
        draggable: { type: Boolean, default: false },
        modifiers: {
            type: Array,
            default: () =>
                [{
                    name: "flip",
                    options: {
                        padding: 32,
                    }
                }, {
                    name: "preventOverflow",
                    options: {
                        mainAxis: true,
                        altAxis: true,
                    }
                }]
        },
        strategy: { type: String, default: "fixed" },
    },
    data() {
        return { popper: null, dragged: false }
    },
    mounted() {
        this.createPopper()
        _.defer(() => {
            document.addEventListener("keydown", this.interceptKeyDownEvent)
            document.addEventListener("mousedown", this.interceptClickEvent)
        })
        if (this.draggable)
            $(this.resolveElement()).draggable({
                distance: 5,
                cancel: "a, button, input, select",
                start: () => this.dragged = true,
            })

        _.defer(() => {
            let element = this.resolveElement()
            this.elementHeight = $(element).height()
            this.resizeSensor = new ResizeSensor(this.resolveElement(), () => {
                let elementHeight = $(element).height()
                if (this.elementHeight !== elementHeight) {
                    this.elementHeight = elementHeight
                    this.popper.update()
                }
            })
        })
    },
    beforeDestroy() {
        this.popper.destroy()
        document.removeEventListener("keydown", this.interceptKeyDownEvent)
        document.removeEventListener("mousedown", this.interceptClickEvent)
        if (this.draggable)
            $(this.resolveElement()).draggable("destroy")  
        this.resizeSensor.detach()
    },
    methods: {
        resolveElement() {
            return _.isFunction(this.element) ? this.element() : this.$refs.element
        },
        resolveReference() {
            return _.isFunction(this.reference) ? this.reference() : this.$refs.reference
        },
        interceptKeyDownEvent(e) {
            if (e.key === "Escape") {
                this.$emit("escape")
            }
        },
        interceptClickEvent(e) {
            let node = e.target
            let base = this.resolveElement()
            while (node && node !== base)
                node = node.parentNode
            if (!node) {
                this.$emit("clickoutside")
            }
        },
        createPopper() {
            if (this.dragged)
                return

            if (this.popper)
                this.popper.destroy()
            
            let options = {
                placement: this.placement,
                strategy: this.strategy
            }

            if (this.modifiers)
                options.modifiers = this.modifiers

            this.popper = createPopper(
                this.resolveReference(),
                this.resolveElement(),
                options)
        },
        destoryPopper() {
            if (this.popper)
                this.popper.destroy()
        }
    },
    watch: {
        reference() { this.createPopper() },
        element()   { this.createPopper() },
        placement() { this.createPopper() },
        modifiers() { this.createPopper() },
        strategy()  { this.createPopper() },
        dragged()   { this.destoryPopper() },
    }
}
</script>
<style>
.my-popper .popover {
    position: relative;
}
*[data-popper-placement] {
}
.my-popper-element-fixed {
    position: fixed;
    z-index: 6;
}
.my-popper-element-absolute {
    position: absolute;
    z-index: 6;
}
</style>
