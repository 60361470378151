var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-users" },
    [
      _c("gp-data", {
        attrs: {
          stream: "classif",
          filter2: "category != ''",
          dims: ["category", "class"]
        },
        model: {
          value: _vm.classifReport,
          callback: function($$v) {
            _vm.classifReport = $$v
          },
          expression: "classifReport"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "gp-users-tools" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchString,
              expression: "searchString"
            }
          ],
          staticClass: "form-control form-control-sm",
          attrs: { type: "search", placeholder: _vm.l10n("Search...") },
          domProps: { value: _vm.searchString },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchString = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-primary",
              on: {
                click: function($event) {
                  _vm.user = {}
                  _vm.showUserDialog = true
                }
              }
            },
            [_c("l10n", { attrs: { value: "Create user" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-secondary",
              attrs: { disabled: _vm.selectedUsersCount == 0 },
              on: { click: _vm.resetPasswordForSelectedUsers }
            },
            [
              _c("l10n", {
                attrs: { value: "Reset passwords for selected users" }
              }),
              _vm._v(
                "\r\n                (" +
                  _vm._s(_vm.selectedUsersCount) +
                  ")\r\n            "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-danger",
              attrs: { disabled: _vm.selectedUsersCount == 0 },
              on: { click: _vm.deleteSelectedUsers }
            },
            [
              _c("l10n", { attrs: { value: "Delete selected users" } }),
              _vm._v(
                "\r\n                (" +
                  _vm._s(_vm.selectedUsersCount) +
                  ")\r\n            "
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("plain-table", {
        ref: "table",
        attrs: {
          stream: "users",
          provider: _vm.provider,
          dims: _vm.dims,
          vals: _vm.vals,
          pagination: true,
          throttled: false,
          initialSort: [2]
        },
        on: { change: _vm.handleTableChange, action: _vm.handleTableAction }
      }),
      _vm._v(" "),
      _vm.showUserDialog
        ? _c(
            "my-dialog",
            {
              attrs: { title: "User", large: true },
              on: {
                close: function($event) {
                  _vm.showUserDialog = false
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-primary",
                            on: {
                              click: function($event) {
                                _vm.createUser(_vm.user)
                                _vm.showUserDialog = false
                              }
                            }
                          },
                          [_c("l10n", { attrs: { value: "Submit" } })],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-secondary",
                            on: {
                              click: function($event) {
                                _vm.showUserDialog = false
                              }
                            }
                          },
                          [_c("l10n", { attrs: { value: "Cancel" } })],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1914309794
              )
            },
            [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_c("l10n", { attrs: { value: "ID" } })], 1),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.id,
                      expression: "user.id"
                    }
                  ],
                  staticClass: "form-control",
                  domProps: { value: _vm.user.id },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "id", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_c("l10n", { attrs: { value: "Name" } })], 1),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.name,
                      expression: "user.name"
                    }
                  ],
                  staticClass: "form-control",
                  domProps: { value: _vm.user.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "name", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_c("l10n", { attrs: { value: "E-Mail" } })], 1),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.email,
                      expression: "user.email"
                    }
                  ],
                  staticClass: "form-control",
                  domProps: { value: _vm.user.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "email", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", [_c("l10n", { attrs: { value: "Groups" } })], 1),
                  _vm._v(" "),
                  _c("gp-pills", {
                    attrs: { options: _vm.accessGroups },
                    model: {
                      value: _vm.user.groups,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "groups", $$v)
                      },
                      expression: "user.groups"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", [_c("l10n", { attrs: { value: "Access" } })], 1),
                  _vm._v(" "),
                  _c("gp-pills", {
                    attrs: { options: _vm.categories },
                    model: {
                      value: _vm.user.access,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "access", $$v)
                      },
                      expression: "user.access"
                    }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }