const utils = require('./my-utils');

module.exports = {
  props: {
    batch: { type: String },
    bounds: { type: String },
    groups: { type: Array, default: () => [] },
    stream: { type: String, default: 'default' },
    cores: { type: Number, default: 24 },
    cache: { type: Boolean, default: true },
    stored: { type: Boolean, default: false },
    vars: { type: Object, default: () => ({}) },
    dims: { type: Array, default: () => [] },
    vals: { type: Array, default: () => [] },
    cols: { type: Array, default: () => [] },
    filter0: { type: String, default: '' },
    filter1: { type: String, default: '' },
    filter2: { type: String, default: '' },
    filter3: { type: String, default: '' },
    source: { type: Object },
    sources: { type: Array },
    expand: { type: [String, Array] },
    instant: { type: Boolean, default: false },
    totals: { type: Boolean, default: false },
    throttled: { type: Boolean, default: true },
    initialSort: { type: Array, default: () => [] },
    initialTake: { type: Number, default: null },
    initialSkip: { type: Number, default: null },
    clientSort: { type: Boolean, default: false },
    sortByGrades: {
      type: Boolean,
      required: false,
      default: false,
    },
    sortByGradesWithoutGroups: {
      type: Boolean,
      required: false,
      default: false,
    },
    changeTypeFromStringToNumber: {
      type: Array,
      required: false,
      default: () => [],
    },
    sortByRegions: {
      type: Boolean,
      required: false,
      default: false,
    },
    timeZone: { type: String },
    provider: { type: [String, Function] },
  },
    data: function() {
        return {
            ndx: crossfilter([]),
            data: null,
            meta: null,
            query: null,
            errors: [],
            sorting: false,
            downloading: false,
            progress: 0,
            reportId: null,
            silent: null,
            file: null,
            crossFilters: this.$crossFilters,
        }
    },
    computed: {
        sort: function() {
            return this.initialSort
        },
        take: function() {
            return this.initialTake
        },
        skip: function() {
            return this.initialSkip
        },
        report: function() {
            if (this.data == null || this.meta == null || !_.isEmpty(this.errors))
                return null
            let totals = this.data.dataset.source.totals
            let report = this.data.dataset.source.report
            while (report.report) {
                totals = report.totals
                report = report.report
            }
            let filters = []
            for (let column of this.meta.columns) {
                if (column.filter && _.isFunction(column.filter)) {
                    let i = this.meta.columns.indexOf(column)
                    filters.push(row => column.filter(row[i]))
                }
            }
            if (!_.isEmpty(filters)) {
                report = _.clone(report)
                let filter = row => _.every(filters, f => f(row))
                report.rows = report.rows.filter(filter)
            }
            report = _.assign({}, report, {totals, meta: this.meta})
            // for (let key of _.keys(report)) {
            //     let val = report[key]
            //     if (typeof val === "object" && !Object.isFrozen(val))
            //         report[key] = utils.deepFreeze(val)
            // }
            return report
        },
        assembly: function() {
            return this.report != null ? this.report.assembly : null
        },
        columns: function() {
            return this.report != null ? this.report.columns : null
        },
        prepareTime: function() {
            return this.report != null ? this.report.stats.prepareTime : null
        },
        processTime: function() {
            return this.report != null ? this.report.stats.processTime : null
        },
        processRows: function() {
            return this.report != null ? this.report.stats.processRows : null
        },
        working: function() {
            return this.reportId != null
        },
        extraFilters() {
            let extraFilter0 = []
            let extraFilter1 = []
            let extraFilter2 = []
            let extraFilter3 = []

            let getBounds = (node) => node.bounds || node.$parent && getBounds(node.$parent) || ""

            let uids = {}
            let root = this
            while (root.$parent) {
                root = root.$parent
            }
            let loop = (node) => {
                uids[node._uid] = node
                for (child of node.$children)
                    loop(child)
            }
            loop(root)

            let bounds = this.bounds || getBounds(this.$parent)

           _(this.crossFilters)
                .toPairs()
                .filter(([uid]) => {
                    let node = uid < 0 ? uids[-uid] : uids[uid]
                    return node && bounds === getBounds(node)
                })
                .filter(([uid, {groups, stream}]) => {
                    return uid != this._uid &&
                        (_.isEmpty(groups) || _.isEmpty(this.groups)
                            ? stream === this.stream
                            : _.intersection(groups.slice(0,1), this.groups).length > 0)
                })
                .forEach(([uid, {filter0, filter1, filter2, filter3}]) => {
                    if (!_.isEmpty(filter0)) extraFilter0.push(filter0)
                    if (!_.isEmpty(filter1)) extraFilter1.push(filter1)
                    if (!_.isEmpty(filter2)) extraFilter2.push(filter2)
                    if (!_.isEmpty(filter3)) extraFilter3.push(filter3)
                })

            return {
                extraFilter0: extraFilter0.join(" && "),
                extraFilter1: extraFilter1.join(" && "),
                extraFilter2: extraFilter2.join(" && "),
                extraFilter3: extraFilter3.join(" && "),
            }
        },
        extraFilter0() { return this.extraFilters.extraFilter0 },
        extraFilter1() { return this.extraFilters.extraFilter1 },
        extraFilter2() { return this.extraFilters.extraFilter2 },
        extraFilter3() { return this.extraFilters.extraFilter3 },
    },
    watch: {
        data: function(x) {
            this.ndx.remove(() => true)
            if (this.report != null)
                this.ndx.add(this.report.rows)
        },
        stream:       { deep: true,  handler: function(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled() } },
        groups:       { deep: true,  handler: function(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled() } },
        vars:         { deep: true,  handler: function(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled() } },
        dims:         {
            deep: true,
            handler: function(a,b) {
              const newA = a.map((item) => {
                const { action, actionicon, ...rest } = item;

                return rest;
              });

              const newB = b.map((item) => {
                const { action, actionicon, ...rest } = item;

                return rest;
              });

              if (!_.isEqual(newA,newB)) {
                this.requestDataThrottled();
              }
            }
        },
        vals:         { deep: true,  handler: function(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled() } },
        cols:         { deep: true,  handler: function(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled() } },
        filter0:      { deep: true,  handler: function(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled() } },
        filter1:      { deep: true,  handler: function(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled() } },
        filter2:      { deep: true,  handler: function(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled() } },
        filter3:      { deep: true,  handler: function(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled() } },
        source:       { deep: true,  handler: function(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled() } },
        expand:       { deep: true,  handler: function(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled() } },
        provider:     { deep: false, handler: function(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled() } },
        sort:         { deep: true,  handler: function(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled({delay:10}) } },
        take:         { deep: false, handler: function(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled({delay:10}) } },
        skip:         { deep: false, handler: function(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled({delay:10}) } },
        totals:       { deep: false, handler: function(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled({delay:10}) } },
        extraFilter0(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled() },
        extraFilter1(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled() },
        extraFilter2(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled() },
        extraFilter3(a,b) { if (!_.isEqual(a,b)) this.requestDataThrottled() },
        reportId(currentId, previousId) {
            let activeReports = localStorage.activeReports ? JSON.parse(localStorage.activeReports) : {}
            if (!currentId && previousId)
                delete activeReports[previousId]
            if (currentId && !previousId)
                activeReports[currentId] = _.now()
            localStorage.activeReports = JSON.stringify(activeReports)
        }
    },
    methods: {
        isSameFormulas(xs, ys) {
            if (_.isArray(xs) && _.isArray(ys) && xs.length == ys.length) {
                for (let i=0; i<xs.length; ++i) {
                    let x = xs[i]
                    let y = ys[i]
                    if ((_.isString(x) ? x    : x.calc) !== (_.isString(y) ? y    : y.calc) ||
                        (_.isString(x) ? true : x.show) !== (_.isString(y) ? true : y.show))
                        return false
                }
                return true
            }
            else return _.isEqual(xs,ys)
        },
        cancelPendingReport() {
            if (this.reportId && !this.batch) {
                utils.bridge.trigger("cancelReport", this.reportId, "obsolete")
                this.reportId = null
            }
        },
        beforeWindowUnload() {
            if (this.reportId && !this.batch)
                utils.bridge.trigger("cancelReport", this.reportId, "obsolete")
        },
        handleWsMessage(message) {
            if (message.reportId && message.reportId == this.reportId) {
                if (message.sorting)
                    this.sorting = message.sorting
                if (message.progress)
                    this.progress = message.progress
            }
        },
        requestDataThrottled({silent, delay, forced} = {}) {
            this.cancelPendingReport()
            this.silent = null
            if (delay === undefined)
                delay = this.throttled ? 300 : 0;
            clearTimeout(this.requestDataTimeout)
            this.requestDataSilent = silent
            this.requestDataForced = forced
            this.requestDataTimeout = setTimeout(() =>
                this.requestData({
                    silent: this.requestDataSilent,
                    forced: this.requestDataForced,
                }), delay)
            // }
            // else
            //     this.requestData({silent})
        },
        buildQuery({id, args, sort, vars, dims, vals, cols, filter0, filter1, filter2, filter3, fields, totals, links, funcs, expand, exportToFile, exportConfig, extraFilters}) {

            let makeCols = (dims, vals, cols) =>
                _.concat(
                    _(dims).map(({show}, i) => show !== false ? `dim${i+1}` : null).filter().value(),
                    _(vals).map(({show}, i) => show !== false ? `val${i+1}` : null).filter().value(),
                    _(cols).map("calc").value())

            let name = _.get(this, "$attrs.id") || null

            if (_.isArray(links)) {
                links = links.map(link => {
                    if (_.isPlainObject(link.source)) {
                        link = _.clone(link)
                        let source = _.clone(link.source)
                        if (_.isArray(source.dims))
                            source.dims = source.dims.join(", ")
                        if (_.isArray(source.vals))
                            source.vals = source.vals.join(", ")
                        if (_.isArray(source.cols))
                            source.cols = source.cols.join(", ")
                        link.sourceName = JSON.stringify(source)
                        delete link.source
                    }
                    return link
                })
            }

            return `
                report(
                    name: ${utils.quote(name)},
                    cores: ${this.cores},
                    cache: ${this.cache},
                    stored: ${this.stored},
                    ${ id ? `id: \"${id}\",` : ""}
                    ${ links ? `links: ${utils.quote(links)},` : ""}
                    ${ funcs ? `funcs: ${utils.quote(funcs)},` : ""}
                    ${ expand ? `expand: ${utils.quote(expand)},` : ""}
                    ${ filter0 ? `filter0: ${utils.quote(filter0)},` : ""}
                    ${ filter1 ? `filter1: ${utils.quote(filter1)},` : ""}
                    ${ filter2 ? `filter2: ${utils.quote(filter2)},` : ""}
                    ${ filter3 ? `filter3: ${utils.quote(filter3)},` : ""}
                    ${ !_.isEmpty(vars) ? `vars: ${utils.quote(JSON.stringify(vars))},` : ""}
                    ${ dims.length > 0 ? `dims: ${utils.quote(_(dims).map("calc").join(", "))},` : ""}
                    ${ vals.length > 0 ? `vals: ${utils.quote(_(vals).map("calc").join(", "))},` : ""}
                    cols: ${utils.quote(makeCols(dims,vals,cols).join(", "))},
                    sort: [${sort != null ? sort.join(", ") : ""}])
                {
                    size
                    ${fields}
                    columns { name type synonym }
                    stats {
                        prepareTime
                        processTime
                        processRows
                    }
                    #assembly {
                    #    Dims: dims
                    #    Vals: vals
                    #    Cols: cols
                    #    Filter2: filter2
                    #    Filter3: filter3
                    #}
                }
                ${totals && !exportToFile ? `
                    totals: report(
                        name: ${utils.quote(name)},
                        cores: ${this.cores},
                        cache: ${this.cache},
                        stored: ${this.stored},
                        ${ links ? `links: ${utils.quote(links)},` : ""}
                        ${ funcs ? `funcs: ${utils.quote(funcs)},` : ""}
                        ${ expand ? `expand: ${utils.quote(expand)},` : ""}
                        ${ filter0 ? `filter0: ${utils.quote(filter0)},` : ""}
                        ${ filter1 ? `filter1: ${utils.quote(filter1)},` : ""}
                        ${ filter2 ? `filter2: ${utils.quote(filter2)},` : ""}
                        ${ filter3 ? `filter3: ${utils.quote(filter3)},` : ""}
                        ${ !_.isEmpty(vars) ? `vars: ${utils.quote(JSON.stringify(vars))},` : ""}
                        vals: ${utils.quote(_(vals).map("calc").join(", "))},
                        cols: ${utils.quote(makeCols([],vals,cols).join(", "))},
                        sort: [])
                    {
                        rows
                    }`: ""}
            `
        },
        alterRows(data, meta, func) {
            let path = ["dataset", "source", "report"]
            let report = _.get(data, path)
            while (report.report) {
                report = report.report
                path.push("report")
            }
            let rows = func(report.rows, report.columns)

            let set = (object, path, value) => {
                object = _.clone(object)
                object[path[0]] = path.length == 1 ? value : set(object[path[0]], path.slice(1), value)
                return object
            }

            return set(data, path.concat("rows"), rows)
        },
        async requestData(req = {}) {
            let {silent, forced, exportToFile, exportConfig, extraFilters, replaceData} = req

            let provider = this.provider
            if (_.isString(provider)) {
                try {
                    provider = eval(provider)
                }
                catch (ex) {
                    console.warn(provider, ex)
                }
            }
            if (_.isFunction(provider)) {

                let reportId = utils.randomId()
                this.errors = []
                this.progress = 0
                this.reportId = reportId
                this.silent = silent

                try {
                    let {meta, data} = await provider(req, this)

                    if (this.reportId == reportId) {
                        this.data = utils.deepFreeze(data)
                        this.meta = utils.deepFreeze(_.cloneDeep(meta))

                        this.errors = []
                        this.progress = 1
                        this.reportId = null
                        this.silent = null
                    }
                }
                catch(ex) {
                    if (this.reportId == reportId) {
                        this.errors = [ex]
                        this.progress = 1
                        this.reportId = null
                        this.silent = null
                    }
                }

                return
            }

            if (!exportToFile)
                this.cancelPendingReport()

            let makeName = calc =>
                _.snakeCase(calc.replace(/^.* as (.*)$/, (x,y) => y))
                    .replace(/_/g, ' ')
                    .split(/\s+/g)
                    .map(_.capitalize)
                    .join(" ")

            let stream = this.stream
            let vars = this.vars
            let dims = this.dims.map((entry, index) => {
              let uniqueDimensionKey = `dimension-${entry.id}-${index}`;
              return _.isString(entry) ? {calc: entry, name: makeName(entry), uniqueDimensionKey} : {...entry, uniqueDimensionKey}
            })
            let vals = this.vals.map((entry) => _.isString(entry) ? {calc: entry, name: makeName(entry)} : entry)
            let cols = this.cols.map((entry) => _.isString(entry) ? {calc: entry, name: makeName(entry)} : entry)
            let sort = this.sort

            if (exportToFile) {
                let visibleDims = dims.filter(({show}) => show !== false)
                let visibleVals = vals.filter(({show}) => show !== false)
                sort = sort.map(i => ({
                    asc: i > 0,
                    column:
                        visibleDims[Math.abs(i)-1] ||
                        visibleVals[Math.abs(i)-1-visibleDims.length] ||
                        cols[Math.abs(i)-1-visibleDims.length-visibleVals.length],
                }))
                dims = _.sortBy(dims, "order")
                vals = _.sortBy(vals, "order")
                cols = _.sortBy(cols, "order")
            }

            let columns = []
            columns = columns.concat(dims)
            columns = columns.concat(vals)
            columns = columns.filter(column => column && column.show !== false)
            // invisible columns are used to visualize complex columns
            columns = columns.concat(cols)
            let makeFilter = (filters) =>
                _(filters)
                    .filter((filter) => !_.isEmpty(filter))
                    .map((filter) => `(${filter})`)
                    .join(" && ")
            let args = {}
            if (this.take !== null) args.take = this.take;
            if (this.skip !== null) args.skip = this.skip;
            // if (this.sort !== null) args.sort = `[${this.sort}]`;

            let reportId = utils.nextReportId()
            if (exportToFile) {
                // we don't need invisible columns when we export data
                columns = columns.filter(({show}) => show !== false)
                sort = sort.map(({asc, column}) =>
                    (columns.indexOf(column) + 1) * (asc ? 1 : -1))
            }
            else {
                this.reportId = reportId
                this.silent = silent
            }

            let header = columns.map(({name}) => `${name}`)

            let fields = exportToFile
                ? `file(
                    header: ${utils.quote(header)},                                         
                    format: "xlsx"
                    ${exportConfig ? `, config: ${utils.quote(JSON.stringify(exportConfig))}`: ''}
                    ) { link size rows cols }`
                : `rows${
                    !_.isEmpty(args)
                        ? `(${_(args)
                            .toPairs()
                            .map(([k,v]) => `${k}: ${v}`)
                            .join(", ")})`
                        : ""}`

            let sources = this.sources || []
            if (_.isPlainObject(this.source))
                sources.push(this.source)

            let queries = []
            if (sources.length > 0) {
                for (let source of sources) {
                    let thisSource = source
                    if (_.isPlainObject(source.source))
                        source = source.source
                    let subQuery = this.buildQuery({
                        id: exportToFile ? null : reportId,
                        args: {},
                        sort: source.sort,
                        vars: vars,
                        dims: (source.dims || []).map((entry) => _.isString(entry) ? {calc: entry} : entry),
                        vals: (source.vals || []).map((entry) => _.isString(entry) ? {calc: entry} : entry),
                        cols: (source.cols || []).map((entry) => _.isString(entry) ? {calc: entry} : entry),
                        filter0: makeFilter([this.extraFilter0, source.filter0, extraFilters?.filter0]),
                        filter1: makeFilter([this.extraFilter1, source.filter1, extraFilters?.filter1]),
                        filter2: makeFilter([this.extraFilter2, source.filter2, extraFilters?.filter2]),
                        filter3: makeFilter([source.filter3, extraFilters?.filter3]),
                        fields: source === thisSource ?
                            this.buildQuery({
                                id: exportToFile ? null : reportId,
                                args: args,
                                sort: this.clientSort && !exportToFile ? [] : sort,
                                vars: vars,
                                dims: dims,
                                vals: vals,
                                cols: exportToFile ? cols.filter(({show}) => show !== false) : cols,
                                filter0: makeFilter([this.filter0]),
                                filter1: makeFilter([this.filter1]),
                                filter2: makeFilter([this.filter2]),
                                filter3: makeFilter([this.extraFilter3, this.filter3]),
                                fields,
                                totals: this.totals,
                                expand: this.expand
                            }) :
                            this.buildQuery({
                                id: exportToFile ? null : reportId,
                                args: args,
                                sort: thisSource.sort,
                                vars: vars,
                                dims: (thisSource.dims || []).map((entry) => _.isString(entry) ? {calc: entry} : entry),
                                vals: (thisSource.vals || []).map((entry) => _.isString(entry) ? {calc: entry} : entry),
                                cols: (thisSource.cols || []).map((entry) => _.isString(entry) ? {calc: entry} : entry),
                                filter0: makeFilter([thisSource.filter0]),
                                filter1: makeFilter([thisSource.filter1]),
                                filter2: makeFilter([thisSource.filter2]),
                                filter3: makeFilter([thisSource.filter3]),
                                fields: this.buildQuery({
                                    id: exportToFile ? null : reportId,
                                    args: args,
                                    sort: this.clientSort && !exportToFile ? [] : sort,
                                    vars: vars,
                                    dims: dims,
                                    vals: vals,
                                    cols: exportToFile ? cols.filter(({show}) => show !== false) : cols,
                                    filter0: makeFilter([this.filter0]),
                                    filter1: makeFilter([this.filter1]),
                                    filter2: makeFilter([this.filter2]),
                                    filter3: makeFilter([this.extraFilter3, this.filter3]),
                                    fields,
                                    totals: this.totals,
                                    expand: this.expand
                                }),
                                totals: false,
                                links: thisSource.links,
                                funcs: thisSource.funcs,
                                expand: thisSource.expand,
                            }),
                        totals: false,
                        links: source.links,
                        funcs: source.funcs,
                        expand: source.expand
                    })

                    queries.push(`
                        source(name: ${utils.quote(source.report || source.stream || stream)}) {
                            ${subQuery}
                        }`)
                }
            }
            else {
                queries.push(`
                    source(name: ${utils.quote(stream)}) {
                        ${this.buildQuery({
                            id: exportToFile ? null : reportId,
                            args: args,
                            sort: this.clientSort && !exportToFile ? [] : sort,
                            vars: vars,
                            dims: dims,
                            vals: vals,
                            cols: exportToFile ? cols.filter(({show}) => show !== false) : cols,
                            filter0: makeFilter([this.extraFilter0, this.filter0, extraFilters?.filter0]),
                            filter1: makeFilter([this.extraFilter1, this.filter1, extraFilters?.filter1]),
                            filter2: makeFilter([this.extraFilter2, this.filter2, extraFilters?.filter2]),
                            filter3: makeFilter([this.extraFilter3, this.filter3, extraFilters?.filter3]),
                            fields,
                            totals: this.totals,
                            expand: this.expand})}
                    }`)
            }

            query = `
                query {
                    dataset {
                        ${queries.join("\n")}
                    }
                }`

            let meta = {
                vars,
                dims,
                vals,
                cols,
                args,
                sort: this.clientSort && !exportToFile ? [] : sort,
                query,
                stream,
                source: sources[0],
                sources,
                columns,
                filters: {
                    filter0: this.filter0,
                    filter1: this.filter1,
                    filter2: this.filter2,
                    filter3: this.filter3,
                    extraFilter0: this.extraFilter0,
                    extraFilter1: this.extraFilter1,
                    extraFilter2: this.extraFilter2,
                    extraFilter3: this.extraFilter3,
                }
            }

            if (!forced &&
                !this.provider &&
                !exportToFile &&
                this.meta &&
                this.meta.stream === meta.stream &&
                _.isEqual(this.meta.vars, meta.vars) &&
                _.isEqual(this.meta.args, meta.args) &&
                _.isEqual(this.meta.sort, meta.sort) &&
                _.isEqual(this.meta.source, meta.source) &&
                _.isEqual(this.meta.filters, meta.filters) &&
                this.isSameFormulas(this.meta.dims, meta.dims) &&
                this.isSameFormulas(this.meta.vals, meta.vals) &&
                this.isSameFormulas(this.meta.cols, meta.cols))
            {
                if (this.clientSort && !_.isEmpty(sort)) {
                    this.data = this.alterRows(this.data, this.meta, (rows) => {

                        if (this.changeTypeFromStringToNumber) {
                            for (let i = 0; i < this.changeTypeFromStringToNumber.length; i++ ) {
                                rows.map((row) => row[this.changeTypeFromStringToNumber[i]] = Number(row[this.changeTypeFromStringToNumber[i]]));
                            }
                        }

                        return _.orderBy(
                            rows,
                            sort.map(i => `${Math.abs(i) - 1}`),
                            sort.map(i => i > 0 ? "asc" : "desc"));
                    })

                    if ((this.sortByGrades || this.sortByGradesWithoutGroups) && this.columns[this.selectedColumn]?.calc === 'grade') {
                        const { rows } = this.data.dataset.source.report;
                        let sortedRows = [];
                        let index = rows[0].indexOf('A');
                    
                        if (index === -1) {
                            index = rows[0].indexOf('C');
                        }

                        if (this.sortByGradesWithoutGroups) {
                            sortedRows = rows.sort((a, b) => {
                                if (sort[0] > 0 && a[index]?.length > b[index]?.length) {
                                    return -1;
                                } else if (sort[0] < 0 && a[index]?.length < b[index]?.length) {
                                    return -1;
                                } else {
                                    return 0;
                                };
                            });
                        } else {
                            const chunkSize = 4;
                            const chunkedArray = _.chunk(rows, chunkSize);

                            for (let i = 0; i < chunkedArray.length; i++) {
                                chunkedArray[i].sort((a, b) => {
                                    if (a[index]?.length > b[index]?.length) {
                                        return -1;
                                    } else {
                                        return 0;
                                    }
                                });
                                chunkedArray[i].forEach((array) => {
                                    sortedRows.push(array);
                                });
                            };
                        }

                        this.data.dataset.source.report.rows = sortedRows;
                    }

                    if (this.sortByRegions) {
                        const { rows, columns } = this.data.dataset.source.report;
                        const sortedData =  this.sortDataByRegions(rows, columns);

                        this.data.dataset.source.report.rows = sortedData;
                    }

                    if (this.changeTypeFromStringToNumber) {
                        // Revert type number to string after sorting
                        const { rows } = this.data.dataset.source.report;

                        for (let i = 0; i < this.changeTypeFromStringToNumber.length; i++ ) {
                            rows.map((row) => row[this.changeTypeFromStringToNumber[i]] = String(row[this.changeTypeFromStringToNumber[i]]));
                        }
                    }
                }

                this.meta = utils.deepFreeze(_.cloneDeep(meta))
                this.silent = null
                this.reportId = null
                this.progress = 1
                this.sorting = false
                this.downloading = false
                return
            }

            if (!exportToFile) {
                this.progress = 0
                this.sorting = false
                this.downloading = false
            }

            let ajaxOpts = {
                url: this.$attrs.id ? `/graphql?id=${this.$attrs.id}` : "/graphql",
                method: 'POST',
                data: JSON.stringify({query}),
                dataType: 'json',
                contentType: 'application/json',
                loadstart: () => {
                    if (reportId === this.reportId)
                        this.downloading = true
                },
                loadend: () => {
                    if (reportId === this.reportId)
                        this.downloading = false
                },
            }

            // // Убираем id, потому что только он может и отличаться, при совершенно одинаковых запросах
            const removeIdFromQuery = (dataQuery) => dataQuery.replace(/id: \\"[a-z0-9]+\\"./gm, '');
            let duplicateId = reportId;

            const currentPendingRequests = utils.getCurrentPendingRequests();
            
            const hasDuplicates = currentPendingRequests.some((request) => {
              const pendingData = removeIdFromQuery(request?.ajaxOpts?.data);
              const currentData = removeIdFromQuery(JSON.stringify({query}));

              if (pendingData === currentData && request?.ajaxOpts?.url === `/graphql?id=${this.$attrs.id}`) {
                duplicateId = request?.ajaxOpts?.data.match(/(?<=id: ")[a-z0-9]+(?=")/gm)?.[0];
                return true;
              }

              return false;
            });

            if (hasDuplicates) {
              return false;
            }

            return utils.scheduleRequest(this, ajaxOpts, this.instant, this.$attrs.id)
                .then(({data, errors}) => {
                    if (exportToFile) {
                        if (errors) {
                            errors = errors.filter(error => error.message != "report is cancelled")
                            if (errors.length) {
                                for (let error of errors)
                                    console.warn(error.message)
                            }
                        }
                        else {
                            let report = data.dataset.source.report
                            while (report.report)
                                report = report.report
                            file = _.clone(report.file)
                            let date = moment().format('YYYY-MM-DD')
                            file.name = `${this.caption ? this.caption : "export"}-${date}${file.link.replace(/^[^.]+/,"")}`
                            this.file = file
                        }
                    }
                    else {
                        if (this.reportId === reportId || duplicateId) {

                            if (errors) {
                                for (let error of errors)
                                    console.warn(error.message)

                                this.data = null
                                this.meta = null
                                this.errors = errors
                                this.sorting = false
                                this.progress = 1
                                this.reportId = null
                                this.silent = null
                            }
                            else {
                                data = this.alterRows(data, meta, (rows, columns) => {
                                    for (let i = 0; i < rows.length; ++i) {
                                        rows[i].key = `${reportId}-${i}`
                                        rows[i].__cache = {}
                                    }

                                    for (let i = 0; i < columns.length; ++i) {
                                        switch (columns[i].type) {
                                            case "date":
                                                for (let row of rows) {
                                                    let key = row[i]
                                                    if (key !== 0)
                                                        if (this.timeZone) {
                                                            let x = key.toString()
                                                            row[i] = new Date(`${x.slice(0,4)}-${x.slice(4,6)}-${x.slice(6,8)}T00:00:00${this.timeZone}`)
                                                        }
                                                        else {
                                                            row[i] = new Date(
                                                                key / 10000,
                                                                key / 100 % 100 - 1,
                                                                key / 1 % 100)
                                                        }
                                                    else
                                                        row[i] = null
                                                }
                                                break

                                            case "datetime":
                                                for (let row of rows) {
                                                    let key = row[i]
                                                    if (key !== 0) {
                                                        if (this.timeZone) {
                                                            let x = key.toString()
                                                            row[i] = new Date(`${x.slice(0,4)}-${x.slice(4,6)}-${x.slice(6,8)}T${x.slice(8,10)}:${x.slice(10,12)}:${x.slice(12,14)}${this.timeZone}`)
                                                        }
                                                        else {
                                                            row[i] = new Date(
                                                                key / 10000000000,
                                                                key / 100000000 % 100 - 1,
                                                                key / 1000000 % 100)
                                                            row[i].setHours(
                                                                key / 10000 % 100,
                                                                key / 100 % 100,
                                                                key / 1 % 100)
                                                        }
                                                    }
                                                    else
                                                        row[i] = null
                                                }
                                                break
                                        }
                                    }
                                    return rows
                                })

                                if (this.clientSort && !_.isEmpty(sort)) {
                                    data = this.alterRows(data, meta, (rows) => {
                                        if (this.changeTypeFromStringToNumber) {
                                            for (let i = 0; i < this.changeTypeFromStringToNumber.length; i++ ) {
                                                rows.map((row) => row[this.changeTypeFromStringToNumber[i]] = Number(row[this.changeTypeFromStringToNumber[i]]));
                                            }
                                        }

                                        return _.orderBy(
                                            rows,
                                            sort.map(i => `${Math.abs(i) - 1}`),
                                            sort.map(i => i > 0 ? "asc" : "desc"))
                                        })    
                                    
                                    if ((this.sortByGrades || this.sortByGradesWithoutGroups)) {
                                        const { rows } = data.dataset.source.report;
                                        let sortedRows = [];
                                        let index = rows[0].indexOf('A');
                                            
                                        if (index === -1) {
                                            index = rows[0].indexOf('C');
                                        }
                        
                                        if (this.sortByGradesWithoutGroups) {
                                            sortedRows = rows.sort((a, b) => {
                                                if (sort[0] > 0 && a[index]?.length > b[index]?.length) {
                                                    return -1;
                                                } else if (sort[0] < 0 && a[index]?.length < b[index]?.length) {
                                                    return -1;
                                                } else {
                                                    return 0;
                                                };
                                            });
                                        } else {
                                            const chunkSize = 4;
                                            const chunkedArray = _.chunk(rows, chunkSize);
                        
                                            for (let i = 0; i < chunkedArray.length; i++) {
                                                chunkedArray[i].sort((a, b) => {
                                                    if (a[index]?.length > b[index]?.length) {
                                                        return -1;
                                                    } else {
                                                        return 0;
                                                    }
                                                });
                                                chunkedArray[i].forEach((array) => {
                                                    sortedRows.push(array);
                                                });
                                            };
                                        }
                        
                                        data.dataset.source.report.rows = sortedRows;
                                    }

                                    if (this.sortByRegions) {
                                        const { rows,  columns } = data.dataset.source.report;
                                        const sortedData =  this.sortDataByRegions(rows, columns);
                
                                        data.dataset.source.report.rows = sortedData;
                                    }

                                    if (this.changeTypeFromStringToNumber) {
                                        // Revert type number to string after sorting
                                        const { rows } = data.dataset.source.report;
                
                                        for (let i = 0; i < this.changeTypeFromStringToNumber.length; i++ ) {
                                            rows.map((row) => row[this.changeTypeFromStringToNumber[i]] = String(row[this.changeTypeFromStringToNumber[i]]));
                                        }
                                    }
                                }

                                this.data = utils.deepFreeze(data)
                                this.meta = utils.deepFreeze(_.cloneDeep(meta))
                                this.errors = null
                                this.sorting = false
                                this.progress = 1
                                this.reportId = null
                                this.silent = null
                            }
                        }
                    }
                })
                .catch((error) => {
                    if (exportToFile) {
                        console.error("failed to export!", error)
                    }
                    else {
                        if (this.reportId === reportId) {
                            this.data = null
                            this.meta = null
                            this.errors = [error]
                            this.sorting = false
                            this.progress = 1
                            this.reportId = null
                            this.silent = null
                        }
                    }
                })
        },
        sortDataByRegions(rows, columns) {
            const sortOrder = [
                'Центральный регион',
                'Северо-Западный регион',
                'Поволжье',
                'Южный регион',
                'Урал',
                'Сибирь',
                'Дальний Восток',
                'Украина',
                'Грузия',
                'Белоруссия',
                'Казахстан',
                'ECommerce',
            ];
            const index = columns.findIndex(column => column.synonym === 'region');
            
            return _.sortBy( rows, (item) => _.indexOf(sortOrder, item[index]));
        },
        exportToFile(config, filters) {
            this.requestData({exportToFile:true, exportConfig:config, extraFilters:filters})
        },
        crossFiltersChanged() {
            this.crossFilters = this.$crossFilters
        },
        updateActiveReports() {
            if (this.reportId) {
                let activeReports = localStorage.activeReports ? JSON.parse(localStorage.activeReports) : {}
                activeReports[this.reportId] = _.now()
                localStorage.activeReports = JSON.stringify(activeReports)
            }
        },
    },
    mounted() {
        utils.bridge.bind("ws-message", this.handleWsMessage)
        utils.bridge.bind("beforeWindowUnload", this.beforeWindowUnload)
        utils.bridge.bind("crossFiltersChanged", this.crossFiltersChanged)
        this.updateActiveReportsTimer = setInterval(this.updateActiveReports, 1000)
        this.requestDataThrottled()
    },
    beforeDestroy: function() {
        this.cancelPendingReport()
        utils.bridge.unbind("ws-message", this.handleWsMessage)
        utils.bridge.unbind("beforeWindowUnload", this.beforeWindowUnload)
        utils.bridge.unbind("crossFiltersChanged", this.crossFiltersChanged)
        clearInterval(this.updateActiveReportsTimer)
        // if (requests[this._uid]) {
        //     requests[this._uid].xhr.abort()
        //     delete requests[this._uid]
        // }
    },
}
