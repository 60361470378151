<template>
	<div>
		<gp-stored family="reports" v-model="config"/>
		<gp-check v-model="checked">test</gp-check>
	</div>
</template>
<script>
module.exports = {
	data() {
		return {
			config: null,
			checked: null
		}
	},
	watch: {
		config(config) {
			if (config)
				this.checked = config.checked
		},
		checked(checked) {
			this.$set(this.config, "checked", checked)
		},
	}
}
</script>