var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "provider-card-container" },
    [
      _c("div", { staticClass: "header" }, [
        _c("p", { staticClass: "headerTitle" }, [
          _vm._v("Справочник поставщиков / Little Freddie (арт. LITT001)")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "search-form" }, [
          _c("input", {
            staticClass: "form-control form-control-sm",
            staticStyle: { "margin-right": "5px" },
            attrs: { value: "" }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-sm search-btn",
              attrs: { type: "button" },
              on: { click: _vm.onClickSearchBtn }
            },
            [_vm._v("Поиск")]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "gp-check",
        {
          attrs: { checked: _vm.isActiveCard },
          on: { change: _vm.handleChange }
        },
        [_c("l10n", { attrs: { value: "Активная карточка" } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "chain-type parameter" }, [
        _c(
          "p",
          { staticClass: "title" },
          [_c("l10n", { attrs: { value: "ID карточки" } })],
          1
        ),
        _vm._v(" "),
        _c("p", [_vm._v("000017")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "chain-type parameter" }, [
        _c(
          "p",
          { staticClass: "title" },
          [_c("l10n", { attrs: { value: "Артикул" } })],
          1
        ),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control form-control-sm",
          attrs: { value: "LITT001", placeholder: "Выберите артикул" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "chain-type stock-select parameter" }, [
        _c(
          "p",
          { staticClass: "title" },
          [_c("l10n", { attrs: { value: "Договоры" } })],
          1
        ),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control form-control-sm",
          attrs: {
            value: "LITT0012323; LITT0012211; LITT0013322",
            placeholder: "Выберите договор"
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary select-btn",
            attrs: { type: "button" },
            on: { click: _vm.onStockSelect }
          },
          [_vm._v("\n      Договоры >>>")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "chain-type stock-select parameter" }, [
        _c(
          "p",
          { staticClass: "title" },
          [_c("l10n", { attrs: { value: "Склады отгрузки" } })],
          1
        ),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control form-control-sm text-input",
          attrs: { value: "LF001", placeholder: "Выберите договор" }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary select-btn",
            attrs: { type: "button" },
            on: { click: _vm.onStockSelect }
          },
          [_vm._v("\n      Склады >>>\n    ")]
        )
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "h2",
        [
          _c("span", { staticStyle: { display: "none" } }, [_vm._v("Heading")]),
          _vm._v(" "),
          _c("l10n", { attrs: { value: "Условия обработки заказов" } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "gp-check",
        {
          attrs: { checked: _vm.differentStockContacts, disabled: true },
          on: { change: _vm.handleChange }
        },
        [_c("l10n", { attrs: { value: "Разные контакты по складам" } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "chain-type parameter" }, [
        _c(
          "p",
          { staticClass: "title" },
          [_c("l10n", { attrs: { value: "Контактное лицо" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.contact,
                expression: "contact"
              }
            ],
            staticClass: "form-control form-control-sm dropdown-select",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.contact = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c(
              "option",
              { attrs: { value: "in" } },
              [_c("l10n", { attrs: { value: "Carl Morris" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "eq" } },
              [_c("l10n", { attrs: { value: "Davie Jones" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "ne" } },
              [_c("l10n", { attrs: { value: "John Wick" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "ne" } },
              [_c("l10n", { attrs: { value: "Leonard Hoffstaedter" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "ne" } },
              [_c("l10n", { attrs: { value: "Malcolm McDowell" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "ne" } },
              [_c("l10n", { attrs: { value: "Michelle Pfifer" } })],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "chain-type parameter" }, [
        _c(
          "p",
          { staticClass: "title" },
          [_c("l10n", { attrs: { value: "Адрес приема заказов" } })],
          1
        ),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control form-control-sm",
          staticStyle: { width: "200px" },
          attrs: {
            value: "orders@littlefreddie.com",
            placeholder: "Выберите артикул"
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "edi" },
        [
          _c(
            "gp-check",
            { attrs: { checked: _vm.isEdi }, on: { change: _vm.handleChange } },
            [_c("l10n", { attrs: { value: "EDI" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-primary select-btn",
              staticStyle: { width: "140px" },
              attrs: { type: "button" },
              on: { click: _vm.onStockSelect }
            },
            [_vm._v("\n      Настройки EDI >>>\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "orderSchedule" }, [
        _vm._v("График приема заказов")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "weekdays" },
        [
          _c(
            "gp-check",
            {
              staticClass: "inline-checkbox",
              attrs: { checked: _vm.monday },
              on: { change: _vm.handleChange }
            },
            [_c("l10n", { attrs: { value: "Понедельник" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "gp-check",
            {
              staticClass: "inline-checkbox",
              attrs: { checked: _vm.tuesday },
              on: { change: _vm.handleChange }
            },
            [_c("l10n", { attrs: { value: "Вторник" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "gp-check",
            {
              staticClass: "inline-checkbox",
              attrs: { checked: _vm.wednesday },
              on: { change: _vm.handleChange }
            },
            [_c("l10n", { attrs: { value: "Среда" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "gp-check",
            {
              staticClass: "inline-checkbox",
              attrs: { checked: _vm.thursday },
              on: { change: _vm.handleChange }
            },
            [_c("l10n", { attrs: { value: "Четверг" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "gp-check",
            {
              staticClass: "inline-checkbox",
              attrs: { checked: _vm.friday },
              on: { change: _vm.handleChange }
            },
            [_c("l10n", { attrs: { value: "Пятница" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "gp-check",
            {
              staticClass: "inline-checkbox",
              attrs: {
                checked: _vm.saturday,
                disabled: !_vm.areWeekEndDaysActive
              },
              on: { change: _vm.handleChange }
            },
            [_c("l10n", { attrs: { value: "Суббота" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "gp-check",
            {
              staticClass: "inline-checkbox",
              attrs: {
                checked: _vm.sunday,
                disabled: !_vm.areWeekEndDaysActive
              },
              on: { change: _vm.handleChange }
            },
            [_c("l10n", { attrs: { value: "Воскресенье" } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "weekdays" },
        [
          _c(
            "gp-check",
            {
              staticClass: "inline-checkbox",
              attrs: { checked: _vm.weekends },
              on: { change: _vm.handleChange }
            },
            [_c("l10n", { attrs: { value: "Выходные" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "gp-check",
            {
              staticClass: "inline-checkbox",
              attrs: { checked: _vm.holidays },
              on: { change: _vm.handleChange }
            },
            [_c("l10n", { attrs: { value: "Праздничные дни" } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "chain-type parameter time-select-container" }, [
        _c(
          "p",
          { staticClass: "title" },
          [_c("l10n", { attrs: { value: "Время отправки заказов (до):" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.departureTime,
                expression: "departureTime"
              }
            ],
            staticClass: "form-control form-control-sm time-select",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.departureTime = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c(
              "option",
              { attrs: { value: "ne" } },
              [_c("l10n", { attrs: { value: "10.00" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "in" } },
              [_c("l10n", { attrs: { value: "12.00" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "eq" } },
              [_c("l10n", { attrs: { value: "16.00" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "ne" } },
              [_c("l10n", { attrs: { value: "20.00" } })],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c("p", { staticClass: "metaData" }, [
          _vm._v(
            "Создано 04/03/2023   Автор: Tatyana I.    Последнее изменение: 11/03/2023"
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "button-block" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-primary select-btn",
              staticStyle: { "margin-right": "5px" },
              attrs: { type: "button" },
              on: { click: _vm.onStockSelect }
            },
            [_vm._v("\n        Дублировать\n      ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-primary select-btn",
              attrs: { type: "button" },
              on: { click: _vm.onStockSelect }
            },
            [_vm._v("\n        Сохранить\n      ")]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }