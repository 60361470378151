<template>
    <div ref="editor" :style="{
        width,
        height,
        minHeight: computedHeight
    }">
    <small v-if="!editor" class="text-muted" style="padding:20px">Initializing...</small>
    </div>
</template>
<script>
let utils = require("./my-utils")
// var Ace = require('ace-builds/src-noconflict/ace')
// require("ace-builds/src-noconflict/theme-chrome")
// require("ace-builds/src-noconflict/theme-clouds_midnight")
// require("ace-builds/src-noconflict/mode-yaml")
// require("ace-builds/src-noconflict/mode-markdown")
// require("ace-builds/src-noconflict/mode-plain_text")
// require("ace-builds/src-noconflict/mode-assembly_x86")
// // require("ace-builds/src-noconflict/mode-graphqlschema")
// require("ace-builds/src-noconflict/ext-language_tools")

module.exports = {
    model: {
        prop: "value",
        event: "change"
    },
    props: {
        value: {
            type: String,
            // required: true
        },
        options: {
            type: Object,
            default: () => ({
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                highlightActiveLine: false,
                enableLinking: true,
                tabSize: 2,
                // autoScrollEditorIntoView: true,
                // showLineNumbers: false
            })
        },
        mode:   { default: "plain_text" },
        theme:  { default: undefined },
        width:  { default: "100%" },
        height: { default: "100%" },
        autoHeight: { default: false },
        readOnly: { default: false },
        persistKey: { type: String },
        commands: { type: Array, default: () => [] },
        removeCommand: { type: Array, default: () => [] },
        getCompletions: { type: Function, default: null },
        snippets: { type: Array, default: () => [] },
        darkTheme: { type: Boolean, default: false },
    },
    data: function () {
        return {
            editor: null,
            valueBackup: "",
            computedHeight: null
        }
    },
    computed: {
        computedTheme() {
            return this.theme || this.darkTheme ? "clouds_midnight" : "chrome"
        }
    },
    mounted: function() {
        let self = this

        import('./ace').then(({Ace}) => {
            let editor = Ace.edit(this.$refs.editor)
            this.editor = editor

            editor.setValue(this.value, 1)
            editor.setTheme(`ace/theme/${this.computedTheme}`)
            editor.session.setMode(`ace/mode/${this.mode}`)
            editor.session.setUseWrapMode(true)
            editor.setReadOnly(this.readOnly)
            editor.setOptions(this.options)
            // editor.renderer.setShowGutte(false)
            this.valueBackup = this.value

            if (this.persistKey && localStorage[this.persistKey]) {
                try {
                    let state = JSON.parse(localStorage[this.persistKey])
                    editor.moveCursorTo(state.cursor.row, state.cursor.column)
                    editor.session.setScrollTop(state.scrollTop)
                    editor.session.setScrollLeft(state.scrollLeft)
                    editor.session.getSelection().setRange(state.range)
                }
                catch (ex) {
                    console.warn(ex)
                }
            }

            if (!_.isEmpty(this.snippets)) {
                let snippetManager = ace.require("ace/snippets").snippetManager
                for (let snippet of this.snippets)
                    snippetManager.insertSnippet(editor, snippet)
            }


            // const snippet =
            //   '# AddNode\n\
            // snippet addn\n\
            //     {\n\
            //         "nodeName": "${1:node_name}",\n\
            //         "algorithmName": "${2:algo_name}",\n\
            //         "input": []\n\
            //     }\n\
            // ';

            if (this.mode == "javascript")
                editor.session.$worker?.call("changeOptions", [{
                    asi: true,
                    esversion: 9
                }])

            for (let command of this.removeCommand)
                editor.commands.removeCommand(command)

            for (let command of this.commands)
                editor.commands.addCommand(command)

            editor.on("change", () => {
                if (this.muted)
                    return
                var value = editor.getValue()
                self.$emit("input", value)
                self.$emit("change", value)
                self.valueBackup = value

                if (this.autoHeight) {
                    this.computedHeight = this.computeHeight()
                    this.$nextTick(() => this.editor.resize())
                }
            });

            let saveState = () => {
                if (this.persistKey) {
                    localStorage[this.persistKey] = JSON.stringify({
                        cursor: editor.session.getSelection().getCursor(),
                        range: editor.session.getSelection().getRange(),
                        scrollTop: editor.session.getScrollTop(),
                        scrollLeft: editor.session.getScrollLeft(),
                    })
                }
            }

            editor.session.selection.on("changeCursor", saveState)
            editor.session.on("changeScrollTop", saveState)
            editor.session.on("changeScrollLeft", saveState)

            editor.on("linkClick", (data) =>
                self.$emit("link-click", data))

            if (this.getCompletions !== null)
                editor.completers.push({ getCompletions: this.getCompletions })


            if (this.autoHeight) {
                this.computedHeight = this.computeHeight()
                this.$nextTick(() => this.editor.resize())
            }

            editor.session.getUndoManager().reset()

            this.$emit("loaded", editor)

        })
    },
    beforeDestroy: function() {
        if (this.editor) {
            this.editor.destroy()
            this.editor.container.remove()
        }
    },
    watch: {
        value() {
            value = this.value || ""
            if(this.valueBackup !== value) {
                if (this.editor) {
                    this.muted = true
                    this.editor.session.setValue(value, 1)
                    this.muted = false
                }
                this.valueBackup = value
                if (this.autoHeight) {
                    this.computedHeight = this.computeHeight()
                    this.$nextTick(() => this.editor?.resize())
                }
            }
        },
        width() {
            this.$nextTick(function() {
                this.editor.resize()
            })
        },
        height() {
            this.$nextTick(() => this.editor.resize())
        },
        mode() {
            this.editor.session.setMode(`ace/mode/${this.mode}`)
        },
        readOnly() {
            this.editor.setReadOnly(this.readOnly)
        },
        computedHeight() {
            $(this.$refs.editor).css("min-height", `${this.computedHeight + 2}px`)
        },
        computedTheme() {
            this.editor.setTheme(`ace/theme/${this.computedTheme}`)
        },
    },
    methods: {
        computeHeight() {
            let height =
                this.editor.getSession().getScreenLength() *
                this.editor.renderer.lineHeight +
                this.editor.renderer.scrollBar.getWidth()
            return height
        },
    },
}
</script>

<style>
.ace-clouds-midnight .ace_keyword,
.ace-clouds-midnight .ace_meta,
.ace-clouds-midnight .ace_support.ace_constant.ace_property-value {
    color: rgb(52,209,197);
}

.ace-clouds-midnight .ace_keyword,
.ace-clouds-midnight .ace_meta,
.ace-clouds-midnight .ace_function,
.ace-clouds-midnight .ace_support.ace_constant.ace_property-value {
    color: rgb(88,167,202);
}
.ace-clouds-midnight .ace_constant {
    color: rgb(52,209,197);
}
.ace-clouds-midnight {
    color: rgb(189,198,207);
}
.ace-clouds-midnight .ace_string,
.ace-clouds-midnight .ace_register {
    color: rgb(250,160,83);
}
.ace-clouds-midnight .ace_gutter {
/*    background: #191919;
    border-right: 1px solid #444;
*/
}
.ace-clouds-midnight .ace_marker-layer .ace_selection {
    background: rgb(90,127,165);
}
</style>