<template>
<td
    :class="classes"
    :style="style"
    :title="title"
    :data-column="column.i"
    :data-type="column.type"
    :data-name="column.name"
    >
    <a  v-if="column.actionable && actionicon && !noAction"
        class="my-colum-action"
        href="javascript:void(0)">
        <feather-icon :name="actionicon"/>
    </a>
    <label v-if="label">{{label}}</label>
    <span v-if="column.actionable && !actionicon && !noAction && !totals">
        <a href="javascript:void(0)">
            {{formattedValue}}
        </a>
    </span>
    <span v-else-if="actionlink">
        <a  :href="actionlink"
            :target="actionlink.startsWith('http') ? '_blank' : ''">
            {{formattedValue}}
        </a>
    </span>
    <span v-else-if="column.type == 'html'" v-html="formattedValue"/>
    <span v-else-if="isLink && !noLink">
        <a :href="value" target="_blank">
            {{formattedValue}}
        </a>
    </span>
    <span v-else>{{formattedValue}}</span>
</td>
</template>
<script>
let utils = require('./my-utils')

module.exports = {
    props: {
        label:      { type: String, default: null },
        totals:     { type: Boolean, default: false },
        meta:       { type: Object },
        row:        { type: Array },
        column:     { type: Object },
        headerWithoutSum: {
            type: Boolean,
            required: false,
            default: false,
        },
        section:    { type: Object },
        height:     { type: Number },
        noStyle:    { type: Boolean, default: false },
        noClass:    { type: Boolean, default: false },
        noAction:   { type: Boolean, default: false },
        noLink:     { type: Boolean, default: false },
    },
    computed: {
        info() {
            let {row, column, meta} = this
            return {row, column, meta}
        },
        value() {
            let value = undefined
            if (this.totals)
                value = this.row[this.column.i - this.meta.dims.length]
            else
                value = this.row[this.column.i]
            if (this.column.override)
                value = this.column.override({
                    row: this.row,
                    column: this.column,
                    totals: this.totals,
                    value
                })
            return value
        },
        formattedValue() {
            if (this.headerWithoutSum && !this.value) {
                return '***';
            }
            
            return this.column.format(
                this.value,
                this.row,
                this.column,
                this.meta)
        },
        actionlink() {
            return this.column.actionlink ?
                this.column.actionlink(this.row, this.column, this.meta) : null
        },
        actionicon() {
            return this.column.actionicon ?
                this.column.actionicon(this.row, this.column, this.meta) : null
        },
        style() {
            if (this.noStyle)
                return null
            let style = this.column.style(
                this.value, this.row, this.column, this.meta)
            if (this.height !== undefined) {
                if (!style)
                    style = {}
                style.height = `${this.height}px`
            }
            return style
        },
        title() {
            return this.column.title(this.value, this.row, this.column, this.meta)
        },
        classes() {
            if (this.noClass)
                return null
            let {column, meta, row} = this
            let {visibleColumns} = this.section
            let classes = {
                "section-start": visibleColumns[0] === column,
                "section-end": visibleColumns[visibleColumns.length-1] === column,
                "my-column-editable": !this.totals && column.editable && column.editable(row, column, meta),
                "my-column-actionable": !this.totals && column.actionable,
            }

            if (column.className)
                classes[column.className] = true

            if (column.columnClass) {
                let class_ = column.columnClass(this.value, row, column, meta)
                if (class_)
                    classes[class_] = true
            }

            return classes
        },
        isLink() {
            return _.startsWith(this.value, "http")
        },
    },
}
</script>
<style>
    
</style>