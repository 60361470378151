<template>
    <my-dialog
        title="Export settings"
        :large="true"
        @close="$emit('close')">
        <div class="pim-export-dialog">
            <div class="form-group">
                <label>File name</label>
                <input class="form-control form-control-sm" v-model="fileName"/>
            </div>
            <div class="form-group">
                <gp-check v-model="includeParentChild">
                    Include parent-child relationships
                </gp-check>
                <gp-check v-model="exportChildren" v-if="entry.childrenType">
                    Export child {{pluralize(entry.childrenType)}}
                </gp-check>
                <gp-check v-model="exportEditable">
                    Export all editable attributes
                </gp-check>
                <gp-check v-model="exportMandatry">
                    Export all mandatory attributes
                </gp-check>
            </div>
            <div class="form-group pim-export-dialog-columns">
                <div>
                    <label>{{capitalize(pluralize(entry.type))}} attributes to include</label>
                    <pim-columns
                        :type="entry.type"
                        :fields="fields"
                        />
                </div>
                <div v-if="entry.childrenType && exportChildren">
                    <label>{{capitalize(pluralize(entry.childrenType))}} attributes to include</label>
                    <pim-columns
                        :type="entry.childrenType"
                        :fields="fields"
                        />
                </div>
            </div>
        </div>
        <template slot="footer">
            <button class="btn btn-primary" @click="$emit('close')">Export</button>
            <button class="btn btn-secondary" @click="$emit('close')">Cancel</button>
        </template>
    </my-dialog>
</template>
<script>
module.exports = {
    mixins: [
        require("./pim-helper.js")
    ],
    props: {
        entry: { type: Object },
    },
    data() {
        return {
            fileName: this.capitalize(this.pluralize(this.entry.type)) + " attributes.xlsx",
            includeParentChild: false,
            exportChildren: true,
            exportEditable: false,
            exportMandatry: true,
            columns: [],
        }
    }
}
</script>