<template>
  <table class="hierarchy-table">
    <thead>
      <tr class="hierarchy-table__row">
        <td
          v-for="column in tableColumns"
          :key="column.title"
          class="hierarchy-table__cell head-cell"
        >
          <a
            href="#"
            @click.prevent
            class="hierarchy-table__head-title"
          >
            {{ column.title }}
          </a>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, rowIndex) in rows"
        :key="`${row.nodeType}_${rowIndex}_${row.id}`"
        class="hierarchy-table__row"
        :class="{ 'hierarchy-table__row_leaf': row.nodeType === 'leaf' }"
        @click="row.nodeType === 'leaf' && handleLeafClick(row.id)"
      >
        <hierarchy-link
          v-if="row.level"
          :level="row.level"
          :isLastRowOnLevel="isLastRowOnLevel({ row, rows, rowIndex })"
          :isRestRowsHasNodes="isRestRowsHasNodes({ row, rows, rowIndex })"
        />
        <td
          v-for="(column, columnIndex) in tableColumns"
          :key="column.title"
          class="hierarchy-table__cell"
          :style="columnIndex === 0 ? { 'padding-left': `${getRowLeftSpacing(row.level)}px` } : null"
        >
          <node-button
            v-if="!columnIndex && row.nodeType === 'node'"
            :isOpen="row.isOpen"
            @click="handleNodeClick({ row, rowIndex })"
          />
          <span>
            {{ getValueOfCell(column.field, row) }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
const {
  getTableRows,
  insertRows,
  removeRows,
  toggleOpenNode,
  getFullPath,
  isLastRowOnLevel,
  isRestRowsHasNodes,
} = require('../utils/hierarchy');

const NodeButton = require('../ui-kit/node-button.vue').default;
const HierarchyLink = require('../ui-kit/hierarchy-link.vue').default;

module.exports = {
  components: {
    NodeButton,
    HierarchyLink,
  },

  props: {
    tableColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
    leafsField: {
      type: String,
      required: true,
    },
    endpoint: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      rows: [],
      isLoading: false,
    };
  },

  methods: {
    // eslint-disable-next-line consistent-return
    async getNodes(id) {
      const URL = this.getFullPath({
        path: this.endpoint,
        id,
      });

      try {
        this.isLoading = true;
        const response = await fetch(URL);

        if (response.ok) {
          return await response.json();
        }
      } catch (error) {
        this.createNotification(error.message, 'error');
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },
    async handleNodeClick({ row, rowIndex }) {
      if (this.isLoading) {
        return;
      }

      if (!row.isOpen) {
        const options = {
          parentRowIds: row.parentIds?.length
            ? [...row.parentIds, row.id].filter((id) => id) : [row.id],
          parentRowLevel: row?.level,
          leafsFieldName: this.leafsField,
        };

        const childCollection = await this.getNodes(row.id);
        const childRows = this.getTableRows(childCollection, options);
        this.rows = this.insertRows({ rows: this.rows, childRows, rowIndex });
      } else {
        this.rows = this.removeRows({ rows: this.rows, row, rowIndex });
      }

      this.rows = this.toggleOpenNode({ rows: this.rows, rowIndex });
    },
    handleLeafClick(id) {
      if (!id) return;
      this.$emit('onOpenItem', id);
    },
    getRowLeftSpacing(lvl) {
      return ((lvl * 18) + 8);
    },

    getValueOfCell(handler, row) {
      if (typeof handler === 'function') {
        return handler(row);
      }
      return row[handler];
    },
    // hierarchy methods
    getTableRows,
    insertRows,
    removeRows,
    toggleOpenNode,
    getFullPath,
    isLastRowOnLevel,
    isRestRowsHasNodes,
  },

  async created() {
    const nodes = await this.getNodes();
    const childRows = this.getTableRows({ nodes });
    this.rows = this.insertRows({ rows: this.rows, parentRowId: 0, childRows });
  },
};
</script>

<style>
  .hierarchy-table {
    width: 100%;
    border-collapse: collapse;
  }

  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #E9E9E9;
    border-radius: 10px;
    background-clip: content-box;
    border: 2px solid transparent;
    min-height: 50px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #5C606F;
  }

  .head-cell {
    min-height: 52px;
    background-color: #F7F7F7;
    border: 1px solid #E9E9E9;
    outline: none;
  }

  .hierarchy-table__cell {
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 8px;
    gap: 8px;
  }

  .hierarchy-table__cell:first-child {
    min-width: 250px;
  }

  .hierarchy-table__head-title {
    color: #3385FF;
  }

  .hierarchy-table__head-title:hover {
    text-decoration: none;
  }

  .hierarchy-table__row {
    display: flex;
    cursor: pointer;
    position: relative;
    z-index: 0;
  }

  .hierarchy-table__row:hover {
    background-color: #CCE5FF;
  }

  .hierarchy-table__row_leaf {
    background-color: #F4FAFF;
  }
</style>
