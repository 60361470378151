<template>
    <div class="pim-hierarchies-side">
        <div class="pim-hierarchies-side-main">
            <template v-if="mode != 'filters' && mode != 'strategies'">
                <template v-if="entries && entries.length > 1 && mode == 'attributes'">
                    <div class="alert alert-info">
                        Multiple categories selected.<br>
                        Bulk editing mode activated.
                    </div>
                </template>
                <template v-else-if="entry">
                    <h2>
                        <a href="javascript:void(0)" style="float:right" @click="changeName">
                            <feather-icon name="edit"/>
                        </a>
                        {{entry.name || '[name]'}}
                    </h2>
                    <p>
                        <a href="javascript:void(0)" style="float:right" @click="changeDesc">
                            <feather-icon name="edit"/>
                        </a>
                        {{entry.desc || '[description]'}}
                    </p>
                </template>
                <p v-else>
                    Please select a category from the hierarchy one the left side.
                </p>
            </template>

            <pim-attributes
                v-if="entries && entries.length > 0 || entry"
                v-show="mode == 'attributes'"
                stream="categories"
                :focus="entry"
                :entries="entries && entries.length ? entries : [entry]"
                :search="search"
                :fields="fields"
                :username="username"
                />

            <div v-if="entry && entry.type == 'category'" v-show="mode == 'assignment'">
                <h3>Business rules</h3>
                <ul class="pim-hierarchies-rules">
                    <li v-for="rule in entryRules" :key="rule.id" class="pim-hierarchies-rule" :id="rule.id">
                        <div class="pim-hierarchies-rule-head">
                            <a style="float: right;" href="javascript:void(0)" @click="showRuleMenu = rule">
                                <feather-icon name="more-vertical"/>
                            </a>
                            <a href="javascript:void(0)" @click="$set(rule, 'opened', !rule.opened)">
                                <feather-icon :name="rule.opened ? 'chevron-down' : 'chevron-right'"/>
                                {{rule.name || '[name]'}}
                            </a>
                        </div>
                        <div v-if="rule.opened" class="pim-hierarchies-rule-body">
                            <div class="form-group" v-if="$refs.strategies">
                                <label>Strategy</label>
                                <gp-select :options="$refs.strategies.savedConfigs" v-model="rule.strategy"/>
                            </div>
                            <div class="form-group">
                                <label>
                                    <gp-check v-model="rule.hasItemFilter">Item filter</gp-check>
                                </label>
                                <gp-filter
                                    v-if="rule.hasItemFilter"
                                    v-model="rule.itemFilter"
                                    stream="items"
                                    :formulas="config.formulas"
                                    :attributes="config.attributes"
                                    />
                                <gp-check
                                    v-if="rule.hasItemFilter"
                                    v-model="rule.itemFilterSetAsPrimary">
                                    Set as primary category
                                </gp-check>
                            </div>
                            <div class="form-group">
                                <label>
                                    <gp-check v-model="rule.hasVariationFilter">Variation filter</gp-check>
                                </label>
                                <gp-filter
                                    v-if="rule.hasVariationFilter"
                                    v-model="rule.variationFilter"
                                    stream="items"
                                    :formulas="config.formulas"
                                    :attributes="config.attributes"
                                    />
                                <gp-check
                                    v-if="rule.hasVariationFilter"
                                    v-model="rule.variationFilterSetAsPrimary">
                                    Set as primary category
                                </gp-check>
                            </div>
                        </div>
                    </li>
                </ul>
                <a href="javascript:void(0)" @click="addRule">
                    <feather-icon name="plus"/>
                    Add business rule
                </a>
                <div class="pim-hierarchies-rules-actions">
                    <button class="btn btn-sm btn-primary" @click="submitRules" :disabled="!rulesChanged">
                        Submit changes
                    </button>
                    <button class="btn btn-sm btn-secondary" @click="discardRules" :disabled="!rulesChanged">
                        Discard changes
                    </button>
                </div>
            </div>
            <div v-show="mode == 'filters'">
                <gp-stored
                    family="filters"
                    :username="username"
                    :config="filter"
                    @saved="filter = $event"
                    @change="filter = $event"
                    ref="filters"/>
                <template v-if="filter">
                    <div class="form-group">
                        <label>Conditions</label>
                        <gp-filter
                            v-model="filter.filter"
                            stream="items"
                            :formulas="config.formulas"
                            :attributes="config.attributes"
                            />
                    </div>
                </template>
            </div>
            <div v-show="mode == 'strategies'">
                <gp-stored family="strategies" :username="username" v-model="strategy" ref="strategies"/>
                <template v-if="strategy">
                    <div class="form-group">
                        <label>Date start</label>
                        <input class="form-control form-control-sm" type="datetime-local" v-model="strategy.dateStart">
                    </div>
                    <div class="form-group">
                        <label>Date end</label>
                        <input class="form-control form-control-sm" type="datetime-local" v-model="strategy.dateEnd">
                    </div>
                    <div class="form-group" v-if="$refs.filters">
                        <label>Item filter</label>
                        <gp-select :options="$refs.filters.savedConfigs" v-model="strategy.itemFilter"/>
                    </div>
                    <div class="form-group" v-if="$refs.filters">
                        <label>Variation filter</label>
                        <gp-select :options="$refs.filters.savedConfigs" v-model="strategy.variationFilter"/>
                    </div>
                </template>
            </div>
            <div
                class="pim-hierarchies-side-live"
                v-if="entry && entry.type == 'category' && (mode == 'assignment' || mode == 'filters' || mode == 'strategies')">
                <h3>Live preview</h3>
                <div class="form-inline" v-if="mode == 'assignment'">
                    <div class="form-group">
                        <label>Simulation date</label>
                        <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="simulationDay">
                    </div>
                </div>
                <plain-table
                    style="--tile-size: 100px"
                    stream="items"
                    :dims="['key']"
                    :vals="[{
                        calc: 'image',
                        type: 'html',
                        name: 'Item Image',
                        format: x => x ? `<img src='${x}'>` : '',
                    }, {
                        calc: 'name',
                        name: 'Item Name',
                    }]"
                    :initialSort="[1]"
                    :filter2="previewFilter"
                    />
            </div>
        </div>
        <my-popup
            v-if="showRuleMenu"
            :draggable="true"
            placement="bottom"
            @clickoutside="showRuleMenu = null"
            :anchor="`#${showRuleMenu.id} .feather-icon-more-vertical`">
            <div class="popover pim-hierarchies-menu">
                <div class="popover-body">
                    <label>{{showRuleMenu.name}}</label>
                    <ul>
                        <li>
                            <a href="javascript:void(0)" @click="previewRuleVariations(showRuleMenu); showRuleMenu = null;">
                                <feather-icon name="list"/>Preview items
                            </a>
                            <a href="javascript:void(0)" @click="previewRuleVariations(showRuleMenu); showRuleMenu = null;">
                                <feather-icon name="list"/>Preview variations
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" @click="runRule(showRuleMenu); showRuleMenu = null;">
                                <feather-icon name="play"/>Run strategy now
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" @click="changeRuleName(showRuleMenu); showRuleMenu = null;">
                                <feather-icon name="edit"/>Rename this rule
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" @click="removeRule(showRuleMenu); showRuleMenu = null;">
                                <feather-icon name="trash"/>Remove this rule
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </my-popup>
        <gp-data
            v-if="(entry || entries && entries.find(entry => entry.type == 'category')) && fields"
            stream="categories"
            :filter2="`id in [
                ${(entries || [entry])
                    .filter(entry => entry.type == 'category')
                    .map(entry => `'${entry.id}'`).join(',')}]`"
            :dims="['id']"
            :vals="fields.map(row => row.key)"
            v-model="valuesReport"
            :throttled="false"
            />
    </div>
</template>
<script>
let utils = require("../my-utils")

module.exports = {
    props: {
        mode: { type: String },
        entry: { type: Object },
        entries: { type: Array },
        username: { type: String },
        // config: { type: Object },
        search: { type: Object },
        fields: { type: Array },
    },
    data() {
        return {
            filter: null,
            strategy: null,
            filterPreviewType: "items",
            showRuleMenu: null,
            entryRules: [],
            valuesReport: null,
            searchString: "",
            attributes: {},
            showEmptyValues: false,
            simulationDay: moment().format("YYYY-MM-DD"),
        }
    },
    watch: {
        entry(newEntry, oldEntry) {
            if (newEntry && newEntry?.id != oldEntry?.id)
                this.entryRules = _.cloneDeep(newEntry.rules) || []
        },
        valuesReport() {
            if (this.valuesReport.rows.length == 0) {
                this.attributes = {}
                return
            }

            let keys = ['id'].concat(this.fields.map(field => field.key))
            let vals = _.unzip(this.valuesReport.rows)
            let types = ['string'].concat(this.fields.map(field => field.type))

            for (let i=0; i<vals.length; ++i) {
                if (types[i] == "date")
                    vals[i] = vals[i].map(x => x ? moment(new Date(x)).format("YYYY-MM-DD") : "")
                if (types[i] == "datetime")
                    vals[i] = vals[i].map(x => x ? moment(new Date(x)).format("YYYY-MM-DDThh:mm:ss") : "")
            }

            let attributes = {}
            let same = vals.map(xs => xs.every(x => x == xs[0]))

            for ([key, vals, same] of _.zip(keys, vals, same)) {
                attributes[key] = {
                    vals,
                    mixed: !same ? _.sortBy(_.uniq(vals)) : null,
                    edited: undefined,
                }
            }
            this.attributes = attributes
        },
        values: {
            deep: true,
            handler() {

            },
        }
    },
    computed: {
        config() {
            let attributes = []
            let metrics = []
            let formats = {}
            let timeframes = {}
            let formulas = {}
            for (let field of this.fields) {
                if (field.group_name == "Item Attribute") {
                    if (field.field_type == "alphanumeric") {
                        attributes.push({
                            name: field.description || field.field_name,
                            calc: field.api_name,
                        })
                    }
                }
            }
            return {
                attributes,
                metrics,
                formats,
                timeframes,
                formulas,
            }
        },
        cursor() {
            if (this.entries) {
                let cursor = this.entries.indexOf(this.entry)
                return cursor != -1 ? cursor : 0
            }
            else return 0
        },
        fieldsFuse() {
            return new Fuse(this.fields, this.search)
        },
        visibleFields() {
            return this.searchString ?
                this.fieldsFuse
                    .search(this.searchString)
                    .map(entry => {
                        entry.formattedName =
                            utils.formatSearchItem(
                                entry.item.name,
                                entry.matches.filter(match => match.key == "name"))
                        return entry
                    }) : this.fields.map(field => ({item: field}))
        },
        rulesChanged() {
            return this.entry ? !_.isEqual(this.entry.rules, this.entryRules) : false
        },
        attributesChanged() {
            return _(this.attributes).values().some(attribute => attribute.edited !== undefined)
        },
        previewFilter() {
            let filter = null

            if (this.mode == "filters")
                filter = this.makeFilter(this.filter.filter)
            if (this.mode == "strategies")
                filter = this.makeFilter(this.strategy.itemFilter?.filter)
            if (this.mode == "assignment") {
                let filters = []
                for (let rule of this.entryRules) {
                    if (!rule.strategy ||
                        (!rule.strategy.dateStart ||
                        rule.strategy.dateStart <= this.simulationDay + 'T23:59:59' &&
                        !rule.strategy.dateEnd ||
                        rule.strategy.dateEnd >= this.simulationDay + 'T00:00:00'))
                    {
                        filters.push(utils.makeFilter([
                            this.makeFilter(rule.strategy?.itemFilter?.filter),
                            this.makeFilter(rule.hasItemFilter && rule.itemFilter)]))
                    }
                }
                filter = filters.join(" || ")
            }

            return filter
        },
    },
    methods: {
        makeFilter(filter) {
            return _(filter)
                .map((condition) =>
                    _(condition)
                        .toPairs()
                        .filter(([key, value]) => key && value)
                        .map(([key, value]) => 
                            value.length == 1
                                ? `(${key}) == ${utils.quote(value[0])}`
                                : `(${key}) in ${utils.quote(value)}`)
                        .join(" && "))
                .filter()
                .join(" || ")
        },
        addRule() {
            let name = window.prompt("Please enter rule name:")?.trim()
            if (name !== undefined) {
                this.entryRules.push({
                    id: utils.randomId(),
                    name,
                    opened: true,
                    strategy: null,
                    itemFilter: null,
                    variationFilter: null,
                })
            }
        },
        removeRule(rule) {
            if (window.confirm(`Are you sure you want to remove rule ${rule.name}?`)) {
                let i = this.entryRules.indexOf(rule)
                this.entryRules.splice(i, 1)
            }
        },
        submitRules() {
            this.$set(this.entry, "rules", _.cloneDeep(this.entryRules))
        },
        discardRules() {
            this.entryRules = _.cloneDeep(this.entry.rules)
        },
        changeName() {
            let name = window.prompt("Please enter category name:", this.entry.name)?.trim()
            if (name !== undefined)
                this.$set(this.entry, "name", name)
        },
        changeDesc() {
            let desc = window.prompt("Please enter category description:", this.entry.desc)?.trim()
            if (desc !== undefined)
                this.$set(this.entry, "desc", desc)
        },
        changeRuleName(rule) {
            let name = window.prompt("Please enter business rule name:", rule.name)?.trim()
            if (name !== undefined)
                this.$set(rule, "name", name)
        },
        async submitAttributes() {
            let changes = {}
            for (let key of _.keys(this.attributes)) {
                let attribute = this.attributes[key]
                let value = attribute.edited
                if (value !== undefined) {
                    changes[key] = value
                    this.$set(attribute, "vals", attribute.vals.map(() => value))
                    this.$delete(attribute, "edited")
                }
            }

            if (_.isEmpty(changes))
                return

            let actions = []
            let createUser = this.username
            let createTime = new Date().toISOString().split(".")[0]
            for (let key of _.keys(changes)) {
                let field = this.fields.find(field => field.key == key)
                let stream = `attr_category_${key}`
                let value = changes[key]
                let records = []
                for (let entry of this.entries || [this.entry]) {
                    records.push([
                        "manual",
                        0,
                        entry.id,
                        changes[key],
                        createTime,
                        createUser,
                        createTime,
                        createUser
                    ])
                    let attr = entry.attrs.find(attr => attr.key == key)
                    if (attr)
                        this.$st(attr, "val", value)
                }
                actions.push(
                    `\t${key}:appendRecords(
                        stream: ${utils.quote(stream)},
                        format: "json",
                        records: ${utils.quote(JSON.stringify(records))})`)
            }

            if (_.isEmpty(actions))
                return
            let query = `mutation {\n${actions.join("\m")}}`

            await fetch("/graphql", {
                method: "POST",
                body: JSON.stringify({query}),
                headers: {"Content-Type": "application/json"},
            })
        },
        discardAttributes() {
            for (let attribute of _.values(this.attributes))
                if (attribute.edited !== undefined)
                    this.$delete(attribute, "edited")
        },
    },
}
</script>