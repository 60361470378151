<template>
  <div class="productcard-container">
    <header class="header">
      <div class="header__title-block">
        <button
          type="button"
          class="button-return"
          @click="onBackBtnClick"
        />
        <p class="header-title">Happy Horses (apt. HRSS707)</p>
        <my-tooltip-simple>
          <button type="button" class="button-info" />
          <template v-slot:tooltip-data>
            <div class="header-title-tooltip">
              <p>ID карточки №001936</p>
              <p>Создано: 04/03/2023</p>
              <p>Автор: Tatyana I.</p>
              <p>Последнее изменение: 06/10/2023</p>
            </div>
          </template>
        </my-tooltip-simple>
      </div>
      <div class="header__search-form">
        <input
          class="input input-search"
          value=""
        >
      </div>
    </header>

    <main class="main">
      <section class="main__section">
        <h3 class="section__title">Атрибуты поставщика</h3>
        <div class="inputblock">
          <label
            class="inputblock__label"
            for="vendor-code"
          >
            Артикул
          </label>
          <input
            v-model="vendorCode"
            class="inputblock__input input"
            id="vendor-code"
            placeholder="Введите артикул товара"
          />
        </div>
        <div class="inputblock">
          <label class="inputblock__label" for="contracts">Договоры</label>
          <my-multiselect
            id="contracts"
            :options="contracts"
            :selectedOption="selectedContract"
            @select="onTrademarkSelect"
            multiple
            searchable
            placeholder="Выберите договоры"
          />
        </div>
        <div class="inputblock">
          <label class="inputblock__label" for="shipping-warehouses">Склады отгрузки</label>
          <my-multiselect
            id="shipping-warehouses"
            :options="shippingWarehouses"
            :selectedOption="selectedShippingWarehouses"
            @select="onTrademarkSelect"
            multiple
            searchable
            placeholder="Выберите склады отгрузки"
          />
        </div>
        <div class="inputblock">
          <gp-check
            :checked="isActiveCard"
            @change="handleChange"
          >
            &nbsp;&nbsp;
            <l10n value="Разные контакты по складам" />
          </gp-check>
        </div>
      </section>
      <section class="main__section">
        <h3 class="section__title">Условия обработки заказов</h3>
        <div class="inputblock">
          <label class="inputblock__label" for="contacts">Контакты</label>
          <my-multiselect
            id="contacts"
            :options="contacts"
            :selectedOption="selectedContacts"
            @select="onTrademarkSelect"
            placeholder="Выберите контакты"
          />
        </div>
      </section>
      <section class="main__section">
        <h3 class="section__title">График заказов</h3>
        <div class="inputblock">
          <label class="inputblock__label" for="ordersShippingDays">Дни отправки заказов</label>
          <my-multiselect
            id="ordersShippingDays"
            :options="ordersShippingDays"
            :selectedOption="selectedOrdersShippingDays"
            @select="onTrademarkSelect"
            placeholder="Выберите дни отправки заказов"
          />
        </div>
        <div class="inputblock">
          <label class="inputblock__label" for="ordersShippingTime">Время отправки заказов(до)</label>
          <my-multiselect
            id="ordersShippingTime"
            :options="ordersShippingTime"
            :selectedOption="selectedOrdersShippingTime"
            @select="onTrademarkSelect"
            placeholder="Выберите время отправки заказов"
          />
        </div>
      </section>
    </main>

    <footer class="footer">
      <div class="footer__checkbox">
        <gp-check
          :checked="isActiveCard"
          @change="handleChange"
        >
          &nbsp;&nbsp;
          <l10n value="Активная карточка" />
        </gp-check>
      </div>
      <div class="footer__buttons-block">
        <button
          type="button"
          class="button button_primary"
        >
          Дублировать
        </button>
        <button
          type="button"
          class="button button_secondary"
        >
          Сохранить
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
const MyTooltipSimple = require('../my-tooltip-simple.vue').default;
const MyMultiSelect = require('../my-multiselect.vue').default;

module.exports = {
  components: {
    'my-tooltip-simple': MyTooltipSimple,
    'my-multiselect': MyMultiSelect,
  },

  data() {
    return {
      vendorCode: 'HPPHRS002',
      contracts: ['HPP123', 'HPP124', 'HPP125', 'HPP126', 'HPP127', 'HPP128', 'HPP129', 'HPP130', 'HPP131', 'HPP132', 'HPP133'],
      selectedContracts: 'HPP123',
      shippingWarehouses: ['Склад HPP123', 'Склад HPP124', 'Склад HPP125', 'Склад HPP126', 'Склад HPP127', 'Склад HPP128', 'Склад HPP129', 'Склад HPP130', 'Склад HPP131', 'Склад HPP132', 'Склад HPP133'],
      selectedShippingWarehouses: 'HPP127',
      contacts: ['Склад HPP123', 'Склад HPP124', 'Склад HPP125', 'Склад HPP126', 'Склад HPP127', 'Склад HPP128', 'Склад HPP129', 'Склад HPP130', 'Склад HPP131', 'Склад HPP132', 'Склад HPP133'],
      selectedContacts: 'HPP127',
      ordersShippingDays: ['Пн–Чт', 'Пн–Чт, Сб, Праздничные дни', 'Праздничные дни'],
      selectedOrdersShippingDays: 'Пн–Чт, Сб, Праздничные дни',
      ordersShippingTime: ['10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00'],
      selectedOrdersShippingTime: '10:00',
    };
  },

  methods: {
    handleChange() {
      console.log('changed');
    },

    onClickSearchBtn() {
      console.log('search btn clicked');
    },

    onBackBtnClick() {
      console.log('back btn clicked');
    },
  },
};
</script>

<style scoped>
.productcard-container {
  display: flex;
  flex-direction: column;
  width: 1376px;
  margin: 0 auto;
  padding: 0.65rem;
  font-weight: 600;
}
.productcard-container h2 {
  font-weight: 600;
}
.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;
}
.header__title-block {
  display: flex;
  align-items: center;
}
.header-title {
  margin-bottom: 0;
  margin-right: 12px;
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
}
.header-title-tooltip {
  width: 210px;
  text-align: left;
}
.header-title-tooltip p {
  margin-bottom: 0;
}
.main {
  display: flex;
  justify-content: space-between;
  margin-bottom: 150px;
}
.main__section {
  display: flex;
  flex-direction: column;
  width: 437px;
}

.section__title {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}
.inputblock {
  margin-bottom: 16px;
}
.inputblock__label {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #9B9B9B;
}
.input {
  display: flex;
  align-items: center;
  width: 438px;
  min-width: 350px;
  height: 46px;
  margin: 0;
  padding: 0px 8px 0px 12px;
  border: none;
  border-radius: 8px;
  background: #f7f7f7;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
}
.input:hover {
  background-color: #E9E9E9;
  cursor: pointer;
}
.input:focus {
  border-color: #5295D5;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem #5295D5;
  box-shadow: 0 0 0 0.2rem #5295D5;
}
.select {
  display: flex;
  align-items: center;
  width: 438px;
  min-width: 350px;
  height: 46px;
  margin: 0;
  padding: 0px 8px 0px 12px;
  border: none;
  border-radius: 8px;
  background: #f7f7f7 url('./arrow-down.svg') no-repeat center right 10px;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
}
.fileinput__stub {
  width: 438px;
  height: 440px;
  background: #F7F7F7 url('./image-icon.svg') no-repeat center center;
  border-radius: 8px;
}
.fileinput__image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 438px;
  height: 440px;
  background: #F7F7F7;
  border-radius: 8px;
  overflow: hidden;
}
.button-return {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  border: none;
  border-radius: 50%;
  background: #F7F7F7 url('./arrow-left.svg') no-repeat center center;
}
.button-return:hover {
  background-color: #E9E9E9;
}
.button-return_disabled {
  background-color: #E9E9E9;
}
.button-return:focus {
  outline: none;
}
.button-info {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  padding-bottom: 1px;
  border: none;
  border-radius: 50%;
  background: transparent;
  background: url('./info.svg') no-repeat center center;
}
.button-info:focus {
  outline: none;
}
.icon-button svg {
  stroke: #cfcfcf;
}
.icon-button:focus {
  outline: none;
  box-shadow: 0px 0px 3px 3px #5295D5;
}
.header__search-form {
  display: flex;
}
.main {
  display: flex;
  justify-content: space-between;
}
.footer {
  display: flex;
  justify-content: space-between;
}
.modal-container {
  left: 305px;
  max-height: 100vh;
  min-width: 300px;
  max-width: 300px;
  padding: 0.5rem;
}
.input-search {
  display: flex;
  align-items: center;
  width: 438px;
  min-width: 350px;
  height: 46px;
  padding: 0px 8px 0px 12px;
  margin: 0;
  border: none;
  border-radius: 8px;
  padding-left: 42px;
  background: #f7f7f7 url('./search-icon.svg' ) no-repeat 12px;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 215px;
  height: 46px;
  border: none;
  border-radius: 8px;
  transition: background-color .3s ease-in-out;
}
.button_primary {
  background-color: #5295D5;
  color: #ffffff;
}
.button_secondary {
  background-color: #F7F7F7;
  color: #000000;
}
.button_primary:hover {
  background-color: #3C80C1;
  transition: background-color .3s ease-in-out;
}
.button_secondary:hover {
  background-color: #E9E9E9;
  transition: background-color .3s ease-in-out;
}
.button_disabled {
  background-color: #F7F7F7;
  color: #D4D4D4;
  cursor: default;
}
.button-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 20px;
  text-decoration: underline;
  color: #3385FF;
}
.button-upload_disabled {
  color: #9B9B9B;
}
.footer__buttons-block {
  display: flex;
}
.footer__buttons-block .button_primary {
  margin-right: 8px;
}
.footer__checkbox {
  width: 200px;
}
</style>
