const utils = require('../my-utils');

module.exports.saveToLocalStorage = (tableKey, key, value) => {
  localStorage.setItem(`${tableKey}-${key}`, JSON.stringify(value));
};

module.exports.loadFromLocalStorage = (tableKey, key) => {
  try {
    if (localStorage.getItem(`${tableKey}-${key}`) !== null) {
      return JSON.parse(localStorage.getItem(`${tableKey}-${key}`));
    }
  } catch {
    const notification = {
      text: `Cannot load from local storage: ${key}`,
      type: 'error',
      id: utils.randomId(),
      created: Date.now(),
    };

    return notification;
  }

  return undefined;
};

module.exports.loadDataFromLocalStorage = (key) => {
  try {
    if (localStorage.getItem(key) !== null) {
      return JSON.parse(localStorage.getItem(key));
    }
  } catch {
    const notification = {
      text: `Cannot load from local storage: ${key}`,
      type: 'error',
      id: utils.randomId(),
      created: Date.now(),
    };

    return notification;
  }

  return undefined;
};
