<template>
  <my-popup
    v-if="isPopupVisible"
    portal="popup"
    placement="bottom-end"
    :anchor="`*[data-section='${selectedSection.id}'] .feather-icon-more-vertical`"
    @escape="closePopup"
    @clickoutside="closePopup"
  >
    <div class="popover show plain-table-manage-table">
      <div class="popover-body">
        <ul>
          <li>
            <span @click="renameSection">
              <feather-icon name="edit-3" />
              <l10n value="Rename section" />
            </span>
          </li>

          <li>
            <span @click="cloneSection">
              <feather-icon name="copy" />
              <l10n value="Duplicate section" />
            </span>
          </li>

          <li>
            <span @click="deleteSection">
              <feather-icon name="trash" />
              <l10n value="Delete section" />
            </span>
          </li>
        </ul>
      </div>
    </div>
  </my-popup>
</template>

<script>
const utils = require('../../my-utils');

module.exports = {
  props: {
    selectedSection: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    isPopupVisible() {
      return !!this.selectedSection?.id;
    },
  },

  methods: {
    closePopup() {
      this.$emit('close-popup');
    },

    renameSection() {
      const text = utils.l10n('Please enter rules section name');
      const defaultValue = this.selectedSection.name;
      const newName = window.prompt(text, defaultValue);

      if (newName) {
        this.$emit('rename-section', {
          id: this.selectedSection.id,
          newName,
        });
      }

      this.closePopup();
    },

    cloneSection() {
      const newSection = _.cloneDeep(this.selectedSection);

      newSection.id = utils.randomId();
      newSection.name = `${this.selectedSection.name} (clone)`;
      newSection.rules.map((rule) => ({
        ...rule,
        id: utils.randomId(),
      }));

      this.$emit('clone-section', {
        id: this.selectedSection.id,
        newSection,
      });

      this.closePopup();
    },

    deleteSection() {
      const text = utils.l10n('Are you sure you want to delete section {section}?').replace('{section}', this.selectedSection.name);
      const agree = window.confirm(text);

      if (agree) {
        this.$emit('delete-section', this.selectedSection.id);
      }

      this.closePopup();
    },
  },
};
</script>

<style scoped>
.popover span {
  cursor: pointer;
  color: var(--cyan);
}

.popover span:hover {
  text-decoration: underline;
}
</style>
