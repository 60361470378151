<template>
	<div>
		<div class="sharethis-inline-share-buttons" :id="container"></div>
	</div>
</template>
<script>
module.exports = {
	props: {
		alignment: 	{ type: String, default: "left" },
		container: 	{ type: String, default: "my-inline-share-buttons" },
		enabled: 	{ type: Boolean, default: true },
		font_size: 	{ type: Number, default: 11 },
		labels: 	{ type: String, default: "none" },
		min_count: 	{ type: Number, default: 10 },
		padding: 	{ type: Number, default: 8 },
		radius: 	{ type: Number, default: 0 },
		networks: 	{ type: Array, default: () =>
			["facebook", "telegram", "twitter", "whatsapp", "sharethis", "sms"] },
		show_total: { type: Boolean, default: false },
		show_mobile_buttons: { type: Boolean, default: undefined },
		use_native_counts: 	 { type: Boolean, default: false },
		size: 		{ type: Number, default: 32 },
		spacing: 	{ type: Number, default: 8 },
	},
	methods: {
		setup() {
			let props = _.clone(this.$props)
			window.__sharethis__.load('inline-share-buttons', props)
		}
	},
	mounted() {
		if (window.__sharethis__ !== undefined) {
			this.setup()
		}
		else {
			let script = document.createElement("script")
			script.id = "sharethis"
			script.type = "text/javascript"
			script.src = "https://platform-api.sharethis.com/js/sharethis.js"
			script.async = "async"
			script.onload = () => this.setup()
			document.body.appendChild(script)
		}
	}
}
</script>
<style>
.sharethis-inline-share-buttons:not(:empty) {
    margin-bottom: 14px;
}
.st-close {
	padding: 8px!important;
}
</style>