<template>
<div class="gp-loaded-path-info">
    <l10n v-if="!lines" value="Loading..."/>
    <template v-else>
        <gp-check v-model="onlyImportant">
            <l10n value="Show only important messages"/>
        </gp-check>
    <div class="gp-loaded-path-info-lines">
        <div
            v-for="line in lines"
            v-if="!onlyImportant || line.startsWith('!')"
            :class="{important: line.startsWith('!')}"
            >
            {{line.replace(/^! /, '')}}
        </div>
    </div>
    </template>
</div>
</template>
<script>
let utils = require("../my-utils")
module.exports = {
    props: {
        stream: String,
        paths: Array,
    },
    data() {
        return {
            lines: null,
            onlyImportant: true,
        }
    },
    mounted() {
        this.update();
    },
    watch: {
        stream(curr, prev) {
            if (curr !== prev)
                this.update()
        },
        paths(curr,prev) {
            if (!_.isEqual(curr, prev))
                this.update()
        },
    },
    methods: {
        update() {
            this.lines = null
            let requestId = utils.randomId()
            this.requestId = requestId
            let query = `
                query {
                    dataset {
                        streams {
                            ${this.stream} {
                                spouts {
                                    ... on DelimitedFile {
                                        loadedPathsInfo(paths:${utils.quote(this.paths)}) {
                                            path
                                            stderr
                                        }
                                    }
                                }
                            }
                        }
                    }
                }`

            utils.fetchWithAjaxOpts({
                    url: "/graphql",
                    method: "POST",
                    data: JSON.stringify({query}),
                    dataType: "json",
                    contentType: "application/json"
                })
                .then(({data}) => {
                    if (this.requestId === requestId) {
                        this.requestId = null
                        let lines = []
                        for (let spout of data.dataset.streams[this.stream].spouts)
                            for (let info of spout.loadedPathsInfo)
                                for (let line of info.stderr)
                                    lines.push(line)
                        this.lines = lines
                    }
                })
                .catch((err) => {
                    if (this.requestId === requestId) {
                        this.requestId = null
                        this.lines = [`${err}`]
                    }
                })
        }
    }
}
</script>
<style>
.gp-loaded-path-info pre {
    font-size: 0.8em;
    background-color: black;
    color: var(--green);
    padding: 8px;
    border-radius: 4px;
    overflow: auto;
}
</style>