<template>
  <div class="my-4">
    <h4 class="mb-4">
      <l10n value="Rules migration tool" />
    </h4>

    <p class="text-danger mb-2">
      <l10n value="1) Transform old configs and save it with new name" />
    </p>

    <div class="config-rename">
      <span>
        <l10n value="Old config name" />
      </span>

      <input
        id="oldConfigName"
        v-model="oldFamilyName"
        type="text"
      >

      <span>
        <l10n value="New config name" />
      </span>

      <input
        id="newConfigName"
        v-model="newFamilyName"
        type="text"
      >
    </div>

    <button
      type="button"
      class="btn btn-sm btn-primary mt-2 w-100"
      @click="migrateConfig"
    >
      <l10n value="Migrate saved configs" />
    </button>

    <p class="text-danger mb-2 mt-4">
      <l10n value="2) Add Elements -> rules:storedFamilyName with value from 'New config name'" />
    </p>

    <p class="text-danger mb-2 mt-4">
      <l10n value="3) Replace old config at Elements -> rulesLibrary with new one from textarea below" />
    </p>

    <textarea
      class="w-100"
      rows="10"
      :value="newLibraryRules"
    />
  </div>
</template>

<script>
const oldVarsToNewTypes = {
  money: ['budget', 'range_start', 'range_end', 'rounding_ranges[0].start', 'rounding_ranges[0].end', 'revenue_limit', 'margin_limit'],
  percent_abs: ['min', 'max', 'secondary_min', 'secondary_max', 'target'],
  percent: ['limit', 'goal_target'],
  number: ['demand_limit', 'md_cnt', 'md_gap', 'md_lag', 'min_cpi', 'max_cpi'],
  endings: ['rounding_ranges[0].wholeEndings', 'rounding_ranges[0].fractionalEndings', 'percents', 'md_percents', 'md_states'],
  list: ['rounding_ranges[0].ignorePrices'],
  calendar: ['md_dates', 'md_force_percents'],
};

const getTypeByKey = (key) => Object.keys(oldVarsToNewTypes).find((k) => oldVarsToNewTypes[k].includes(key)) || 'text';

const oldRuleFormatter = (rule) => {
  const {
    id = null,
    name,
    text,
    type,
    strict = false,
    filter = [],
    grouper = [],
    expander = [],
  } = rule;

  const newRule = {
    name,
    text,
    type,
    strict,
    filter,
    grouper,
    expander,
  };

  const skipKeys = [
    ...Object.keys(newRule),
    'options',
    'multiple',
  ];

  if (rule.id) {
    newRule.id = id;
  }

  const variablesFromText = [...rule.text.matchAll(/{([^}\.\[]+[^}]*?)}/g)]
    .reduce((acc, [, key]) => {
      const keyRenamed = key
        .replaceAll(/\[(\d+)\]/g, '_$1')
        .replace('.', '_');

      if (key === 'md_force_percents') {
        newRule.text = newRule.text.replace(key, 'md_dates:md_settings');
      }

      newRule.text = newRule.text.replace(key, keyRenamed);
      skipKeys.push(key.split('[')[0]);

      const variable = {
        [keyRenamed]: {
          key,
          value: _.get(rule, key),
        },
      };

      if (_.has(rule.options, key)) {
        variable[keyRenamed].type = 'select';
        variable[keyRenamed].options = rule.options[key];
        variable[keyRenamed].multiple = rule.multiple || false;
      } else {
        variable[keyRenamed].type = getTypeByKey(key);

        if (key === 'min') variable[keyRenamed].initial = 'min';
        if (key === 'max') variable[keyRenamed].initial = 'max';
      }

      return {
        ...acc,
        ...variable,
      };
    }, {});

  const constantsFromLibrary = Object.keys(rule)
    .filter((key) => !skipKeys.includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: { key, value: rule[key] } }), {});

  newRule.variables = {
    ...variablesFromText,
    ...constantsFromLibrary,
  };

  return newRule;
};

module.exports = {
  props: {
    rulesLibrary: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      oldFamilyName: 'strategies',
      newFamilyName: 'strategies_new',
      oldConfig: null,
      newConfig: null,
      oldConfigCount: 0,
      newConfigCount: 0,
      newLibraryRules: '',
    };
  },

  async beforeMount() {
    this.oldConfig = await this.fetchConfig(this.oldFamilyName);
    this.newConfig = await this.fetchConfig(this.newFamilyName);

    this.oldConfigCount = Object.keys(this.oldConfig).length;
    this.newConfigCount = Object.keys(this.newConfig).length;
  },

  watch: {
    rulesLibrary: {
      deep: true,
      immediate: true,
      handler() {
        this.updateRulesLibrary();
      },
    },
  },

  methods: {
    updateRulesLibrary() {
      const newYaml = this.rulesLibrary.map((rule) => oldRuleFormatter(rule));

      this.newLibraryRules = jsyaml
        .dump({ rulesLibrary: newYaml })
        .replace('rulesLibrary:', 'rulesLibrary: &rulesLibrary');
    },

    async fetchConfig(configName) {
      const res = await fetch(`/storage/${configName}`);
      const data = await res.json();

      return data;
    },

    async migrateConfig() {
      if (this.newFamilyName === 'strategies') {
        console.error('You cannot override old configs!!!');
        return;
      }

      Object.entries(this.oldConfig).forEach(([configId, config]) => {
        this.newConfig[configId] = {
          ...config,
          sections: config.sections.map((section) => ({
            ...section,
            rules: section.rules.map((rule) => oldRuleFormatter(rule)),
          })),
        };
      });

      Object.keys(this.newConfig).forEach((key) => {
        fetch(`/storage/${this.newFamilyName}/${key}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.newConfig[key]),
        });
      });
    },
  },
};
</script>

<style scoped>
.config-rename {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
