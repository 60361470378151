// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../icons/search-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.plain-table-manage-section ul {\r\n    list-style: none;\r\n    margin: 0;\r\n    padding: 0;\n}\n.gp-section-columns .form-control {\r\n    height: 46px;\r\n    padding: 0 8px 0 42px;\r\n    font-family: Inter;\r\n    font-size: 12px;\r\n    font-style: normal;\r\n    font-weight: 500;\r\n    line-height: 120%;\r\n    border: none;\r\n    border-bottom: 1px solid #E9E9E9;\r\n    border-top-left-radius: 8px;\r\n    border-top-right-radius: 8px;\r\n    border-bottom-left-radius: 0px;\r\n    border-bottom-right-radius: 0px;\r\n    background: #f7f7f7 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + " ) no-repeat 12px;\n}\n.gp-section-columns .form-control:focus {\r\n    -webkit-box-shadow: none;\r\n    box-shadow: none;\n}\n.gp-section-columns ul.gp-section-columns__list {\r\n    overflow-y: auto;\r\n    max-height: 300px;\r\n    padding: 8px 12px;\n}\nul.gp-section-columns__list::-webkit-scrollbar {\r\n    width: 3px;\r\n    background-color: #ffffff;\n}\nul.gp-section-columns__list::-webkit-scrollbar-thumb {\r\n    width: 3px;\r\n    border-radius: 50%;\r\n    background-color: #E9E9E9;\n}\n.gp-section-columns__list hr {\r\n    margin: 0;\n}\n.gp-section-columns__list li {\r\n    padding: 8px;\n}\n.gp-section-columns-actions {\r\n    display: flex;\r\n    justify-content: space-around;\r\n    padding: 8px 16px 16px;\n}\n.gp-section-columns-actions .button {\r\n    width: 130px;\r\n    height: 40px;\n}\n.gp-section-columns .matched {\r\n    color: var(--red);\n}\n.gp-section-columns ul {\r\n  list-style: none;\n}\r\n", ""]);
// Exports
module.exports = exports;
