import { render, staticRenderFns } from "./autoorder-warehouses.vue?vue&type=template&id=698579f4&scoped=true&"
import script from "./autoorder-warehouses.vue?vue&type=script&lang=js&"
export * from "./autoorder-warehouses.vue?vue&type=script&lang=js&"
import style0 from "./autoorder-warehouses.vue?vue&type=style&index=0&id=698579f4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "698579f4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\aazat\\OneDrive\\Documents\\Repositories\\Goalprofit\\gloriajeans_frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('698579f4')) {
      api.createRecord('698579f4', component.options)
    } else {
      api.reload('698579f4', component.options)
    }
    module.hot.accept("./autoorder-warehouses.vue?vue&type=template&id=698579f4&scoped=true&", function () {
      api.rerender('698579f4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "public/autoorder/views/warehouses/autoorder-warehouses.vue"
export default component.exports