<template>
  <div class="products">
    <header class="products__header">
      <autoorder-tabs
        :staticTabs="tabs"
        :dynamicTabs="cardTabs"
        :activeTabId="currentTabId"
        @onSelect="selectTab"
        @onCloseTab="tabCloseInit"
      />
    </header>
    <main class="products__main">
      <keep-alive :exclude="['product-card']">
        <component
          v-if="currentComponent"
          :is="currentComponent"
        />
      </keep-alive>
    </main>

    <close-tab-modal v-model="closeTabModalVisible" @onTabCloseConfirm="tabCloseConfirm" />
  </div>
</template>

<script>
const {
  saveToStorageDynamicTabIds,
  getFromStorageDynamicTabIds,
  clearCardStatesCache,
} = require('../../utils/useStorage');

// async components https://v2.vuejs.org/v2/guide/components-dynamic-async#Async-Components
/* eslint-disable import/no-dynamic-require, global-require */
const ProductCard = (resolve) => {
  require(['./product-card.vue'], resolve);
};
const ProductsCatalog = (resolve) => {
  require(['./products-catalog.vue'], resolve);
};
const AutoorderTabs = (resolve) => {
  require(['../../components/autoorder-tabs.vue'], resolve);
};
const CloseTabModal = (resolve) => {
  require(['../../components/custom-modals/close-tab-modal.vue'], resolve);
};
/* eslint-enable import/no-dynamic-require, global-require */

const productTabsStorageKey = 'product-tabs';
const productEntityStorageKey = 'product-cards';
const catalogId = 'product-catalog';
const newProduct = 'new-product';
const initialCardTab = {
  id: null,
  component: ProductCard,
};

module.exports = {
  components: {
    ProductsCatalog,
    ProductCard,
    AutoorderTabs,
    CloseTabModal,
  },

  provide() {
    return {
      newProduct,
      productEntityStorageKey,
      openProductCatalog: this.openCatalog,
      createNewProductCard: this.createNewCard,
      openExistProductById: this.openExistCard,
      onSuccessfulCreateProduct: this.onSuccessfulCreateProduct,
      reactiveProvidedProductData: this.reactiveData, // https://ru.vuejs.org/v2/api/#provide-inject
    };
  },

  data() {
    return {
      tabs: [
        { id: catalogId, title: 'Справочник товаров', component: ProductsCatalog },
      ],
      cardTabs: [],
      newCardCounter: 0,
      reactiveData: {
        currentTabView: null,
      },
      tabIdForClose: null,
      closeTabModalVisible: false,
    };
  },

  computed: {
    currentComponent() {
      return this.reactiveData.currentTabView?.component || null;
    },
    currentTabId() {
      return this.reactiveData.currentTabView?.id || null;
    },
    dynamicTabIds() {
      return this.cardTabs?.length ? this.cardTabs.map((item) => item?.id) : [];
    },
  },

  methods: {
    selectTab(id) {
      this.reactiveData.currentTabView = this.tabs.find((tab) => tab.id === id)
      || this.cardTabs.find((tab) => tab.id === id) || null;
    },
    removeTab(id) {
      const result = [];

      for (let i = 0; i < this.cardTabs.length; i += 1) {
        const card = this.cardTabs[i];
        if (card.id !== id) {
          result.push(card);
        }
      }

      this.cardTabs = result;

      if (this.currentTabId === id) {
        this.selectTab(catalogId);
      }
    },
    tabCloseConfirm() {
      this.removeTab(this.tabIdForClose);
      this.tabIdForClose = null;
      this.closeTabModalVisible = false;
    },
    tabCloseInit(tabId) {
      this.closeTabModalVisible = true;
      this.tabIdForClose = tabId;
    },
    openCatalog() {
      this.selectTab(catalogId);
    },
    openExistCard(id) {
      const newCardTab = {
        ...initialCardTab,
        id,
      };

      if (!this.cardTabs.find((card) => card.id === id)) {
        this.cardTabs.push(newCardTab);
        this.selectTab(id);
      } else {
        this.selectTab(id);
      }
    },
    createNewCard() {
      this.newCardCounter += 1;
      const newId = `${newProduct}-${this.newCardCounter}`;
      this.openExistCard(newId);
    },
    onSuccessfulCreateProduct(id) {
      if (id) {
        this.cardTabs = this.cardTabs.map((card) => (card.id === this.currentTabId ? {
          ...card,
          id,
        } : card));
        this.selectTab(id);
      }
    },
  },

  watch: {
    dynamicTabIds(newIds) {
      saveToStorageDynamicTabIds({ key: productTabsStorageKey, tabIds: newIds });

      clearCardStatesCache({ entity: productEntityStorageKey, tabIds: newIds });
    },
  },

  mounted() {
    const { tabs, counter } = getFromStorageDynamicTabIds({
      key: productTabsStorageKey, newCardName: newProduct, initialCardTab,
    });
    this.cardTabs = tabs;
    this.newCardCounter = counter;
  },
};
</script>

<style  scoped>
.products {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
  overflow-y: auto;
  padding: 12px;
}

.products__main {
  flex: 1 0 auto;
}
</style>
