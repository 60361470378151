const { checkStartWith } = require('./validation');

// save/get dynamic tab ids
const saveToStorageDynamicTabIds = ({ key, tabIds }) => {
  sessionStorage.setItem(key, JSON.stringify(tabIds));
};

const getFromStorageDynamicTabIds = ({ key, newCardName, initialCardTab }) => {
  const tabIds = JSON.parse(sessionStorage.getItem(key)) || [];
  const result = [];
  let counter = 0;

  for (let i = 0; i < tabIds.length; i += 1) {
    const id = tabIds[i];

    if (checkStartWith(id, newCardName)) {
      const count = String(id).split('-').pop();
      counter = counter < count ? count : counter;
    }

    const card = { ...initialCardTab, id };
    result.push(card);
  }

  return { tabs: result, counter: Number(counter) };
};

// save/get/clear dynamic cards states
const getEntityCardCached = (entity) => JSON.parse(sessionStorage.getItem(entity)) || {};

const saveCardStateToCache = ({ entity, cardId, cardState }) => {
  const entityCache = getEntityCardCached(entity);
  entityCache[cardId] = cardState;

  sessionStorage.setItem(entity, JSON.stringify(entityCache));
};

const getCardStateFromCache = ({ entity, cardId }) => {
  const entityCache = getEntityCardCached(entity);
  return entityCache[cardId] || null;
};

const clearCardStatesCache = ({ entity, tabIds }) => {
  const oldEntityCache = getEntityCardCached(entity);
  const cleanedCache = {};

  tabIds.forEach((key) => {
    if (oldEntityCache[key]) {
      cleanedCache[key] = oldEntityCache[key];
    }
  });

  sessionStorage.setItem(entity, JSON.stringify(cleanedCache));
};

module.exports = {
  saveToStorageDynamicTabIds,
  getFromStorageDynamicTabIds,
  saveCardStateToCache,
  getCardStateFromCache,
  clearCardStatesCache,
};
