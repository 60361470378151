<template>
    <div>
        <my-errors :errors="errors" @retry="requestData"/>

        <my-caption :caption="caption"/>

        <my-progress
            :key="reportId"
            :progress="progress"
            :processRows="processRows"
            :processTime="processTime"
            :meta="meta"
            :assembly="assembly"/>

        <!-- <a href="javascript:void(0)" @click="$el.requestFullscreen()"><feather-icon name="maximize"/></a> -->

        <my-filters v-model="filters" :chart="chart"/>

        <my-download :file="file"/>

        <my-tooltip
            ref="tooltip"
            :meta="meta"
            :keepOnly="keepOnly"
            :exclude="exclude"
            :actions="actions"
            />

        <div class="my-chart" :style="style" ref="base">
            <div ref="chart"></div>
        </div>
    </div>
</template>

<script>
module.exports = {
    mixins: [
        require("../data.js"),
        require("./base.js"),
        require("./margin.js"),
        require("./coordinate-grid.js"),
        require("./series-chart.js"),
    ]
}
</script>