<template>
  <div class="rules-card">
    <header class="header">
      <div class="header__title-block">
        <button
          type="button"
          class="button-return"
          @click="redirectToDirectory"
        />
        <p class="header-title" v-if="cardExist">{{ cardTitle }}</p>
      </div>

      <div class="header__search-form">
        <autoorder-search />
      </div>
    </header>

    <main class="empty-card-container" v-if="isCardEmpty">
      <autoorder-empty-card
        text="Select a card in the registry"
        @on-redirect="redirectToDirectory"
      />
    </main>

    <main class="error-card-container" v-else-if="hasError">
      <autoorder-error-card @on-reload="reloadWindow" />
    </main>

    <main class="main" v-else>
      <h3 class="main__title">Атрибуты документа</h3>
      <div class="main__sections">
        <section class="main__section">
          <div class="inputblock" v-if="!isNewRules && !isRulesDuplicated">
            <label
              class="inputblock__label"
              for="rulesId"
            >
              ID документа
            </label>
            <input
              v-model="rulesId"
              class="inputblock__input input"
              id="rulesId"
              disabled
            />
          </div>
          <div class="inputblock">
            <label
              class="inputblock__label"
              for="dateFrom"
            >
              <l10n value='Дата действия с' />
            </label>
            <input
              v-model="dateFrom"
              type="date"
              class="inputblock__input input"
              id="dateFrom"
              :placeholder="localizeValue('Select date')"
            />
          </div>
          <div class="inputblock">
            <label class="inputblock__label" for="linksTo">Ссылка на параметр</label>
            <my-multiselect
              id="linksTo"
              v-model="rulesLinksTo"
              :options="allLinksTo"
              :closeOnSelect="false"
              :loading="selectLoading === 'linksTo'"
              :class="{ invalid: isLinksToNotChecked && tryToSend }"
              label="name"
              track-by="id"
              placeholder="Выберите ссылку на параметр"
              @open="getLinksTo"
              @select="formWasEdited = true" />
          </div>
          <div class="inputblock">
            <label class="inputblock__label" for="products">Выбор товара(ов)</label>
            <my-multiselect
              id="products"
              v-model="rulesProducts"
              :options="allProducts"
              :closeOnSelect="false"
              :loading="selectLoading === 'products'"
              :class="{ invalid: isProductsNotChecked && tryToSend }"
              label="name"
              track-by="id"
              placeholder="Выберите товар(ы)"
              @open="getProducts"
              @select="formWasEdited = true" />
          </div>
          <div class="inputblock">
            <label class="inputblock__label" for="warehouses">Выбор объекта(ов)</label>
            <my-multiselect
              id="warehouses"
              v-model="rulesWarehouses"
              :options="allWarehouses"
              :closeOnSelect="false"
              :loading="selectLoading === 'warehouses'"
              :class="{ invalid: isWarehousesNotChecked && tryToSend }"
              label="name"
              track-by="id"
              placeholder="Выберите объект(ы)"
              @open="getWarehouses"
              @select="formWasEdited = true" />
          </div>
        </section>
        <section class="main__section">
          <div class="inputblock">
            <label
              class="inputblock__label"
              for="rulesNote"
            >
              Описание
            </label>
            <input
              v-model="rulesNote"
              class="inputblock__input input"
              id="rulesNote"
              :placeholder="localizeValue('Enter the description')"
            />
          </div>
          <div class="inputblock">
            <label
              class="inputblock__label"
              for="dateTo"
            >
              <l10n value='Дата действия по' />
            </label>
            <input
              v-model="dateTo"
              type="date"
              class="inputblock__input input"
              id="dateTo"
              :placeholder="localizeValue('Select date')"
            />
          </div>
          <div class="inputblock" v-if="!isNewRules && !isRulesDuplicated">
            <label
              class="inputblock__label"
              for="dateCreate"
            >
              <l10n value='Дата создания' />
            </label>
            <input
              v-model="dateCreate"
              type="date"
              class="inputblock__input input"
              id="dateCreate"
              :placeholder="localizeValue('Select date')"
              disabled
            />
          </div>
          <div class="inputblock" v-if="!isNewRules && !isRulesDuplicated">
            <label
              class="inputblock__label"
              for="rulesAuthor"
            >
              Автор
            </label>
            <input
              v-model="rulesAuthor"
              class="inputblock__input input"
              id="rulesAuthor"
              disabled
            />
          </div>
          <div class="inputblock">
            <label class="inputblock__label" for="linkToPolitic">Ссылка на политику</label>
            <my-multiselect
              id="linkToPolitic"
              v-model="ruleslinkToPolitic"
              :options="allLinkToPolitic"
              :closeOnSelect="false"
              :loading="selectLoading === 'linkToPolitic'"
              :class="{ invalid: islinkToPoliticNotChecked && tryToSend }"
              label="name"
              track-by="id"
              placeholder="Выберите политику"
              @open="getlinkToPolitic"
              @select="formWasEdited = true" />
          </div>
        </section>
      </div>
    </main>

    <footer class="footer" v-if="cardExist">
      <div class="footer__checkbox">
        <gp-check
          :checked="isActiveCard"
          @change="handleChangeActivity"
        >
            &nbsp;&nbsp;
          <l10n value="Активный документ" />
        </gp-check>
      </div>
      <div class="footer__buttons-block">
        <button
          type="button"
          class="button button_secondary"
          :class="{ button_disabled: isLoading }"
          :disabled="isLoading"
          @click="handleSecondaryButtonAction">
          {{ secondaryButtonTitle }}
        </button>
        <button
          type="button"
          class="button button_primary"
          :class="{ button_disabled: isLoading }"
          :disabled="isSubmitButtonDisabled"
          @click="submitRulesForm">
          Сохранить
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
const utils = require('../my-utils');
const AutoorderSearch = require('./autoorder-search.vue').default;
const AutoorderEmptyCard = require('./components/autoorder-empty-card.vue').default;
const AutoorderErrorCard = require('./components/autoorder-error-card.vue').default;
const MyMultiSelect = require('../my-multiselect.vue').default;

module.exports = {
  components: {
    'autoorder-search': AutoorderSearch,
    'autoorder-empty-card': AutoorderEmptyCard,
    'autoorder-error-card': AutoorderErrorCard,
    'my-multiselect': MyMultiSelect,
  },

  API_URL: 'https://web.dev.az.gp.itnap.ru/chains',

  data() {
    return {
      rulesId: null,
      rulesAuthor: null,
      dateFrom: null,
      allLinksTo: [],
      rulesLinksTo: [],
      allFeatures: [],
      rulesFeatures: [],
      isRulesDuplicated: false,
      isNewRules: false,
      hasError: false,
      formWasEdited: false,
      selectLoading: null,
      savedRulesData: null,
      isActiveCard: true,
      isLoading: false,
      tryToSend: false,
    };
  },

  computed: {
    cardExist() {
      return !this.hasError && !this.isCardEmpty;
    },
    cardTitle() {
      return `Документ привязки цепочки № ${this.rulesId}`;
    },
    isCardEmpty() {
      return !this.isNewRules && !this.isRulesDuplicated && !this.rulesId;
    },
    isFeaturesNotChecked() {
      return !this.rulesFeatures.length;
    },
    isLinksToNotChecked() {
      return !this.rulesLinksTo.length;
    },
    isFormInvalid() {
      return this.isFeaturesNotChecked || this.isLinksToNotChecked;
    },
    needToCancelFormChange() {
      return this.isNewRules || this.isRulesDuplicated || this.formWasEdited;
    },
    secondaryButtonTitle() {
      return this.needToCancelFormChange ? 'Отменить' : 'Дублировать';
    },
    isSubmitButtonDisabled() {
      return (this.tryToSend && this.isFormInvalid) || this.isLoading;
    },
    currentTabId() {
      return this.$parent.data._?.tabId;
    },
    parentStorageKey() {
      return this.$parent?.storageKey;
    },
  },

  methods: {
    handleExistRulesFetchEvent({ childCardTabId, itemId }) {
      if (childCardTabId === this.currentTabId) {
        this.clearRulesForm();
        this.getRulesById(itemId);
      }
    },
    reloadWindow() {
      window.location.reload();
    },
    redirectToDirectory() {
      const payload = {
        parentStorageKey: this.parentStorageKey,
        catalogTabId: this.$parent.data._?.catalogTabId,
      };

      utils.bridge.trigger('onOpenItemsCatalog', payload);
    },
    duplicateRules() {
      this.isRulesDuplicated = true;
      this.formWasEdited = false;
      this.isNewRules = false;
      this.rulesAuthor = null;
      this.dateFrom = null;
      this.rulesId = null;
      this.savedRulesData = null;
    },
    checkFieldIsInvalid(value) {
      if (!value) {
        return true;
      }
      return false;
    },
    handleSecondaryButtonAction() {
      if (this.needToCancelFormChange) {
        this.cancelRulesChange();
      } else {
        this.duplicateRules();
      }
    },
    handleNewRulesCreateEvent(tabId) {
      if (tabId === this.currentTabId) {
        this.clearRulesForm();
        this.isNewRules = true;
      }
    },
    handleChangeActivity(value) {
      this.isActiveCard = value;
    },
    clearRulesForm() {
      this.hasError = false;
      this.rulesId = null;
      this.rulesAuthor = null;
      this.rulesLinksTo = [];
      this.rulesFeatures = [];
      this.isActiveCard = true;
      this.isRulesDuplicated = false;
      this.isNewRules = false;
      this.dateFrom = null;
      this.savedRulesData = null;
      this.tryToSend = false;
      this.formWasEdited = false;
    },
    cancelRulesChange() {
      this.formWasEdited = false;

      if (this.savedRulesData) {
        this.updateRules(this.savedRulesData);
      } else {
        this.clearRulesForm();
      }
    },
    async getRulesById(id) {
      if (id) {
        const URL = `${this.$options.API_URL}/retail_rules/${id}`;

        try {
          this.isLoading = true;
          const response = await fetch(URL);

          if (response.ok) {
            const body = await response.json();
            this.savedRulesData = body;
            this.updateRules(body);
          }
        } catch (error) {
          this.createNotification(error.message, 'error');
          this.isLoading = false;
          this.hasError = true;
        } finally {
          this.isLoading = false;
        }
      }
    },
    updateRules(payload) {
      this.rulesId = payload.id;
      this.isActiveCard = payload.is_active;
      this.rulesAuthor = payload.creator.username;
      this.dateFrom = payload.created_at.slice(0, 10);
      this.rulesLinksTo = payload.linksTo;
      this.rulesFeatures = payload.features;
    },
    async fetchItems(domain) {
      const URL = domain ? `${this.$options.API_URL}/${domain}` : this.$options.API_URL;
      let result = [];

      try {
        this.selectLoading = domain;
        const response = await fetch(URL);

        if (response.ok) {
          result = await response.json();
        }
      } catch (error) {
        this.createNotification(error.message, 'error');
        this.selectLoading = null;
      } finally {
        this.selectLoading = null;
      }

      return result;
    },
    async getLinksTo() {
      const domain = '';
      const result = await this.fetchItems(domain);
      this.allLinksTo = result.length ? result : this.allLinksTo;
    },
    async getFeatures() {
      const domain = 'features';
      const result = await this.fetchItems(domain);
      this.allFeatures = result.length ? result : this.allFeatures;
    },
    submitRulesForm() {
      this.tryToSend = true;

      if (this.isFormInvalid) {
        return;
      }

      if (this.rulesId) {
        this.updateExistRules();
      }

      if (this.isNewRules || this.isRulesDuplicated) {
        this.createNewRules();
      }
    },
    async createNewRules() {
      try {
        this.isLoading = true;
        const URL = `${this.$options.API_URL}/`;

        const body = JSON.stringify({
          category_ids: this.rulesLinksTo.map(({ id }) => id),
          feature_ids: this.rulesFeatures.map(({ id }) => id),
          is_active: this.isActiveCard,
        });

        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          body,
        };

        const response = await fetch(URL, options);

        if (response.ok) {
          const responseBody = await response.json();

          this.savedRulesData = null;
          this.formWasEdited = false;
          this.createNotification('Политика успешно создана', 'success');
          this.isNewRules = false;
          this.isRulesDuplicated = false;
          this.rulesId = responseBody.id;
        }
      } catch (error) {
        this.createNotification(error.message, 'error');
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },
    async updateExistRules() {
      try {
        this.isLoading = true;
        const URL = `${this.$options.API_URL}/${this.rulesId}`;

        const body = JSON.stringify({
          category_ids: this.rulesLinksTo.map(({ id }) => id),
          feature_ids: this.rulesFeatures.map(({ id }) => id),
          is_active: this.isActiveCard,
        });

        const options = {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          body,
        };

        const response = await fetch(URL, options);

        if (response.ok) {
          this.savedRulesData = null;
          this.formWasEdited = false;
          this.createNotification('Политика успешно обновлена', 'success');
        }
      } catch (error) {
        this.createNotification(error.message, 'error');
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },
    localizeValue(value) {
      return utils.l10n(value);
    },
  },

  created() {
    utils.bridge.bind('onCreateNewItem', this.handleNewRulesCreateEvent);
    utils.bridge.bind('onFetchExistItem', this.handleExistRulesFetchEvent);
  },
  beforeDestroy() {
    utils.bridge.unbind('onCreateNewItem', this.handleNewRulesCreateEvent);
    utils.bridge.unbind('onFetchExistItem', this.handleExistRulesFetchEvent);
  },
  mounted() {
    utils.bridge.trigger('onTabMounted', { tabId: this.currentTabId, parentStorageKey: this.parentStorageKey });
  },
};
</script>

  <style scoped>
  .rules-card {
    width: 100%;
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 12px;
    font-weight: 600;
  }

  .empty-card-container,
  .error-card-container {
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .rules-card h2 {
    font-weight: 600;
  }
  .header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
  .header__title-block {
    display: flex;
    align-items: center;
  }
  .header-title {
    margin-bottom: 0;
    margin-right: 12px;
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
  }
  .main {
    margin-bottom: 20px;
  }
  .main__title {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
  }
  .main__sections {
    display: flex;
    gap: 20px;
  }
  .main__section {
    display: flex;
    flex-direction: column;
    width: 437px;
  }
  .inputblock {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
  }
  .inputblock__label {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #9B9B9B;
    margin: 0px;
  }
  .input {
    display: flex;
    align-items: center;
    width: 438px;
    min-width: 350px;
    height: 46px;
    margin: 0;
    padding: 0px 8px 0px 12px;
    border: none;
    border-radius: 8px;
    background: #f7f7f7;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  }
  .input:hover {
    background-color: #E9E9E9;
    cursor: pointer;
  }
  .input:focus {
    border-color: #5295D5;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem #5295D5;
    box-shadow: 0 0 0 0.2rem #5295D5;
  }
  .input:disabled {
    cursor: text;
  }

  .inputblock__input,
  .multiselect {
    min-width: 350px;
    min-height: 46px;
  }

  .inputblock__input_invalid,
  .inputblock__input_invalid:hover,
  .inputblock__input_invalid:focus {
    border: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #FAE6EB;
  }

  .select {
    display: flex;
    align-items: center;
    width: 438px;
    min-width: 350px;
    height: 46px;
    margin: 0;
    padding: 0px 8px 0px 12px;
    border: none;
    border-radius: 8px;
    background: #f7f7f7 url('./arrow-down.svg') no-repeat center right 10px;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
  }

  .select-custom-option {
    display: flex;
    row-gap: 6px;
    align-items: center;
    padding: 3px 0;
  }

  .select-custom-option__label {
    font-size: 14px;
    font-weight: 500;
  }
  .button-return {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    border: none;
    border-radius: 50%;
    background: #F7F7F7 url('./arrow-left.svg') no-repeat center center;
  }
  .button-return:hover {
    background-color: #E9E9E9;
  }
  .button-return_disabled {
    background-color: #E9E9E9;
  }
  .button-return:focus {
    outline: none;
  }
  .header__search-form {
    display: flex;
  }
  .footer {
    position: relative;
    margin-top: auto;
    align-self: flex-end;
  }
  .input-search {
    display: flex;
    align-items: center;
    width: 438px;
    min-width: 350px;
    height: 46px;
    padding: 0px 8px 0px 12px;
    margin: 0;
    border: none;
    border-radius: 8px;
    padding-left: 42px;
    background: #f7f7f7 url('./search-icon.svg' ) no-repeat 12px;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 215px;
    height: 46px;
    border: none;
    border-radius: 8px;
    transition: background-color .3s ease-in-out;
  }
  .button:disabled {
    background-color: #F7F7F7;
    color: #D4D4D4;
    cursor: not-allowed;
  }

  .button_primary {
    background-color: #5295D5;
    color: #ffffff;
  }
  .button_secondary {
    background-color: #F7F7F7;
    color: #000000;
  }
  .button_primary:not(:disabled):hover {
    background-color: #3C80C1;
    transition: background-color .3s ease-in-out;
  }
  .button_secondary:not(:disabled):hover {
    background-color: #E9E9E9;
    transition: background-color .3s ease-in-out;
  }
  .button_disabled {
    background-color: #F7F7F7;
    color: #D4D4D4;
    cursor: default;
  }
  .footer__buttons-block {
    display: flex;
    gap: 8px;
  }
  .footer__checkbox {
    width: 200px;
    margin-bottom: 15px;
  }
  </style>
