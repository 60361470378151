<template>
  <div
    ref="base"
    class="gp-select"
  >
    <input
      ref="input"
      v-model="inputValue"
      class="form-control"
      :placeholder="placeholder"
      @focus="optionsOpened = true; showAllOptions = true"
      @keydown="handleInputKeyDown"
      @input="$emit('select-input', $event)"
    />

    <a
      v-if="inputValue === ''"
      href="javascript:void(0)"
      @click="optionsOpened = true; showAllOptions = true"
    >
      <svg viewBox="-4 -4 18 18">
        <path d="M0 1 L10 1 L 5 9 Z" />
      </svg>
    </a>

    <a
      v-else
      href="javascript:void(0)"
      @click="submitValue(null)"
    >
      <feather-icon name="x" />
    </a>

    <ul
      v-if="optionsOpened && visibleOptions.length > 0"
      refs="options"
    >
      <li
        v-for="(option, i) in recentOptions"
        :key="i"
        :class="`${option.item.class || ''} ${selectedOption === option ? 'selected' : ''}`"
      >
        <a
          href="javascript:void(0)"
          class="gp-select-remove-from-recent"
          @click.stop="removeFromRecent(option.item)"
        >
          <feather-icon name="x" />
        </a>

        <a
          href="javascript:void(0)"
          @click="submitValue(option.item)"
        >
          <span
            v-if="option.item.username"
            class="gp-select-username"
          >
            {{ option.item.username }}
            <feather-icon v-if="option.item.shared" name="users" />
            <feather-icon v-else name="user" />
          </span>

          <span v-if="option.item.badge" class="gp-select-badge">
            {{ option.item.badge }}
          </span>

          <span
            v-for="(part, j) in formatOption(option)"
            :key="j"
            :class=" { matched: part.matched }"
          >{{ part.text }}</span>
        </a>
      </li>

      <li v-if="recentOptions.length">
        <hr />
      </li>

      <li
        v-for="(option, n) in visibleOptions"
        :key="recentOptions.length + n"
        v-if="recentOptions.indexOf(option.item) === -1 && (n < threshold || showMore)"
        :class="`${option.item.class || ''} ${selectedOption === option ? 'selected' : ''}`"
      >
        <a
          href="javascript:void(0)"
          @click="submitValue(option.item)"
        >
          <span
            v-if="option.item.username"
            class="gp-select-username"
          >
            {{ option.item.username }}
            <feather-icon v-if="option.item.shared" name="users" />
            <feather-icon v-else name="user" />
          </span>

          <span
            v-if="option.item.badge"
            class="gp-select-badge"
          >{{ option.item.badge }}</span>

          <span
            v-for="(part, i) in formatOption(option)"
            :key="i"
            :class="{ matched: part.matched }"
          >{{ part.text }}</span>
        </a>
      </li>

      <li v-if="visibleOptions.length > threshold && !showMore">
        <a
          href="javascript:void(0)"
          @click="showMore = true"
        >
          <l10n value="Show more..." />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
const Fuse = require('fuse.js').default;

module.exports = {
  props: {
    value: { type: Object },
    options: { type: Array },
    placeholder: { type: String },
    autofocus: { type: Boolean, default: false },
    recentOptionsKey: { type: String },
    threshold: { type: Number, default: 100 },
  },

  data() {
    let recentOptionsIds = [];
    if (this.recentOptionsKey && localStorage[this.recentOptionsKey]) {
      try {
        recentOptionsIds = JSON.parse(localStorage[this.recentOptionsKey]);
      } catch (ex) {
        console.warn('cannot load recent options from local storage', ex);
      }
    }
    return {
      inputValue: this.value ? this.value.name : '',
      optionsOpened: false,
      showAllOptions: true,
      selectedOption: null,
      recentOptionsIds,
      showMore: false,
    };
  },

  mounted() {
    if (this.autofocus) {
      this.optionsOpened = true;
      this.$refs.input.focus();
      this.$refs.input.select();
      this.$refs.input.scrollLeft = 0;
    }

    window.addEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.handleClickOutside);
  },

  methods: {
    getOptionId(option) {
      return option.id || option.name;
    },

    addToRecent(option) {
      if (this.recentOptionsKey) {
        this.removeFromRecent(option);
        this.recentOptionsIds.push(this.getOptionId(option));
        if (this.recentOptionsIds.length > 10) {
          this.recentOptionsIds.splice(0, this.recentOptionsIds.length - 10);
        }
      }
    },

    removeFromRecent(option) {
      if (this.recentOptionsKey) {
        const i = this.recentOptionsIds.indexOf(this.getOptionId(option));
        if (i !== -1) {
          this.recentOptionsIds.splice(i, 1);
        }
      }
    },

    submitValue(value) {
      if (this.value !== value) {
        this.$emit('input', value);
        this.$emit('change', value);
        this.inputValue = '';
      }

      this.optionsOpened = false;
      this.$refs.input.blur();
    },

    handleClickOutside(e) {
      if ($(e.target).closest(this.$refs.base).length === 0) {
        this.optionsOpened = false;
      }
    },

    handleInputKeyDown(e) {
      switch (e.keyCode) {
        // ArrowUp
        case 38:
          this.selectPreviousOption();
          break;
        // ArrowDown
        case 40:
          this.selectNextOption();
          break;
        // Enter
        case 13:
          if (this.selectedOption) {
            this.submitValue(this.selectedOption.item);
          }
          break;
        // Esc
        case 27:
          this.optionsOpened = false;
          this.$refs.input.blur();
          break;
        // Other keys
        default:
          break;
      }
    },

    selectNextOption() {
      if (this.visibleOptions.length > 0) {
        const n = this.visibleOptions.length;
        const i = this.visibleOptions.indexOf(this.selectedOption);
        if (i === -1) {
          this.selectedOption = this.visibleOptions[0];
        } else if (i < n - 1) {
          this.selectedOption = this.visibleOptions[i + 1];
        }
      } else {
        this.selectedOption = null;
      }
    },

    selectPreviousOption() {
      if (this.visibleOptions.length > 0) {
        const i = this.visibleOptions.indexOf(this.selectedOption);
        if (i > 0) {
          this.selectedOption = this.visibleOptions[i - 1];
        }
      } else {
        this.selectedOption = null;
      }
    },

    formatOption({ item, matches }) {
      let indices = _(matches).map(({ indices }) => indices).flatten().value();
      let i = 0;
      const text = item.name;
      const parts = [];

      while (i < text.length) {
        if (indices.length > 0) {
          const [a, b] = indices[0];
          if (i === a) {
            parts.push({
              text: text.slice(a, b + 1),
              matched: true,
            });
            i = b + 1;
            indices = indices.slice(1);
          } else {
            parts.push({
              text: text.slice(i, a),
              matched: false,
            });
            i = a;
          }
        } else {
          parts.push({
            text: text.slice(i),
            matched: false,
          });
          i = text.length;
        }
      }

      return parts;
    },
  },

  watch: {
    value() {
      this.inputValue = this.value ? this.value.name : '';

      if (this.value) {
        this.addToRecent(this.value);
      }
    },

    inputValue() {
      this.showAllOptions = false;
    },

    visibleOptions() {
      if (this.visibleOptions.indexOf(this.selectedOption) === -1) {
        this.selectedOption = null;
      }
    },

    recentOptionsIds: {
      deep: true,
      handler() {
        if (this.recentOptionsKey) {
          localStorage[this.recentOptionsKey] = JSON.stringify(this.recentOptionsIds);
        }
      },
    },
  },

  computed: {
    optionsSearch() {
      return new Fuse(this.options, {
        isCaseSensitive: false,
        shouldSort: true,
        includeMatches: true,
        keys: ['name'],
      });
    },

    matchedOptions() {
      return this.optionsSearch.search(this.inputValue);
    },

    recentOptions() {
      if (!this.recentOptionsKey) {
        return [];
      }

      return _(this.visibleOptions)
        .filter(({ item: option }) => this.recentOptionsIds.indexOf(this.getOptionId(option)) !== -1)
        .sortBy(({ item: option }) => this.recentOptionsIds.indexOf(this.getOptionId(option)))
        .value();
    },

    visibleOptions() {
      return this.showAllOptions || this.inputValue === ''
        ? this.options.map((item) => ({ item }))
        : this.matchedOptions;
    },
  },
};
</script>

<style>
.gp-select {
  position: relative;
}
.gp-select input {
  font-size: 1em;
  padding-left: 8px;
  padding-right: 22px;
  height: 26px;
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  color: #212529!important;
}
.gp-select input:focus {
  box-shadow: none;
}
.gp-select > a {
  position: absolute;
  top: 50%;
  right: 2px;
  width: 20px;
  text-align: center;
  transform: translate(0, -50%);
}
.gp-select > a svg {
  width: 18px;
  height: 18px;
  color: var(--gray);
  fill: var(--gray);
}
.gp-select ul {
  font-size: 1em;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid var(--gray);
  position: absolute;
  box-shadow: 0 2px 10px -5px gray;
  background-color: white;
  left: 0;
  right: 0;
  margin-top: -1px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 300px;
  z-index: 100;
}
.gp-select ul a {
  color: #212529;
  display: block;
  padding: 2px 8px;
}
.gp-select ul a:hover {
  text-decoration: none;
}
.gp-select li:hover,
.gp-select li.selected {
  background-color: var(--cyan);
}
.gp-select li:hover *,
.gp-select li.selected * {
  color: white;
}
.gp-select .matched {
  color: var(--red);
  text-decoration: underline;
}
.gp-select ul {
  right: initial;
  min-width: 100%;
}
.gp-select li a {
  line-height: 18px;
  padding: 3px 7px;
}
.gp-select li:hover .gp-select-username {
  color: var(--light-gray);
}
.gp-select input {
  color: #222!important;
  background-color: white;
  border-color: var(--dark);
}
.my-dark-theme .gp-select input {
  color: white!important;
  background-color: transparent;
  border-color: var(--light);
}
.gl-select-shared {
  float: right;
}
.gp-select-username svg {
  width: 16px;
  height: 16px;
  color: inherit;
}
.gp-select-remove-from-recent {
  float: right;
  margin-top: -1px;
  margin-left: 2px;
  margin-right: -7px;
  margin-bottom: -1px;
  color:  var(--gray);
}
.gp-select-remove-from-recent svg {
  width: 16px;
  height: 16px;
}
.gp-select ul a.gp-select-remove-from-recent {
  color: var(--gray);
}
.gp-select hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.gp-select-username {
  float: right;
  color: var(--gray);
  font-size: 0.9em;
}
.gp-select-username svg {
  width: 16px;
  height: 16px;
  color: inherit;
}
.gl-select-shared {
  float: right;
}
</style>
