var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "19",
        height: "23",
        viewBox: "0 0 19 23",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M17 10V6.3C17 4.61984 17 3.77976 16.673 3.13803C16.3854 2.57354 15.9265 2.1146 15.362 1.82698C14.7202 1.5 13.8802 1.5 12.2 1.5H5.8C4.11984 1.5 3.27976 1.5 2.63803 1.82698C2.07354 2.1146 1.6146 2.57354 1.32698 3.13803C1 3.77976 1 4.61984 1 6.3V16.7C1 18.3802 1 19.2202 1.32698 19.862C1.6146 20.4265 2.07354 20.8854 2.63803 21.173C3.27976 21.5 4.11984 21.5 5.8 21.5H9M11 10.5H5M7 14.5H5M13 6.5H5M15 20.5V14.5M12 17.5H18",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }