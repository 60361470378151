var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tooltip-container" }, [
    _c(
      "div",
      {
        staticClass: "tooltip-icon",
        on: {
          mouseover: _vm.onIconMouseover,
          mouseleave: _vm.onIconMouseleave,
          focusin: _vm.onIconMouseover,
          focusout: _vm.onIconMouseleave
        }
      },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tooltip", style: { opacity: _vm.isHovered ? 1 : 0 } },
      [_vm._t("tooltip-data")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }