<template>
  <div class="tooltip-container">
    <div
      class="tooltip-icon"
      @mouseover="onIconMouseover"
      @mouseleave="onIconMouseleave"
      @focusin="onIconMouseover"
      @focusout="onIconMouseleave"
    >
      <slot />
    </div>
    <div
      class="tooltip"
      :style="{ opacity: isHovered ? 1 : 0 }"
    >
      <slot name="tooltip-data" />
    </div>
  </div>
</template>

<script>
module.exports = {
  props: {

  },

  data() {
    return {
      isHovered: false,
    };
  },

  methods: {
    onIconMouseover() {
      this.isHovered = true;
    },

    onIconMouseleave() {
      this.isHovered = false;
    },
  },
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}
.tooltip {
  color: #9B9B9B;
  font-size: 12px;
  padding: 12px;
  border-radius: 12px;
  left: 30px;
  top: 18px;
  transition: opacity .5s ease-in;
  position: absolute;
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(160, 160, 170, 0.20);
}
</style>
