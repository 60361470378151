<template>
    <div>
        <my-errors :errors="errors" @retry="requestData"/>

        <my-caption :caption="caption"/>

        <my-progress
            :key="reportId"
            :working="working"
            :sorting="sorting"
            :progress="progress"
            :downloading="downloading"
            :processRows="processRows"
            :processTime="processTime"
            :meta="meta"
            :assembly="assembly"/>

        <my-filters v-model="filters" :chart="chart"/>

        <my-tooltip
            ref="tooltip"
            :meta="meta"
            :chart="chart"
            :keepOnly="keepOnly"
            :exclude="exclude"
            :actions="actions"
            />

        <div class="my-chart" :style="style" ref="base">
            <div ref="chart"></div>
        </div>
    </div>
</template>

<script>
module.exports = {
    mixins: [
        require("../data.js"),
        require("./base.js"),
        require("./margin.js"),
        require("./coordinate-grid.js"),
        require("./line-chart.js"),
    ]
}
</script>

<style>
.dc-legend-item text {
    transform: translate(2px, 4px);
}
</style>