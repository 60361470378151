<template>
    <my-dialog
        v-if="file != null && !dismissed"
        title="File is ready to download"
        @close="dismissed = true"
        >
        <p><l10n value="Pease use the link below to start downloading"/></p>
        <div><a :href="file.link" :download="file.name" target="_blank">{{file.name}}</a></div>
        <div>
            <small class="text-muted">
                <l10n
                    value="{size} bytes, {rows} rows, {cols} columns"
                    :size="new Number(file.size).toLocaleString()"
                    :rows="new Number(file.rows).toLocaleString()"
                    :cols="new Number(file.cols).toLocaleString()"
                    />
            </small>
        </div>
    </my-dialog>
</template>
<script>
module.exports = {
    data: function() {
        return {
            dismissed: true,
        }
    },
    props: {
        file: { type: Object },
    },
    watch: {
        file: function() {
            this.dismissed = false
        }
    }
}
</script>
