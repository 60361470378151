<template>
<div class="my-sitemap">
    <ul>
        <li v-for="node in flat" :style="{'--level':node.level}">
            <feather-icon v-if="!node.children" size="18px" name="empty"/>
            <a v-else-if="node.children.length > 0" href="javascript:void(0)" @click="$set(node, 'open', !node.open)">
                <feather-icon size="18px" :name="node.open ? 'book-open' : 'book'"/>
            </a>
            <feather-icon v-else size="18px" name="empty"/>
            <a :href="node.path" nofollow>
                {{node.name}}
            </a>
            <span class="text-muted" v-if="node.title">
                – {{node.title}}
            </span>
            <span class="my-sitemap-here" v-if="node.path == path">
                <feather-icon size="18px" name="arrow-left"/>
                you are here
                <a href="javascript:void(0)" @click="trash(node)" v-if="node.level != 0">
                    <feather-icon size="18px" name="trash"/>
                </a>
            </span>
        </li>
    </ul>
</div>
</template>
<script>
let utils = require('./my-utils')
module.exports = {
    props: {
        path: { type: String }
    },
    data() {
        return {
            root: {
                level: 0,
                path: "/pages/",
                name: "/pages/",
                open: true,
            },
        }
    },
    mounted() {
        this.load([this.root])
        this.info([this.root])
    },
    computed: {
        flat() {
            let flat = []
            let loop = node => {
                flat.push(node)
                if (node.open && node.children)
                    for (let child of node.children)
                        loop(child)
            }
            loop(this.root)
            return flat
        }
    },
    methods: {
        async trash(node) {
            if (window.prompt(`Please type in page name "${node.name}" to delete page`) == node.name) {
                let query = `mutation { deletePage(path: ${utils.quote(node.path.split("/").slice(2,-1).join("/"))}) }`
                await utils.fetchWithAjaxOpts({
                    url: '/graphql?id=sitemap',
                    method: 'POST',
                    data: JSON.stringify({query}),
                    dataType: 'json',
                    contentType: 'application/json'
                })
                if (node.parent)
                    node.parent.children.splice(node.parent.children.indexOf(node), 1)
            }
        },
        async load(nodes) {
            let nextWave = []
            let query = `
                query {
                    ${nodes.map((node, i) => `node${i}: pages(base: ${utils.quote(node.path.split("/").slice(2,-1).join("/"))} mask: "")`).join("\n")}
                }`
            let {data} = await utils.fetchWithAjaxOpts({
                url: '/graphql?id=sitemap',
                method: 'POST',
                data: JSON.stringify({query}),
                dataType: 'json',
                contentType: 'application/json'
            })

            for (let key in data) {
                let node = nodes[key.slice(4)]
                let children = data[key]
                    .filter(path => !path.includes(".") && !path.match(/_\d{4}-\d{2}-\d{2}/))
                    .map(path => ({
                        name: _.last(path.split("/")),
                        path: `/pages/${path}/`,
                        level: node.level + 1,
                        parent: node,
                    }))
                this.$set(node, "children", children)
                nextWave = nextWave.concat(children)
            }
            if (nextWave.length) {
                this.load(nextWave)
                this.info(nextWave)
            }
        },
        async info(nodes) {
            let query = `
                query {
                    ${nodes.map((node, i) => `node${i}: page(path: ${utils.quote(node.path.split("/").slice(2,-1).join("/"))}) { elements }`).join("\n")}
                }`
            let {data} = await utils.fetchWithAjaxOpts({
                url: '/graphql?id=sitemap',
                method: 'POST',
                data: JSON.stringify({query}),
                dataType: 'json',
                contentType: 'application/json'
            })

            for (let key in data) {
                let node = nodes[key.slice(4)]
                let {elements} = data[key]
                try {
                    let text = elements || ""
                    let vars = {}
                    let now = new Date()
                    let today = utils.formatDate(now)
                    let yesterday = utils.formatDate(utils.prevDate(now))
                    let tomorrow = utils.formatDate(utils.nextDate(now))
                    let username = this.username
                    let ctx = { yesterday, today, tomorrow, username }
                    let schema = utils.jsyamlSchema
                    let data = Handlebars.compile(text)(_.assign({}, ctx, vars))
                    data = jsyaml.safeLoad(data, {schema})
                    this.$set(node, "title", _.get(data, "_.title"))
                }
                catch (ex) {
                    this.$set(node, "title", ex.message)
                }         
            }
        }
    }
}
</script>
<style>
.my-sitemap ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.my-sitemap li .feather-icon {
    margin: -1px 0;
}
.my-sitemap li .feather-icon svg {
    transform: translate(0, -1px);
}
.my-sitemap li {
    padding-left: calc(var(--level) * 20px);
}
.my-sitemap-here {
    color: var(--red);
}
.my-sitemap-here .feather-icon-trash {
    visibility: hidden;
    color: var(--red);
}
.my-sitemap-here:hover .feather-icon-trash {
    visibility: visible;
}
</style>