<template>
    <div class="gp-stream">
        <div class="gp-stream-loading" v-if="loading">
            <feather-icon name="clock"/>
        </div>
        <div v-if="dontLoadHuge" class="alert alert-info">
            <button style="float:right;margin:-3px 0" @click="loadHugeConfirmed=true" class="btn btn-sm btn-danger">
                <l10n value="Process {size} records" :size="new Number(streamSize).toLocaleString()"/>
            </button>
            <l10n value="Stream has above {limit} records. Are you sure you want to process them?" :limit="new Number(threshold).toLocaleString()"/>
            <div style="clear:right;"/>
        </div>
        <template v-if="!dontLoadHuge">
            <gp-filter
                v-model="filter_"
                :stream="stream"
                :popup-portal="null"
                :attributes="
                    columns
                        .filter(({indexed}) => indexed)
                        .map(({gqlName:calc, name, type}) => ({name: l10n(name), calc, type}))"
                />
            <div
                v-if="pageCount > 1"
                tabindex="0"
                @keydown="handlePaginatorKeyDown"
                class="plain-table-paginator">

                <a
                    href="javascript:void(0)"
                    :class="{disabled: page === 0}"
                    @click="setDesiredPage(page - 10)">
                    <feather-icon name="chevrons-left"/></a><a
                    href="javascript:void(0)"
                    :class="{disabled: page === 0}"
                    @click="setDesiredPage(page - 1)">
                    <feather-icon name="chevron-left"/></a>

                <a href="javascript:void(0)" @click="promptPage">
                    <l10n value="page {page}" :page="new Number(page+1).toLocaleString()"/></a>

                <a
                    href="javascript:void(0)"
                    :class="{disabled: page === pageCount-1}"
                    @click="setDesiredPage(page + 1)">
                    <feather-icon name="chevron-right"/></a><a
                    href="javascript:void(0)"
                    :class="{disabled: page === pageCount-1}"
                    @click="setDesiredPage(page + 10)">
                    <feather-icon name="chevrons-right"/></a>

                <l10n class="nowrap" :rows="new Number(size).toLocaleString()" value="{rows} rows,"/>
                <l10n class="nowrap" :pages="new Number(pageCount).toLocaleString()" value="{pages} pages,"/>

                <a href="javascript:void(0)" @click="promptPageSize">
                    <l10n class="nowrap" :page-size="new Number(pageSize).toLocaleString()" value="{page-size} rows per page"/>
                </a>

                <inline-help
                    text="Click and use use <Left> and <Right> arrow keys for pagination. Hold <Shift> for fast forward."
                    :html="true"/>

            </div>

            <table
                class="table table-sm table-striped table-hover table-responsive"
                :style="{opacity: loading ? 0.8 : 1}"
                >
                <thead>
                    <tr>
                        <th>
                            <div class="form-check">
                                <input
                                    id="row_all"
                                    type="checkbox"
                                    class="form-check-input"
                                    :checked="allSelected"
                                    @click="toogleSelectedStreamSelection()"
                                    />
                                <label
                                    for="row_all"
                                    class="form-check-label"/>
                            </div>
                        </th>
                        <th
                            v-for="column, i in columns" :data-type="column.type"
                            v-if="
                                (showColumns == null || showColumns.includes(column.gqlName)) && 
                                (hideColumns == null || !hideColumns.includes(column.gqlName))"
                            >
                            <a
                                href="javascript:void(0)"
                                @click="toogleSort(i)">
                                <l10n :value="column.name"/>
                                <feather-icon v-if="sort[0] === i+1" name="arrow-up"/>
                                <feather-icon v-if="sort[0] === -i-1" name="arrow-down"/>
                            </a>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="record in records.rows">
                        <td>
                            <div class="form-check">
                                <input
                                    :id="`row_${record[0]}`"
                                    type="checkbox"
                                    class="form-check-input"
                                    :checked="selection[record[0]]"
                                    @click="toogleSelectedStreamSelection(record[0])"
                                    />
                                <label
                                    :for="`row_${record[0]}`"
                                    class="form-check-label"/>
                            </div>

                        </td>
                        <template 
                            v-for="column, i in columns"
                            v-if="
                                (showColumns == null || showColumns.includes(column.gqlName)) && 
                                (hideColumns == null || !hideColumns.includes(column.gqlName))"
                            >
                            <td :data-type="column.type">
                                <template v-if="isLink(record[i+1])">
                                    <a :href="record[i+1]" target="_blank">{{record[i+1]}}</a>
                                </template>
                                <template v-else>{{formatValue(record[i+1], column)}}</template>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>
            <p>
                <l10n
                    :rows="new Number(selectedCount).toLocaleString()"
                    value="{rows} rows selected"/>
            </p>
        </template>
        <div class="gp-stream-actions">
            <button
                v-if="!dontLoadHuge"
                :disabled="!selectedCount"
                class="btn btn-secondary"
                @click="deleteSelectedRows">
                <l10n value="Delete selected rows"/>
            </button>
            <button
                class="btn btn-secondary"
                @click="exportData">
                <l10n value="Export data"/>
            </button>
            <button
                class="btn btn-secondary"
                style="position: relative;">
                <l10n value="Import data"/>
                <input type="file" @change="importData" :style="{
                    position: 'absolute',
                    opacity: 0,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: '100%'
                }">
            </button>
            <button class="btn btn-secondary" @click="addRow">
                <l10n value="Manually add a row"/>
            </button>
        </div>
        <my-dialog v-if="newRow" @close="newRow = null" title="New row">
            <div class="gp-stream-new-row">
                <table ref="newRow" @keydown="handleKeyDown">
                    <tbody>
                        <tr v-for="column, i in columns">
                            <td>{{column.name}}</td>
                            <td>
                                <input
                                    class="form-control form-control-sm"
                                    v-if="['string'].includes(column.type)"
                                    :value="newRow[column.name]"
                                    @change="newRow[column.name] = $event.target.value"/>
                                <input
                                    class="form-control form-control-sm"
                                    v-else-if="['int8', 'int16', 'int32', 'int64'].includes(column.type)"
                                    type="number"
                                    :value="newRow[column.name]"
                                    @change="newRow[column.name] = parseInt($event.target.value)"/>
                                <input
                                    class="form-control form-control-sm"
                                    v-else-if="['float', 'double'].includes(column.type)"
                                    type="number"
                                    :value="newRow[column.name]"
                                    @change="newRow[column.name] = parseFloat($event.target.value)"/>
                                <input
                                    class="form-control form-control-sm"
                                    v-else-if="['date'].includes(column.type)"
                                    type="date"
                                    :value="newRow[column.name]"
                                    @change="newRow[column.name] = $event.target.value"/>
                                <input
                                    class="form-control form-control-sm"
                                    v-else-if="['datetime'].includes(column.type)"
                                    type="datetime-local"
                                    :value="newRow[column.name]"
                                    @change="newRow[column.name] = $event.target.value"/>
                                <textarea
                                    class="form-control form-control-sm"
                                    v-else-if="['json'].includes(column.type)"
                                    :value="newRow[column.name]"
                                    @change="newRow[column.name] = $event.target.value"/>
                                <gp-check
                                    v-else-if="['bool'].includes(column.type)"
                                    :checked="newRow[column.name]"
                                    @change="newRow[column.name] = $event"/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <template v-slot:footer>
                <button class="btn btn-primary" @click="submitNewRow">
                    <l10n value="Submit"/>
                </button>
                <button class="btn btn-secondary" @click="newRow = null">
                    <l10n value="Cancel"/>
                </button>
            </template>
        </my-dialog>
    </div>
</template>
<script>
let utils = require("../my-utils")

module.exports = {
    props: {
        stream: { type: String },
        filter: { type: Array },
        threshold: { type: Number, default: 1e7 },
        showColumns: { type: Array },
        hideColumns: { type: Array },
    },
    data() {
        return {
            l10n: utils.l10n,
            records: { rows: [], size: 0 },
            selection: {},
            columns: [],
            pageSize: 20,
            desiredPage: 0,
            streamSize: 0,
            filter_: _.cloneDeep(this.filter) || [],
            sort: [],
            loading: null,
            loadHugeConfirmed: false,
            newRow: null,
        }
    },
    mounted() {
        if (this.stream)
            this.loadStream(this.stream)
    },
    beforeDestroy() {
    },
    watch: {
        newRow() {
            Vue.nextTick(() => $(this.$refs.newRow).find("tr:first-child input").focus().select())
        },
        loadHugeConfirmed() {
            if (this.stream)
                this.loadStream(this.stream)
        },
        stream() {
            this.columns = []
            this.records = { rows: [], size: 0 }
            this.selection = {}
            this.streamSize = 0
            this.desiredPage = 0
            if (this.stream)
                this.loadStream(this.stream)
        },
        skip() {
            if (this.stream)
                this.loadStream(this.stream)
        },
        take() {
            if (this.stream)
                this.loadStream(this.stream)
        },
        sort() {
            if (this.stream)
                this.loadStream(this.stream)
        },
        filterString() {
            if (this.stream)
                this.loadStream(this.stream)
        },
    },
    computed: {
        huge() {
            return this.streamSize >= this.threshold
        },
        size() {
            return this.records.size
        },
        page() {
            let desiredPage = this.desiredPage
            return Math.max(0, Math.min(this.pageCount - 1, desiredPage))
        },
        pageCount() {
            return Math.floor((this.size + this.pageSize - 1) / this.pageSize)
        },
        take() {
            return this.pageSize
        },
        skip() {
            return this.page * this.pageSize
        },
        allSelected() {
            return _.every(
                this.records.rows,
                ([id]) => this.selection[id])
        },
        selectedCount() {
            return _(this.selection).values().sum()
        },
        filterString() {
            return this.makeFilter(this.filter_)
        },
        dontLoadHuge() {
            return !(!this.huge || this.loadHugeConfirmed || this.filterString)
        },
    },
    methods: {
        handleKeyDown(e) {
            if (e.key == "Enter" && e.target.tagName != "TEXTAREA") {
                if (document.activeElement?.tagName == "INPUT") {
                    let event = new Event("change")
                    document.activeElement.dispatchEvent(event)
                }
                Vue.nextTick(this.submitNewRow)
            }
        },
        addRow() {
            let record = {}
            for (let column of this.columns) {
                switch (column.type) {
                case "json":
                    record[column.name] = "null"
                    break
                }
            }
            this.newRow = record
        },
        async submitNewRow() {
            let stream = this.stream
            let record = this.newRow
            this.newRow = null
            for (let column of this.columns) {
                switch (column.type) {
                case "string":
                    record[column.name] = record[column.name].trim()
                    break
                case "json":
                    record[column.name] = JSON.parse(record[column.name])
                    break
                }
            }
            await utils.appendRecords(stream, [record])
            this.loadStream(stream)
        },
        toogleSort(i) {
            if (this.sort[0] === i+1)
                this.sort = [-i-1];
            else if (this.sort[0] === -i-1)
                this.sort = []
            else
                this.sort = [i+1]
        },
        reload() {
            if (this.stream)
                this.loadStream(this.stream)
        },
        loadStream(stream) {
            let columns = this.columns
            let filter = this.filterString
            this.submitQuery(`
                query {
                    dataset {
                        streams {
                            stream:${stream} {
                                size
                                columns {
                                    name
                                    type
                                    synonym
                                    indexed
                                    gqlName
                                    description
                                }
                                ${columns.length > 0 && !this.dontLoadHuge?
                                    `
                                    records(
                                        ${filter ? `filter: ${utils.quote(filter)},` : ""}
                                        take: ${this.take},
                                        skip: ${this.skip},
                                        sort: [${this.sort.join(",")}])
                                    {
                                        size
                                        rows(columns:${utils.quote(["__id"].concat(columns.map(({gqlName}) => gqlName)))})
                                    }
                                    ` : ""}
                            }
                        }
                    }
                }
            `).then(({dataset:{streams:{stream:{
                    size,
                    columns,
                    records,
            }}}}) => 
            {
                if (this.stream !== stream)
                    return

                this.streamSize = size
                this.columns = columns

                if (records !== undefined)
                    this.records = records

                else if (this.columns.length > 0 && !this.dontLoadHuge)
                    this.loadStream(stream)

            })
        },
        setDesiredPage(page) {
            this.desiredPage = page
        },
        promptPageSize() {
            let pageSize = prompt("Rows per page:", this.pageSize)
            if (pageSize !== null) {
                pageSize = parseInt(pageSize)
                if (!_.isNaN(pageSize))
                    this.pageSize = pageSize
            }
        },
        handlePaginatorKeyDown: function(e) {
            switch (e.key) {
                case "ArrowLeft":
                    e.preventDefault()
                    e.stopPropagation()
                    this.setDesiredPage(this.page - (e.shiftKey ? 10 : 1))
                    break
                case "ArrowRight":
                    e.preventDefault()
                    e.stopPropagation();
                    this.setDesiredPage(this.page + (e.shiftKey ? 10 : 1))
                    break
            }
        },
        toogleSelectedStreamSelection(id) {
            if (id === undefined) {
                let state = !this.allSelected
                let selection = this.selection
                for (let record of this.records.rows)
                    this.$set(selection, record[0], state)
            }
            else {
                let selection = this.selection
                this.$set(selection, id, !selection[id])
            }
        },
        async exportData() {
            let stream = this.stream
            let query = `
                query {
                  dataset {
                    streams {
                      ${stream} {
                        records {
                          file(format:"csv") {
                            link
                          }
                        }
                      }
                    }
                  }
                }`

            let res = await Promise
                .resolve($.ajax({
                    url: '/graphql',
                    method: 'POST',
                    data: JSON.stringify({query}),
                    dataType: 'json',
                    contentType: 'application/json'
                }))

            let link = res.data.dataset.streams[stream].records.file.link
            let a = document.createElement("a")
            a.href = link
            a.setAttribute("download", `${stream} ${moment().format('Y-M-D H:m:s')}.csv.gz`)
            a.style.display = "none"
            document.body.appendChild(a)
            a.click()
        },
        importData(e) {
            let stream = this.stream
            let file = e.target.files[0]
            e.target.value = ""
            if (!confirm(utils.l10n("Are you sure you want to import data from file {file} into stream {stream}?").replace("{file}", file.name).replace("{stream}", this.stream)))
                return
            let format = file.name.split(".").slice(1).concat("b64").join(".")
            let reader = new FileReader()
            reader.addEventListener("load", async () => {
                let records = reader.result.split(";base64,")[1]
                let query = `
                    mutation {
                        appendRecords(
                            skip: 1,
                            stream: ${utils.quote(stream)},
                            format: ${utils.quote(format)},
                            records: ${utils.quote(records)})
                    }`
                let res = await Promise
                    .resolve($.ajax({
                        url: '/graphql',
                        method: 'POST',
                        data: JSON.stringify({query}),
                        dataType: 'json',
                        contentType: 'application/json'
                    }))
                let rows = res.data.appendRecords.length
                alert(utils.l10n("{rows} rows have been imported into stream {stream}")
                    .replace("{rows}", new Number(rows).toLocaleString())
                    .replace("{stream}", stream))
            })
            reader.readAsDataURL(file)
        },
        deleteSelectedRows() {
            let stream = this.stream
            let selection = this.selection

            let ids = _(selection)
                .toPairs()
                .filter(([id, selected]) => selected)
                .map(([id, selected]) => id)
                .value()

            if (!window.confirm(`Are you sure you want to delete ${new Number(ids.length)} rows?`))
                return

            this.submitQuery(`
                mutation {
                    removeRecords(
                        stream: ${utils.quote(stream)}
                        ids: [${ids.join(",")}])
                }
            `).then(({removeRecords}) => {
                if (removeRecords && this.stream == stream) {
                    return this.loadStream(stream)
                }
            }).then(() => {
                for (let id of ids)
                    this.$set(selection, id, false)
            })
        },
        async submitQuery(query) {
            let loading = utils.randomId()
            this.loading = loading
            try {
                let {errors, data} = await Promise
                    .resolve($.ajax({
                        url: '/graphql?wait',
                        method: 'POST',
                        data: JSON.stringify({query}),
                        dataType: 'json',
                        contentType: 'application/json'
                    }))
                if (errors) {
                    this.errors = errors
                    throw errors
                }
                else {
                    this.errors = null
                    return data
                }
            }
            finally {
                if (this.loading === loading)
                    this.loading = null
            }
        },
        isLink(x) {
            return x && x.startsWith && (x.startsWith("http://") || x.startsWith("https://"))
        },
        formatValue(x, column) {
            switch (column.type) {
                case "json":
                    return JSON.stringify(x, null, 1)

                case "date":
                    if (!x)
                        return "-"
                    {
                        x = `${x}`
                        let Y = x.slice(0, x.length-4)
                        let m = x.slice(x.length-4, x.length-2)
                        let d = x.slice(x.length-2, x.length)
                        return `${Y}-${m}-${d}`
                    }

                case "datetime":
                    if (!x)
                        return "-"
                    {
                        x = `${x}`
                        let Y = x.slice(0, x.length-10)
                        let m = x.slice(x.length-10, x.length-8)
                        let d = x.slice(x.length-8, x.length-6)
                        let H = x.slice(x.length-6, x.length-4)
                        let M = x.slice(x.length-4, x.length-2)
                        let S = x.slice(x.length-2, x.length)
                        return `${Y}-${m}-${d} ${H}:${M}:${S}`
                    }

                default:
                    if (_.isNumber(x)) {
                        if (x > 1e12 && x < 1e13)
                            return new Date(x).toLocaleString()
                        return new Number(x).toLocaleString()
                    }
                    if (_.isBoolean(x))
                        return x ? "yes" : "no"
                    if (_.isPlainObject(x) || _.isArray(x))
                        return JSON.stringify(x)
                    return `${x}`
            }
        },
        promptPage() {
            let page = prompt("Go to page:", this.page+1)
            if (page !== null) {
                page = parseInt(page)
                if (!_.isNaN(page)) {
                    this.setDesiredPage(page-1)
                }
            }
        },
        setDesiredPage(page) {
            this.desiredPage = page
        },
        promptPageSize() {
            let pageSize = prompt("Rows per page:", this.pageSize)
            if (pageSize !== null) {
                pageSize = parseInt(pageSize)
                if (!_.isNaN(pageSize))
                    this.pageSize = pageSize
            }
        },
        makeFilter(filter) {
            return _(filter)
                    .map((condition) =>
                        _(condition)
                            .toPairs()
                            .map(([key, value]) => 
                                value.length == 1
                                    ? `${key} == ${utils.quote(value[0])}`
                                    : `${key} in ${utils.quote(value)}`)
                            .join(" && "))
                    .join(" || ")
        },
    }
}
</script>
<style>
.gp-stream-loading {
    float: right
}
.gp-stream-loading svg {
    width: 18px;
    height: 18px;
}
.gp-stream .gp-filter {
    margin-bottom: 10px;
}
.gp-stream .plain-table-paginator {
    float: none;
}
.gp-stream .table {
    font-size: 0.9em;
    margin-top: 8px;
    margin-bottom: 4px;
    clear: both;
}
.gp-stream .table td {
    white-space: nowrap;
}
.gp-stream .table th {
    font-weight: normal;
    line-height: 15px;
}
.gp-stream .table svg {
    width: 18px;
    height: 18px;
    margin: -1px;
}
.gp-stream .table .form-check {
    display: inline-block;
    margin-right: -8px;
}
.gp-stream .table th .form-check {
    vertical-align: bottom;
    margin-bottom: 4px;
}
.gp-stream .table td .form-check {
    margin-top: -2px;
    vertical-align: top;
}
.gp-stream p {
    padding-left: 6px;
    font-size: 0.9em;
}
.gp-stream .feather-icon-clock {
    color: var(--green);
}
.gp-stream-actions {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-bottom: -10px;
}
.gp-stream-actions > * {
    margin-right: 10px;
    margin-bottom: 10px;
}
.gp-stream-new-row table {
    margin: auto;
}
.gp-stream-new-row td:first-child {
    text-align: right;
    padding-right: 10px;
    vertical-align: top;
    line-height: 26px;
}
.gp-stream-new-row td:last-child .form-control {
    border-color: transparent;
    border-bottom-color: var(--gray);
    border-radius: 0;
}
.gp-stream-new-row td:last-child input.form-control {
    height: 26px;
}
</style>