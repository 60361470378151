<template>
  <gp-endings
    ref="input"
    v-model="model"
    :suffix="suffix"
    :prefix="prefix"
  />
</template>

<script>
module.exports = {
  props: {
    value: {
      type: Array,
    },

    suffix: {
      type: String,
      default: '',
    },

    prefix: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      model: _.cloneDeep(this.value),
    };
  },
};
</script>
