<template>
<div class="gp-bounds-related">
    <gp-data
        v-model="scope"
        :stream="stream"
        :groups="groups"
        :dims="rule.selector ? rule.grouper.concat(rule.selector) : rule.grouper"/>
    <plain-table
        v-if="scope && (this.rule.grouper.length === 1 || this.scope.rows.length < 10)"
        :stream="stream"
        :groups="groups.filter(group => group !== 'content')"
        :dims="dims"
        :vals="vals"
        :filter2="filter2"
        :initialSort="sort"
        :expandable="false"
        />
    <l10n v-else-if="scope" value="Rules scope is too large."/>
    <l10n v-else value="Loading..."/>
</div>
</template>
<script>
let utils = require("../my-utils")
module.exports = {
    mixins: [utils.extraFilters, utils.configHelpers],
    props: {
        rule: Object,
        relatedMetrics: { type: Array, default: () => ["avg_price_zone_price", "avg_recommended_price", "avg_new_price"] }
    },
    data() {
        return { scope: null }
    },
    computed: {
        sort() {
            let sort = []
            for (let i=0; i < this.rule.grouper.length; ++i)
                sort.push(i+1)
            if (this.rule.selector)
                sort.push(this.rule.grouper.length + 1)
            return sort
        },
        dims() {
            let makeDim = (calc) => ({
                name: this.attributesByCalc[calc]?.name || calc,
                calc: this.resolveSubstitutes(calc) || calc,
                format: this.formats[this.attributesByCalc[calc]?.format],
            })
            let dims = _.clone(this.rule.grouper)
            if (this.rule.selector)
                dims.push(this.rule.selector)
            dims.push('item')
            return dims.map(makeDim)
        },
        vals() {
            let makeVal = (calc) => ({
                name: this.metricsByFormula[calc]?.name || calc,
                calc: this.resolveDateConditions(this.resolveSubstitutes(calc)),
                format: this.formats[this.metricsByFormula[calc]?.format],
            })
            return this.relatedMetrics.map(makeVal)
        },
        filter2() {
            let filter2 = []
            if (this.rule.grouper.length === 1) {
                filter2.push(`${this.rule.grouper[0]} in ${utils.quote(this.scope.rows.map(([x]) => x))}`)
            }
            else {
                for (let row of this.scope.rows) {
                    let condition = []
                    for (let i=0; i < this.rule.grouper.length; ++i) {
                        if (this.rule.grouper[i] === "price_zone")
                            continue
                        condition.push(`${this.rule.grouper[i]} == ${utils.quote(row[i])}`)
                    }
                    filter2.push(condition.join(" && "))
                }
            }
            filter2 = filter2.length > 0 ? filter2.join(" || ") : "false"
            if (this.rule.selector)
                filter2 = `(${filter2}) && ${this.rule.selector} in ${utils.quote(this.rule.order)}`
            if (!_.isEmpty(this.rule.filter))
                filter2 = `(${filter2}) && (${
                    _(this.rule.filter)
                        .map((condition) =>
                            _(condition)
                                .toPairs()
                                .map(([key, value]) => 
                                    value.length == 1
                                        ? `(${this.resolveSubstitutes(key)}) == ${utils.quote(value[0])}`
                                        : `(${this.resolveSubstitutes(key)}) in ${utils.quote(value)}`)
                                .join(" && "))
                        .join(" || ")
                })`
            return filter2
        }
    },
}
</script>