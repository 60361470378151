var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accordions-container" },
    [
      _c("my-accordion", {
        ref: "accordion-1",
        attrs: { openedByDefault: "" },
        on: {
          accordionOpened: function($event) {
            return _vm.handleAccordionOpened("accordion-1")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "span",
                  { staticClass: "accordion-title" },
                  [_c("l10n", { attrs: { value: "Информация по заказу" } })],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticStyle: { "font-size": "0.75rem", padding: "5px" } },
                  [
                    _c("b", [_vm._v("Номер заказа:")]),
                    _vm._v(" 000646 "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Отправитель:")]),
                    _vm._v(" РЦ Запад-1     "),
                    _c("b", [_vm._v("Получатель:")]),
                    _vm._v(" Томск Мира (062) "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Текущая дата:")]),
                    _vm._v(" 27.12.2023"),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Дата формирования:")]),
                    _vm._v(" 27.12.2023"),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Дата отправки:")]),
                    _vm._v(" 27.12.2023  "),
                    _c("b", [_vm._v("Дата обработки:")]),
                    _vm._v(" 27.12.2023  "),
                    _c("b", [_vm._v("Дата отгрузки:")]),
                    _vm._v(" 29.12.2023  "),
                    _c("b", [_vm._v("Дата прихода:")]),
                    _vm._v(" 29.12.2023"),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Время приема заявок:")]),
                    _vm._v(" 10:00"),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Статус согласования:")]),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "orange" } }, [
                      _vm._v("на проверке")
                    ]),
                    _vm._v("  "),
                    _c("b", [_vm._v("Статус отправки:")]),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "orange" } }, [
                      _vm._v("не отправлен")
                    ])
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("my-accordion", {
        ref: "accordion-2",
        on: {
          accordionOpened: function($event) {
            return _vm.handleAccordionOpened("accordion-2")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "span",
                  { staticClass: "accordion-title" },
                  [
                    _c("l10n", {
                      attrs: {
                        value: "Ограничения по заказам по цепочке поставщика"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticStyle: { "font-size": "0.75rem", padding: "5px" } },
                  [
                    _c("b", [_vm._v("Период действия:")]),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "orange" } }, [
                      _vm._v("31.12.2099")
                    ]),
                    _vm._v(" "),
                    _c("b", [_vm._v("Вид цепочки:")]),
                    _vm._v(" Прямая поставка "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Склады отгрузки:")]),
                    _vm._v(" РЦ Запад-1 "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Ассортиментные наборы:")]),
                    _vm._v(" Cherkiz sausages "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Время обработки заявок:")]),
                    _vm._v(" 2 дня "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Минимальные границы:")]),
                    _vm._v(" -     "),
                    _c("b", [_vm._v("Максимальные границы:")]),
                    _vm._v(" - "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Склады получатели:")]),
                    _vm._v("  Томск Мира (062) "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Срок поставки:")]),
                    _vm._v("  29.12.2023 "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Дата создания цепочки поставки:")]),
                    _vm._v(" 01.01.2022 "),
                    _c("br")
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("my-accordion", {
        ref: "accordion-3",
        on: {
          accordionOpened: function($event) {
            return _vm.handleAccordionOpened("accordion-3")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "span",
                  { staticClass: "accordion-title" },
                  [
                    _c("l10n", {
                      attrs: { value: "Информация по контрагенту" }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticStyle: { "font-size": "0.75rem", padding: "5px" } },
                  [
                    _c("b", [_vm._v("Наименование контрагента:")]),
                    _vm._v(" РЦ Запад-1 "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Id карточки:")]),
                    _vm._v(" 908653 "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Артикул:")]),
                    _vm._v(" SKL001 "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("ИНН:")]),
                    _vm._v(" - "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Телефон:")]),
                    _vm._v(" -     "),
                    _c("b", [_vm._v("Телеграм:")]),
                    _vm._v(" -\n        "),
                    _c("b", [_vm._v("Договоры:")]),
                    _vm._v(" - "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Склады отгрузки:")]),
                    _vm._v(" - "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Контактное лицо:")]),
                    _vm._v(" - "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("Адрес приема заказов:")]),
                    _vm._v(" - "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v("График приема заказов:")]),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "orange" } }, [
                      _vm._v("понедельник - пятница 9:00 - 18: 00")
                    ]),
                    _vm._v("  "),
                    _c("b", [_vm._v("Время отправки заказов (до):")]),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "orange" } }, [
                      _vm._v("до 14:00")
                    ])
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }