/* eslint-disable camelcase */

const text = require('./text');
const money = require('./money');
const percent = require('./percent');
const percent_abs = require('./percent_abs');
const select = require('./select');
const endings = require('./endings');
const list = require('./list');
const number = require('./number');
const calendar = require('./calendar');

module.exports = {
  text,
  money,
  percent,
  percent_abs,
  select,
  endings,
  list,
  number,
  calendar,
};
