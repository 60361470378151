<template>
  <div class="file-input">
    <input
      id="file-input"
      class="hide-file-input"
      type="file"
      accept="image/*"
      @change="onFileChange($event)"
    />
    <label class="file-label" for="file-input"> Загрузить файл</label>
  </div>
</template>

<script>
module.exports = {
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);

        reader.onload = () => {
          file.previewBase64 = reader.result;
          this.$emit('file-updated', file);
        };

        reader.onerror = (error) => {
          // TODO: handle error
          console.warn("Error ", error);
        };
      }
    },
  },
};
</script>

<style scoped>
.file-input {
  display: flex;
}
.hide-file-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.file-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 20px;
  margin-left: auto;
  margin-top: 8px;
  text-decoration: underline;
  text-underline-offset: 6px;
  color: #3385FF;
  cursor: pointer;
}
input[type='file']:focus + .file-label {
  box-shadow: 0 0 0 4px #bae6fd;
}
</style>
