<template>
  <markdown
    ref="markdown"
    class="gp-page"
    :base="base"
    :dark-theme="darkTheme"
    :config="config"
    :editable="editable"
    :username="username"
    :bounds="bounds"
    :path="path"
    :hash="hash"
    :data="dataThrottled"
    :text="textThrottled"
    :storage="storage"
    :storage-key="storageKey"
    :auto-height="autoHeight"
    @editing="handleEditing"
    @edited="handleEdited"
  />
</template>

<script>
const utils = require('../my-utils');
const includes = require('../includes');

module.exports = {
  mixins: [
    includes,
  ],

  props: {
    darkTheme: { type: Boolean },
    config: { type: Object },
    username: { type: String },
    bounds: { type: String },
    path: { type: String },
    patch: { type: Object },
    storageKey: { type: String },
    editable: { type: Boolean, default: false },
    autoHeight: { type: Boolean, default: false },
  },

  data() {
    return {
      data: '',
      text: 'Loading...',
      hash: null,
      storage: null,
      textThrottled: '',
      dataThrottled: {},
      textWarning: null,
      dataWarning: null,
    };
  },

  watch: {
    path() {
      this.fetch();
    },

    includes() {
      this.loadIncludes();
    },

    storage: {
      deep: true,
      async handler(storage, prevStorage) {
        if (this.storageKey && prevStorage != null) {
          await fetch(`/storage/storage/${this.storageKey}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(storage),
          });
        }
      },
    },

    text() {
      this.updateText();
    },

    data() {
      this.updateData();
      this.updateText();
    },

    patch: {
      deep: true,
      handler() {
        this.updateData();
        this.updateText();
      },
    },

    loadedIncludes: {
      deep: true,
      handler() {
        this.updateData();
        this.updateText();
      },
    },

    customIncludes: {
      deep: true,
      handler() {
        this.updateData();
        this.updateText();
      },
    },
  },

  mounted() {
    this.fetch();
  },

  computed: {
    base() {
      return this.path ? `/pages/${this.parsedPath.path}/` : undefined;
    },

    includes() {
      return _.get(this.dataThrottled, '_.includes') || [];
    },

    parsedPath() {
      let path;
      let hash = null;

      if (this.path) {
        if (this.path.startsWith('http')) {
          [, path] = this.path.split('/pages/');
        }

        [path, hash] = this.path.split('#');
        path = path.replace(/\/$/, '');
      }

      return { path, hash };
    },
  },

  methods: {
    parseData(text, vars = {}) {
      const now = new Date();
      const today = utils.formatDate(now);
      const yesterday = utils.formatDate(utils.prevDate(now));
      const tomorrow = utils.formatDate(utils.nextDate(now));
      const ctx = {
        path: this.path,
        hash: this.hash,
        username: this.username,
        today,
        yesterday,
        tomorrow,
      };

      try {
        const schema = utils.jsyamlSchema;
        let data = window.Handlebars.compile(text)(_.assign({}, ctx, vars));
        data = jsyaml.safeLoad(data, { schema });

        if (!_.isPlainObject(data)) {
          data = {};
        }

        _.merge(data, this.patch);
        this.mergeIncludes(data);
        data = window.Handlebars.compile(text)(_.assign({}, ctx, vars, data));
        data = jsyaml.safeLoad(data, { schema });

        if (!_.isPlainObject(data)) {
          data = {};
        }

        _.merge(data, this.patch);
        this.mergeIncludes(data);

        return data;
      } catch (ex) {
        if (ex.mark) {
          const context = 10;
          const start = Math.max(0, ex.mark.line - context);
          const end = ex.mark.line + context + 1;
          const lines = ex.mark.buffer.split('\n');
          const snippet = lines
            .slice(start, end)
            .map((line, i) => {
              let newLine = line;
              const prefix = `${start + i + 1}: `;

              if (i + start === ex.mark.line) {
                const pads = ' '.repeat(Math.max(0, prefix.length + ex.mark.column));
                newLine = `${line}\n%c${pads}^\n  ${ex.reason}%c`;
              }

              return prefix + newLine;
            });

          if (start > 0) {
            snippet.splice(0, 0, '...');
          }

          if (end < lines.length) {
            snippet.push('...');
          }
        } else {
          // eslint-disable-next-line no-console
          console.warn(ex.message);
        }

        throw ex;
      }
    },

    parseText(text, vars = {}) {
      return window.Handlebars.compile(text)(vars);
    },

    updateText() {
      try {
        const vars = _.assign({ config: this.config }, this.dataThrottled);

        this.textThrottled = this.parseText(this.text, vars);
        this.textWarning = null;
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.warn(ex.message);
        this.textWarning = ex.message;
      }
    },

    updateData() {
      try {
        const dataThrottled = this.parseData(this.data, { config: this.config });
        const dataSynced = utils.reuseData(this.dataThrottled, dataThrottled);

        this.dataThrottled = dataSynced;
        this.dataWarning = null;
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.warn(ex.message);
        this.dataWarning = ex.message;
      }
    },

    async handleEditing() {
      const { text } = this;
      await this.fetch();
      if (text !== this.text && this.$refs.markdown.editing) {
        this.$refs.markdown.editingText = this.text;
      }
    },

    async handleEdited(text) {
      const { path } = this.parsedPath;

      this.text = text;

      if (path) {
        const page = window.app.pagesCache[this.path];

        if (page) {
          page.text = text;
        }

        const query = `
          mutation {
            updatePage(
              path: ${utils.quote(path)}
              page: ${utils.quote({ template: text })})
          }`;
        await fetch('/graphql', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ query }),
        });
      }
    },

    async fetch() {
      const { path, hash } = this.parsedPath;

      if (!path) {
        this.text = 'empty';
        this.data = '';
        this.storage = {};
        return;
      }

      let text = '';
      let data = {};
      let storage = {};

      const page = window.app.pagesCache[this.path];

      if (this.storageKey) {
        storage = await fetch(`/storage/storage/${this.storageKey}`, { headers: utils.nocahe() })
          .then((res) => {
            switch (res.status) {
              case 200: return res.json();
              case 404: return {};
              default: return {};
            }
          });
        if (!_.isEqual(this.storage, storage)) {
          this.storage = storage;
        }
      } else {
        storage = {};
      }

      if (page) {
        text = page.text;
        data = page.data;
        this.text = page.text;
        this.data = page.data;
        this.hash = hash;
        this.storage = storage;
      }
      try {
        const query = `
          query {
            page(path: ${utils.quote(path.replace(/^\/pages\//, ''))}) {
              elements
              template
            }
          }`;
        const res = await (await fetch('/graphql', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ query }),
        })).json();

        text = res.data.page.template || '';
        data = res.data.page.elements || '';
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(`failed to load content for page ${this.path}: ${ex}`);
        text = '';
        data = '';
      }

      if (this.text !== text) {
        this.text = text;
      }

      if (this.data !== data) {
        this.data = data;
      }

      this.hash = hash;

      if (!_.isEqual(this.storage, storage)) {
        this.storage = storage;
      }
      window.app.pagesCache[this.path] = { text, data };
    },
  },
};
</script>]

<style>
.gp-page.editing {
  display: flex;
  flex-direction: column;
}
.gp-page > .markdown-actions {
  display: flex;
  margin-right: -10px;
  margin-bottom: 10px;
}
.gp-page > .markdown-actions > * {
  flex-grow: 1;
  margin-left: 0;
  margin-right: 10px;
}
.gp-page > .ace_editor {
  flex-grow: 1;
}
</style>
