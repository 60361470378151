<template>
  <div class="provider-card-container">
    <div class="header">
      <p class="headerTitle">Справочник поставщиков / Little Freddie (арт. LITT001)</p>
      <div class="search-form">
        <input
          class="form-control form-control-sm"
          value=""
          style="margin-right: 5px;"
        >
        <button
          type="button"
          class="btn btn-primary btn-sm search-btn"
          @click="onClickSearchBtn"
        >Поиск</button>
      </div>
    </div>
    <gp-check
      :checked="isActiveCard"
      @change="handleChange"
    >
      <l10n value="Активная карточка" />
    </gp-check>
    <div class="chain-type parameter">
      <p class="title">
        <l10n value="ID карточки" />
      </p>
      <p>000017</p>
    </div>
    <div class="chain-type parameter">
      <p class="title">
        <l10n value="Артикул" />
      </p>
      <input
        class="form-control form-control-sm"
        value="LITT001"
        placeholder="Выберите артикул"
      >
    </div>
    <div class="chain-type stock-select parameter">
      <p class="title">
        <l10n value="Договоры" />
      </p>
      <input
        class="form-control form-control-sm"
        value="LITT0012323; LITT0012211; LITT0013322"
        placeholder="Выберите договор"
      >
      <button
        type="button"
        class="btn btn-sm btn-primary select-btn"
        @click="onStockSelect"
      >
        Договоры >>></button>
    </div>
    <div class="chain-type stock-select parameter">
      <p class="title">
        <l10n value="Склады отгрузки" />
      </p>
      <input
        class="form-control form-control-sm text-input"
        value="LF001"
        placeholder="Выберите договор"
      >
      <button
        type="button"
        class="btn btn-sm btn-primary select-btn"
        @click="onStockSelect"
      >
        Склады >>>
      </button>
    </div>
    <br />
    <h2>
      <span style="display: none;">Heading</span>
      <l10n value="Условия обработки заказов" />
    </h2>
    <gp-check
      :checked="differentStockContacts"
      :disabled="true"
      @change="handleChange"
    >
      <l10n value="Разные контакты по складам" />
    </gp-check>
    <div class="chain-type parameter">
      <p class="title">
        <l10n value="Контактное лицо" />
      </p>
      <select v-model="contact" class="form-control form-control-sm dropdown-select">
        <option value="in"><l10n value="Carl Morris"/></option>
        <option value="eq"><l10n value="Davie Jones"/></option>
        <option value="ne"><l10n value="John Wick"/></option>
        <option value="ne"><l10n value="Leonard Hoffstaedter"/></option>
        <option value="ne"><l10n value="Malcolm McDowell"/></option>
        <option value="ne"><l10n value="Michelle Pfifer" /></option>
      </select>
    </div>
    <div class="chain-type parameter">
      <p class="title">
        <l10n value="Адрес приема заказов" />
      </p>
      <input
        class="form-control form-control-sm"
        style="width: 200px;"
        value="orders@littlefreddie.com"
        placeholder="Выберите артикул"
      >
    </div>
    <div class="edi">
      <gp-check
        :checked="isEdi"
        @change="handleChange"
      >
        <l10n value="EDI" />
      </gp-check>
      <button
        type="button"
        class="btn btn-sm btn-primary select-btn"
        style="width: 140px;"
        @click="onStockSelect"
      >
        Настройки EDI >>>
      </button>
    </div>
    <p class="orderSchedule">График приема заказов</p>
    <div class="weekdays">
      <gp-check
        :checked="monday"
        @change="handleChange"
        class="inline-checkbox"
      >
        <l10n value="Понедельник" />
      </gp-check>
      <gp-check
        :checked="tuesday"
        @change="handleChange"
        class="inline-checkbox"
      >
        <l10n value="Вторник" />
      </gp-check>
      <gp-check
        :checked="wednesday"
        @change="handleChange"
        class="inline-checkbox"
      >
        <l10n value="Среда" />
      </gp-check>
      <gp-check
        :checked="thursday"
        @change="handleChange"
        class="inline-checkbox"
      >
        <l10n value="Четверг" />
      </gp-check>
      <gp-check
        :checked="friday"
        @change="handleChange"
        class="inline-checkbox"
      >
        <l10n value="Пятница" />
      </gp-check>
      <gp-check
        :checked="saturday"
        :disabled="!areWeekEndDaysActive"
        @change="handleChange"
        class="inline-checkbox"
      >
        <l10n value="Суббота" />
      </gp-check>
      <gp-check
        :checked="sunday"
        :disabled="!areWeekEndDaysActive"
        @change="handleChange"
        class="inline-checkbox"
      >
        <l10n value="Воскресенье" />
      </gp-check>
    </div>
    <div class="weekdays">
      <gp-check
        :checked="weekends"
        @change="handleChange"
        class="inline-checkbox"
      >
        <l10n value="Выходные" />
      </gp-check>
      <gp-check
        :checked="holidays"
        @change="handleChange"
        class="inline-checkbox"
      >
        <l10n value="Праздничные дни" />
      </gp-check>
    </div>
    <br />
    <div class="chain-type parameter time-select-container">
      <p class="title">
        <l10n value="Время отправки заказов (до):" />
      </p>
      <select v-model="departureTime" class="form-control form-control-sm time-select">
        <option value="ne"><l10n value="10.00"/></option>
        <option value="in"><l10n value="12.00"/></option>
        <option value="eq"><l10n value="16.00"/></option>
        <option value="ne"><l10n value="20.00"/></option>
      </select>
    </div>
    <div class="footer">
      <p class="metaData">Создано 04/03/2023 &nbsp; Автор: Tatyana I. &nbsp;&nbsp; Последнее изменение: 11/03/2023</p>
      <div class="button-block">
        <button
          type="button"
          class="btn btn-sm btn-primary select-btn"
          style="margin-right: 5px;"
          @click="onStockSelect"
        >
          Дублировать
        </button>
        <button
          type="button"
          class="btn btn-sm btn-primary select-btn"
          @click="onStockSelect"
        >
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const MySelect = require('../my-select.vue').default;

module.exports = {
  components: {
    'my-select': MySelect,
  },

  props: {

  },

  data() {
    return {
      isActiveCard: true,
      differentStockContacts: false,
      contact: 'in',
      isEdi: false,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: false,
      sunday: false,
      areWeekEndDaysActive: false,
      weekends: true,
      holidays: true,
      departureTime: 'ne',
    };
  },

  methods: {
    handleChange() {
      console.log('changed');
    },

    onClickSearchBtn() {
      console.log('search btn clicked');
    }
  },
}
</script>

  <style scoped>
  .provider-card-container {
    padding: 0.65rem;
    font-weight: 600;
  }
  .provider-card-container h2 {
    font-weight: 600;
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
  .headerTitle {
    font-style: italic;
    color: var(--cyan);
  }
  .search-form {
    display: flex;
  }
  .search-btn {
    width: 110px;
    height: 29px;
    background-color: transparent;
    border: 2px solid rgb(8, 144, 8);
    color: rgb(8, 144, 8);
  }
  .parameter {
    display: flex;
    justify-content: space-between;
  }
  .chain-type {
    width: 450px;
  }
  .chain-type.stock-select {
    width: 560px;
  }
  .chain-type .title {
    width: 250px;
  }
  .chain-type.time-select-container {
    width: 350px;
  }
  .time-select {
    width: 150px;
  }
  .title {
    color: var(--cyan);
  }
  .select-btn {
    width: 200px;
    height: 29px;
    background-color: var(--cyan);
    border: none;
  }
  .edi .select-btn {
    background-color: grey;
  }
  .dropdown-select {
    width: 150px;
  }
  .stock-select input {
    width: 350px;
    margin-right: 10px;
  }
  .edi {
    display: flex;
    justify-content: space-between;
    width: 560px;
  }
  .orderSchedule {
    font-weight: bold;
  }
  .weekdays {
    display: flex;
  }
  .weekdays gp-check {
    margin-right: 5px;
  }
  .inline-checkbox {
    margin-right: 10px;
  }
  .footer {
    display: flex;
    justify-content: space-between;
  }
  .button-block .select-btn {
    width: 160px;
  }
  .metaData {
    font-style: italic;
    color: #dedede;
  }
  </style>