var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-multiselect", {
    ref: "multiselect",
    attrs: {
      closeOnSelect: _vm.closeOnSelect,
      clearOnSelect: _vm.clearOnSelect,
      customLabel: _vm.customLabel,
      disabled: _vm.disabled,
      deselectLabel: _vm.deselectLabel,
      hideSelected: _vm.hideSelected,
      options: _vm.optionsWithName,
      limit: _vm.limit,
      loading: _vm.loading,
      label: _vm.label,
      multiple: _vm.multiple,
      placeholder: _vm.placeholder,
      searchable: _vm.searchable,
      selectLabel: _vm.selectLabel,
      selectedLabel: _vm.selectedLabel,
      showLabels: _vm.showLabels,
      trackBy: _vm.trackBy,
      maxHeight: _vm.maxHeight,
      openDirection: _vm.openDirection
    },
    on: { open: _vm.onOpen, close: _vm.onClose, select: _vm.onSelect },
    scopedSlots: _vm._u(
      [
        {
          key: "beforeList",
          fn: function() {
            return [_vm._t("beforeList")]
          },
          proxy: true
        },
        {
          key: "option",
          fn: function(ref) {
            var option = ref.option
            return [_vm._t("option", null, { option: option })]
          }
        },
        {
          key: "afterList",
          fn: function() {
            return [_vm._t("afterList")]
          },
          proxy: true
        }
      ],
      null,
      true
    ),
    model: {
      value: _vm.localValue,
      callback: function($$v) {
        _vm.localValue = $$v
      },
      expression: "localValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }