var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "empty-card" }, [
    _c(
      "button",
      {
        staticClass: "empty-card__retry-btn",
        attrs: { type: "button" },
        on: { click: _vm.redirectToDirectory }
      },
      [_c("add-icon")],
      1
    ),
    _vm._v(" "),
    _c("p", { staticClass: "empty-card__text" }, [
      _vm._v("\n    " + _vm._s(_vm.localizeValue(_vm.text)) + "\n  ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }