// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./search-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.input-search[data-v-2e953f0e] {\n  display: flex;\n  align-items: center;\n  width: 438px;\n  min-width: 350px;\n  height: 46px;\n  margin: 0;\n  padding: 0px 8px 0px 12px;\n  padding-left: 42px;\n  border: none;\n  border-radius: 8px;\n  background: #f7f7f7 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + " ) no-repeat 12px;\n  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;\n  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;\n  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;\n  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;\n}\n.input-search[data-v-2e953f0e]:hover {\n  background-color: #E9E9E9;\n}\n.input-search[data-v-2e953f0e]:focus {\n  border-color: #5295D5;\n  outline: 0;\n  -webkit-box-shadow: 0 0 0 0.2rem #5295D5;\n  box-shadow: 0 0 0 0.2rem #5295D5;\n}\n", ""]);
// Exports
module.exports = exports;
