var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file-input" }, [
    _c("input", {
      staticClass: "hide-file-input",
      attrs: { id: "file-input", type: "file", accept: "image/*" },
      on: {
        change: function($event) {
          return _vm.onFileChange($event)
        }
      }
    }),
    _vm._v(" "),
    _c("label", { staticClass: "file-label", attrs: { for: "file-input" } }, [
      _vm._v(" Загрузить файл")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }