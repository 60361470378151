<template>
  <table class="ao-table">
    <thead>
      <tr>
        <td
          v-for="column in tableColumns"
          :key="column.title"
          class="ao-table__cell head-cell"
        >
          <div class="head-cell__container">
            <a
              href="#"
              @click.prevent
              class="ao-table__head-title"
            >
              {{ column.title }}
            </a>
          </div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, rowIndex) in rows"
        :key="`${row.nodeType}_${rowIndex}_${row.id}`"
        class="ao-table__row"
        @click="handleRowClick(row)"
      >
        <td
          v-for="column in tableColumns"
          :key="column.title"
          class="ao-table__cell"
        >
          <div class="body-cell__container">
            <span>
              {{ getValueOfCell(column.field, row) }}
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
module.exports = {
  props: {
    tableColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
    endpoint: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      rows: [],
      isLoading: false,
    };
  },

  methods: {
    // eslint-disable-next-line consistent-return
    async getData() {
      const URL = this.endpoint;

      try {
        this.isLoading = true;
        const response = await fetch(URL);

        if (response.ok) {
          return await response.json();
        }
      } catch (error) {
        this.createNotification(error.message, 'error');
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },
    getValueOfCell(handler, row) {
      if (typeof handler === 'function') {
        return handler(row);
      }
      return row[handler];
    },
    handleRowClick(row) {
      if (!row.id) {
        return;
      }

      this.$emit('onOpenItem', row.id);
    },
  },

  async created() {
    const rows = await this.getData();
    this.rows = rows;
  },
};
</script>

  <style>
    .ao-table {
      display: flex;
      flex-direction: column;
      border-collapse: collapse;
      height: 100%;
    }

    .ao-table tbody {
      display: block;
      flex-grow: 1;
      overflow-y: auto;
    }

    .ao-table thead,
    .ao-table tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    .ao-table tbody tr {
      border: 1px solid #E9E9E9;
      border-bottom: none;
    }

    .ao-table tbody tr:first-of-type {
      border-top: none;
    }

    .ao-table tbody tr:last-of-type {
      border-bottom: 1px solid #E9E9E9;
    }

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #E9E9E9;
      border-radius: 10px;
      background-clip: content-box;
      border: 2px solid transparent;
      min-height: 50px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #5C606F;
    }

    .head-cell {
      background-color: #F7F7F7;
      border: 1px solid #E9E9E9;
      outline: none;
    }

    .head-cell__container {
      min-height: 33px;
    }

    .ao-table__cell {
      padding: 8px;
    }

    .ao-table__cell:first-child {
      min-width: 250px;
    }

    .ao-table__head-title {
      color: #3385FF;
    }

    .ao-table__head-title:hover {
      text-decoration: none;
    }

    .ao-table__row {
      cursor: pointer;
      position: relative;
      z-index: 0;
    }

    .ao-table__row:hover {
      background-color: #CCE5FF;
    }

    .ao-table__row_leaf {
      background-color: #F4FAFF;
    }

    .body-cell__container {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  </style>
