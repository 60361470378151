var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-column-filters" },
    [
      _c(
        "a",
        {
          attrs: { href: "javascript:void(0)" },
          on: {
            click: function($event) {
              _vm.conditionExpanded = !_vm.conditionExpanded
            }
          }
        },
        [
          _c("feather-icon", {
            attrs: {
              name: _vm.conditionExpanded ? "chevron-down" : "chevron-right"
            }
          }),
          _vm._v(" "),
          _c("l10n", { attrs: { value: "Filter by condition" } })
        ],
        1
      ),
      _vm._v(" "),
      _vm.conditionExpanded
        ? [
            _c(
              "select",
              {
                staticClass: "form-control form-control-sm",
                domProps: { value: _vm.currentCondition },
                on: { change: _vm.handleConditionChange }
              },
              _vm._l(_vm.validConditions, function(condition, key) {
                return _c(
                  "option",
                  {
                    ref: "conditions",
                    refInFor: true,
                    domProps: { value: key }
                  },
                  [_c("l10n", { attrs: { value: condition.name } })],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.validConditions[_vm.currentCondition]
              ? _c(
                  "div",
                  { staticClass: "gp-column-filters-args" },
                  _vm._l(
                    _vm.validConditions[_vm.currentCondition].args,
                    function(type, i) {
                      return _c("input", {
                        staticClass: "form-control form-control-sm",
                        attrs: { type: type },
                        domProps: { value: _vm.currentValue(i) },
                        on: {
                          change: function($event) {
                            return _vm.handleValueChange($event, i)
                          }
                        }
                      })
                    }
                  ),
                  0
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "text"
        ? _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  _vm.valueExpanded = !_vm.valueExpanded
                }
              }
            },
            [
              _c("feather-icon", {
                attrs: {
                  name: _vm.valueExpanded ? "chevron-down" : "chevron-right"
                }
              }),
              _vm._v(" "),
              _c("l10n", { attrs: { value: "Filter by value" } })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "text" && _vm.valueExpanded
        ? [
            _c("input", {
              staticClass: "form-control form-control-sm",
              attrs: { type: "search", placeholder: _vm.l10n("Search...") },
              on: {
                change: _vm.updateSearchString,
                search: _vm.updateSearchString,
                click: _vm.updateSearchString,
                keyup: _vm.updateSearchString
              }
            }),
            _vm._v(" "),
            _c(
              "ul",
              [
                !_vm.report && !(_vm.errors && _vm.errors.length)
                  ? _c(
                      "li",
                      [
                        _c("l10n", {
                          staticClass: "text-muted",
                          attrs: { value: "Loading..." }
                        })
                      ],
                      1
                    )
                  : _vm.errors && _vm.errors.length
                  ? _c("li", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.errors) +
                          "\n                "
                      )
                    ])
                  : [
                      _vm._l(
                        _vm.visibleValues.slice(0, _vm.moreThreshold),
                        function(value) {
                          return _c(
                            "li",
                            [
                              _c(
                                "gp-check",
                                {
                                  attrs: {
                                    checked:
                                      _vm.currentValues[
                                        value.item ? value.item[1] : value
                                      ] !== false
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.handleValuesChange(
                                        value.item ? value.item[1] : value,
                                        $event
                                      )
                                    }
                                  }
                                },
                                [
                                  value.item
                                    ? _vm._l(
                                        _vm.formatSearchItem(value),
                                        function(part) {
                                          return _c(
                                            "span",
                                            {
                                              class: { matched: part.matched }
                                            },
                                            [_vm._v(_vm._s(part.text))]
                                          )
                                        }
                                      )
                                    : value
                                    ? [_vm._v(_vm._s(_vm.formatValue(value)))]
                                    : [
                                        _c("l10n", {
                                          attrs: { value: "(Blanks)" }
                                        })
                                      ]
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }
                      ),
                      _vm._v(" "),
                      _vm.visibleValues.length > _vm.moreThreshold
                        ? _c("li", [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.moreThreshold = _vm.moreThreshold * 2
                                  }
                                }
                              },
                              [
                                _c("l10n", {
                                  attrs: {
                                    value: "and {more} more...",
                                    more: new Number(
                                      _vm.visibleValues.length -
                                        _vm.moreThreshold
                                    ).toLocaleString()
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "gp-column-filters-values-actions" }, [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: { click: _vm.selectAllValues }
                },
                [_c("l10n", { attrs: { value: "Select all" } })],
                1
              ),
              _vm._v("\n                –\n                "),
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: { click: _vm.clearValues }
                },
                [_c("l10n", { attrs: { value: "Select none" } })],
                1
              )
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }