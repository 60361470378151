<template>
<div class="gp-tabs">
    <ul ref="tabs">
        <li v-for="tab in tabs" :class="{active: tab.id == activeTabId}" :key="tab.id" :data-tab="tab.id">
            <a
                href="#"
                @click.prevent="activeTabId == tab.id ? showTabMenu = tab : activeTabId = tab.id">
                <l10n :value="tab.name"/>
                <feather-icon name="chevron-down" v-if="tab.id == activeTabId" ref="activeTab"/>
            </a>
        </li>
        <li>
            <a
                href="#"
                @click.prevent="addTab()">
                <feather-icon name="plus"/>
            </a>
        </li>
    </ul>
    <my-popup
        v-if="showTabMenu"
        :set="tab = showTabMenu"
        @escape="showTabMenu = null"
        @clickoutside="showTabMenu = null"
        placement="bottom-end"
        :anchor="`.gp-tabs > ul > li.active[data-tab=${activeTabId}]`">
        <div class="popover show gp-tabs-menu">
            <div class="popover-body">
                <ul>
                    <li>
                        <a href="#" @click.prevent="refreshTab(showTabMenu); showTabMenu = null">
                            <feather-icon name="refresh-cw"/>
                            <l10n value="Refresh"/>
                        </a>
                    </li>
                    <li>
                        <a :href="tab.path.startsWith('http') ? tab.path : `/pages/${tab.path}`" @click="showTabMenu = null" target="_blank">
                            <feather-icon name="external-link"/>
                            <l10n value="Open"/>
                        </a>
                    </li>
                    <li>
                        <a href="#" @click.prevent="removeTab(tab); showTabMenu = null">
                            <feather-icon name="x"/>
                            <l10n value="Remove"/>
                        </a>
                    </li>
                    <li>
                        <a href="#" @click.prevent="showSettings = !showSettings; showTabMenu = null">
                            <feather-icon name="settings"/>
                            <l10n value="Settings"/>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </my-popup>
    <!--my-popup
        v-if="showAddMenu"
        @escape="showAddMenu = false"
        @clickoutside="showAddMenu = false"
        placement="bottom-center"
        anchor=".gp-tabs > ul > li:last-child">
        <div class="popover show">
            <div class="popover-body">
                <ul>
                    <li><a href="#"><l10n value="New dashboard"/></a></li>
                    <li><a href="#"><l10n value="New report"/></a></li>
                    <li><a href="#"><l10n value="New page"/></a></li>
                    <li><a href="#"><l10n value="Import"/></a></li>
                </ul>
            </div>
        </div>
    </my-popup-->
    <portal :to="portal">
        <template v-for="tab in tabs">
            <div
                class="gp-tab"
                :key="tab.id"
                v-if="tab.id == activeTabId || tab.keep || tab.lazy && visitedTabs[tab.id]"
                v-show="tab.id == activeTabId">
                <form v-if="showSettings" class="gp-tab-settings" @submit.prevent="showSettings = false">
                    <div class="form-group">
                        <label><l10n value="Name"/></label>
                        <input class="form-control" :value="tab.name" @change="modifyTab(tab, {name:$event.target.value})"/>
                    </div>
                    <div class="form-group">
                        <label><l10n value="Path"/></label>
                        <input class="form-control" :value="tab.path" @change="modifyTab(tab, {path:$event.target.value})"/>
                    </div>
                    <div class="form-group">
                        <gp-check :checked="tab.lazy" @change="modifyTab(tab, {lazy:$event})">
                            <l10n value="Keep content once loaded"/>
                        </gp-check>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary" @click="showSettings = false">
                            <l10n value="Done"/>
                        </button>
                    </div>
                </form>
                <gp-page
                    v-if="tab.type == 'page'"
                    v-show="!showSettings"
                    :key="refreshKeys[tab.id]"
                    :path="tab.path"
                    :darkTheme="darkTheme"
                    :config="config"
                    :username="username"
                    :bounds="storageKey ? `${storageKey}.${tab.id}` : tab.id"
                    :storageKey="storageKey
                      ? storageKey.split('.').length < 3
                      ? `${storageKey}.${tab.id}`
                      : storageKey
                      : null"
                    />
            </div>
        </template>
    </portal>
</div>
</template>
<script>
module.exports = {
    model: {
        prop: "tabs",
        event: "change",
    },
    props: {
        darkTheme:      { type: Boolean },
        config:         { type: Object },
        username:       { type: String },
        bounds:         { type: String },
        storageKey:     { type: String },
        tabs:           { type: Array, default: () => [] },
        portal:         { type: String, default: "gp-tabs" },
    },
    data() {
        let activeTabId = localStorage.activeTabId || this.tabs[0]?.id
        return {
            activeTabId,
            showTabMenu: false,
            showAddMenu: false,
            visitedTabs: {},
            showSettings: false,
            refreshKeys: {},
        }
    },
    mounted() {
        $(this.$refs.tabs)
            .sortable({
                axis: "x",
                cursor: "move",
                helper: "original",
                distance: 5,
                cancel: "li:last-child",
                forceHelperSize: true,
                forcePlaceholderSize: true,
                start: (e, ui) => {
                    ui.item.data("n", -1)
                    let w = ui.item.width();
                    ui.placeholder.width(w)
                },
                change: (e, ui) => {
                    let x = ui.placeholder[0]
                    let n = Array.prototype.indexOf.call(x.parentNode.childNodes, x)
                    ui.item.data("n", n)
                },
                beforeStop: (e, ui) => {
                    ui.item.parent().sortable("cancel")

                    let tab = ui.item.data("tab")
                    let n = ui.item.data("n")
                    if (n != -1) {
                        let tabs = _.clone(this.tabs)
                        let i = tabs.findIndex(({id}) => id === tab)
                        tab = tabs.splice(i, 1)[0]
                        tabs.splice(n > i ? n-1 : n, 0, tab)
                        this.$emit("change", tabs)
                    }
                }
            })
        utils.bridge.bind("new-tab", this.openNewTab)
    },
    beforeDestroy() {
        $(this.$refs.tabs).sortable("destroy")
        utils.bridge.unbind("new-tab", this.openNewTab)
    },
    watch: {
        tabs() {
            if (!this.activeTabId)
                this.activeTabId = this.tabs[0]?.id
        },
        activeTabId() {
            this.$set(this.visitedTabs, this.activeTabId, true)
            localStorage.activeTabId = this.activeTabId
        },
        showSettings() {
            if (this.showSettings)
                _.defer(() => $(".gp-tab-settings input").first().focus())
        },
    },
    computed: {
        activeTab() {
            return this.tabs.find(tab => tab.id === this.activeTabId)
        }
    },
    methods: {
        openNewTab(tab) {
            this.tabs.push(tab)
            this.activeTabId = tab.id
        },
        refreshTab(tab) {
            this.$set(this.refreshKeys, tab.id, (this.refreshKeys[tab.id] || 0) + 1)
        },
        modifyTab({id}, props) {
            this.$emit("change", this.tabs.map(tab => tab.id === id ? _.assign({}, tab, props) : tab))
        },
        removeTab(tab) {
            if (window.confirm(utils.l10n("Are you sure you want to remove tab {tab}").replace("{tab}", tab.name)))
                this.$emit("change", this.tabs.filter(({id}) => id !== tab.id))
        },
        addTab() {
            let tab = {
                id: utils.randomId(),
                name: "",
                type: "page",
                path: "",
                lazy: true,
            }
            this.activeTabId = tab.id
            this.showSettings = true
            this.$emit("change", (this.tabs || []).concat(tab))
        }
    }
}
</script>
<style>
.gp-tabs {
    background-color: var(--blue);
    padding-left: 20px;
    padding-right: 10px;
}
.gp-tabs > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}
.gp-tabs > ul > li {
    white-space: nowrap;
    display: inline-block;
    line-height: 28px;
    margin-top: 4px;
    margin-right: 4px;
    background-color: rgb(90,97,111);
    border-radius: 4px 4px 0 0;
}
.gp-tabs > ul > li a {
    color: white;
    display: block;
    padding: 0 12px;
}
.gp-tabs > ul > li a:hover {
    text-decoration: none;
}
.gp-tabs > ul > li.active {
    background-color: white;
}
.gp-tabs > ul > li.active a {
    color: #222;
}
.gp-tabs > ul > li:last-child {
    background-color: transparent;
    margin-left: 10px;
}
.gp-tabs > ul > li:last-child a {
    width: 20px;
    height: 20px;
    line-height: 20px;
    display: inline-block;
    background-color: white;
    color: var(--blue);
    border-radius: 4px;
    padding: 0;
}
.gp-tabs > ul > li:last-child a svg {
    width: 20px;
    height: 20px;
    vertical-align: top;
}
.gp-tabs > ul > li.active a svg {
    width: 20px;
    height: 20px;
    margin: 0 -2px;
}
.gp-tabs-menu .popover-body ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.gp-tabs-menu .popover-body ul li svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}
.gp-tabs-menu.popover {
    width: 130px;
}
.gp-tab-settings {
    padding: 15px 20px;
}
.gp-tab .gp-side-bar-hide.close {
    top: 2px;
}
.gp-tab h1 {
    display: none;
}
.gp-tab {
    position: relative;
}
.gp-tab > .gp-page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 20px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
.gp-tab,
.gp-tab .gp-page > div:first-child:last-child,
.gp-tab .gp-container {
    height: 100%;
}
.gp-tab .gp-container {
    margin: -15px -20px;
    height: calc(100% + 30px);
}
.gp-tabs > ul > li.ui-sortable-helper {
    background-color: rgb(90,97,111);
}
.gp-tabs > ul > li.ui-sortable-helper.active {
    background-color: white;
}
</style>
