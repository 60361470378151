var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    _vm._l(_vm.levels, function(lvl, i) {
      return _c("i", {
        key: lvl,
        staticClass: "hierarchy-link",
        class: {
          "hierarchy-link__last": _vm.isLastLineStyle(i),
          "hierarchy-link__right-line": _vm.isRightLineStyle(i),
          "hierarchy-link__hidden": _vm.isFirstLinesHidden(i)
        },
        style: { left: _vm.getOffset(lvl) + "px" }
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }