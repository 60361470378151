<template>
  <div class="error-card">
    <button
      class="error-card__reload-btn"
      @click="reloadCurrentPage"
      type="button">
      <retry-icon />
    </button>
    <div>
      <p class="error-card__text">Ошибка загрузки.</p>
      <p class="error-card__text">Пожалуйста, перезагрузите страницу.</p>
    </div>
  </div>
</template>

<script>
const retryIcon = require('../../icons/retry-arrows-icon.vue').default;

module.exports = {
  components: {
    'retry-icon': retryIcon,
  },
  methods: {
    reloadCurrentPage() {
      this.$emit('on-reload');
    },
  },
};
</script>

<style scoped>
.error-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
}
.error-card__reload-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 28px;
    border-radius: 50%;
    background-color: #F7F7F7;
    outline: none;
    border: none;
    color: #AFAFAF;
}
.error-card__reload-btn:hover {
  background-color: #E9E9E9;
}
.error-card__text {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #9B9B9B;
}
</style>
