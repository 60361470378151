module.exports = _.merge({
    watch: {
        report() {
            this.chart?.calculateColorDomain()
            this.chart?.redraw()
        }
    },
    methods: {
        makeChart(base, instant) {
            let n = this.dims.length
            let m = this.vals.length
            let dim = this.ndx.dimension(row => row.slice(0,n))

            let grp = dim.group()
                .reduce(
                    (p,v) => { for (let i = 0; i < m; ++i) { p[i] += v[i+n] }; return p },
                    (p,v) => { for (let i = 0; i < m; ++i) { p[i] -= v[i+n] }; return p },
                    () => _.fill(new Array(m), 0))

            let chart = new dc.HeatMap(base)

            chart
                .dimension(dim)
                .group(grp, this.title, (d) => d.value[0])
                .keyAccessor(function(d) { return +d.key[0]; })
                .valueAccessor(function(d) { return +d.key[1]; })
                .colorAccessor(function(d) { return +d.value[0]; })
                .colors(["#ffffd9","#edf8b1","#c7e9b4","#7fcdbb","#41b6c4","#1d91c0","#225ea8","#253494","#081d58"])
                .calculateColorDomain()

            window.ndx = this.ndx
            window.dim = dim
            window.grp = grp
            window.chart = chart

            // _(this.vals)
            //     .take(1)
            //     .forEach((group, i) => {
            //         let name = _.isString(group) ? group : group.name
            //         chart.group(grp, name, (d) => d.value[i])
            //     })

            return chart
        },
    },
}, require("./props").chartProps({
    keyAccessor:    { type: [Function, String], default: (d) => d.key[0] },
    valueAccessor:  { type: [Function, String], default: (d) => d.key[1] },
    colorAccessor:  { type: [Function, String], default: (d) => d.value[0] },
}))

