<template>
  <div class="accordion-container">
    <button
      @click="toggleAccordion()"
      class="accordion"
      :aria-expanded="isOpen"
      :aria-controls="`collapse${_uid}`"
    >
      <slot name="title" />
      <svg
        class="accordion-image"
        :class="{
          'rotate-180': isOpen,
          'rotate-0': !isOpen,
        }"
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.46967 8.96967C6.76256 8.67678 7.23744 8.67678 7.53033 8.96967L12 13.4393L16.4697 8.96967C16.7626 8.67678 17.2374 8.67678 17.5303 8.96967C17.8232 9.26256 17.8232 9.73744 17.5303 10.0303L12.5303 15.0303C12.2374 15.3232 11.7626 15.3232 11.4697 15.0303L6.46967 10.0303C6.17678 9.73744 6.17678 9.26256 6.46967 8.96967Z"
          fill="#CFCFCF"
        />
      </svg>
    </button>

    <my-transition-expand>
      <div v-if="isOpen">
        <slot name="content" />
      </div>
    </my-transition-expand>

  </div>
</template>

<script>
const MyTransitionExpand = require('./my-transition-expand.vue').default;

export default {
  components: {
    'my-transition-expand': MyTransitionExpand,
  },

  props: {
    openedByDefault: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    let isOpen = false;

    if (this.openedByDefault) {
      isOpen = true;
    }

    return {
      isOpen,
    };
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        this.$emit('accordionOpened');
      }
    },

    closeAccordion() {
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
.accordion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 46px;
  margin-bottom: 8px;
  border: none;
  border-bottom: 1px solid #E9E9E9;
  outline: none;
  background-color: #ffffff;
}
.accordion-title {
  color: #000000;
}
.accordion-image {
  width: 18px;
  margin-right: 10px;
  transition: transform 0.5s ease-in-out;
}
.rotate-180 {
  transform: rotate(180deg);
}
.rotate-0 {
  transform: rotate(0);
}

.expand-enter-active,
.expand-leave-active {
  transition-property: opacity, transform, height;
}

.expand-enter,
.expand-leave-to {
  opacity: 0;
  transform: translateX(10px);
}
</style>
