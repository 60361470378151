<template>
  <div class="product-card-container">
    <header class="header">
      <div class="header__title-block">
        <button
          type="button"
          class="back-button"
          @click="onBackBtnClick"
        >
          <feather-icon name="chevron-left" />
        </button>
        <p class="header-title">Happy Horses (apt. HRSS707)</p>
        <my-tooltip-simple>
          <button type="button" class="icon-button">
            <feather-icon name="alert-circle" />
          </button>
          <template v-slot:tooltip-data>
            <div class="header-title-tooltip">
              <p>ID карточки №001936</p>
              <p>Создано: 04/03/2023</p>
              <p>Автор: Tatyana I.</p>
              <p>Последнее изменение: 06/10/2023</p>
            </div>
          </template>
        </my-tooltip-simple>
      </div>
      <div class="header__search-form">
        <input
          class="form-control form-control-sm input-search"
          value=""
          style="margin-right: 5px;"
        >
      </div>
    </header>
    <main class="main">
      <div>
        <gp-check
          :checked="isActiveCard"
          @change="handleChange"
        >
          <l10n value="Активная карточка" />
        </gp-check>
        <div class="chain-type parameter">
          <p class="title">
            <l10n value="ID карточки" />
          </p>
          <p>001936</p>
        </div>
        <div class="chain-type parameter">
          <p class="title">
            <l10n value="Артикул" />
          </p>
          <input
            class="form-control form-control-sm"
            value="SFLT002"
            placeholder="Выберите артикул"
          >
        </div>
        <div style="display: flex;">
          <div class="chain-type parameter" style="margin-right: 10px;">
            <p class="title">
              <l10n value="Поставщики" />
            </p>
            <input
              class="form-control form-control-sm"
              value="Little Freddie (арт. LITT001)"
              placeholder="Выберите артикул"
            >
          </div>
          <button
            type="button"
            class="btn btn-sm btn-primary select-btn"
            @click="onSuppliersSelect"
            ref="supplierButton"
          >
            Поставщики >>>
          </button>
        </div>
        <div class="chain-type parameter">
          <p class="title">
            <l10n value="Производитель" />
          </p>
          <select v-model="manufacturer" class="form-control form-control-sm dropdown-select">
            <option value="in"><l10n value="Sunny Fields ent. ltd."/></option>
            <option value="eq"><l10n value="Sony"/></option>
            <option value="ne"><l10n value="Microsoft"/></option>
            <option value="ne"><l10n value="Procter&Gamble"/></option>
            <option value="ne"><l10n value="PagerDuty"/></option>
            <option value="ne"><l10n value="Pfizer"/></option>
          </select>
        </div>
        <div class="chain-type parameter">
          <p class="title">
            <l10n value="Торговая марка" />
          </p>
          <select v-model="trademark" class="form-control form-control-sm dropdown-select">
            <option value="in"><l10n value="Little Freddie"/></option>
            <option value="eq"><l10n value="Sony Vaio"/></option>
            <option value="ne"><l10n value="Microsoft Windows"/></option>
            <option value="ne"><l10n value="Head&Shoulders"/></option>
            <option value="ne"><l10n value="GNFR"/></option>
            <option value="ne"><l10n value="Viagra"/></option>
          </select>
        </div>
        <br />
        <h2>
          <l10n value="Атрибуты товара" />
        </h2>
        <br />
        <div class="chain-type parameter">
          <p class="title">
            <l10n value="Срок годности, дн." />
          </p>
          <input
            class="form-control form-control-sm"
            value="180"
            placeholder="Выберите срок годности"
          >
        </div>
        <div style="display: flex; align-items: center;">
          <div class="chain-type parameter" style="margin-right: 10px;">
            <p class="title">
              <l10n value="Вес/Объем" />
            </p>
            <input
              class="form-control form-control-sm"
              value="90"
              placeholder="Выберите вес/объем"
            >
          </div>
        </div>
        <div class="chain-type parameter">
          <p class="title">
            <l10n value="Вложение в короб" />
          </p>
          <input
            class="form-control form-control-sm"
            value="12"
            placeholder="Выберите объем вложения в короб"
          >
        </div>
        <div class="chain-type parameter">
          <p class="title">
            <l10n value="Аналоги" />
          </p>
          <input
            class="form-control form-control-sm"
            value=""
            placeholder="Введите аналог"
          >
        </div>
        <div class="chain-type parameter">
          <p class="title">
            <l10n value="Категория" />
          </p>
          <select v-model="category" class="form-control form-control-sm dropdown-select">
            <option value="in"><l10n value="Organic Snacks (id: 48)"/></option>
            <option value="eq"><l10n value="Milk products"/></option>
            <option value="ne"><l10n value="Adult clothes"/></option>
            <option value="ne"><l10n value="Home electronics"/></option>
            <option value="ne"><l10n value="Leather Shoes"/></option>
            <option value="ne"><l10n value="Newspapers"/></option>
          </select>
        </div>
      </div>
      <div style="align-self: center">
        <img
          v-if="filePreview"
          :src="filePreview"
          alt="fileName"
          style="max-height: 256px; margin-bottom: 5px; border-radius: 5px;"
        >
        <my-fileinput @file-updated="captureFile($event)" />
      </div>
    </main>

    <div class="footer">
      <p class="metaData">Создано 04/03/2023 &nbsp; Автор: Tatyana I. &nbsp;&nbsp; Последнее изменение: 11/03/2023</p>
      <div class="button-block">
        <button
          type="button"
          class="btn btn-sm btn-primary select-btn"
          style="margin-right: 5px;"
        >
          Дублировать
        </button>
        <button type="button" class="btn btn-sm btn-primary select-btn">Сохранить</button>
      </div>
    </div>
    <my-popup
      v-if="isSuppliersPopupOpened"
      :anchor="this.$refs.supplierButton"
      placement="end"
      @clickoutside="handleModalClose"
    >
      <div class="popover modal-container">
        List of suppliers
      </div>
    </my-popup>
  </div>
</template>

<script>
const MySelect = require('../my-select.vue').default;
const MyFileInput = require('../my-fileinput.vue').default;
const MyTooltipSimple = require('../my-tooltip-simple.vue').default;

module.exports = {
  components: {
    'my-select': MySelect,
    'my-fileinput': MyFileInput,
    'my-tooltip-simple': MyTooltipSimple,
  },

  props: {

  },

  data() {
    return {
      isActiveCard: true,
      isPieceGoods: true,
      manufacturer: 'in',
      trademark: 'in',
      category: 'in',
      filePreview: '',
      fileName: '',
      isSuppliersPopupOpened: false,
    };
  },

  methods: {
    handleChange() {
      console.log('changed');
    },

    onClickSearchBtn() {
      console.log('search btn clicked');
    },

    onBackBtnClick() {
      console.log('back btn clicked');
    },

    onSuppliersSelect() {
      this.isSuppliersPopupOpened = !this.isSuppliersPopupOpened;
    },

    captureFile($event) {
      this.filePreview = $event.previewBase64;
      this.fileName = $event.name;
    },

    handleModalClose() {
      this.isSuppliersPopupOpened = false;
    },
  },
}
</script>

  <style scoped>
  button.btn-sm {
    font-weight: 700;
  }
  .product-card-container {
    padding: 0.65rem;
    font-weight: 600;
  }
  .product-card-container h2 {
    font-weight: 600;
  }
  .header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
  .header__title-block {
    display: flex;
    align-items: center;
  }
  .header-title {
    margin-bottom: 0;
    margin-right: 12px;
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
  }
  .header-title-tooltip {
    width: 210px;
    text-align: left;
  }
  .header-title-tooltip p {
    margin-bottom: 0;
  }
  .back-button {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    border: none;
    border-radius: 50%;
  }
  .back-button:focus {
    outline: none;
    box-shadow: 0px 0px 5px 5px #5295D5;
  }
  .icon-button {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-bottom: 1px;
    border: none;
    border-radius: 50%;
    background: transparent;
  }
  .icon-button svg {
    stroke: #cfcfcf;
  }
  .icon-button:focus {
    outline: none;
    box-shadow: 0px 0px 3px 3px #5295D5;
  }
  .header__search-form {
    display: flex;
  }
  .search-btn {
    width: 110px;
    height: 29px;
    background-color: transparent;
    border: 2px solid rgb(8, 144, 8);
    color: rgb(8, 144, 8);
  }
  .main {
    display: flex;
    justify-content: space-between;
  }
  .parameter {
    display: flex;
    justify-content: space-between;
  }
  .chain-type {
    width: 450px;
  }
  .chain-type.stock-select {
    width: 560px;
  }
  .stock-select input {
    width: 350px;
    margin-right: 10px;
  }
  .chain-type .title {
    width: 250px;
  }
  .chain-type.time-select-container {
    width: 350px;
  }
  .time-select {
    width: 150px;
  }
  .title {
    color: var(--cyan);
  }
  .select-btn {
    width: 200px;
    height: 29px;
    background-color: var(--cyan);
    border: none;
  }
  .footer {
    display: flex;
    justify-content: space-between;
  }
  .button-block .select-btn {
    width: 160px;
  }
  .metaData {
    font-style: italic;
    color: #dedede;
  }
  .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", IBM Plex Sans, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.8203125rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    box-shadow: 1px 1px 5px;
  }
  .modal-container {
    left: 305px;
    max-height: 100vh;
    min-width: 300px;
    max-width: 300px;
    padding: 0.5rem;
  }
  .input-search {
    display: flex;
    align-items: center;
    width: 438px;
    min-width: 350px;
    height: 46px;
    padding: 0px 8px 0px 12px;
    border: none;
    border-radius: 8px;
    padding-left: 42px;
    background: #f7f7f7 url('./search-icon.svg' ) no-repeat 12px;
  }
  </style>