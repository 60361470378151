var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chains" },
    [
      _c(
        "header",
        { staticClass: "chains__header" },
        [
          _c("autoorder-tabs", {
            attrs: {
              staticTabs: _vm.tabs,
              dynamicTabs: _vm.cardTabs,
              activeTabId: _vm.currentTabId
            },
            on: { onSelect: _vm.selectTab, onCloseTab: _vm.tabCloseInit }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "chains__main" },
        [
          _c(
            "keep-alive",
            { attrs: { exclude: ["chain-card"] } },
            [
              _vm.currentComponent
                ? _c(_vm.currentComponent, { tag: "component" })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("close-tab-modal", {
        on: { onTabCloseConfirm: _vm.tabCloseConfirm },
        model: {
          value: _vm.closeTabModalVisible,
          callback: function($$v) {
            _vm.closeTabModalVisible = $$v
          },
          expression: "closeTabModalVisible"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }