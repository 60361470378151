var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-bounds-related" },
    [
      _c("gp-data", {
        attrs: {
          stream: _vm.stream,
          groups: _vm.groups,
          dims: _vm.rule.selector
            ? _vm.rule.grouper.concat(_vm.rule.selector)
            : _vm.rule.grouper
        },
        model: {
          value: _vm.scope,
          callback: function($$v) {
            _vm.scope = $$v
          },
          expression: "scope"
        }
      }),
      _vm._v(" "),
      _vm.scope &&
      (this.rule.grouper.length === 1 || this.scope.rows.length < 10)
        ? _c("plain-table", {
            attrs: {
              stream: _vm.stream,
              groups: _vm.groups.filter(function(group) {
                return group !== "content"
              }),
              dims: _vm.dims,
              vals: _vm.vals,
              filter2: _vm.filter2,
              initialSort: _vm.sort,
              expandable: false
            }
          })
        : _vm.scope
        ? _c("l10n", { attrs: { value: "Rules scope is too large." } })
        : _c("l10n", { attrs: { value: "Loading..." } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }