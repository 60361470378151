<template>
    <div class="gp-recorder"/>
</template>
<script>
module.exports = {
    props: {
        username: { type: String,  default: null },
    },
    mounted() {
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:2498099,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

        if (this.username)
            window.hj('identify', this.username, {})
    },
    watch: {
        username() {
            if (this.username)
                window.hj('identify', this.username, {})
        }
    }
}
</script>