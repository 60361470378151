var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "productcard-container" }, [
    _c("header", { staticClass: "header" }, [
      _c(
        "div",
        { staticClass: "header__title-block" },
        [
          _c("button", {
            staticClass: "button-return",
            attrs: { type: "button" },
            on: { click: _vm.onBackBtnClick }
          }),
          _vm._v(" "),
          _c("p", { staticClass: "header-title" }, [
            _vm._v("Happy Horses (apt. HRSS707)")
          ]),
          _vm._v(" "),
          _c(
            "my-tooltip-simple",
            {
              scopedSlots: _vm._u([
                {
                  key: "tooltip-data",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "header-title-tooltip" }, [
                        _c("p", [_vm._v("ID карточки №001936")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("Создано: 04/03/2023")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("Автор: Tatyana I.")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("Последнее изменение: 06/10/2023")])
                      ])
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("button", {
                staticClass: "button-info",
                attrs: { type: "button" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0)
    ]),
    _vm._v(" "),
    _c("main", { staticClass: "main" }, [
      _c("section", { staticClass: "main__section" }, [
        _c("h3", { staticClass: "section__title" }, [
          _vm._v("Атрибуты поставщика")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "inputblock" }, [
          _c(
            "label",
            { staticClass: "inputblock__label", attrs: { for: "vendor-code" } },
            [_vm._v("\n          Артикул\n        ")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.vendorCode,
                expression: "vendorCode"
              }
            ],
            staticClass: "inputblock__input input",
            attrs: { id: "vendor-code", placeholder: "Введите артикул товара" },
            domProps: { value: _vm.vendorCode },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.vendorCode = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputblock" },
          [
            _c(
              "label",
              { staticClass: "inputblock__label", attrs: { for: "contracts" } },
              [_vm._v("Договоры")]
            ),
            _vm._v(" "),
            _c("my-multiselect", {
              attrs: {
                id: "contracts",
                options: _vm.contracts,
                selectedOption: _vm.selectedContract,
                multiple: "",
                searchable: "",
                placeholder: "Выберите договоры"
              },
              on: { select: _vm.onTrademarkSelect }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputblock" },
          [
            _c(
              "label",
              {
                staticClass: "inputblock__label",
                attrs: { for: "shipping-warehouses" }
              },
              [_vm._v("Склады отгрузки")]
            ),
            _vm._v(" "),
            _c("my-multiselect", {
              attrs: {
                id: "shipping-warehouses",
                options: _vm.shippingWarehouses,
                selectedOption: _vm.selectedShippingWarehouses,
                multiple: "",
                searchable: "",
                placeholder: "Выберите склады отгрузки"
              },
              on: { select: _vm.onTrademarkSelect }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputblock" },
          [
            _c(
              "gp-check",
              {
                attrs: { checked: _vm.isActiveCard },
                on: { change: _vm.handleChange }
              },
              [
                _vm._v("\n            \n          "),
                _c("l10n", { attrs: { value: "Разные контакты по складам" } })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "main__section" }, [
        _c("h3", { staticClass: "section__title" }, [
          _vm._v("Условия обработки заказов")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputblock" },
          [
            _c(
              "label",
              { staticClass: "inputblock__label", attrs: { for: "contacts" } },
              [_vm._v("Контакты")]
            ),
            _vm._v(" "),
            _c("my-multiselect", {
              attrs: {
                id: "contacts",
                options: _vm.contacts,
                selectedOption: _vm.selectedContacts,
                placeholder: "Выберите контакты"
              },
              on: { select: _vm.onTrademarkSelect }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "main__section" }, [
        _c("h3", { staticClass: "section__title" }, [_vm._v("График заказов")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputblock" },
          [
            _c(
              "label",
              {
                staticClass: "inputblock__label",
                attrs: { for: "ordersShippingDays" }
              },
              [_vm._v("Дни отправки заказов")]
            ),
            _vm._v(" "),
            _c("my-multiselect", {
              attrs: {
                id: "ordersShippingDays",
                options: _vm.ordersShippingDays,
                selectedOption: _vm.selectedOrdersShippingDays,
                placeholder: "Выберите дни отправки заказов"
              },
              on: { select: _vm.onTrademarkSelect }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputblock" },
          [
            _c(
              "label",
              {
                staticClass: "inputblock__label",
                attrs: { for: "ordersShippingTime" }
              },
              [_vm._v("Время отправки заказов(до)")]
            ),
            _vm._v(" "),
            _c("my-multiselect", {
              attrs: {
                id: "ordersShippingTime",
                options: _vm.ordersShippingTime,
                selectedOption: _vm.selectedOrdersShippingTime,
                placeholder: "Выберите время отправки заказов"
              },
              on: { select: _vm.onTrademarkSelect }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("footer", { staticClass: "footer" }, [
      _c(
        "div",
        { staticClass: "footer__checkbox" },
        [
          _c(
            "gp-check",
            {
              attrs: { checked: _vm.isActiveCard },
              on: { change: _vm.handleChange }
            },
            [
              _vm._v("\n          \n        "),
              _c("l10n", { attrs: { value: "Активная карточка" } })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header__search-form" }, [
      _c("input", { staticClass: "input input-search", attrs: { value: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer__buttons-block" }, [
      _c(
        "button",
        { staticClass: "button button_primary", attrs: { type: "button" } },
        [_vm._v("\n        Дублировать\n      ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "button button_secondary", attrs: { type: "button" } },
        [_vm._v("\n        Сохранить\n      ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }