var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-search" },
    [
      _vm.product == "price"
        ? [
            _c("gp-data", {
              attrs: {
                id: "gp-search-categories",
                stream: _vm.stream,
                source: _vm.source,
                groups: ["categories", "reference-date"],
                filter0: _vm.filter0,
                filter1: _vm.filter1,
                filter2: _vm.filter2,
                filter3: "dim1 != ''",
                dims: ["classif.category", "class"],
                initialSort: [1],
                instant: false
              },
              model: {
                value: _vm.categoriesReport,
                callback: function($$v) {
                  _vm.categoriesReport = $$v
                },
                expression: "categoriesReport"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "gp-search-classes" },
              [
                _c("feather-icon", { attrs: { name: "folder" } }),
                _c("gp-pills", {
                  attrs: {
                    options: _vm.selectableCategories,
                    placeholder: _vm.l10n("Categories"),
                    recentOptionsKey: "recentCategories"
                  },
                  model: {
                    value: _vm.selectedCategories,
                    callback: function($$v) {
                      _vm.selectedCategories = $$v
                    },
                    expression: "selectedCategories"
                  }
                }),
                _vm._v(" "),
                _c("gp-pills", {
                  attrs: {
                    options: _vm.knownClasses,
                    placeholder: _vm.l10n("Classes"),
                    recentOptionsKey: "recentClasses"
                  },
                  model: {
                    value: _vm.selectedClasses,
                    callback: function($$v) {
                      _vm.selectedClasses = $$v
                    },
                    expression: "selectedClasses"
                  }
                })
              ],
              1
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("gp-filter", {
        attrs: {
          stream: _vm.stream,
          source: _vm.source,
          groups: ["search-filter"],
          filter0: _vm.filterFilters.filter0,
          filter1: _vm.filterFilters.filter1,
          filter2: _vm.filterFilters.filter2,
          threshold: _vm.threshold,
          attributes: _vm.attributes,
          formulas: _vm.formulas,
          vars: _vm.vars
        },
        model: {
          value: _vm.filter,
          callback: function($$v) {
            _vm.filter = $$v
          },
          expression: "filter"
        }
      }),
      _vm._v(" "),
      _vm.product == "price"
        ? [
            _c(
              "gp-check",
              {
                attrs: { disabled: !_vm.selectiveFilter },
                model: {
                  value: _vm.showLinkedItems,
                  callback: function($$v) {
                    _vm.showLinkedItems = $$v
                  },
                  expression: "showLinkedItems"
                }
              },
              [
                _c("l10n", { attrs: { value: "Show linked items" } }),
                _vm._v(" "),
                _c("inline-help", {
                  attrs: {
                    text:
                      "If set includes related items in line, size, brand and other groups"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "gp-check",
              {
                model: {
                  value: _vm.showInactiveAssortment,
                  callback: function($$v) {
                    _vm.showInactiveAssortment = $$v
                  },
                  expression: "showInactiveAssortment"
                }
              },
              [_c("l10n", { attrs: { value: "Show inactive assortment" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "gp-check",
              {
                model: {
                  value: _vm.showClosedStores,
                  callback: function($$v) {
                    _vm.showClosedStores = $$v
                  },
                  expression: "showClosedStores"
                }
              },
              [_c("l10n", { attrs: { value: "Show closed stores" } })],
              1
            ),
            _vm._v(" "),
            _vm.showLinkedItems && _vm.selectiveFilter
              ? _c("gp-data", {
                  attrs: {
                    id: "gp-linked-attrs",
                    stream: _vm.stream,
                    source: _vm.source,
                    groups: ["linked-attrs", "reference-date"],
                    filter0: _vm.directFilters.filter0,
                    filter1: _vm.directFilters.filter1,
                    filter2: _vm.directFilters.filter2,
                    dims: _vm.linkedAttrs
                  },
                  model: {
                    value: _vm.linkedAttrsReport,
                    callback: function($$v) {
                      _vm.linkedAttrsReport = $$v
                    },
                    expression: "linkedAttrsReport"
                  }
                })
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }