<template>
	<div class="gp-column-filters">
        <a
            href="javascript:void(0)"
            @click="conditionExpanded = !conditionExpanded">
            <feather-icon :name="conditionExpanded ? 'chevron-down' : 'chevron-right'"/>
            <l10n value="Filter by condition"/>
        </a>
        <template v-if="conditionExpanded">
            <select
                class="form-control form-control-sm"
                :value="currentCondition"
                @change="handleConditionChange">
                <option
                    v-for="condition, key in validConditions"
                    :value="key" ref="conditions"
                    >
                    <l10n :value="condition.name"/>
                </option>
            </select>
            <div class="gp-column-filters-args" v-if="validConditions[currentCondition]">
                <input
                    class="form-control form-control-sm"
                    v-for="type, i in validConditions[currentCondition].args"
                    :type="type"
                    :value="currentValue(i)"
                    @change="handleValueChange($event, i)"/>
            </div>
        </template>
        <a
            v-if="type === 'text'"
            href="javascript:void(0)"
            @click="valueExpanded = !valueExpanded">
            <feather-icon :name="valueExpanded ? 'chevron-down' : 'chevron-right'"/>
            <l10n value="Filter by value"/>
        </a>
        <template v-if="type === 'text' && valueExpanded">
            <input
                class="form-control form-control-sm"
                type="search"
                :placeholder="l10n('Search...')"
                @change="updateSearchString"
                @search="updateSearchString"
                @click="updateSearchString"
                @keyup="updateSearchString"/>
            <ul>
                <li v-if="!report && !(errors && errors.length)">
                    <l10n class="text-muted" value="Loading..."/>
                </li>
                <li v-else-if="errors && errors.length">
                    {{ errors }}
                </li>
                <template v-else>
                    <li v-for="value in visibleValues.slice(0,moreThreshold)">
                        <gp-check
                            :checked="currentValues[value.item ? value.item[1] : value] !== false"
                            @change="handleValuesChange(value.item ? value.item[1] : value, $event)"
                            >
                            <template v-if="value.item">
                                <span
                                    v-for="part in formatSearchItem(value)"
                                    :class="{matched: part.matched}"
                                    >{{part.text}}</span>
                            </template>
                            <template v-else-if="value">{{formatValue(value)}}</template>
                            <template v-else><l10n value="(Blanks)"/></template>
                        </gp-check>
                    </li>
                    <li v-if="visibleValues.length > moreThreshold">
                        <a href="#" @click.prevent="moreThreshold = moreThreshold * 2">
                            <l10n value="and {more} more..." :more="new Number(visibleValues.length-moreThreshold).toLocaleString()"/>
                        </a>
                    </li>
                </template>
            </ul>
            <div class="gp-column-filters-values-actions">
                <a
                    href="javascript:void(0)"
                    @click="selectAllValues">
                    <l10n value="Select all"/>
                </a>
                –
                <a
                    href="javascript:void(0)"
                    @click="clearValues">
                    <l10n value="Select none"/>
                </a>
            </div>
        </template>
    </div>
</template>
<script>
const utils = require("./my-utils")

const conditions = {
    "none": {
      "name": "Not set",
    },
    "is_empty": {
      "name": "Is empty",
    },
    "is_not_empty": {
      "name": "Is not empty",
    },
    "text_contains": {
      "name": "Text contains",
      "args": [
        "text"
      ],
    },
    "text_doesnt_contain": {
      "name": "Text doesn't contain",
      "args": [
        "text"
      ],
    },
    "text_starts_with": {
      "name": "Text starts with",
      "args": [
        "text"
      ],
    },
    "text_ends_with": {
      "name": "Text ends with",
      "args": [
        "text"
      ],
    },
    "text_is_exactly": {
      "name": "Text is exactly",
      "args": [
        "text"
      ],
    },
    "greater_than": {
      "name": "Greater than",
      "args": [
        "number"
      ],
    },
    "greater_than_or_equal": {
      "name": "Greater than or equal to",
      "args": [
        "number"
      ],
    },
    "less_than": {
      "name": "Less than",
      "args": [
        "number"
      ],
    },
    "less_than_or_equal": {
      "name": "Less than or equal to",
      "args": [
        "number"
      ],
    },
    "is_equal_to": {
      "name": "Equal to",
      "args": [
        "number"
      ],
    },
    "is_not_equal_to": {
      "name": "Not equal to",
      "args": [
        "number"
      ],
    },
    "is_between": {
      "name": "Insider interval",
      "args": [
        "number",
        "number"
      ],
    },
    "is_not_betweeen": {
      "name": "Outside interval",
      "args": [
        "number",
        "number"
      ],
    }
}

module.exports = {
    mixins: [
        require("./data.js"),
    ],
    model: {
        prop: "state",
        event: "change",
    },
    props: {
        state:      { type: Object, default: () => ({}) },
        type:       { type: String, default: "text" },
        format:     { type: Function, default: (x) => `${x}` },
        parse:      { type: Function, default: (x) => x },
    },
    data() {
        return {
            l10n: utils.l10n,
            conditionExpanded: this.state.condition && this.state.condition !== "none",
            valueExpanded: this.state.values && Object.keys(this.state.values).length > 0,
            changes: {},
            searchString: "",
            moreThreshold: 100,
            conditions,
        }
    },
    computed: {
        validConditions() {
            return _(this.conditions)
                .toPairs()
                .filter(([key, {args}]) =>
                    key === "none" ||
                    !args && this.type === "text" ||
                    args && args[0] === this.type)
                .fromPairs()
                .value()
        },
        somethingChanged() {
            return !_.isEmpty(this.changes)
        },
        currentCondition() {
            return this.changes.condition || this.state.condition || "none"
        },
        currentValues() {
            return this.changes.values || this.state.values || {}
        },
        currentArgs() {
            return this.changes.args || this.state.args || []
        },
        uniqueValues() {
            return this.report?.rows?.map(x => x[0]) || []
        },
        knownValues() {
            return new Fuse(this.uniqueValues.map(x => [this.formatValue(x), x]), {
                isCaseSensitive: false,
                shouldSort: true,
                includeMatches: true,
                keys: ["0"]
            })
        },
        visibleValues() {
            const values = this.knownValues && this.searchString
                ? this.knownValues.search(this.searchString)
                : this.uniqueValues
            return [...values].sort((a, b) => this.formatValue(a) > this.formatValue(b) ? 1 : (this.formatValue(a) === this.formatValue(b) ? 0 : -1))
        },
    },
    methods: {
        currentValue(i) {
            let x = this.currentArgs[i]
            if (x === undefined)
                return this.type == "number" ? "0" : ""
            else
                return this.currentArgs[i]
        },
        updateSearchString(e) {
            let value = e.target.value
            clearTimeout(this.searchStringTimeout)
            if (value === "")
                this.searchString = ""
            else
                this.searchStringTimeout = setTimeout(() => this.searchString = value, 200)
        },
        handleConditionChange(e) {
            let condition = this.$refs.conditions.find(({selected}) => selected)
            this.changeValues("condition", condition.value)
        },
        handleValueChange(e,i) {
            let args = _.clone(this.currentArgs)
            args[i] = this.parse(e.target.value)
            this.changeValues("args", args)
        },
        handleValuesChange(value, checked) {
            let values = _.clone(this.currentValues)
            values[value] = checked
            if (_(values).values().every((x) => x))
                values = {}
            this.changeValues("values", values)
        },
        changeValues: _.debounce(
            function (field, value) {
                if (field === "values") {
                    let include = []
                    let exclude = []
                    for (let val of _.keys(value))
                        if (value[val])
                            include.push(val)
                        else
                            exclude.push(val)
                    if (exclude.length == this.uniqueValues.length) {
                        exclude = undefined
                        include = []
                    }
                    else if (!include.length && exclude.length)
                        include = undefined
                    else if (!exclude.length && include.length)
                        exclude = undefined
                    else if (exclude.length + include.length == this.uniqueValues.length) {
                        if (exclude.length < this.uniqueValues.length / 2)
                            include = undefined
                        else
                            exclude = undefined
                    }
                    else
                        include = undefined

                    this.$set(this.changes, "include", include)
                    this.$set(this.changes, "exclude", exclude)
                }
                this.$set(this.changes, field, value)
                const newState = { ...this.state, ...this.changes }
                newState.filter = this.changeFilters(newState)
                this.changes = {}
                this.$emit("change", newState)
            }, 300, { trailing: true }
        ),
        changeFilters(state) {
            const field = this.columns[0].synonym
            let filter3 = []
            if (state.include?.length > 0)
                filter3.push(`${field} in ${JSON.stringify(state.include)}`)
            if (state.exclude?.length > 0)
                filter3.push(`!(${field} in ${JSON.stringify(state.exclude)})`)
            if (state.condition) {
                let [arg1, arg2] = state.args || []
                if (!arg1) {
                    arg1 = this.type === "number" ? 0 : ""
                }
                if (!arg2) {
                    arg2 = this.type === "number" ? 0 : ""
                }
                switch (state.condition) {
                    case "is_empty":
                        filter3.push(`isnull(${field} == "", true)`)
                        break
                    case "is_not_empty":
                        filter3.push(`isnull(${field} != "", false)`)
                        break
                    case "text_contains":
                        filter3.push(`${field} =~ ${utils.quote(`(?i).*${arg1}.*`)}`)
                        break
                    case "text_doesnt_contain":
                        filter3.push(`${field} !~ ${utils.quote(`(?i).*${arg1}.*`)}`)
                        break
                    case "text_starts_with":
                        filter3.push(`${field} =~ ${utils.quote(`(?i)^${arg1}.*`)}`)
                        break
                    case "text_ends_with":
                        filter3.push(`${field} =~ ${utils.quote(`(?i).*${arg1}$`)}`)
                        break
                    case "text_is_exactly":
                        filter3.push(`${field} == ${utils.quote(arg1)}`)
                        break
                    case "greater_than":
                        filter3.push(`${field} > ${utils.quote(arg1, { type: "int32" })}`)
                        break
                    case "greater_than_or_equal":
                        filter3.push(`${field} >= ${utils.quote(arg1, { type: "int32" })}`)
                        break
                    case "less_than":
                        filter3.push(`${field} < ${utils.quote(arg1, { type: "int32" })}`)
                        break
                    case "less_than_or_equal":
                        filter3.push(`${field} <= ${utils.quote(arg1, { type: "int32" })}`)
                        break
                    case "is_equal_to":
                        filter3.push(`${field} == ${utils.quote(arg1, { type: "int32" })}`)
                        break
                    case "is_not_equal_to":
                        filter3.push(`${field} != ${utils.quote(arg1, { type: "int32" })}`)
                        break
                    case "is_between":
                        filter3.push(`(${field} >= ${utils.quote(arg1, { type: "int32" })} && ${field} <= ${utils.quote(arg2, { type: "int32" })})`)
                        break
                    case "is_not_betweeen":
                        filter3.push(`(${field} < ${utils.quote(arg1, { type: "int32" })} || ${field} > ${utils.quote(arg2, { type: "int32" })})`)
                        break
                }
            }
            return filter3.join(" && ")
        },
        selectAllValues() {
            if (this.searchString === "")
                this.changeValues("values", {})
            else {
                let values = _.clone(this.currentValues)
                for (let value of this.visibleValues)
                    values[value.item ? value.item : value] = true
                this.changeValues("values", values)
            }
        },
        clearValues() {
            let values = _.clone(this.currentValues)
            for (let value of this.visibleValues)
                values[value.item ? value.item : value] = false
            this.changeValues("values", values)
        },
        formatSearchItem({item, matches}) {
            let indices = _(matches).map(({indices}) => indices).flatten().value()
            let i = 0
            let text = item[0]
            let parts = []
            while (i < text.length) {
                if (indices.length > 0) {
                    let [a,b] = indices[0]
                    if (i === a) {
                        parts.push({
                            text: text.slice(a, b+1),
                            matched: true
                        })
                        i = b+1
                        indices = indices.slice(1)
                    }
                    else {
                        parts.push({
                            text: text.slice(i, a),
                            matched: false
                        })
                        i = a
                    }
                }
                else {
                    parts.push({
                        text: text.slice(i),
                        matched: false
                    })
                    i = text.length
                }
            }
            return parts
        },
        formatValue(value) {
            try {
                return this.format(value)
            } catch {
                return `${value}`
            }
        },
    }
}
</script>
<style scoped>
.gp-column-filters-args {
    display: flex!important;
    margin-right: -8px;
}
.gp-column-filters-args > * {
    margin-right: 8px;
    min-width: 0;
    flex-grow: 1;
    flex-basis: 1px;
    width: 90px;
}
.gp-column-filters > * {
    display: block;
    margin-bottom: 4px;
}
.gp-column-filters > ul {
    padding-left: 10px;
    padding-bottom: 4px;
    overflow-y: auto;
    max-height: 150px;
}
.gp-column-filters > ul:not(:empty) {
    border: 1px solid #ced4da;
    border-radius: 5px;
}
.gp-column-filters > ul > li label {
    line-height: 1.1em;
}
.gp-column-filters-actions {
    display: flex;
    margin-top: 8px;
    margin-right: -8px;
    margin-bottom: 4px;
}
.gp-column-filters-actions > .btn-sm {
    flex-grow: 1;
    flex-basis: 1px;
    margin-right: 8px;
    height: auto;
    line-height: 16px;
}
.gp-column-filters .matched {
    color: var(--red);
}
</style>