import VJstree from 'vue-jstree';
import VCalendar from 'v-calendar';
import utils from './my-utils';


let App = require("./app.vue").default;

Vue.use(VCalendar)

Vue.component(VJstree)
Vue.component("dev", require("./dev.vue").default)
Vue.component("l10n", require("./l10n.vue").default)
Vue.component("backups", require("./backups.vue").default)
Vue.component("sitemap", require("./sitemap.vue").default)
Vue.component("promise", require("./promise.vue").default)
Vue.component("popper", require("./popper.vue").default)
Vue.component("login-form", require("./login-form.vue").default)
Vue.component("ace-editor", require("./ace-editor.vue").default)
Vue.component("markdown", require("./markdown.js"))
Vue.component("feather-icon", require("./feather-icon.vue").default)
Vue.component("inline-help", require("./inline-help.vue").default)
Vue.component("inline-input", require("./inline-input.vue").default)
Vue.component("inline-select", require("./inline-select.vue").default)
Vue.component("my-description", require("./my-description.vue").default)
Vue.component("my-errors", require("./my-errors.vue").default)
Vue.component("my-dialog", require("./my-dialog.vue").default)
Vue.component("my-filters", require("./my-filters.vue").default)
Vue.component("my-caption", require("./my-caption.vue").default)
Vue.component("my-assembly", require("./my-assembly.vue").default)
Vue.component("my-download", require("./my-download.vue").default)
Vue.component("my-progress", require("./my-progress.vue").default)
Vue.component("my-tooltip", require("./my-tooltip.vue").default)
Vue.component("my-popup", require("./my-popup.vue").default)
Vue.component("my-search", require("./my-search.vue").default)
Vue.component("my-date-picker", require("./my-date-picker.vue").default)
Vue.component("svg-title", require("./svg-title.vue").default)
Vue.component("html-title", require("./html-title.vue").default)
Vue.component("bar-chart", require("./dc/bar-chart.vue").default)
Vue.component("row-chart", require("./dc/row-chart.vue").default)
Vue.component("pie-chart", require("./dc/pie-chart.vue").default)
Vue.component("line-chart", require("./dc/line-chart.vue").default)
Vue.component("series-chart", require("./dc/series-chart.vue").default)
Vue.component("bubble-chart", require("./dc/bubble-chart.vue").default)
Vue.component("scatter-plot", require("./dc/scatter-plot.vue").default)
Vue.component("number-display", require("./dc/number-display.vue").default)
Vue.component("composite-chart", require("./dc/composite-chart.vue").default)
Vue.component("heat-map", require("./dc/heat-map.vue").default)

Vue.component("plain-table", require("./plain-table.vue").default)
Vue.component("plain-table-tile", require("./plain-table-tile.vue").default)
Vue.component("plain-table-tile-size", require("./plain-table-tile-size.vue").default)
Vue.component("plain-table-row-static", require("./plain-table-row-static.vue").default)
Vue.component("plain-table-row-editable", require("./plain-table-row-editable.vue").default)
Vue.component("plain-table-cell-static", require("./plain-table-cell-static.vue").default)
Vue.component("plain-table-cell-editable", require("./plain-table-cell-editable.vue").default)
Vue.component("plain-table-column-filter", require("./plain-table-column-filter.vue").default)

Vue.component("date-filter", require("./date-filter.vue").default)
Vue.component("chosen", require("./chosen.vue").default)
Vue.component("leaflet", require("./leaflet.vue").default)
Vue.component("foamtree", require("./foamtree.vue").default)
Vue.component("fancytree", require("./fancytree.vue").default)
Vue.component("sharethis", require("./sharethis.vue").default)
Vue.component("basket", require("./basket.vue").default)
Vue.component("choropleth", require("./choropleth.vue").default)
Vue.component("fulltext-search", require("./fulltext-search.vue").default)
Vue.component("gauge-chart", require("./gauge-chart.vue").default)
Vue.component("gp-portal", require("./gp/gp-portal.vue").default)
Vue.component("gp-show", require("./gp/gp-show.vue").default)
Vue.component("gp-menu", require("./gp/gp-menu.vue").default)
Vue.component("gp-stream", require("./gp/gp-stream.vue").default)
Vue.component("gp-report", require("./gp/gp-report.vue").default)
Vue.component("gp-cache", require("./gp/gp-cache.vue").default)
Vue.component("gp-paths", require("./gp/gp-paths.vue").default)
Vue.component("gp-pills", require("./gp/gp-pills.vue").default)
Vue.component("gp-check", require("./gp/gp-check.vue").default)
Vue.component("gp-search", require("./gp/gp-search.vue").default)
Vue.component("gp-date", require("./gp/gp-date.vue").default)
Vue.component("gp-data", require("./gp/gp-data.vue").default)
Vue.component("gp-chart", require("./gp/gp-chart.vue").default)
Vue.component("gp-table", require("./gp/gp-table.vue").default)
Vue.component("gp-table-runs", require("./gp/gp-table-runs.vue").default)
Vue.component("gp-table-search", require("./gp/gp-table-search.vue").default)
Vue.component("gp-rules", require("./gp/gp-rules.vue").default)
Vue.component("gp-rules-new", require("./gp/gp-rules/gp-rules.vue").default)
Vue.component("gp-bounds", require("./gp/gp-bounds.vue").default)
Vue.component("gp-select", require("./gp/gp-select.vue").default)
Vue.component("gp-scope", require("./gp/gp-scope.vue").default)
Vue.component("gp-scopes", require("./gp/gp-scopes.vue").default)
Vue.component("gp-filter", require("./gp/gp-filter.vue").default)
Vue.component("gp-reports", require("./gp/gp-reports.vue").default)
Vue.component("gp-stored", require("./gp/gp-stored.vue").default)
Vue.component("gp-test", require("./gp/gp-test.vue").default)
Vue.component("gp-config", require("./gp/gp-config.vue").default)
Vue.component("gp-news", require("./gp/gp-news.vue").default)
Vue.component("gp-upload", require("./gp/gp-upload.vue").default)
Vue.component("gp-endings", require("./gp/gp-endings.vue").default)
Vue.component("gp-version", require("./gp/gp-version.vue").default)
Vue.component("gp-kpis", require("./gp/gp-kpis.vue").default)
Vue.component("gp-kpis3", require("./gp/gp-kpis3.vue").default)
Vue.component("gp-users", require("./gp/gp-users.vue").default)
Vue.component("gp-support", require("./gp/gp-support.vue").default)
Vue.component("gp-zendesk", require("./gp/gp-zendesk.vue").default)
Vue.component("gp-recorder", require("./gp/gp-recorder.vue").default)
Vue.component("gp-runs", require("./gp/gp-runs.vue").default)
Vue.component("gp-tasks", require("./gp/gp-tasks.vue").default)
Vue.component("gp-tabs", require("./gp/gp-tabs.vue").default)
Vue.component("gp-page", require("./gp/gp-page.vue").default)
Vue.component("gp-grid", require("./gp/gp-grid.vue").default)
Vue.component("gp-treeview", require("./gp/gp-treeview.vue").default)
Vue.component("gp-timeframe-create", require("./gp/gp-timeframe-create.vue").default)
Vue.component("gp-timeframe-select", require("./gp/gp-timeframe-select.vue").default)
Vue.component("gp-jupyter", require("./gp/gp-jupyter.vue").default)

Vue.component("gp-column-precision", require("./gp/gp-column-precision.vue").default)
Vue.component("gp-column-filters", require("./gp/gp-column-filters.vue").default)
Vue.component("gp-column-styles", require("./gp/gp-column-styles.vue").default)
Vue.component("gp-column-stats", require("./gp/gp-column-stats.vue").default)
Vue.component("gp-section-columns", require("./gp/gp-section-columns.vue").default)
Vue.component("gp-bounds-related", require("./gp/gp-bounds-related.vue").default)
Vue.component("gp-loaded-path-info", require("./gp/gp-loaded-path-info.vue").default)

Vue.component("pim-main", require("./pim/pim-main.vue").default)
Vue.component("pim-tabs", require("./pim/pim-tabs.vue").default)
Vue.component("pim-field", require("./pim/pim-field.vue").default)
Vue.component("pim-fields", require("./pim/pim-fields.vue").default)
Vue.component("pim-stream", require("./pim/pim-stream.vue").default)
Vue.component("pim-columns", require("./pim/pim-columns.vue").default)
Vue.component("pim-permalink", require("./pim/pim-permalink.vue").default)
Vue.component("pim-select", require("./pim/pim-select.vue").default)
Vue.component("pim-tree", require("./pim/pim-tree.vue").default)
Vue.component("pim-tree-chart", require("./pim/pim-tree-chart.vue").default)
Vue.component("pim-tree-entry", require("./pim/pim-tree-entry.vue").default)
Vue.component("pim-tree-table", require("./pim/pim-tree-table.vue").default)
Vue.component("pim-tree-paginator", require("./pim/pim-tree-paginator.vue").default)
Vue.component("pim-tree-multiselect", require("./pim/pim-tree-multiselect.vue").default)
Vue.component("pim-attributes", require("./pim/pim-attributes.vue").default)
Vue.component("pim-attributes-ex", require("./pim/pim-attributes-ex.vue").default)
Vue.component("pim-hierarchies", require("./pim/pim-hierarchies.vue").default)
Vue.component("pim-hierarchies-foot", require("./pim/pim-hierarchies-foot.vue").default)
Vue.component("pim-hierarchies-side", require("./pim/pim-hierarchies-side.vue").default)
Vue.component("pim-hierarchies-entry", require("./pim/pim-hierarchies-entry.vue").default)
Vue.component("pim-entries-attrs", require("./pim/pin-entries-attrs.vue").default)
Vue.component("pim-image-over", require("./pim/pim-image-over.vue").default)
Vue.component("pim-export-dialog", require("./pim/pim-export-dialog.vue").default)
Vue.component("pim-filter", require("./pim/pim-filter.vue").default)
Vue.component("pim-workflow", require("./pim/pim-workflow.vue").default)
Vue.component("pim-function", require("./pim/pim-function.vue").default)
Vue.component("pim-ai-cell", require("./pim/pim-ai-cell.vue").default)
Vue.component("pim-items", require("./pim/pim-items.vue").default)

Vue.component("autoorder-chain-rules-card", require("./autoorder/autoorder-chain-rules-card.vue").default);
Vue.component("autoorder-provider-card", require("./autoorder/autoorder-provider-card.vue").default);
Vue.component("autoorder-provider-card-old", require("./autoorder/autoorder-provider-card-old.vue").default);
Vue.component("autoorder-productcard-old", require("./autoorder/autoorder-productcard-old.vue").default);
Vue.component("autoorder-chart", require("./autoorder/autoorder-chart.vue").default);
Vue.component("autoorder-table", require("./autoorder/autoorder-table.vue").default);
Vue.component("autoorder-table-hierarchy", require("./autoorder/components/autoorder-table-hierarchy.vue").default);
Vue.component("autoorder-policies", require("./autoorder/views/policies/autoorder-policies.vue").default);
Vue.component("autoorder-contractors", require("./autoorder/views/contractors/autoorder-contractors.vue").default);
Vue.component("autoorder-calendars", require("./autoorder/views/calendars/autoorder-calendars.vue").default);
Vue.component("autoorder-chains", require("./autoorder/views/chains/autoorder-chains.vue").default);
Vue.component("autoorder-products", require("./autoorder/views/products/autoorder-products.vue").default);
Vue.component("autoorder-warehouses", require("./autoorder/views/warehouses/autoorder-warehouses.vue").default);
Vue.component("autoorder-promos", require("./autoorder/views/promos/autoorder-promos.vue").default);

Vue.prototype.$username = null;
Vue.prototype.$notifications = JSON.parse(localStorage.getItem('notifications')) || [];
Vue.prototype.$crossFilters = {};

Vue.prototype.getComponentById = function (id) {
    let front = [this];
    while (front.length) {
        for (let component of front) {
            if (component.$attrs.id == id || component.$options.propsData?.id == id) {
                return component;
            }
        }
        front = _(front).map(component => component.$children).flatten().value();
    }
}

Vue.prototype.getElementsData = function (id) {
    let component = id ? this.getComponentById(id) : this;
    for (; component; component = component.$parent) {
        if (component.dataThrottled) {
            return component.dataThrottled;
        }
        if (component.fixedData) {
            return component.fixedData;
        }
    }
    return {};
}

Vue.prototype.createNotification = function (text, type) {
    const notification = {
        text,
        type,
        id: utils.randomId(),
        created: Date.now(),
        isNew: true,
    };
    this.$notifications.push(notification);
    localStorage.setItem('notifications', JSON.stringify(this.$notifications));
}

let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

if (iOS) {
    document.body.classList.add("my-device-ios");
}

new Vue({
    el: "#app",
    render: h => h(App),
});
