<template>
    <div class="gp-news" @mouseenter="created = true">
        <a href="javascript:void(0)"
            @click="created = true; opened = !opened">
            <feather-icon :name="opened ? 'x' : 'gift'"/>
        </a>
        <gp-page
            :path="path"
            v-if="created"
            v-show="opened"
            ref="page"
            :username="username"
            :darkTheme="darkTheme"
            :editable="true"
            />
    </div>
</template>
<script>
module.exports = {
    props: {
        path: { type: String, default: "news" },
        username: { type: String },
        darkTheme: { type: Boolean },
    },
	data() {
		return {
			created: false,
			opened: false,
		}
	},
    watch: {
        opened() {
            this.$refs.page.fetch()
        }
    },
}
</script>
<style>
.gp-news {
    position: absolute;
    right: 20px;
}
.gp-news > a .feather-icon svg {
    color: var(--orange);
    width: 22px;
    height: 22px;
    margin-top: 1px;
    display: inline-block;
    vertical-align: top;
}
.gp-news > .gp-page {
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 100;
    width: 600px;
    max-width: calc(100vw - 80px);
    height: 80vh;
    background-color: white;
    border: 1px solid var(--dark);
    box-shadow: 0 0 10px 0px var(--dark);
    padding: 15px 20px;
}
.gp-news > .gp-page.editing {
    padding: 15px 15px;
}
.my-dark-theme .gp-news > .gp-page {
    border: 1px solid var(--light);
    background-color: #222;
    box-shadow: 0 0 10px 0px black;
}
</style>