<template>
    <div class="my-composite-chart" :style="{opacity: pending ? 0.5 : 1}">
        <my-caption :caption="caption"/>
        <slot></slot>
        <my-tooltip
            ref="tooltip"
            :meta="meta"
            :chart="chart"
            :keepOnly="keepOnly"
            :exclude="exclude"
            :actions="actions"
            />
        <div class="my-chart" :style="style" ref="base">
            <div ref="chart"></div>
        </div>
    </div>
</template>

<script>
module.exports = {
    mixins: [
        require("./base.js"),
        require("./margin.js"),
        require("./coordinate-grid.js"),
        require("./composite-chart.js"),
    ],
    data() {
        return { meta: null, pending: false, pendingTimer: null }
    },
    mounted() {
        this.pendingTimer = setInterval(() => {
            this.pending = _.some(this.$slots.default, (slot) => slot.componentInstance?.reportId)
        }, 100)
    },
    beforeDestroy() {
        clearInterval(this.pendingTimer)
    },
}
</script>