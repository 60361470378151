<template>
  <div
    ref="base"
    class="gp-rules"
  >
    <MigrationTool
      v-if="showRulesMigrateTool"
      :rules-library="rulesLibrary"
    />

    <gp-stored
      v-model="config"
      :family="storedFamilyName"
      :username="username"
      :share-groups="shareGroups"
      @saved="configChanged"
      @change="configChanged"
    />

    <div class="form-group">
      <label>
        <l10n value="Rules scope" />
      </label>

      <gp-filter
        v-model="editingFilter"
        :stream="stream"
        :source="source"
        :groups="groups"
        :filter0="extraFilter0"
        :filter1="extraFilter1"
        :filter2="extraFilter2"
        :threshold="threshold"
        :attributes="attributes"
        :formulas="formulas"
        :vars="vars"
      />
    </div>

    <div class="gp-rules-hint">
      <l10n value="Use drag & drop to reorder sections and rules" />
    </div>

    <div class="gp-rules-weight">
      <l10n value="Rule weight" />
      <inline-help text="Optimization minimizes cummulative rules violation measured in price delta beyond boundaries defined by rules. Rule weight allows to control importance of every rule or an entire section of rules." />
    </div>

    <div
      ref="sections"
      class="gp-rules-sections"
    >
      <div
        v-for="section in editingSections"
        :key="section.id"
        :data-section="section.id"
        class="gp-rules-section"
        :class="{ expanded: expandedSections[section.id] }"
      >
        <label>
          <a
            href="javascript:void(0)"
            @click="selectedSection = section"
          >
            <feather-icon name="more-vertical" />
          </a>

          <a
            href="javascript:void(0)"
            class="gp-rules-weight"
            @click="promptSectionWeight(section)"
          >{{ formatPercent(section.weight !== undefined ? section.weight * 100 : 100) }}</a>

          <div
            v-if="types.length > 1"
            class="gp-section-opt-types-icons"
          >
            <span
              v-for="(sectionType, idx) in types"
              :key="idx"
              :class="{
                active: section.types.includes(sectionType.code),
              }"
            >
              <l10n :value="sectionType.icon" />
            </span>
          </div>

          <a
            href="javascript:void(0)"
            @click="$set(expandedSections, section.id, !expandedSections[section.id])"
          >
            <feather-icon :name="expandedSections[section.id] ? 'chevrons-down' : 'chevrons-right'" />
            <span>{{ sectionName(section) }}</span>
          </a>

          <span>–</span>

          <l10n
            value="{rules} price rules"
            :rules="new Number(section.rules.length).toLocaleString()"
          />
        </label>

        <a
          v-if="section.rules.length"
          class="gp-section-expand-collapse"
          href="javascript:void(0)"
          @click="isAllRulesExpanded(section)
            ? collapseAllRules(section)
            : expandAllRules(section)
          "
        >
          <l10n
            :value="isAllRulesExpanded(section)
              ? 'collapse all'
              : 'expand all'
            "
          />
        </a>

        <div
          v-if="types.length > 1 && expandedSections[section.id]"
          class="gp-section-opt-types-checks"
        >
          <gp-check
            v-for="(sectionType, idx) in types"
            :key="idx"
            :checked="section.types.includes(sectionType.code)"
            @click="toogleSectionType(section, sectionType.code)"
          >
            <l10n :value="sectionType.name" />
          </gp-check>
        </div>

        <!-- rules list -->
        <ul
          v-if="expandedSections[section.id]"
          ref="rules"
          class="gp-rules-list"
        >
          <li
            v-for="(rule, i) in section.rules"
            :key="rule.id"
            :data-section="section.id"
            :data-rule="rule.id"
            :class="{
              'gp-rule-item': true,
              expanded: expandedRules[rule.id],
            }"
          >
            <!-- rule label -->
            <label>
              <a
                href="javascript:void(0)"
                @click="selectedRule = rule"
              >
                <feather-icon name="more-vertical" />
              </a>

              <a
                v-if="rule.type !== 'rounding'
                  && rule.type !== 'allowable_percent'
                  && rule.type !== 'price_change_cnt_limits'
                  && rule.type !== 'weak_optimization'
                  && rule.type !== 'optimization_effect_limits'
                "
                href="javascript:void(0)"
                class="gp-rules-weight"
                @click="promptRuleWeight(rule)"
              >
                {{ formatPercent(rule.weight !== undefined ? rule.weight * 100 : 100) }}
              </a>

              <a
                href="javascript:void(0)"
                @click="$set(expandedRules, rule.id, !expandedRules[rule.id])"
              >
                <feather-icon :name="expandedRules[rule.id] ? 'chevron-down' : 'chevron-right'" />
                <span>#{{ i + 1 }}</span>
                <l10n :value="rule.name" />
              </a>
            </label>

            <!-- checkbox for strict rule -->
            <gp-check
              v-if="expandedRules[rule.id] && strictRules.includes(rule.type)"
              v-model="rule.strict"
              class="float-right"
            >
              <l10n value="strict rule" />
            </gp-check>

            <!-- filterы for rule -->
            <p
              v-if="expandedRules[rule.id]
                && rule.type !== 'price_change_cnt_limits'
                && rule.type !== 'optimization_effect_limits'
              "
              class="gp-rule-filter"
            >
              <gp-filter
                v-model="rule.filter"
                :stream="stream"
                :source="source"
                :groups="groups"
                :filter0="extraFilter0"
                :filter1="extraFilter1"
                :filter2="extraFilter2"
                :threshold="threshold"
                :attributes="attributes"
                :formulas="formulas"
                :vars="vars"
              />

              <gp-filter
                v-model="rule.filter_not"
                :stream="stream"
                :source="source"
                :groups="groups"
                :filter0="extraFilter0"
                :filter1="extraFilter1"
                :filter2="extraFilter2"
                :threshold="threshold"
                :attributes="attributes"
                :formulas="formulas"
                :vars="vars"
              />
            </p>

            <!-- new rule text -->
            <RuleText
              v-if="expandedRules[rule.id]"
              :rule="rule"
              :locale="locale"
              :currency="currency"
              @update-value="updateValue(rule, $event)"
            />
          </li>
        </ul>

        <!-- add new rule -->
        <div
          v-if="expandedSections[section.id]"
          class="gp-rules-add-rule"
        >
          <my-popup
            v-if="showRulesLibrary === section.id"
            portal="popup"
            placement="bottom-start"
            @escape="showRulesLibrary = null"
            @clickoutside="showRulesLibrary = null"
          >
            <template #anchor>
              <a
                href="javascript:void(0)"
                style="display: block;"
                @click="showRulesLibrary = null"
              >
                <feather-icon name="plus" />
                <l10n value="Add price rule" />
              </a>
            </template>

            <ul class="gp-rules-library">
              <li
                v-for="(rule, idx) in rulesLibrary.filter((rule) => rule.show !== false)"
                :key="idx"
              >
                <a
                  href="javascript:void(0)"
                  @click="addRule(section, rule)"
                >{{ rule.name }}</a>
              </li>
            </ul>
          </my-popup>

          <a
            v-else
            href="javascript:void(0)"
            @click="showRulesLibrary = section.id"
          >
            <feather-icon name="plus" />
            <l10n value="Add price rule" />
          </a>
        </div>
      </div>

      <RulesActions
        :default-types="defaultTypes"
        :editing-sections="editingSections"
        :config="config"
        @add-section="addSection"
      />
    </div>

    <OptimizationSettings
      :config-name="config.name"
      :multiple-types="multipleTypes"
      :available-optimization-types="types"
      :checked-optimization-types="optimizationTypes"
      @set-run-name="runName = $event"
      @set-checked-optimization-types="optimizationTypes = $event"
      @set-is-temporary-run="temporaryRun = $event"
    />

    <div class="form-group">
      <button
        type="button"
        class="btn btm-sm btn-secondary"
        :disabled="optimizing"
        @click="optimize"
      >
        <l10n value="Optimize" />
      </button>
    </div>

    <div v-if="optimizing">
      <l10n :value="optimizationStatus" />
      <div class="progress">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated bg-info"
          :style="{ width: `${optimizationProgress * 100}%` }"
        />
      </div>
    </div>

    <OptimizationResults
      :stats="stats"
      :locale="locale"
    />

    <OptimizationSchedule
      :config-schedule="config.schedule"
      :default-scheduled-run="defaultScheduledRun"

      :stream="stream"
      :source="source"
      :filter0="filter0"
      :filter1="filter1"
      :filter2="filter2"
      :vars="vars"

      @set-schedule="$set(config, 'schedule', $event)"
    />

    <OptimizationConfiguration
      :filter="editingFilter"
      :sections="editingSections"
      :schedule="config.schedule"
      @apply-config="applyConfig"
    />

    <SelectionPopup
      :selected-section="selectedSection"
      @close-popup="selectedSection = null"
      @rename-section="renameSection"
      @clone-section="cloneSection"
      @delete-section="deleteSection"
    />

    <my-popup
      v-if="selectedRule"
      portal="popup"
      placement="bottom-end"
      :anchor="`*[data-rule='${selectedRule.id}'] .feather-icon-more-vertical`"
      @escape="selectedRule = null"
      @clickoutside="selectedRule = null"
    >
      <div class="popover show plain-table-manage-table">
        <div class="popover-body">
          <ul>
            <li>
              <a
                href="javascript:void(0)"
                @click="
                  promptRuleName(selectedRule);
                  selectedRule = null;
                "
              >
                <feather-icon name="edit-3" />
                <l10n value="Rename price rule" />
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                @click="
                  cloneRule(selectedRule);
                  selectedRule = null;
                "
              >
                <feather-icon name="copy" />
                <l10n value="Duplicate price rule" />
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                @click="
                  deleteRule(selectedRule);
                  selectedRule = null;
                "
              >
                <feather-icon name="trash" />
                <l10n value="Delete price rule" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </my-popup>
  </div>
</template>

<script>
const levenshtein = require('js-levenshtein');
const FuzzySearch = require('fuzzy-search').default;
const utils = require('../../my-utils');
const gpUtils = require('./gp-rules-utils');

const SelectionPopup = require('./_section-menu.vue').default;
const RulesActions = require('./_actions.vue').default;
const OptimizationSettings = require('./_optimization-settings.vue').default;
const OptimizationResults = require('./_optimization-results.vue').default;
const OptimizationSchedule = require('./_optimization-schedule.vue').default;
const OptimizationConfiguration = require('./_optimization-configuration.vue').default;
const RuleText = require('./rule-text/index.vue').default;
const MigrationTool = require('./_migration-tool.vue').default;

const getDefaultTypes = () => ([
  {
    code: 'rbp',
    name: 'Base price optimization',
    icon: 'BP',
  },
  {
    code: 'markdown',
    name: 'Markdown optimization',
    icon: 'MD',
  },
]);

const getDefaultScheduledRun = () => ({
  days: [],
  dows: [],
  time: null,
  categories: [],
});

module.exports = {
  mixins: [
    utils.extraFilters,
    utils.configHelpers,
    utils.referenceDateHelper,
  ],

  components: {
    SelectionPopup,
    RulesActions,
    OptimizationSettings,
    OptimizationResults,
    OptimizationSchedule,
    OptimizationConfiguration,
    RuleText,
    MigrationTool,
  },

  props: {
    username: { type: String, default: null },
    types: { type: Array, default: getDefaultTypes },
    stream: { type: String, default: 'default' },
    vars: { type: Object, default: () => ({}) },
    source: { type: Object },
    groups: { type: Array },
    filter0: { type: String },
    filter1: { type: String },
    filter2: { type: String },
    threshold: { type: Number, default: 100 },
    rulesLibrary: { type: Array, default: () => [] },
    competitors: { type: Array, default: () => [] },
    optimization: { type: Object, default: () => ({}) },
    strictRules: { type: Array, default: () => ['pct_change'] },
    shareGroups: { type: Array, default: () => [] },
    defaultTypes: { type: Array, default: () => ['rbp'] },
    multipleTypes: { type: Boolean, default: false },
    defaultScheduledRun: { type: Object, default: () => getDefaultScheduledRun() },
    settings: { type: Object, default: () => ({}) },
    categories: { type: Object, default: () => ({}) },
    threadsCount: { type: Number, default: 1000 },
    localStorageKey: { type: String, default: 'currentRulesConfigNew' },
    showRulesMigrateTool: { type: Boolean, default: false },
    storedFamilyName: { type: String, default: 'strategies' },
  },

  data() {
    const config = gpUtils.loadConfig(this.localStorageKey);

    let editingSections = [];

    if (config.sections) {
      editingSections = _.cloneDeep(config.sections);
    } else if (config.rules) {
      const id = utils.randomId();
      editingSections = [{ id, rules: _.cloneDeep(config.rules) }];
    }

    return {
      config,

      editingFilter: _.cloneDeep(config.filter) || [],
      editingSections,

      stats: null,
      possibleValues: { competitors: Promise.resolve(this.competitors) },
      showRulesLibrary: null,
      expandedSections: {},
      expandedRules: {},
      optimizationId: null,
      optimizationStatus: null,
      optimizationProgress: 0,
      runName: '',
      selectedSection: null,
      selectedRule: null,
      optimizationTypes: _.clone(this.defaultTypes),
      temporaryRun: false,
    };
  },

  beforeMount() {
    this.actualizeSections(this.editingSections);
  },

  mounted() {
    utils.bridge.bind('ws-message', this.handleWsMessage);

    this.setupSectionsSortable();
    this.setupRulesSortable();
  },

  beforeDestroy() {
    utils.bridge.unbind('ws-message', this.handleWsMessage);
    $(this.$refs.sections).sortable('destroy');
    $(this.$refs.rules).sortable('destroy');
  },

  computed: {
    optimizing() {
      return this.optimizationId != null;
    },
  },

  methods: {
    updateValue(rule, { variableName, newValue }) {
      this.$set(rule.variables[variableName], 'value', newValue);
    },

    applyConfig({ filter, sections, schedule }) {
      this.editingFilter = filter;
      this.editingSections = sections;
      this.$set(this.config, 'schedule', schedule);
    },

    addSection(newSection) {
      this.editingSections.push(newSection);
      this.$set(this.expandedSections, newSection.id, true);
    },

    toogleSectionType(section, type) {
      const i = section.types.indexOf(type);
      if (i !== -1) {
        section.types.splice(i, 1);
      } else {
        section.types.push(type);
      }
    },

    actualizeSections(sections) {
      const newSections = sections.map((s) => {
        const section = _.cloneDeep(s);

        section.types = section.types ?? this.types.map((type) => type.code);
        section.weight = section.weight ?? 1;

        const newRules = section.rules.map((oldRule) => {
          const newRule = _.cloneDeep(oldRule);
          // we need to find library rule corresponding to saved one in 'sections'
          // keeping in mind that section rule can be ranamed and can have multiply copies
          // thats why we need 'levenshtein'
          const libraryRule = _(this.rulesLibrary)
            .filter(({ type }) => type === newRule.type)
            .minBy(({ name, text }) => {
              if (text === newRule.text || name === newRule.name) {
                return 0;
              }
              return 1 + levenshtein(text.slice(0, 40), newRule.text.slice(0, 40));
            });

          if (libraryRule) {
            // update this keys without conditions because libraryRule is always correct source
            newRule.text = libraryRule.text;
            newRule.type = libraryRule.type;
            newRule.grouper = libraryRule.grouper || [];
            newRule.weight = newRule.weight ?? 1;

            if (libraryRule.variables) {
              newRule.variables = Object.entries(libraryRule.variables)
                .reduce((acc, [libraryVariableName, libraryVarialeValue]) => {
                  const ruleKeys = [...libraryRule.text.matchAll(/{([^}\.\[]+)[^}]*?}/g)].map((match) => match[1]);
                  let ruleVariableValue = libraryVarialeValue;

                  // if variable exists in rule text we leave it as is
                  // else take one by key from library
                  if (ruleKeys.includes(libraryVariableName)) {
                    ruleVariableValue = newRule.variables[libraryVariableName];
                  }

                  return {
                    ...acc,
                    [libraryVariableName]: ruleVariableValue,
                  };
                }, {});
            }

            return newRule;
          }

          return oldRule;
        });

        section.rules = newRules;

        return section;
      });

      this.editingSections = newSections;
    },

    handleWsMessage(message) {
      if (message.optimizationId === this.optimizationId) {
        if (message.status !== undefined) {
          this.optimizationStatus = message.status;
        }
        if (message.progress !== undefined) {
          this.optimizationProgress = message.progress;
        }
        if (message.changes !== undefined) {
          this.optimizationId = null;
          // TODO: transfer to _optimization-results.vue
          this.stats = message;
          utils.bridge.trigger('optimizationComplete', message.optimizationId);
        }
      }
    },

    sectionName(section) {
      return section.name || utils.l10n('Default section');
    },

    promptEntityName(entity, text) {
      const name = window.prompt(utils.l10n(text, entity.name), entity.name);
      if (name) {
        this.$set(entity, 'name', name);
      }
    },

    promptEntityWeight(entity, text) {
      let weight = entity.weight !== undefined ? entity.weight * 100 : 100;
      weight = window.prompt(utils.l10n(text), Number(weight).toLocaleString());
      if (weight) {
        this.$set(entity, 'weight', parseFloat(weight.replace(',', '.')) / 100);
      }
    },

    promptSectionWeight(section) {
      this.promptEntityWeight(section, 'Please enter rules section weight');
    },

    renameSection({ id, newName }) {
      const section = this.editingSections.find((s) => s.id === id);
      section.name = newName;
    },

    cloneSection({ id, newSection }) {
      const sectionIndex = this.editingSections.findIndex((s) => s.id === id);
      this.editingSections = [
        ...this.editingSections.slice(0, sectionIndex + 1),
        newSection,
        ...this.editingSections.slice(sectionIndex + 1),
      ];
    },

    deleteSection(id) {
      this.editingSections = this.editingSections.filter((s) => s.id !== id);
    },

    cloneRule(rule) {
      const newRule = _.cloneDeep(rule);
      newRule.id = utils.randomId();
      newRule.name += ' ';
      newRule.name += utils.l10n('(copy)');

      // eslint-disable-next-line no-restricted-syntax
      for (const section of this.editingSections) {
        const i = section.rules.indexOf(rule);
        if (i !== -1) {
          section.rules.splice(i + 1, 0, newRule);
        }
      }
    },

    deleteRule(rule) {
      const text = utils.l10n('Are you sure you want to delete rule {rule}?').replace('{rule}', rule.name);
      if (window.confirm(text)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const section of this.editingSections) {
          const i = section.rules.indexOf(rule);
          if (i !== -1) {
            section.rules.splice(i, 1);
          }
        }
      }
    },

    setupSectionsSortable() {
      $(this.$refs.sections).sortable({
        axis: 'y',
        handle: 'label',
        cursor: 'move',
        helper: 'original',
        distance: 5,
        forceHelperSize: true,
        forcePlaceholderSize: true,
        start: (e, ui) => {
          ui.item.data('n', -1);
          const h = ui.item.height();
          ui.placeholder.height(h);
        },
        change: (e, ui) => {
          const x = ui.placeholder[0];
          const n = Array.prototype.indexOf.call(x.parentNode.childNodes, x);
          ui.item.data('n', n);
        },
        beforeStop: (e, ui) => {
          ui.item.parent().sortable('cancel');
          let section = ui.item.data('section');

          const n = ui.item.data('n');
          if (n !== -1) {
            const sections = this.editingSections;
            const i = sections.findIndex(({ id }) => id === section);
            section = sections.splice(i, 1)[0];
            sections.splice(n > i ? n - 1 : n, 0, section);
          }
        },
      });
    },

    setupRulesSortable() {
      $(this.$refs.rules).sortable({
        axis: 'y',
        handle: 'label',
        cursor: 'move',
        helper: 'original',
        distance: 5,
        forceHelperSize: true,
        forcePlaceholderSize: true,
        start: (e, ui) => {
          ui.item.data('n', -1);
          const h = ui.item.height();
          ui.placeholder.height(h);
        },
        change: (e, ui) => {
          const x = ui.placeholder[0];
          const n = Array.prototype.indexOf.call(x.parentNode.childNodes, x);
          ui.item.data('n', n);
        },
        beforeStop: (e, ui) => {
          ui.item.parent().sortable('cancel');
          let section = ui.item.data('section');
          let rule = ui.item.data('rule');
          const n = ui.item.data('n');
          if (n !== -1) {
            section = this.editingSections.find(({ id }) => id === section);
            const i = _.findIndex(section.rules, ({ id }) => id === rule);
            rule = section.rules.splice(i, 1)[0];
            section.rules.splice(n > i ? n - 1 : n, 0, rule);
          }
        },
      });
    },

    expandAllRules(section) {
      section.rules.forEach(({ id }) => {
        this.$set(this.expandedRules, id, true);
      });
    },

    collapseAllRules(section) {
      section.rules.forEach(({ id }) => {
        this.$delete(this.expandedRules, id);
      });
    },

    isAllRulesExpanded(section) {
      return _.every(section.rules, ({ id }) => this.expandedRules[id]);
    },
    promptRuleName(rule) {
      this.promptEntityName(rule, 'Please enter price rule name');
    },

    promptRuleWeight(rule) {
      this.promptEntityWeight(rule, 'Please enter price rule weight');
    },

    configChanged(config) {
      if (config) {
        const { sections } = config;
        this.editingSections = _.cloneDeep(sections) || [];
        this.editingFilter = _.cloneDeep(config.filter) || [];
        this.actualizeSections(this.editingSections);
      }
    },

    formatPercent(x) {
      if (x == null) {
        return '–';
      }

      switch (x) {
        case -10000000: return '-∞';
        case 10000000: return '+∞';
        default:
          return Number(x / 100).toLocaleString(this.locale, {
            style: 'percent',
            maximumFractionDigits: 1,
          });
      }
    },

    addRule(section, rule) {
      const ruleClone = _.cloneDeep(rule);
      ruleClone.id = utils.randomId();

      if (!ruleClone.filter) {
        ruleClone.filter = [];
      }

      if (!ruleClone.grouper) {
        ruleClone.grouper = [];
      }

      section.rules.push(ruleClone);
      this.$set(this.expandedRules, ruleClone.id, true);
      this.showRulesLibrary = null;
    },

    getPossibleValues(calc) {
      let possibleValues = this.possibleValues;
      let values = possibleValues[calc];
      if (!values) {
        values = utils.query({
          stream: this.stream,
          source: this.source,
          dims: [calc],
          sort: [1],
          filter3: "dim1 != ''",
        }).then((rows) => {
          const fuzzySearch = new FuzzySearch(rows, ['0'], { caseSensitive: false, sort: true });
          window.fuzzySearch = fuzzySearch;
          return fuzzySearch;
        });
        this.$set(possibleValues, calc, values);
      }
      return values;
    },

    getAttributeByName(name) {
      return this.attributes.find((attribute) => attribute.name === name) || { name, calc: name };
    },

    async getOptimizationConfigName(optimizationType) {
      const optimizationSerialUrl = `/storage/user/${this.username}/optimization`;
      let serial = 1;

      try {
        const res = await fetch(optimizationSerialUrl);
        const data = await res.json();
        serial = (data?.serial || 0) + 1;
      } catch(e) {}

      await fetch(optimizationSerialUrl, {
        method: 'PUT',
        body: JSON.stringify({serial}),
      });

      const isTemp = this.temporaryRun ? '[tmp] ' : '';

      const intials = this.username?.slice(0,2)?.toUpperCase() || 'NA';
      const serialNumber = String(serial).padStart(4, '0');
      const configName = `${intials}-${serialNumber} ${this.runName || this.config.name || 'untitled'}`;
      
      const type = this.types.find((t) => t.code === optimizationType);
      const typeName = type?.name ? ` – ${type.name}` : '';

      return `${isTemp}${configName}${typeName}`;
    },

    getOptConfiguration(optimizationType) {
      const optConfiguration = _.clone(this.optimization);
      const type = this.types.find((t) => t.code === optimizationType);
      // eslint-disable-next-line no-eval
      const [startDate, endDate] = eval(this.timeframes[gptable.futureTimeframe].calc)(utils.parseDate(this.referenceDate));

      optConfiguration.type = type?.type || optimizationType;

      optConfiguration.opt_start_date = utils.formatDate(startDate);
      optConfiguration.opt_end_date = utils.formatDate(endDate);

      return optConfiguration;
    },

    getPriceZone(optimizationType) {
      const type = this.types.find((t) => t.code === optimizationType);

      return type?.priceZone || gptable.priceZone;
    },

    restartOptimization(optimizationId) {
      this.optimizationId = optimizationId;
      this.stats = null;
      this.optimizationStatus = 'starting optimization';
      this.optimizationProgress = 0;
    },

    specialCaseForMdDates(ruleInOldFormat) {
      const { md_dates: mdDates } = ruleInOldFormat;
      const mdForcePercents = mdDates.reduce((acc, curr) => {
        const { date, type, value } = curr;
        if (type) {
          const key = new Date(date).toJSON().split('T')[0];

          return {
            ...acc,
            [key]: { type, value },
          };
        }
        return acc;
      }, {});

      return {
        ...ruleInOldFormat,
        md_dates: mdDates.map((day) => new Date(day.date).toJSON().split('T')[0]),
        md_force_percents: mdForcePercents,
      };
    },

    getOptimizationRules(optimizationType) {
      return this.editingSections
        .filter((section) => section.types.includes(optimizationType))
        .map((section) => ({
          ...section,
          rules: section.rules.map((r) => ({ ...r, weight: (r.weight || 1) * (section.weight || 1) })),
        }))
        .reduce((acc, curr) => [...acc, ...curr.rules], [])
        .map((r, i) => {
          const ruleInOldFormat = {
            ...r,
            number: i + 1,
          };

          Object.values(r.variables).forEach((variable) => {
            let { value } = variable;
            if (variable.options) {
              value = variable.options[value] ?? value;
            }
            _.set(ruleInOldFormat, variable.key, value);
          });

          delete ruleInOldFormat.variables;

          if (ruleInOldFormat.md_dates) {
            return this.specialCaseForMdDates(ruleInOldFormat);
          }

          return ruleInOldFormat;
        });
    },

    async optimize() {
      this.optimizationTypes
        .filter((optimizationType) => !optimizationType.startsWith('_'))
        .forEach(async (optimizationType) => {
          const optIncluded = '!isnull(optimization_exclusion.excluded, false)';
          const optimizationId = utils.randomId();
          const permalink = await gptable?.createPermalink({ optimizationId });
          const location = this.getPriceZone(optimizationType);
          const optConfiguration = this.getOptConfiguration(optimizationType);
          const rules = this.getOptimizationRules(optimizationType);
          const configName = await this.getOptimizationConfigName(optimizationType);

          this.restartOptimization(optimizationId);

          const body = JSON.stringify({
            extra_filter0: this.extraFilter0,
            extra_filter1: this.extraFilter1,
            extra_filter2: this.extraFilter2 ? `${this.extraFilter2} && ${optIncluded}` : optIncluded,
            run_id: this.optimizationId,
            config_id: this.config.id || 'untitled',
            config_name: configName,
            config_data: this.config,
            filter: this.editingFilter || [],
            rules,
            reference_date: window.referenceDate,
            effective_date: window.effectiveDate,
            competitors: this.competitors,
            formulas: this.formulas,
            opt_configuration: optConfiguration,
            permalink,
            config: { ...this.settings, location },
            vars: this.vars,
            threads_count: this.threadsCount,
          }, null, 2);

return;
          fetch('/optimize', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body,
          }).then((res) => {
            if (!res.ok) {
              this.restartOptimization(null);
            }
          });
        });
    },
  },

  watch: {
    config: {
      deep: true,
      handler() {
        gpUtils.saveConfig(this.localStorageKey, this.config);
      },
    },

    rulesLibrary() {
      this.actualizeSections(this.editingSections);
    },

    expandedSections() {
      this.$nextTick(() => this.setupRulesSortable());
    },

    editingSections: {
      deep: true,
      handler(sections) {
        this.$set(this.config, 'sections', sections);
        this.$delete(this.config, 'rules');
      },
    },

    editingFilter: {
      deep: true,
      handler(filter) {
        this.$set(this.config, 'filter', filter);
      },
    },
  },
};
</script>

<style>
.gp-rules-hint {
    margin: 10px 0;
    font-size: 0.9em;
    font-style: italic;
    opacity: 0.9;
}
.gp-rules-sections {
    margin: 20px -20px;
}
.gp-rules-add-rule {
    margin: 4px 32px;
}
.gp-rules-sections > a {
    margin-top: 10px;
    margin-left: 32px;
    display: block;
}
.gp-rules-sections > a + a {
    margin-top: 5px;
}
.gp-rules-section {
    border-top: 1px solid var(--dark);
    border-bottom: 1px solid var(--dark);
    margin-top: -1px;
}
.my-dark-theme .gp-rules-section {
    border-top: 1px solid var(--light);
    border-bottom: 1px solid var(--light);
}
.gp-rules-section > label {
    display: block;
    background-color: var(--light);
    padding: 4px 10px;
    margin: 0;
}
.my-dark-theme .gp-rules-section > label {
    background-color: var(--dark);
}
.gp-rules-add-rule {
    margin-bottom: 10px;
}
.gp-rules-section > label .feather-icon-more-vertical {
    float: right;
}
.gp-rules-section > label .feather-icon-more-vertical {
    visibility: hidden;
}
.gp-rules-section.expanded > label .feather-icon-more-vertical {
    visibility: visible;
}
.gp-rules-list {
    list-style: none;
    margin: 10px 10px;
    padding: 0;
}
.gp-rules .feather-icon svg {
    width: 18px;
    height: 18px;
}
.gp-rules-list > li {
    padding: 2px 0;
    margin: 0;
    border-top: 1px solid transparent;
}
.gp-rules-list > li label {
    padding: 0;
    margin: 0;
    display: block;
}
.gp-rules-list > li.expanded > label {
    padding-bottom: 2px;
    border-bottom: 1px solid var(--dark);
}
.gp-rules-list > li.expanded > label + p {
    padding-top: 8px;
}
.gp-rules-list > li p {
    font-size: 0.9em;
    padding: 4px 0;
    padding-left: 10px;
    margin-left: 30px;
    border-left: 1px solid var(--dark);
}
.gp-rules-list > li p:last-child {
    margin-bottom: 10px;
}
.gp-rules-list .feather-icon-more-vertical {
    float: right;
}
.gp-rules-list .feather-icon-more-vertical {
    visibility: hidden
}
.gp-rules-list .expanded .feather-icon-more-vertical {
    visibility: visible;
}
.gp-rules-list > li label .feather-icon svg {
    display: inline-block;
    vertical-align: top;
}
.gp-rules-list > li p {
    line-height: 1.3;
    font-size: 0.9em;
}
.gp-rule-conditions {
    display: inline;
}
.gp-rule-popup {
    padding: 8px 8px;
    border-radius: 4px;
    display: inline-block;
    background-color: rgb(191,223,294);
}
.gp-rule-popup input,
.gp-rule-popup select {
    margin-bottom: 6px;
}
.gp-rule-popup textarea + div {
    margin-bottom: 6px;
    position: relative;
}
.gp-rule-popup .btn.btn-xs {
    padding: 0 8px;
    font-size: 0.9em;
}
.gp-rules .gp-rules-list .new svg {
    color: #1489FF80;
    border: none;
    margin-top: -2px;
    margin-bottom: -6px;
    vertical-align: top;
}
.gp-rules .operator {
    opacity: 0.8;
    font-style: italic;
}
.gp-rule-popup-actions {
    display: flex;
    margin-right: -6px;
}
.gp-rule-popup-actions > * {
    flex-grow: 1;
    flex-basis: 1px;
    margin-right: 6px;
}
.ui-autocomplete {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute!important;
    left: inherit!important;
    top: inherit!important;
    max-height: 200px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.ui-autocomplete .ui-menu-item-wrapper {
    border: none!important;
    outline: none!important;
    padding: 0 8px;
    font-size: 1em;
}
.ui-autocomplete .ui-menu-item-wrapper.ui-state-active {
    border: none!important;
    outline: none!important;
    background-color: #3498db30!important;
    color: inherit!important;
}
.gp-rule-popup {
    box-shadow: 0 0 10px 10px #00000010;
    border: 1px solid var(--cyan);
}
.my-dark-theme .gp-rule-popup {
    background-color: rgb(35,53,74);
    border: 1px solid black;
}
.gp-rule-popup input,
.gp-rule-popup select {
    min-width: 150px;
}
.gp-rule-popup input[type="number"] {
    max-width: 150px;
}
.gp-rules-actions {
    display: flex;
    margin-right: -10px;
}
.gp-rules-actions > * {
    margin-right: 10px;
    flex-grow: 1;
}
.gp-rule-delete {
    float: right;
    margin-right: 4px;
}
.gp-rules .feather-icon-filter svg {
    width: 16px;
    height: 16px;
    opacity: 0.8;
}
.gp-rule-delete {
    margin-top: 2px;
}
.gp-rules-library li {
    padding: 2px 0;
}
.gp-rules-list + .gp-rules-list {
    margin-top: 0;
    margin-bottom: 10px;
}
.gp-rules-library {
    background-color: white;
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 4px;
    border: 1px solid gray;
    font-size: 0.9em;
    line-height: 1.4;
}
.gp-rules-library li {
    cursor: pointer;
}
.gp-rules-library li a {
    padding: 1px 20px;
    display: block;
    color: #444;
    text-decoration: none!important;
}
.gp-rules-library li:hover {
    background-color: var(--blue);
}
.gp-rules-library li:hover a {
    color: white;
}
.gp-rules-actions {
    margin-bottom: 15px;
}
.gp-rules-section > label + a {
    float: right;
    display: none;
    margin-top: 3px;
    margin-right: 12px;
    font-size: 0.9em;
}
.gp-rules-section.expanded > label + a {
    display: block;
}
.gp-rules-section > label + a + ul {
    content: "";
    clear: right;
    display: block;
}
.gp-rules > .gp-check {
    margin-top: 10px;
}
.gp-rule-popup,
.gp-filter-popup {
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.gp-filter-values textarea {
    height: 100px;
}
.gp-rule-popup > *,
.gp-filter-popup > * {
    margin-bottom: 10px!important;
}
.gp-rule-popup > *:last-child,
.gp-filter-popup > *:last-child {
    margin-bottom: 0!important;
}
.gp-rule-popup .btn.btn-xs {
    padding: 2px 8px;
}
.gp-rules-section > label .feather-icon-more-vertical {
    margin-top: -2px;
}
.gp-rules-list .gp-rule-filter {
    padding-top: 8px;
}
.gp-rules-list .gp-check {
    margin-top: 6px;
    margin-left: 4px;
    margin-right: 4px;
}
.gp-rules-weight {
    float: right;
    font-size: 0.9em;
    margin-right: 4px;
}
.gp-rules-weight svg {
    width: 22px!important;
    height: 22px!important;
    margin-left: 2px;
    margin-right: 4px;
}
.gp-rules-sections {
    clear: right;
}
.gp-rules-list {
    margin-top: 30px;
}
.gp-rules-weight {
    margin-left: 5px;
}
.gp-rules-list {
    margin-top: 30px;
}
.gp-rules-section > label .feather-icon-more-vertical {
    margin-top: -2px;
}
.gp-rules-list .gp-rule-filter {
    padding-top: 8px;
}
.gp-rules-list .gp-check {
    margin-top: 6px;
    margin-left: 4px;
    margin-right: 4px;
}
.gp-rules-weight {
    float: right;
    font-size: 0.9em;
    margin-right: 4px;
}
.gp-rules-weight svg {
    width: 22px!important;
    height: 22px!important;
    margin-left: 2px;
    margin-right: 4px;
}
.gp-rules-sections {
    clear: right;
}
.gp-rule-filter .gp-filter {
    display: inline;
}
.gp-rule-filter .feather-icon-plus {
    margin-top: 1px;
    margin-bottom: -1px;
    vertical-align: top;
    display: inline-block;
}
.gp-rule-filter .gp-filter + .gp-filter:before {
    content: "exclude ( ";
}
.gp-rule-filter .gp-filter + .gp-filter:after {
    content: " )";
}
.gp-rule-filter .gp-filter + .gp-filter .feather-icon-filter {
    display: none;
}
.gp-rules-list {
    margin-top: 10px;
}
.gp-section-opt-types-checks {
    margin-top: 10px;
    margin-left: 30px;
}
.gp-section-opt-types-icons {
    float: right;
    font-size: 0.9em;
    margin: 0 4px;
}
.gp-section-opt-types-icons > :not(.active) {
    color: var(--gray);
}
.gp-section-opt-types-icons span + span {
    margin-left: 4px;
}
.gp-rules .btn-group-toggle {
    display: flex;
    margin-right: -8px;
}
.gp-rules .btn-group-toggle .btn {
    flex-basis: 1px;
    flex-grow: 1;
    margin-right: 8px;
}
.gp-rules .btn-group-toggle .btn.btn-secondary {
    color: inherit;
    background-color: inherit;
}
.gp-rules .btn-group-toggle .btn.btn-secondary.active {
    color: inherit;
    background-color: #3498db50;
}
.my-dark-theme .btn-group-toggle .btn.btn-secondary.active {
    color: white;
    background-color: var(--blue);
}
.gp-rules .progress {
    margin-bottom: 15px;
}
.gp-rules .gp-rules-list .new svg {
    color: inherit;
}
.gp-rules-auto-accept-bounds {
    display: flex;
    margin-right: -10px;
    margin-top: 5px;
    margin-left: 20px;
}
.gp-rules-auto-accept-bounds > * {
    margin-right: 10px;
    align-self: center;
}
.gp-rules-auto-accept-bounds span {
    font-size: 0.9em;
    line-height: 1.3em;
    white-space: pre-line;
}
.gp-rules-auto-accept-bounds input {
    width: 70px;
}
.gp-rules > .form-group > a > .feather-icon {
    margin-top: -2px;
    vertical-align: top;
    display: inline-block;
}
.gp-section-expand-collapse {
  position: relative;
  z-index: 2;
}
</style>
