var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "ao-table" }, [
    _c("thead", [
      _c(
        "tr",
        _vm._l(_vm.tableColumns, function(column) {
          return _c(
            "td",
            { key: column.title, staticClass: "ao-table__cell head-cell" },
            [
              _c("div", { staticClass: "head-cell__container" }, [
                _c(
                  "a",
                  {
                    staticClass: "ao-table__head-title",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(column.title) + "\n          "
                    )
                  ]
                )
              ])
            ]
          )
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.rows, function(row, rowIndex) {
        return _c(
          "tr",
          {
            key: row.nodeType + "_" + rowIndex + "_" + row.id,
            staticClass: "ao-table__row",
            on: {
              click: function($event) {
                return _vm.handleRowClick(row)
              }
            }
          },
          _vm._l(_vm.tableColumns, function(column) {
            return _c(
              "td",
              { key: column.title, staticClass: "ao-table__cell" },
              [
                _c("div", { staticClass: "body-cell__container" }, [
                  _c("span", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.getValueOfCell(column.field, row)) +
                        "\n          "
                    )
                  ])
                ])
              ]
            )
          }),
          0
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }