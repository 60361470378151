var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "display-settings-container" },
    [
      _c("h6", [_vm._v("Параметры отображения")]),
      _vm._v(" "),
      _c(
        "gp-check",
        {
          attrs: { checked: _vm.showNullOrder },
          on: { change: _vm.handleChange }
        },
        [
          _c("l10n", {
            attrs: { value: "Показывать строки с нулевым заказом" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "gp-check",
        { attrs: { checked: _vm.showAlert }, on: { change: _vm.handleChange } },
        [_c("l10n", { attrs: { value: "Показывать алерты" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "gp-check",
        { attrs: { checked: _vm.allowEdit }, on: { change: _vm.handleChange } },
        [_c("l10n", { attrs: { value: "Разрешить редактирование заказа" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }