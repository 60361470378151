var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-backups" }, [
    _c(
      "ul",
      [
        _vm._l(_vm.showMore ? _vm.backups : _vm.backups.slice(0, 20), function(
          backup,
          key
        ) {
          return _c("li", { key: key }, [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)", nofollow: "" },
                on: {
                  click: function($event) {
                    return _vm.toggleBackup(backup)
                  }
                }
              },
              [
                _c("feather-icon", {
                  attrs: {
                    name: _vm.opened[backup.page]
                      ? "chevron-down"
                      : "chevron-right",
                    size: "18px"
                  }
                }),
                _vm._v(
                  "\r\n                " +
                    _vm._s(backup.date) +
                    "\r\n            "
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("span", {
              staticStyle: { display: "inline-block", width: "6px" }
            }),
            _vm._v(" "),
            _c(
              "a",
              { attrs: { href: backup.link, nofollow: "", target: "_blank" } },
              [
                _c("feather-icon", {
                  attrs: { name: "external-link", size: "18px" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.opened[backup.page]
              ? _c("div", [
                  _vm.opened[backup.page].html
                    ? _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.opened[backup.page].html)
                        }
                      })
                    : _c("div", [_vm._v("Loading...")])
                ])
              : _vm._e()
          ])
        }),
        _vm._v(" "),
        _vm.backups.length > 20 && !_vm.showMore
          ? _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      _vm.showMore = true
                    }
                  }
                },
                [
                  _vm._v(
                    "and " +
                      _vm._s(Number(_vm.backups.length - 20).toLocaleString()) +
                      " more..."
                  )
                ]
              )
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }