<template>
  <button
    @click.stop="clickHandle"
    class="node-btn"
    type="button"
  >
    <minus-icon v-if="isOpen" />
    <plus-icon v-else />
  </button>
</template>

<script>
const PlusIcon = require('../../icons/plus-icon.vue').default;
const MinusIcon = require('../../icons/minus-icon.vue').default;

module.exports = {
  components: {
    PlusIcon,
    MinusIcon,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    clickHandle() {
      if (!this.isEmpty) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style scoped>
.node-btn {
  display: flex;
  position: relative;
  padding: 2px;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #E9E9E9;
  transition: background-color 0.3ms ease-in;
}

.node-btn svg {
  width: 12px;
  height: 12px;
}

.node-btn:hover {
  background-color: #5C606F;
  color: #F7F7F7;
}
</style>
