<template>
  <div class="form-inline gp-column-precision">
    <label>
		  <l10n value="Precision:" />
    </label>
    <input
      class="form-control form-control-sm"
      type="number"
      min="0"
      max="4"
      :value="value"
      @change="handleChange"
    />
  </div>
</template>
<script>
module.exports = {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: Number },
  },
  methods: {
    handleChange(e) {
      this.$emit('change', e.target.value);
    },
  },
};
</script>

<style scoped>
.form-inline.gp-column-precision {
  flex-wrap: nowrap;
  margin-left: 24px;
}
.form-inline.gp-column-precision label {
  margin-right: 8px;
}
.form-inline.gp-column-precision input {
  flex-grow: 1
}
</style>
