<template>
    <div
        v-if="keep === true || keep === 'true' || show === true || show === 'true'"
        :style="{
            display: show === true || show === 'true' ? null : 'none'
        }">
        <slot/>
    </div>
</template>
<script>
module.exports = {
    props: {
        name: String,
        keep: [String, Boolean],
    },
    data() {
        return {
            show: false
        }
    },
    mounted() {
        window[this.name] = this
    },
    beforeDestroy() {
        if (window[this.name] === this) {
            delete window[this.name]
        }
    }
}
</script>