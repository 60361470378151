module.exports = _.merge({
    methods: {
        makeChart(base, instant) {
            let n = this.dims.length
            let m = this.vals.length

            let dim = this.ndx.dimension((row) => row.slice(0,n))

            let grp = dim.group().reduce(
                (p,v) => { for (let i = 0; i < m; ++i) { p[i] += v[n+i] }; return p },
                (p,v) => { for (let i = 0; i < m; ++i) { p[i] -= v[n+i] }; return p },
                () => _.fill(new Array(m), 0))

            let chart = dc.seriesChart(base)

            chart.dimension(dim)

            _(this.vals)
                .forEach((group, i) => {
                    let name = _.isString(group) ? group : group.name
                    if (i === 0)
                        chart.group(grp, name, (d) => d.value[i])
                    // else
                    //     chart.stack(grp, name, (d) => d.value[i])
                })


            return chart
        },
    }
}, require("./props").chartProps({
    curve:              { type: [Function, String], default: d3.curveLinear },
    dashStyle:          { type: Array, default: () => [] },
    defined:            { type: [Function, String], default: undefined },
    dotRadius:          { type: Number, default: 5 },
    renderArea:         { type: Boolean, default: false },
    renderDataPoints:   { type: Object, default: undefined },
    xyTipsOn:           { type: Boolean, default: false },
    keyAccessor:        { type: [Function, String], default: (d) => d.key[1] },
    seriesAccessor:     { type: [Function, String], default: (d) => d.key[0] },
    seriesSort:         { type: [Function, String], default: d3.ascending },
}))
