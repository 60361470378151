var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-card-container" },
    [
      _c("header", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "header__title-block" },
          [
            _c(
              "button",
              {
                staticClass: "back-button",
                attrs: { type: "button" },
                on: { click: _vm.onBackBtnClick }
              },
              [_c("feather-icon", { attrs: { name: "chevron-left" } })],
              1
            ),
            _vm._v(" "),
            _c("p", { staticClass: "header-title" }, [
              _vm._v("Happy Horses (apt. HRSS707)")
            ]),
            _vm._v(" "),
            _c(
              "my-tooltip-simple",
              {
                scopedSlots: _vm._u([
                  {
                    key: "tooltip-data",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "header-title-tooltip" }, [
                          _c("p", [_vm._v("ID карточки №001936")]),
                          _vm._v(" "),
                          _c("p", [_vm._v("Создано: 04/03/2023")]),
                          _vm._v(" "),
                          _c("p", [_vm._v("Автор: Tatyana I.")]),
                          _vm._v(" "),
                          _c("p", [_vm._v("Последнее изменение: 06/10/2023")])
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _c(
                  "button",
                  { staticClass: "icon-button", attrs: { type: "button" } },
                  [_c("feather-icon", { attrs: { name: "alert-circle" } })],
                  1
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0)
      ]),
      _vm._v(" "),
      _c("main", { staticClass: "main" }, [
        _c(
          "div",
          [
            _c(
              "gp-check",
              {
                attrs: { checked: _vm.isActiveCard },
                on: { change: _vm.handleChange }
              },
              [_c("l10n", { attrs: { value: "Активная карточка" } })],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "chain-type parameter" }, [
              _c(
                "p",
                { staticClass: "title" },
                [_c("l10n", { attrs: { value: "ID карточки" } })],
                1
              ),
              _vm._v(" "),
              _c("p", [_vm._v("001936")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "chain-type parameter" }, [
              _c(
                "p",
                { staticClass: "title" },
                [_c("l10n", { attrs: { value: "Артикул" } })],
                1
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control form-control-sm",
                attrs: { value: "SFLT002", placeholder: "Выберите артикул" }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { display: "flex" } }, [
              _c(
                "div",
                {
                  staticClass: "chain-type parameter",
                  staticStyle: { "margin-right": "10px" }
                },
                [
                  _c(
                    "p",
                    { staticClass: "title" },
                    [_c("l10n", { attrs: { value: "Поставщики" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "form-control form-control-sm",
                    attrs: {
                      value: "Little Freddie (арт. LITT001)",
                      placeholder: "Выберите артикул"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  ref: "supplierButton",
                  staticClass: "btn btn-sm btn-primary select-btn",
                  attrs: { type: "button" },
                  on: { click: _vm.onSuppliersSelect }
                },
                [_vm._v("\n          Поставщики >>>\n        ")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "chain-type parameter" }, [
              _c(
                "p",
                { staticClass: "title" },
                [_c("l10n", { attrs: { value: "Производитель" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.manufacturer,
                      expression: "manufacturer"
                    }
                  ],
                  staticClass: "form-control form-control-sm dropdown-select",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.manufacturer = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { value: "in" } },
                    [
                      _c("l10n", { attrs: { value: "Sunny Fields ent. ltd." } })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "eq" } },
                    [_c("l10n", { attrs: { value: "Sony" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "ne" } },
                    [_c("l10n", { attrs: { value: "Microsoft" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "ne" } },
                    [_c("l10n", { attrs: { value: "Procter&Gamble" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "ne" } },
                    [_c("l10n", { attrs: { value: "PagerDuty" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "ne" } },
                    [_c("l10n", { attrs: { value: "Pfizer" } })],
                    1
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "chain-type parameter" }, [
              _c(
                "p",
                { staticClass: "title" },
                [_c("l10n", { attrs: { value: "Торговая марка" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.trademark,
                      expression: "trademark"
                    }
                  ],
                  staticClass: "form-control form-control-sm dropdown-select",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.trademark = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { value: "in" } },
                    [_c("l10n", { attrs: { value: "Little Freddie" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "eq" } },
                    [_c("l10n", { attrs: { value: "Sony Vaio" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "ne" } },
                    [_c("l10n", { attrs: { value: "Microsoft Windows" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "ne" } },
                    [_c("l10n", { attrs: { value: "Head&Shoulders" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "ne" } },
                    [_c("l10n", { attrs: { value: "GNFR" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "ne" } },
                    [_c("l10n", { attrs: { value: "Viagra" } })],
                    1
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("h2", [_c("l10n", { attrs: { value: "Атрибуты товара" } })], 1),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "chain-type parameter" }, [
              _c(
                "p",
                { staticClass: "title" },
                [_c("l10n", { attrs: { value: "Срок годности, дн." } })],
                1
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control form-control-sm",
                attrs: { value: "180", placeholder: "Выберите срок годности" }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "chain-type parameter",
                    staticStyle: { "margin-right": "10px" }
                  },
                  [
                    _c(
                      "p",
                      { staticClass: "title" },
                      [_c("l10n", { attrs: { value: "Вес/Объем" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "form-control form-control-sm",
                      attrs: { value: "90", placeholder: "Выберите вес/объем" }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "chain-type parameter" }, [
              _c(
                "p",
                { staticClass: "title" },
                [_c("l10n", { attrs: { value: "Вложение в короб" } })],
                1
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control form-control-sm",
                attrs: {
                  value: "12",
                  placeholder: "Выберите объем вложения в короб"
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "chain-type parameter" }, [
              _c(
                "p",
                { staticClass: "title" },
                [_c("l10n", { attrs: { value: "Аналоги" } })],
                1
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control form-control-sm",
                attrs: { value: "", placeholder: "Введите аналог" }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "chain-type parameter" }, [
              _c(
                "p",
                { staticClass: "title" },
                [_c("l10n", { attrs: { value: "Категория" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.category,
                      expression: "category"
                    }
                  ],
                  staticClass: "form-control form-control-sm dropdown-select",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.category = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { value: "in" } },
                    [
                      _c("l10n", {
                        attrs: { value: "Organic Snacks (id: 48)" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "eq" } },
                    [_c("l10n", { attrs: { value: "Milk products" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "ne" } },
                    [_c("l10n", { attrs: { value: "Adult clothes" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "ne" } },
                    [_c("l10n", { attrs: { value: "Home electronics" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "ne" } },
                    [_c("l10n", { attrs: { value: "Leather Shoes" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "ne" } },
                    [_c("l10n", { attrs: { value: "Newspapers" } })],
                    1
                  )
                ]
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "align-self": "center" } },
          [
            _vm.filePreview
              ? _c("img", {
                  staticStyle: {
                    "max-height": "256px",
                    "margin-bottom": "5px",
                    "border-radius": "5px"
                  },
                  attrs: { src: _vm.filePreview, alt: "fileName" }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("my-fileinput", {
              on: {
                "file-updated": function($event) {
                  return _vm.captureFile($event)
                }
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm.isSuppliersPopupOpened
        ? _c(
            "my-popup",
            {
              attrs: { anchor: this.$refs.supplierButton, placement: "end" },
              on: { clickoutside: _vm.handleModalClose }
            },
            [
              _c("div", { staticClass: "popover modal-container" }, [
                _vm._v("\n      List of suppliers\n    ")
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header__search-form" }, [
      _c("input", {
        staticClass: "form-control form-control-sm input-search",
        staticStyle: { "margin-right": "5px" },
        attrs: { value: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c("p", { staticClass: "metaData" }, [
        _vm._v(
          "Создано 04/03/2023   Автор: Tatyana I.    Последнее изменение: 11/03/2023"
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "button-block" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary select-btn",
            staticStyle: { "margin-right": "5px" },
            attrs: { type: "button" }
          },
          [_vm._v("\n        Дублировать\n      ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary select-btn",
            attrs: { type: "button" }
          },
          [_vm._v("Сохранить")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }