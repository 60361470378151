<template>
  <select
    ref="input"
    v-model="model"
    :multiple="multiple"
    class="form-control form-control-sm"
    @keyup.enter="$emit('submit')"
    @keyup.escape="$emit('cancel')"
  >
    <option
      v-for="(key, name) in selectOptions"
      :key="key"
      :value="name"
    >
      {{ name }}
    </option>
  </select>
</template>

<script>
module.exports = {
  props: {
    value: {
      type: [String, Array],
    },

    options: {
      type: [Object, Array],
      default: () => ({}),
    },

    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      model: _.cloneDeep(this.value),
    };
  },

  computed: {
    selectOptions() {
      if (_.isPlainObject(this.options)) {
        return this.options;
      }

      return this.options.reduce((acc, curr) => ({ ...acc, [curr]: curr }), {});
    },
  },

  mounted() {
    this.$refs.input?.focus?.();
  },
};
</script>
