<template>
    <div class="my-date-filter">
        <my-caption :caption="caption"/>
        <div class="form-inline" style="clear:left">
            <div class="form-group">
                <gp-date v-model="date1" :max="date2"/>
            <!--label
                for="date1"
                class="mr-sm-2 mb-sm-2"
                >From:</label-->
            <!--input
                id="date1"
                type="date"
                v-model="date1"
                :max="date2"
                class="form-control"-->
            </div>
            <feather-icon name="minus"/>
            <div class="form-group">
                <gp-date v-model="date2" :min="date1"/>
            <!--label
                for="date2"
                class="mr-sm-2 mb-sm-2"
                >To:</label-->
            <!--input
                id="date2"
                type="date"
                v-model="date2"
                :min="date1"
                class="form-control"-->
            </div>
        </div>
    </div>
</template>
<script>
let utils = require('./my-utils')
module.exports = {
    props: {
        groups:     { type: Array, default: () => [] },
        stream:     { type: String, default: "default" },
        caption:    { type: String },
        function:   { type: String },
        function1:  { type: String },
        function2:  { type: String },
        notBefore:  { type: String, default: "" },
        notAfter:   { type: String, default: "" },
    },
    data() {
        return {
            mode: "exact_date", 
            date1: this.notBefore,
            date2: this.notAfter,
        }
    },
    computed: {
        filters() {
            let conditions = []
            if ((this.function || this.function1) && this.date1 !== "")
                conditions.push(`((${this.function1 || this.function}) >= ${this.date1.replace(/-/g, "")})`)
            if ((this.function || this.function2) && this.date2 !== "")
                conditions.push(`((${this.function2 || this.function}) <= ${this.date2.replace(/-/g, "")})`)
            return {
                groups: this.groups,
                stream: this.stream,
                filter1: "",
                filter2: conditions.join(" && "),
                filter3: "",
            }
        },
    },
    watch: {
        filters(filters, previousFilters) {
            if (!_.isEqual(filters, previousFilters))
                utils.bridge.trigger("filtersChanged", this._uid, filters)
        },
    },
    mounted() {
        Vue.nextTick(() => utils.bridge.trigger("filtersChanged", this._uid, this.filters))
    },
}
</script>
<style>
.my-date-filter .feather-icon {
    display: inline-block;
    vertical-align: middle;
}
.my-date-filter input[type="date"] {
    width: 190px;
}
.my-date-filter .form-inline {
    margin-right: -4px;
    margin-bottom: -4px;
}
.my-date-filter .form-inline > * {
    margin-right: 4px!important;
    margin-bottom: 4px!important;
}
</style>