var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accordion-container" },
    [
      _c(
        "button",
        {
          staticClass: "accordion",
          attrs: {
            "aria-expanded": _vm.isOpen,
            "aria-controls": "collapse" + _vm._uid
          },
          on: {
            click: function($event) {
              return _vm.toggleAccordion()
            }
          }
        },
        [
          _vm._t("title"),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "accordion-image",
              class: {
                "rotate-180": _vm.isOpen,
                "rotate-0": !_vm.isOpen
              },
              attrs: {
                fill: "none",
                stroke: "currentColor",
                xmlns: "http://www.w3.org/2000/svg",
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                "aria-hidden": "true"
              }
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d:
                    "M6.46967 8.96967C6.76256 8.67678 7.23744 8.67678 7.53033 8.96967L12 13.4393L16.4697 8.96967C16.7626 8.67678 17.2374 8.67678 17.5303 8.96967C17.8232 9.26256 17.8232 9.73744 17.5303 10.0303L12.5303 15.0303C12.2374 15.3232 11.7626 15.3232 11.4697 15.0303L6.46967 10.0303C6.17678 9.73744 6.17678 9.26256 6.46967 8.96967Z",
                  fill: "#CFCFCF"
                }
              })
            ]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("my-transition-expand", [
        _vm.isOpen ? _c("div", [_vm._t("content")], 2) : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }