<template>
  <span>
    <i
      v-for="(lvl, i) in levels"
      :key="lvl"
      class="hierarchy-link"
      :class="{
        'hierarchy-link__last': isLastLineStyle(i),
        'hierarchy-link__right-line': isRightLineStyle(i),
        'hierarchy-link__hidden': isFirstLinesHidden(i),
      }"
      :style="{ left: `${getOffset(lvl)}px` }"
    />
  </span>
</template>

<script>
module.exports = {
  props: {
    level: {
      type: Number,
      required: false,
      default: 0,
    },
    isLastRowOnLevel: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRestRowsHasNodes: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    levels() {
      const res = [];
      for (let i = 0; i < this.level; i += 1) {
        res.push(i + 1);
      }
      return res;
    },
  },
  methods: {
    getOffset(lvl) {
      if (lvl === 1) {
        return 15;
      }
      return (lvl * 18) - 3;
    },
    isLastLineLvl(index) {
      return !this.levels[index + 1];
    },
    isFirstLinesHidden(index) {
      return (this.isRestRowsHasNodes && !this.isLastLineLvl(index));
    },
    isLastLineStyle(index) {
      return this.isLastRowOnLevel && this.isLastLineLvl(index);
    },
    isRightLineStyle(index) {
      return !this.isLastRowOnLevel && this.isLastLineLvl(index);
    },
  },

};
</script>

<style scoped>
.hierarchy-link {
  position: absolute;
  top: -25%;
  bottom: -25%;
  display: inline-block;
  width: 2px;
  background-color: #E9E9E9;
}
.hierarchy-link__last {
  position: absolute;
  top: -20%;
  bottom: 0;
  display: inline-block;
  height: 35%;
  background-color: #E9E9E9;
}

.hierarchy-link__hidden {
  visibility: hidden;
}

.hierarchy-link__last::after {
  content: '';
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 8px;
  display: inline-block;
  height: 100%;
  border-left: 2px solid #E9E9E9;
  border-bottom: 2px solid #E9E9E9;
  border-bottom-left-radius: 6px;
}

.hierarchy-link__right-line::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 9px;
  height: 2px;
  z-index: -1;
  border-radius: 4px 0 0 4px;
  background-color: #E9E9E9;
}

.hierarchy-link__last.hierarchy-link__right-line::before {
  display: none;
}
</style>
