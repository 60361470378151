<template>
    <div class="pim-attributes">
        <div v-if="entries.length && show">
            <div class="pim-attributes-head">
                <template v-if="validFields.length != 0">
                    <a href="javascript:void(0)"
                        @click="showColumnSelector = true"
                        ref="showColumnSelector">
                        <feather-icon name="menu"/>
                    </a>
                    <a href="javascript:void(0)" v-if="loading">
                        <feather-icon name="clock"/>
                    </a>
                </template>
                <h2>Attributes</h2>
                <p v-if="entries.length > 1">
                    {{capitalize(entries[cursor].name)}}
                    <span v-if="workflowStatus" :data-status="workflowStatus">
                        – {{workflowStatus}},
                    </span>
                    and {{Number(entries.length - 1).toLocaleString()}}
                    more {{pluralize(entries[cursor].type)}}
                </p>
                <p v-else>
                    {{capitalize(entries[cursor].type)}} {{entries[cursor].name}}
                    <span v-if="workflowStatus" :data-status="workflowStatus">
                        – {{workflowStatus}}
                    </span>
                </p>
                <template v-if="validFields.length != 0">
                    <select
                        v-if="localesInUse.length"
                        v-model="locale"
                        class="form-control form-control-sm"
                        ref="locales">
                        <option value="">All locales</option>
                        <option
                            v-for="locale in localesInUse"
                            :key="locale.id"
                            :value="locale.id">
                            {{locale.name}}
                        </option>
                    </select>
                    <select
                        v-if="storefrontsInUse.length"
                        v-model="storefront"
                        class="form-control form-control-sm"
                        ref="storefronts">
                        <option value="">All storefronts</option>
                        <option
                            v-for="storefront in storefrontsInUse"
                            :key="storefront.id"
                            :value="storefront.id">
                            {{storefront.name}}
                        </option>
                    </select>
                    <gp-check v-model="showOnlyAiFields">
                        Show fields with <em>ai</em> suggesions only
                    </gp-check>
                    <my-search v-model="searchString"/>
                </template>
                <p v-else>
                    There are no attributes available.<br>
                    Try to switch your function group.
                </p>
            </div>
            <!--div class="pim-attributes-image" v-if="hasImages && entries[cursor].image">
                <img :src="entries[cursor].image"/>
            </div-->
            <div class="pim-attributes-image-tiles" v-if="hasImages && entries.length > 1">
                <div
                    class="pim-attributes-image-tile"
                    v-for="entry in entries"
                    :key="entry.id"
                    :status="status = workflowStatuses[entry.id]"
                    :class="{focused: entry == focus}"
                    >
                    <span v-if="status" :data-status="status">{{status}}</span>
                    <span
                        class="pim-attributes-image-tile-ai"
                        :data-status="hasAISuggestions(entry) ? 'ai-different' : 'ai-same'"
                        v-if="hasAIResults(entry)">ai</span>
                    <img
                        :src="entry.image"
                        :alt="entry.name"
                        @click="$emit('focus', entry)"/>
                </div>
            </div>

            <div class="pim-attributes-image-tiles" v-if="hasImages && assetsEntry">
                <div
                    class="pim-attributes-image-tile"
                    v-for="entry in assetsEntry.children || []"
                    :key="entry.id"
                    >
                    <span class="pim-tree-tile-sequence" v-if="entry.attrs.sequence">{{entry.attrs.sequence}}</span>
                    <span class="pim-tree-tile-primary" v-if="entry.attrs.is_primary_image">primary</span>
                    <img :src="entry.image" :alt="entry.name"/>
                </div>
            </div>

            <div
                v-for="fields, group in visibleGroups"
                :key="group"
                class="pim-attributes-fields"
                :data-length="fields.length">
                <h3 v-if="fields.length > 1">
                    <a href="javascript:void(0)" @click="toggleGroup(group)">
                        <feather-icon :name="isGroupOpen(group) ? 'book-open' : 'book'"/>
                        {{group}}
                    </a>
                </h3>
                <template v-if="fields.length == 1 || isGroupOpen(group)">
                    <div 
                        class="form-group"
                        v-for="{item:field, matches, formattedName} in fields"
                        :key="field.id">
                        <gp-check
                            v-if="field.field_type == 'boolean'"
                            :checked="getValue(field)"
                            :required="isRequired(field)"
                            :disabled="isFieldReadonly(field)"
                            @change="setValue(field, $event)">
                            <template v-if="formattedName">
                                <span
                                    v-for="part in formattedName"
                                    :class="{matched: part.matched}"
                                    >{{part.text}}</span>
                            </template>
                            <template v-else>
                                {{field.name}}
                            </template>
                            <span v-if="isRequired(field)" class="required">
                                [required]
                            </span>
                            <span v-if="isOverriden(field)" class="overridden">
                                [overridden]
                                <a href="javascript:void(0)"
                                    @click="setValue(field, null)">
                                    <feather-icon name="x"/>
                                </a>
                            </span>
                            <span v-else-if="isComputed(field)" class="computed">
                                [computed]
                            </span>
                            <span v-else-if="isDerived(field)" class="derived">
                                [derived]
                            </span>
                            <span v-if="isEdited(field)" class="edited">
                                [edited]
                                <a href="javascript:void(0)"
                                    @click="$delete(changes, field.id)">
                                    <feather-icon name="x"/>
                                </a>
                            </span>
                            <span v-else-if="isMixed(field)" class="mixed">
                                [mixed]
                            </span>
                        </gp-check>
                        <template v-else>
                            <label>
                                <template v-if="formattedName">
                                    <span
                                        v-for="part in formattedName"
                                        :class="{matched: part.matched}"
                                        >{{part.text}}</span>
                                </template>
                                <template v-else>
                                    {{field.name}}
                                </template>
                                <span v-if="isRequired(field)" class="required">
                                    [required]
                                </span>
                                <span v-if="isOverriden(field)" class="overridden">
                                    [overridden]
                                    <a href="javascript:void(0)"
                                        @click="setValue(field, null)">
                                        <feather-icon name="x"/>
                                    </a>
                                </span>
                                <span v-else-if="isComputed(field)" class="computed">
                                    [computed]
                                </span>
                                <span v-else-if="isDerived(field)" class="derived">
                                    [derived]
                                </span>
                                <span v-if="isEdited(field)" class="edited">
                                    [edited]
                                    <a href="javascript:void(0)"
                                        @click="$delete(changes, field.id)">
                                        <feather-icon name="x"/>
                                    </a>
                                </span>
                                <span v-else-if="isMixed(field)" class="mixed">
                                    [mixed]
                                </span>
                            </label>
                            <template
                                v-if="
                                    entry &&
                                    romanceCopyField == field.api_name &&
                                    romanceCopyReport[entry.id] &&
                                    romanceCopyReport[entry.id].result
                                    "
                                    >
                                <div
                                    class="pim-attributes-ai">
                                    <em>ai</em>
                                    <ul>
                                        <li 
                                            :class="getValue(field) == choice.text ? 'ai-same' : 'ai-different'"
                                            v-for="choice in romanceCopyReport[entry.id].result.choices">
                                            <a @click="setValue(field, choice.text)">
                                                <feather-icon name="check"/>
                                            </a>
                                            <a @click="discardRomanceCopyChoice(choice)">
                                                <feather-icon name="x"/>
                                            </a>
                                            {{choice.text}}
                                        </li>
                                    </ul>
                                </div>
                            </template>
                            <template
                                v-for="classification in [
                                    entries[cursor] && 
                                    classifyReport &&
                                    classifyReport[entries[cursor].image] &&
                                    classifyReport[entries[cursor].image][field.api_name]]"
                                v-if="classification">
                                <template
                                    v-for="option in [
                                        dropdownOptions &&
                                        dropdownOptions[field.id] &&
                                        dropdownOptions[field.id].find(option => classification.name == option.code)]"
                                        v-if="option">
                                    <div
                                        class="pim-attributes-ai"
                                        :data-status="option.code == getValue(field) ? 'ai-same' : 'ai-different'"
                                        >
                                        <em>ai</em>
                                        <feather-icon v-if="option.code == getValue(field)" name="check"/>
                                        <a v-else href="javascript:void(0)"
                                                @click="setValue(field, option.code)">
                                            <feather-icon name="check"/>
                                        </a>
                                        {{option.name}} <sup>{{Number(Math.round(classification.rank*10)/10).toLocaleString()}}</sup>
                                        <a href="javascript:void(0)"
                                            v-if="option.code != getValue(field)"
                                            @click="$delete(classifyReport[entries[cursor].image], field.api_name)"
                                            >
                                            <feather-icon name="x"/>
                                        </a>
                                    </div>
                                </template>
                            </template>
                            <input
                                class="form-control form-control-sm"
                                v-if="field.field_type == 'datetime'"
                                type="datetime-local"
                                :required="isRequired(field)"
                                :readonly="isFieldReadonly(field)"
                                :value="getValue(field)"
                                @change="setValue(field, $event.target.value)">
                            <input
                                class="form-control form-control-sm"
                                v-if="field.field_type == 'date'"
                                type="date"
                                :required="isRequired(field)"
                                :readonly="isFieldReadonly(field)"
                                :value="getValue(field)"
                                @change="setValue(field, $event.target.value)">
                            </select>
                            <!--textarea
                                class="form-control form-control-sm"
                                v-else-if="field.field_type == 'textarea'"
                                :required="isRequired(field)"
                                :readonly="isFieldReadonly(field)"
                                :value="getValue(field)"
                                @change="setValue(field, $event.target.value)">
                            </textarea-->
                            <editor
                                class="form-control form-control-sm"
                                v-else-if="field.field_type == 'textarea'"
                                :init="editorInit(field)"
                                :api-key="editorApiKey"
                                :initial-value="getValue(field)"
                                :value="getValue(field)"
                                :disabled="isFieldReadonly(field)"
                                model-events="change input"
                                @input="setValue(field, $event)"
                                />
                            <!--input
                                class="form-control form-control-sm"
                                v-else-if="field.field_type == 'alphanumeric'"
                                :required="isRequired(field)"
                                :readonly="isFieldReadonly(field)"
                                :value="getValue(field)"
                                @change="setValue(field, $event.target.value)"-->
                            <editor
                                class="form-control form-control-sm"
                                v-else-if="field.field_type == 'alphanumeric'"
                                :init="editorInit(field)"
                                :api-key="editorApiKey"
                                :initial-value="getValue(field)"
                                :value="getValue(field)"
                                :disabled="isFieldReadonly(field)"
                                output-format="text"
                                model-events="change input"
                                @input="setValue(field, $event)"
                                />
                            <input
                                class="form-control form-control-sm"
                                type="number"
                                v-else-if="field.field_type == 'decimal' || field.field_type == 'numeric'"
                                :required="isRequired(field)"
                                :readonly="isFieldReadonly(field)"
                                :value="getValue(field)"
                                @change="setValue(field, parseFloat($event.target.value))"
                                />
                            <pim-select
                                class="form-control form-control-sm"
                                v-else-if="field.field_type == 'dropdown' || field.field_type == 'multidropdown'"
                                :multiple="field.field_type == 'multidropdown'"
                                :required="isRequired(field)"
                                :readonly="isFieldReadonly(field)"
                                :value="getValue(field)"
                                @change="setValue(field, $event)"
                                :options="dropdownOptions[field.id] || []"
                                />
                            <ace-editor
                                v-else-if="field.field_type == 'json' && !isComputed(field)"
                                mode="json"
                                :autoHeight="true"
                                :value="getValue(field) || ''"
                                @change="setValue(field, $event)"
                                :readOnly="isFieldReadonly(field)"
                                :darkTheme="darkTheme"
                                />
                            <input
                                class="form-control form-control-sm"
                                v-else
                                :required="isRequired(field)"
                                :readonly="isFieldReadonly(field)"
                                :value="getValue(field)"
                                @change="setValue(field, $event.target.value)"
                                />
                        </template>
                    </div>
                </template>
            </div>
            <div class="pim-hierarchies-attributes-stats">
                <div v-if="valuesReport">scalar attributes retrieved in {{valuesReport.stats.prepareTime + valuesReport.stats.processTime}} ms</div>
                <div v-if="dropdownReport">dropdown attributes retrieved in {{dropdownReport.stats.prepareTime + dropdownReport.stats.processTime}} ms</div>
                <div v-if="derivedReport">derived attributes computed in {{derivedReport.stats.prepareTime + derivedReport.stats.processTime}} ms</div>
            </div>
            <div
                class="pim-hierarchies-attributes-actions"
                v-if="validFields.length != 0">
                <button class="btn btn-sm btn-primary" @click="submitChanges" :disabled="!attributesChanged">
                    Save changes
                </button>
                <button class="btn btn-sm btn-secondary" @click="discardChanges" :disabled="!attributesChanged">
                    Discard changes
                </button>
                <template v-if="workflowStatus && !mixedWorkflowStatuses">
                    <button class="btn btn-sm btn-secondary" 
                        v-if="
                            workflowStatus == 'Rejected' ||
                            workflowStatus == 'In Progress'">
                        Submit for approval
                    </button>
                    <button class="btn btn-sm btn-secondary"
                        v-if="workflowStatus == 'Completed'">
                        Approve
                    </button>
                    <button class="btn btn-sm btn-secondary"
                        v-if="workflowStatus == 'Completed'">
                        Reject
                    </button>
                </template>
            </div>
            <my-popup
                v-if="showColumnSelector"
                :anchor="$refs.showColumnSelector"
                >
                <div class="popover">
                    <div class="popover-body">
                        <pim-columns
                            ref="columns"
                            :type="entries[0].type"
                            :fields="validFields"
                            :columns="columns"
                            @submit="showColumnSelector = false; columns = $event;"
                            @cancel="showColumnSelector = false"
                            />
                    </div>
                </div>
            </my-popup>
        </div>
        <gp-data
            ref="valuesReport"
            v-if="fields && entries.length && valuesConfig.stream"
            :stream="valuesConfig.stream"
            :filter1="valuesConfig.filter1"
            :filter2="valuesConfig.filter2"
            :filter3="valuesConfig.filter3"
            :dims="valuesConfig.dims"
            :vals="valuesConfig.vals"
            :cols="valuesConfig.cols"
            :initialSort="valuesConfig.sort"
            :expand="valuesConfig.expand"
            v-model="valuesReport"
            :throttled="false"
            @reportId="valuesReportId = $event"
            />
        <gp-data
            ref="dropdownReport"
            v-if="fields && entries.length && dropdownConfig.stream"
            :stream="dropdownConfig.stream"
            :source="dropdownConfig.source"
            :filter1="dropdownConfig.filter1"
            :filter2="dropdownConfig.filter2"
            :filter3="dropdownConfig.filter3"
            :dims="dropdownConfig.dims"
            :vals="dropdownConfig.vals"
            :cols="dropdownConfig.cols"
            :initialSort="dropdownConfig.sort"
            :expand="dropdownConfig.expand"
            v-model="dropdownReport"
            @reportId="dropdownReportId = $event"
            :throttled="false"
            />
        <gp-data
            ref="optionsReport"
            v-if="fields"
            :stream="optionsConfig.stream"
            :source="optionsConfig.source"
            :filter1="optionsConfig.filter1"
            :filter2="optionsConfig.filter2"
            :filter3="optionsConfig.filter3"
            :dims="optionsConfig.dims"
            :vals="optionsConfig.vals"
            :cols="optionsConfig.cols"
            :initialSort="optionsConfig.sort"
            :expand="optionsConfig.expand"
            v-model="optionsReport"
            @reportId="optionsReportId = $event"
            :throttled="false"
            />
        <gp-data
            ref="derivedReport"
            v-if="fields"
            :stream="derivedConfig.stream"
            :source="derivedConfig.source"
            :filter1="derivedConfig.filter1"
            :filter2="derivedConfig.filter2"
            :filter3="derivedConfig.filter3"
            :dims="derivedConfig.dims"
            :vals="derivedConfig.vals"
            :cols="derivedConfig.cols"
            :initialSort="derivedConfig.sort"
            :expand="derivedConfig.expand"
            v-model="derivedReport"
            @reportId="derivedReportId = $event"
            :throttled="false"
            />
    </div>
</template>
<script>
let utils = require("../my-utils")
let h2p = require('html2plaintext')

module.exports = {
    mixins: [
        require("./pim-helper.js")
    ],
    props: {
        show: { type: Boolean, default: true },
        focus: { type: Object },
        entries: { type: Array },
        locales: { type: Array },
        statuses: { type: Array },
        storefronts: { type: Array },
        fields: { type: Array },
        username: { type: String },
        darkTheme: { type: Boolean },
        romanceCopyField: { type: String },
        romanceCopyPrompt: { type: [Function, String] },
    },
    data() {
        return {
            locale: "",
            storefront: "",
            columns: [],
            changes: {},
            valuesReport: null,
            optionsReport: null,
            derivedReport: null,
            dropdownReport: null,
            classifyModels: ["productTypeFlex", "heelHeightRefinement"],
            classifyReport: null,
            valuesReportId: null,
            optionsReportId: null,
            derivedReportId: null,
            dropdownReportId: null,
            searchString: localStorage["pim-attributes-search-string"] || "",
            visibleFields: [],
            showEmptyValues: true,
            showOnlyEditable: false,
            groupsStatus: JSON.parse(localStorage["pim-attributes-groups-status"] || "{}"),
            editorApiKey: "qczt8v722pqi90tn72bs6e081jviw77q3ive5vki7vm9nnvg",
            showColumnSelector: false,
            showOnlyAiFields: false,
            romanceCopyReport: {},
        }
    },
    async mounted() {
        window.attributes = this
        this.classificationCache = {}
        this.runClassification()
        if (this.assetsEntry)
            this.loadChildren(this.assetsEntry)
        // $(this.$refs.locales).chosen();
        // $(this.$refs.storefronts).chosen();
    },
    watch: {
        assetsEntry(entry) {
            if (this.assetsEntry)
                this.loadChildren(this.assetsEntry)
        },
        showOnlyAiFields() {
            this.updateVisibleFields()
        },
        groupsStatus() {
            localStorage["pim-attributes-groups-status"] = JSON.stringify(this.groupsStatus)
        },
        async entries(entries, previousEntries) {
            if (_.isEqual(_.map(entries, "id"), _.map(previousEntries, "id")))
                return

            this.changes = {}
            this.runClassification()
        },
        visibleFields() {

        },
        loading() {
            if (!this.loading)
                this.generateRomanceCopy()
        },
        attributes() {
            this.$emit("change", this.attributes)
            this.updateVisibleFields()
        },
        classifyReport() {
            this.updateVisibleFields()
        },
        locale() {
            this.updateVisibleFields()
        },
        storefront() {
            this.updateVisibleFields()
        },
        columns() {
            this.updateVisibleFields()
        },
        searchString() {
            this.updateVisibleFields()
            localStorage["pim-attributes-search-string"] = this.searchString
        },
        showEmptyValues() {
            this.updateVisibleFields()
        },
        locales() {
            // Vue.nextTick(() => $(this.$refs.locales).trigger("chosen:updated"))
        },
        storefronts() {
            // Vue.nextTick(() => $(this.$refs.storefronts).trigger("chosen:updated"))
        },
    },
    computed: {
        entry() {
            return this.entries[this.cursor]
        },
        assetsEntry() {
            let entry = this.entries[this.cursor]
            if (this.traverse(entry.type)?.includes("digital asset")) {
                return {
                    id: entry.id,
                    name: entry.name,
                    type: entry.type,
                    attrs: entry.attrs,
                    childrenType: "digital asset",
                }
            }
            else return null
        },
        loading() {
            return this.valuesReportId ||
                this.optionsReportId ||
                this.derivedReportId ||
                this.dropdownReportId
        },
        attributes() {
            return Object.freeze(_.merge({}, this.regularAttributes, this.dropdownAttributes, this.derivedAttributes))
        },
        derivedAttributes() {
            if (!this.derivedReport)
                return {}

            let attributes = {}
            let entriesMap = _(this.entries).map((entry, i) => [entry.id, i]).fromPairs().value()
            let fields = this.derivedReport.meta.cols.map(col => this.getFieldByApiNameAndLevel(col.name, this.entriesType))

            for (let row of this.derivedReport.rows) {
                entryId = row[0]
                for (let i=0; i < this.derivedReport.meta.cols.length; ++i) {
                    let field = fields[i]
                    let fieldId = field.id
                    let value = row[i+1]
                    let attribute = attributes[fieldId]
                    if (attribute === undefined) {
                        attribute = {
                            vals: [],
                            mixed: false,
                            overridden: false,
                            computed: true,
                        }
                        attributes[fieldId] = attribute
                    }
                    attribute.vals[entriesMap[entryId]] = value
                }
            }

            for (let attribute of _.values(attributes))
                Object.freeze(attribute.vals)

            for (let [fieldId, attribute] of _.toPairs(attributes)) {
                for (let i=1; i < this.entries.length; ++i) {
                    if (attribute.vals[i] != attribute.vals[0]) {
                        attribute.mixed = true
                        break
                    }
                }
                Object.freeze(attribute)
            }
            return Object.freeze(attributes)
        },
        dropdownAttributes() {
            if (!this.dropdownReport)
                return {}

            let attributes = {}
            let fieldsMap = _(this.validFields).map(field => [field.id, field]).fromPairs().value()
            let entriesMap = _(this.entries).map((entry, i) => [entry.id, i]).fromPairs().value()

            for (let [fieldId, entryId, optionCode, overridden] of this.dropdownReport.rows) {
                let attribute = attributes[fieldId]
                if (attribute === undefined) {
                    attribute = {
                        vals: [],
                        mixed: false,
                        overridden: false,
                    }
                    attributes[fieldId] = attribute
                }
                let field = fieldsMap[fieldId]
                if (field?.field_type == "dropdown") {
                    attribute.vals[entriesMap[entryId]] = optionCode
                }
                if (field?.field_type == "multidropdown") {
                    let options = attribute.vals[entriesMap[entryId]]
                    if (options === undefined) {
                        options = [optionCode]
                        attribute.vals[entriesMap[entryId]] = options
                    }
                }
                if (overridden)
                    attribute.overridden = true
            }

            for (let attribute of _.values(attributes))
                Object.freeze(attribute.vals)

            for (let [fieldId, attribute] of _.toPairs(attributes)) {
                let field = fieldsMap[fieldId]
                for (let i=1; i < this.entries.length; ++i) {
                    if (attribute.vals[i] != attribute.vals[0]) {
                        attribute.mixed = true
                        break
                    }
                }
                Object.freeze(attribute)
            }
            return Object.freeze(attributes)
        },
        regularAttributes() {
            if (!this.valuesReport)
                return {}

            let attributes = {}

            let fieldsMap = _(this.validFields).map(field => [field.id, field]).fromPairs().value()

            let columns = this.valuesReport.meta.columns.map(column => column.name)

            let columnsMap = _(this.columnsMap)
                .toPairs()
                .map(([type, column]) => [type, columns.indexOf(column)])
                .fromPairs()
                .value()

            columnsMap = _(this.validFields).map(field => [field.id, columnsMap[field.field_type]]).fromPairs().value()

            let entriesMap = _(this.entries).map((entry, i) => [entry.id, i]).fromPairs().value()

            for (let row of this.valuesReport.rows) {
                let fieldId = row[0]
                let entryId = row[1]
                let field = fieldsMap[fieldId]
                let column = columnsMap[fieldId]
                let value = row[column]
                if (value) {
                    if (field.field_type == "numeric" || field.field_type == "decimal") {
                        for (let precision of [1,10,100,1000]) {
                            let rounded = Math.round(value * precision) / precision
                            if (Math.abs(value - rounded) < 1e-5) {
                                value = rounded
                                break
                            }
                        }
                    }
                    if (field.field_type == "date")
                        value = moment(new Date(value)).format("YYYY-MM-DD")
                    if (field.field_type == "datetime")
                        value = moment(new Date(value)).format("YYYY-MM-DDThh:mm:ss")
                }
                let attribute = attributes[fieldId]
                if (!attribute) {
                    attribute = {
                        vals: [],
                        mixed: false,
                        overridden: false,
                    }
                    attributes[fieldId] = attribute
                }
                if (row[row.length-1])
                    attribute.overridden = true
                attribute.vals[entriesMap[entryId]] = value
            }

            for (let attribute of _.values(attributes))
                Object.freeze(attribute.vals)

            for (let [fieldId, attribute] of _.toPairs(attributes)) {
                let field = fieldsMap[fieldId]
                for (let i=1; i < this.entries.length; ++i) {
                    if (attribute.vals[i] != attribute.vals[0]) {
                        attribute.mixed = true
                        break
                    }
                }
                Object.freeze(attribute)
            }
            return Object.freeze(attributes)
        },
        workflowStatus() {
            return this.getWorkflowStatus(this.entries[this.cursor])
        },
        workflowStatuses() {
            return _(this.entries)
                .map(entry => [entry.id, this.getWorkflowStatus(entry)])
                .fromPairs()
                .value()
        },
        mixedWorkflowStatuses() {
            return _(this.workflowStatuses).values().uniq().value().length > 1
        },
        localesInUse() {
            return _(this.validFields)
                .filter(field => field.has_locales)
                .map("locale_id")
                .filter()
                .uniq()
                .map(id => this.locales.find(locale => locale.id == id))
                .filter()
                .sortBy("name")
                .value()
        },
        storefrontsInUse() {
            return _(this.validFields)
                .filter(field => field.has_storefronts)
                .map("storefront_id")
                .filter()
                .uniq()
                .map(id => this.storefronts.find(storefront => storefront.id == id))
                .filter()
                .sortBy("name")
                .value()
        },
        hasImages() {
            return this.entries.some(entry => entry.image)
        },
        dropdownOptions() {
            return _(this.optionsReport?.rows)
                .map(([field_id, id, name, code]) => ({field_id, id, value:code, name, code}))
                .groupBy("field_id")
                .value()
        },
        optionsConfig() {
            let stream = "fields"
            let source = undefined
            let filter1 = `id in ${utils.quote(this.fields.filter(field => field.lookup_id != "0").map(field => field.id))}`
            let filter2 = undefined
            let filter3 = undefined
            let dims = ["id", "lookup_options.id"]
            let vals = ["lookup_options.label_name", "lookup_options.code"]
            let cols = undefined
            let sort = [3]
            let expand = "lookup_options"
            return {
                stream,
                source,
                filter1,
                filter2,
                filter3,
                dims,
                vals,
                cols,
                sort,
                expand
            }
        },
        valuesConfig() {
            let stream = undefined
            let dims = undefined
            let vals = undefined
            let filter1 = undefined
            let filter2 = undefined
            let filter3 = "!is_deleted"

            vals = [
                { calc: "last(alphanumeric_data, updated_at)",  name: "alphanumeric_data",  },
                { calc: "last(textarea_data, updated_at)",      name: "textarea_data",      },
                { calc: "last(numeric_data, updated_at)",       name: "numeric_data",       },
                { calc: "last(date_data, updated_at)",          name: "date_data",          },
                { calc: "last(datetime_data, updated_at)",      name: "datetime_data",      },
                { calc: "last(boolean_data, updated_at)",       name: "boolean_data",       },
                { calc: "last(decimal_data, updated_at)",       name: "decimal_data",       },
                { calc: "last(json_data, updated_at)",          name: "json_data",          },
                { calc: "last(is_overridden, updated_at)",      name: "is_overridden",      },
                { calc: "last(is_deleted, updated_at)",         name: "is_deleted",         },
            ]

            if (this.entriesType == "category") {
                stream = "metadata_flex_field_datas"
                dims = ['sku_master_field_id', 'metadata_id']
                filter1 = `metadata_id in [${this.entries.map(({id}) => `'${id}'`).join(',')}]`
                filter2 = `sku_master_field_id in [${this.validFields.map(({id}) => `'${id}'`).join(',')}]`
            }

            if (this.entriesType == "item") {
                stream = "item_attribute_data"
                dims = ['sku_master_field_id', 'item_master_id']
                filter1 = `item_master_id in [${this.entries.map(({id}) => `'${id}'`).join(',')}]`
                filter2 = `sku_master_field_id in [${this.validFields.map(({id}) => `'${id}'`).join(',')}]`
            }

            if (this.entriesType == "variation") {
                stream = "variation_flex_field_datas"
                dims = ['sku_master_field_id', 'variation_master_id']
                filter1 = `variation_master_id in [${this.entries.map(({id}) => `'${id}'`).join(',')}]`
                filter2 = `sku_master_field_id in [${this.validFields.map(({id}) => `'${id}'`).join(',')}]`
            }

            if (this.entriesType == "sku") {
                stream = "sku_flex_field_datas"
                dims = ['sku_master_field_id', 'sku_master_id']
                filter1 = `sku_master_id in [${this.entries.map(({id}) => `'${id}'`).join(',')}]`
                filter2 = `sku_master_field_id in [${this.validFields.map(({id}) => `'${id}'`).join(',')}]`
            }

            if (this.entriesType == "digital asset") {
                stream = "metadata_flex_field_datas"
                dims = ['sku_master_field_id', 'metadata_id']
                filter1 = `metadata_id in [${this.entries.map(({id}) => `'${id}'`).join(',')}]`
                filter2 = `sku_master_field_id in [${this.validFields.map(({id}) => `'${id}'`).join(',')}]`
            }

            let cols = []
            let sort = []
            return {
                stream,
                filter1,
                filter2,
                filter3,
                dims,
                vals,
                cols,
                sort,
            }
        },

        // 860

        derivedConfig() {
            let stream = undefined
            let source = undefined
            let dims = undefined
            let vals = undefined
            let filter1 = undefined
            let filter2 = undefined
            let filter3 = undefined
            let cols = []
            let sort = []

            let ids = `[${this.entries.map(({id}) => `'${id}'`).join(',')}]`


            if (this.entriesType == "item") {
                stream = "items"
                source = {
                    dims: ["id"],
                    vals: [
                        "taxonomy_id",
                        "item_number",
                        "name as item_name",
                        "image as item_image",
                        "taxonomy.taxonomy_code as taxonomy_code",
                    ],
                    filter1: `id in ${ids}`,
                    links: [],
                }
                dims = ["id"]
                vals = [{
                    calc: "item_number",
                    show: false
                }, {
                    calc: "item_name",
                    show: false
                }, {
                    calc: "item_image",
                    show: false
                }, {
                    calc: "taxonomy_code",
                    show: false
                }]
                cols = []
            }
            if (this.entriesType == "variation") {
                stream = "variations"
                source = {
                    dims: ["id"],
                    vals: [
                        "item.item_number as item_number",
                        "variation_number",
                    ],
                    filter1: `id in ${ids}`,
                    links: [],
                }
                dims = ["id"]
                vals = [{
                    calc: "item_number",
                    show: false
                }, {
                    calc: "variation_number",
                    show: false
                }]
                cols = []
            }
            if (this.entriesType == "sku") {
                stream = "skus"
                source = {
                    dims: ["id"],
                    vals: [
                        "item.item_number as item_number",
                        "variation.variation_number as variation_number",
                        "sku_number"
                    ],
                    filter1: `id in ${ids}`,
                    links: [],
                }
                dims = ["id"]
                vals = [{
                    calc: "item_number",
                    show: false
                }, {
                    calc: "sku_number",
                    show: false
                }, {
                    calc: "variation_number",
                    show: false
                }]
                cols = []
            }

            let fieldsById = _.fromPairs(this.fields.map(field => [field.id, field]))
            let fieldsByApiIndex = _.fromPairs(this.fields.map(field => [field.api_name, field]))
            let fieldsByApi = api_name => {
                let field = fieldsByApiIndex[api_name]
                if (!field)
                    throw `cannot find field ${api_name}`
                return field
            }

            let derivedFields = {}

            for (let field of this.validFields) {
                if (field.is_derived) {
                    if (field.derived_expr) {
                        let expression = field.derived_expr.replace(/sku_master_(\d+)/g, (match, fieldId) => {
                            let field = fieldsById[fieldId]
                            return field.api_name
                        })
                        if (field.field_type == "decimal")
                            expression = `round(${expression}, 4)`
                        derivedFields[field.api_name] = expression
                    }
                }
            }

            if (this.entriesType == "item") {

                derivedFields.brandFlex = `
                    'COACH®'            if taxonomy_code == '1' || taxonomy_code like '1=>*' else (
                    'Kate Spade'        if taxonomy_code == '2' || taxonomy_code like '2=>*' else (
                    'Stuart Weitzman'   if taxonomy_code == '3' || taxonomy_code like '3=>*' else (
                    'ADG'               if taxonomy_code == '4' || taxonomy_code like '4=>*')))`

                derivedFields.heelHeightRefinement = `
                    'FLAT' if heelHeightMm <= 25 else (
                    'LOW'  if heelHeightMm <= 40 else (
                    'MID'  if heelHeightMm <= 80 else (
                    'HIGH' if heelHeightMm <= 105 else (
                    'SHIGH'))))`

                derivedFields.dimension = `subst(handlebars('
                    {{#if widthIn}}{{(round widthIn 1)}}" W x{{/if}}
                    {{#if heightIn}}{{(round heightIn 1)}}" H x{{/if}}
                    {{#if depthIn}}{{(round depthIn 1)}}" D x{{/if}}'), '\\s+x\\s*$', '')`

                derivedFields.dimensionMetricCm = `subst(handlebars('
                    {{#if widthCm}}{{(round widthCm)}} cm (W) x{{/if}}
                    {{#if heightCm}}{{(round heightCm)}} cm (H) x{{/if}}
                    {{#if depthCm}}{{(round depthCm)}} cm (D) x{{/if}}'), '\\s+x\\s*$', '')`

                derivedFields.en_allMaterials = `
                    handlebars('
                        <strong>MATERIALS</strong><br/>
                        <div>
                            {{#if en_webMaterial}}{{en_webMaterial}}<br>{{/if}}
                            {{#if fabrication}}{{fabrication}}<br>{{/if}}
                            {{#if lining}}{{lining}}<br>{{/if}}
                            {{#if insoleMaterial}}{{insoleMaterial}}<br>{{/if}}
                            {{#if outsoleMaterial}}{{outsoleMaterial}}<br>{{/if}}
                            {{#if en_additionalOutsoleInfo}}{{en_additionalOutsoleInfo}}<br>{{/if}}
                            {{#if en_additionalMaterials}}{{en_additionalMaterials}}<br>{{/if}}
                        </div>
                        ')`

                derivedFields.bagSizeRefinement = `
                    ksHandbagSize if brandFlex == 'KS' else (
                    '' if heightIn == 0 else (
                    'Mini' if heightIn < 6.5 else (
                    'Small' if heightIn <= 7.5 else ( 
                    'Medium' if heightIn <= 9.1 else (
                    'Large')))))`

                derivedFields.pouchDimCm = `trim(subst(handlebars('
                    {{#if pouchWidthCm}}{{pouchWidthCm}} cm (W) x{{/if}}
                    {{#if pouchHeightCm}}{{pouchHeightCm}} cm (H) x{{/if}}
                    {{#if pouchDepthCm}}{{pouchDepthCm}} cm (D) x{{/if}}'), '\\s+x\\s*$', ''))`

                derivedFields.pouchDimIn = `trim(subst(handlebars('
                    {{#if pouchWidthIn}}{{round pouchWidthIn 1}}" W x{{/if}}
                    {{#if pouchHeightIn}}{{round pouchHeightIn 1}}" H x{{/if}}
                    {{#if pouchDepthIn}}{{round pouchDepthIn 1}}" D x{{/if}}'), '\\s+x\\s*$', ''))`

                derivedFields.en_measurements = `handlebars('
                    <strong>MEASUREMENTS</strong><br/>
                    <div>
                    {{#if dimensionMetricCm}}
                        {{#if dimension}}{{dimension}}&nbsp;&nbsp;{{dimensionMetricCm}}<br>{{/if}}
                    {{/if}}
                    {{#if strapDropMeasurementCm}}Strap Drop: {{round strapDropMeasurementIn 1}}", {{round strapDropMeasurementCm 0}} cm<br>{{/if}}
                    {{#if handleDropMeasurementCm}}Handle Drop: {{round handleDropMeasurementIn 1}}", {{round handleDropMeasurementCm 0}} cm<br>{{/if}}
                    {{#if pouchDimCm}}Pouch Dimension: {{pouchDimIn}} {{pouchDimCm}}<br>{{/if}}
                    {{#if heelHeightMm}}Heel Height: {{heelHeightMm}} mm<br>{{/if}}
                    {{#if shaftHeightCm}}Shaft Height: {{shaftHeightCm}} cm<br>{{/if}}
                    {{#if shaftCircumferenceCm}}Shaft Circumference: {{shaftCircumferenceCm}} cm<br>{{/if}}
                    {{#if platformHeightMm}}Platform Height: {{platformHeightMm}} cm<br>{{/if}}
                    {{#if accessoryLengthCm}}Length: {{accessoryLengthCm}} cm<br>{{/if}}
                    {{#if en_additionalMeasurements}}{{{en_additionalMeasurements}}}<br>{{/if}}
                    </div>')`

                derivedFields.model = `trim(handlebars('
                    {{#if (eq brandFlex "COACH®")}}
                    {{#if a360StyleGroup}}{{upper a360StyleGroup}}{{else}}{{item_number}}{{/if}}
                    {{else}}
                    {{first (upper item_name) 50}}
                    {{/if}}'))`

                derivedFields.en_features = `handlebars(
                    '{{#if (any productTypeFlex shaftHeightRefinement heelTypeRefinement toeShape bootEntryType insidePockets multiFunctionPockets compartmentDetails closureType creditCardPocket outsidePockets interchangeabelStrap changingMat techSleeve techFit feetDetails pouch carabinerClip en_additionalFeatures)}}
                    <strong>FEATURES</strong><br/>
                    <div>
                    {{#if productTypeFlex}}{{productTypeFlex}}{{/if}}
                    {{#if shaftHeightRefinement}}{{shaftHeightRefinement}}{{/if}}
                    {{#if heelTypeRefinement}}{{heelTypeRefinement}}{{/if}}
                    {{#if toeShape}}{{toeShape}}{{/if}}
                    {{#if bootEntryType}}{{bootEntryType}}{{/if}}
                    {{#if insidePockets}}{{insidePockets}}{{/if}}
                    {{#if multiFunctionPockets}}{{multiFunctionPockets}}{{/if}}
                    {{#if compartmentDetails}}{{compartmentDetails}}{{/if}}
                    {{#if closureType}}{{closureType}}{{/if}}
                    {{#if creditCardPocket}}Credit card slots: Yes{{/if}}
                    {{#if outsidePockets}}{{outsidePockets}}{{/if}}
                    {{#if interchangeabelStrap}}{{interchangeabelStrap}}{{/if}}
                    {{#if changingMat}}Changing Mat Included: Yes{{/if}}
                    {{#if techSleeve}}Tech Sleeve: Yes{{/if}}
                    {{#if techFit}}{{techFit}}{{/if}}
                    {{#if feetDetails}}{{feetDetails}}{{/if}}
                    {{#if pouch}}{{pouch}}{{/if}}
                    {{#if carabinerClip}}Carabiner Clip: Yes{{/if}}
                    {{#if en_additionalFeatures}}{{en_additionalFeatures}}{{/if}}
                    </div>
                    {{/if}}')`

                derivedFields.en_madeIn = `trim(handlebars('
                    {{#if (eq (lower sku_max_countryOfOriginDescription) "spain")}}
                        <strong>MADE IN</strong><br>SPAIN
                    {{else if (eq (lower sku_max_countryOfOriginDescription) "united states")}}
                        <strong>MADE IN</strong><br>USA
                    {{else if (eq (lower sku_max_countryOfOriginDescription) "usa")}}}}
                        <strong>MADE IN</strong><br>USA
                    {{else}}
                        <strong>IMPORTED</strong>
                    {{/if}}
                '))`

                derivedFields.en_webHeelHeight = `handlebars('{{heelHeightMm}} mm')`

                derivedFields.primaryAssetExists = `item_image != ''`
            }

            if (this.entriesType == "item") {
                for (let {name:storefront} of this.storefronts) {
                    derivedFields[`${storefront}_isOnSale`] = `${storefront}_salePrice_max != 0`
                    derivedFields[`${storefront}_maxSalePercent`] =
                        `(1 - ${storefront}_salePrice_min / ${storefront}_listPrice_max) * 100 if ${storefront}_salePrice_max != 0`
                    derivedFields[`${storefront}_minSKUPrice`] = `${storefront}_salePrice_min`
                    derivedFields[`${storefront}_maxSKUPrice`] = `${storefront}_salePrice_max`
                }
            }
            if (this.entriesType == "variation") {
                for (let {name:storefront} of this.storefronts) {
                    derivedFields[`${storefront}_isOnSale`] = `${storefront}_salePrice_max != 0`
                    derivedFields[`${storefront}_maxSalePercent`] =
                        `(1 - ${storefront}_salePrice_min / ${storefront}_listPrice_max) * 100 if ${storefront}_salePrice_max != 0`
                    derivedFields[`${storefront}_minSKUPrice`] = `${storefront}_salePrice_min`
                    derivedFields[`${storefront}_maxSKUPrice`] = `${storefront}_salePrice_max`
                    derivedFields[`${storefront}_maxNextDaySalePrice`] = `${storefront}_nextDaySalePrice_max`
                }
            }
            if (this.entriesType == "sku") {
                for (let {name:storefront} of this.storefronts) {
                    derivedFields[`${storefront}_isOnSale`] = `${storefront}_salePrice != 0`
                    derivedFields[`${storefront}_newToSale`] = `${storefront}_salePrice != 0 && ${storefront}_salePrice_days < 30`
                    derivedFields[`${storefront}_isClearance`] = `${storefront}_salePrice_clearance`
                    derivedFields[`${storefront}_isPrivateSale`] = `${storefront}_privateSale != 0`
                    derivedFields[`${storefront}_currentSalePercent`] =
                        `(1 - ${storefront}_salePrice / ${storefront}_listPrice) * 100 if ${storefront}_salePrice != 0`
                    derivedFields[`${storefront}_currentPrivateSalePercent`] =
                        `(1 - ${storefront}_privateSale / ${storefront}_listPrice) * 100 if ${storefront}_privateSale != 0`
                }
            }

            let symbolRegex = /[a-zA-Z_][a-zA-Z_0-9]+/g

            for (let expression of _.values(derivedFields)) {
                for (let [api_name] of expression.matchAll(symbolRegex)) {
                    let field = fieldsByApiIndex[api_name]
                    if (field && field.is_derived && field.derived_expr) {
                        let expression = field.derived_expr.replace(/sku_master_(\d+)/g, (match, fieldId) => {
                            let field = fieldsById[fieldId]
                            return field.api_name
                        })
                        if (field.field_type == "decimal")
                            expression = `round(${expression}, 4)`
                        derivedFields[field.api_name] = expression
                    }
                }
            }

            let requiredFields = new Set()
            let requiredSkuFields = new Map()
            let requiredVariationFields = new Map()

            for (let expression of _.values(derivedFields)) {
                for (let [name] of expression.matchAll(symbolRegex)) {
                    if (name.startsWith("sku_")) {
                        let func = name.split("_")[1]
                        let api_name = name.slice(func.length + "sku_".length + 1)
                        let field = fieldsByApiIndex[api_name]
                        if (!field) {
                            func = null
                            api_name = name.slice(4)
                            field = fieldsByApiIndex[api_name]
                        }
                        if (field) {
                            let funcs = requiredSkuFields.get(field)
                            if (funcs === undefined) {
                                funcs = new Set()
                                requiredSkuFields.set(field, funcs)
                            }
                            funcs.add(func)
                        }
                    }
                    if (name.startsWith("variation_")) {
                        let func = name.split("_")[1]
                        let api_name = name.slice(func.length + "variation_".length + 1)
                        let field = this.getFieldByApiNameAndLevel(api_name, "variation")

                        if (!field) {
                            func = null
                            api_name = name.slice("variation_".length)
                            field = this.getFieldByApiNameAndLevel(api_name, "variation")
                        }

                        if (field) {
                            let funcs = requiredVariationFields.get(field)
                            if (funcs === undefined) {
                                funcs = new Set()
                                requiredVariationFields.set(field, funcs)
                            }
                            funcs.add(func)
                        }
                    }
                    else {
                        let field = this.getFieldByApiNameAndLevel(name, this.entriesType)
                        if (field && !requiredFields.has(field) && !derivedFields[field.api_name])
                            requiredFields.add(field)
                    }
                }
            }

            if (this.entriesType == "item" || this.entriesType == "variation") {

                let idName = undefined
                switch (this.entriesType) {
                    case "item":
                        idName = "item_master_id"
                        break
                    case "variation":
                        idName = "variation_master_id"
                        break
                }

                for (let {name:storefront} of this.storefronts) {
                    for (let priceType of ["listPrice", "salePrice", "nextDaySalePrice"]) {
                        let api_name = `${storefront}_${priceType}`
                        let report = "prices_now"
                        let filter1 = `api_name == '${api_name}' && ${idName} in ${ids}`
                        if (priceType == "nextDaySalePrice") {
                            report = "prices_next_day"
                            filter1 = `api_name == '${storefront}-salePrice' && ${idName} in ${ids}`
                        }
                        source.links.push({
                            linkName: api_name,
                            sourceName: JSON.stringify({
                                source: report,
                                filter1,
                                dims: idName,
                                vals: [
                                    "max(price if price != 0) as max_price",
                                    "min(price if price != 0) as min_price"
                                ].join(", ")
                            }),
                            columnPairs: {
                                srcColumn: "id",
                                dstColumn: idName,
                            }
                        })
                        vals = vals.concat([{
                            calc: `${api_name}.min_price as ${api_name}_min`,
                            name: `${api_name}_min`,
                            show: false,
                        }, {
                            calc: `${api_name}.max_price as ${api_name}_max`,
                            name: `${api_name}_max`,
                            show: false,
                        }])
                    }
                }
            }

            if (this.entriesType == "sku") {
                for (let field of this.fields) {
                    if (!field.is_derived &&
                        field.level == "sku" && (
                        field.api_name.endsWith("_listPrice") ||
                        field.api_name.endsWith("_salePrice") ||
                        field.api_name.endsWith("_privateSale") ||
                        field.api_name.endsWith("_nextDaySalePrice")))
                    {
                        let report = "prices_now"
                        let filter1 = `api_name == '${field.api_name}'`
                        if (field.api_name.endsWith("_nextDaySalePrice")) {
                            report = "prices_next_day"
                            filter1 = `api_name == '${field.api_name.replace("_nextDaySalePrice", "_salePrice")}'`
                        }
                        source.links.push({
                            linkName: field.api_name,
                            sourceName: JSON.stringify({
                                source: report,
                                filter1,
                                dims: "sku_number",
                                vals: `price, clearance, days`}),
                            columnPairs: {
                                srcColumn: "sku_number",
                                dstColumn: "sku_number",
                            }
                        })
                        vals = vals.concat([{
                            calc: `${field.api_name}.price as ${field.api_name}`,
                            name: field.api_name,
                            show: false,
                        }, {
                            calc: `${field.api_name}.clearance as ${field.api_name}_clearance`,
                            name: `${field.api_name}_clearance`,
                            show: false,
                        }, {
                            calc: `${field.api_name}.days as ${field.api_name}_days`,
                            name: `${field.api_name}_days`,
                            show: false,
                        }])
                        cols.push({
                            calc: field.api_name,
                            name: field.api_name,
                        })
                    }
                }
            }

            for (let field of requiredFields) {
                if (field.api_name.endsWith("_listPrice") ||
                    field.api_name.endsWith("_salePrice") ||
                    field.api_name.endsWith("_privateSale") ||
                    field.api_name.endsWith("_nextDaySalePrice"))
                    continue
                switch (field.field_type) {
                case "dropdown":
                    source.links.push({
                        linkName: field.api_name,
                        sourceName: JSON.stringify({
                            source: "pim_flex_field_multi_dropdown_values",
                            filter1: `sku_master_field_id == '${field.id}' && item_master_id in ${ids}`,
                            dims: "item_master_id",
                            vals: `last(lookup_option.label_name, updated_at) as value`}),
                        columnPairs: {
                            srcColumn: "id",
                            dstColumn: "item_master_id",
                        }
                    })
                    break
                case "multidropdown":
                    source.links.push({
                        linkName: field.api_name,
                        sourceName: JSON.stringify({
                            source: "pim_flex_field_multi_dropdown_values",
                            filter1: `sku_master_field_id == '${field.id}' && item_master_id in ${ids}`,
                            dims: "item_master_id, lookup_option_id",
                            vals: `last(lookup_option.label_name, updated_at) as value`}),
                        expand: true,
                        columnPairs: {
                            srcColumn: "id",
                            dstColumn: "item_master_id",
                        }
                    })
                    break
                default:
                    source.links.push({
                        linkName: field.api_name,
                        sourceName: JSON.stringify({
                            source: "item_attribute_data",
                            filter1: `sku_master_field_id == '${field.id}' && item_master_id in ${ids}`,
                            dims: "item_master_id",
                            vals: `last(${field.field_type}_data, updated_at) as value`}),
                        columnPairs: {
                            srcColumn: "id",
                            dstColumn: "item_master_id",
                        }
                    })
                }
                vals.push({
                    calc: `${field.api_name}.value as ${field.api_name}`,
                    name: field.api_name,
                    show: false,
                })
            }

            for (let [field, funcs] of requiredSkuFields) {
                if (funcs.has(null))
                    source.links.push({
                        linkName: `sku1_${field.api_name}`,
                        sourceName: JSON.stringify({
                            source: "sku_flex_field_datas",
                            filter1: `sku_master_field_id == '${field.id}'`,
                            filter2: `sku.item_master_id in ${ids}`,
                            filter3: "!is_deleted",
                            dims: "sku.item_master_id as item_master_id",
                            vals: [
                                `last(${field.field_type}_data, updated_at) as value`,
                                "last(is_deleted, updated_at) as is_deleted"].join(",")
                        }),
                        expand: true,
                        columnPairs: {
                            srcColumn: "id",
                            dstColumn: "item_master_id",
                        },
                    })

                if ([...funcs].some(func => func != null))
                    source.links.push({
                        linkName: `sku2_${field.api_name}`,
                        sourceName: JSON.stringify({
                            source: {
                                source: "sku_flex_field_datas",
                                filter1: `sku_master_field_id == '${field.id}'`,
                                filter2: `sku.item_master_id in ${ids}`,
                                filter3: "!is_deleted",
                                dims: "sku.item_master_id as item_master_id",
                                vals: [
                                    `last(${field.field_type}_data, updated_at) as value`,
                                    "last(is_deleted, updated_at) as is_deleted"].join(",")
                            },
                            dims: "item_master_id",
                            vals: [...funcs]
                                .filter(func => func != null)
                                .map(func => `${func}(value) as ${func}_value`)
                                .join(",")
                        }),
                        columnPairs: {
                            srcColumn: "id",
                            dstColumn: "item_master_id",
                        },
                    })

                for (let func of funcs) {
                    if (func == null)
                        vals.push({
                            calc: `sku1_${field.api_name}.value as sku_${field.api_name}`,
                            name: field.api_name,
                            show: false,
                        })
                    else 
                        vals.push({
                            calc: `sku2_${field.api_name}.${func}_value as sku_${func}_${field.api_name}`,
                            name: field.api_name,
                            show: false,
                        })
                }
            }

            for (let [field, funcs] of requiredVariationFields) {
                if (funcs.has(null))
                    source.links.push({
                        linkName: `variation1_${field.api_name}`,
                        sourceName: JSON.stringify({
                            source: "variation_flex_field_datas",
                            filter1: `sku_master_field_id == '${field.id}'`,
                            filter2: `variation.item_master_id in ${ids}`,
                            filter3: "!is_deleted",
                            dims: "variation.item_master_id as item_master_id",
                            vals: [
                                `last(${field.field_type}_data, updated_at) as value`,
                                "last(is_deleted, updated_at) as is_deleted"].join(",")
                        }),
                        expand: true,
                        columnPairs: {
                            srcColumn: "id",
                            dstColumn: "item_master_id",
                        },
                    })

                if ([...funcs].some(func => func != null))
                    source.links.push({
                        linkName: `variation2_${field.api_name}`,
                        sourceName: JSON.stringify({
                            source: {
                                source: "variation_flex_field_datas",
                                filter1: `sku_master_field_id == '${field.id}'`,
                                filter2: `variation.item_master_id in ${ids}`,
                                filter3: "!is_deleted",
                                dims: "variation.item_master_id as item_master_id",
                                vals: [
                                    `last(${field.field_type}_data, updated_at) as value`,
                                    "last(is_deleted, updated_at) as is_deleted"].join(",")
                            },
                            dims: "item_master_id",
                            vals: [...funcs]
                                .filter(func => func != null)
                                .map(func => `${func}(value) as ${func}_value`)
                                .join(",")
                        }),
                        columnPairs: {
                            srcColumn: "id",
                            dstColumn: "item_master_id",
                        },
                    })

                for (let func of funcs) {
                    if (func == null)
                        vals.push({
                            calc: `variation1_${field.api_name}.value as variation_${field.api_name}`,
                            name: field.api_name,
                            show: false,
                        })
                    else 
                        vals.push({
                            calc: `variation2_${field.api_name}.${func}_value as variation_${func}_${field.api_name}`,
                            name: field.api_name,
                            show: false,
                        })
                }
            }

            let derivedFieldsDeps = {}
            for (let [api_name, expression] of _.toPairs(derivedFields)) {
                derivedFieldsDeps[api_name] = []
                for (let [dep_api_name] of expression.matchAll(/[a-zA-Z_]+/g)) {
                    if (dep_api_name != api_name && derivedFields[dep_api_name])
                        derivedFieldsDeps[api_name].push(dep_api_name)
                }
            }

            derivedFieldsOrdered = []

            while (!_.isEmpty(derivedFields)) {
                let [api_name, expression] = _(derivedFields)
                    .toPairs()
                    .minBy(([api_name]) => derivedFieldsDeps[api_name].filter(dep_api_name => derivedFields[dep_api_name]).length)
                derivedFieldsOrdered.push([api_name, expression])
                delete derivedFields[api_name]
            }

            for (let [api_name, expression] of derivedFieldsOrdered) {
                cols.push({
                    calc: `${expression} as ${api_name}`,
                    name: api_name
                })
            }

            // stream = "items"
            // source = {
            //     dims: ["id"],
            //     filter1: `id in ${ids}`,
            //     links: {
            //         linkName: "heelHeightMm",
            //         sourceName: JSON.stringify({
            //             source: "item_attribute_data",
            //             filter1: `sku_master_field_id == '878' && item_master_id in ${ids}`,
            //             dims: "item_master_id",
            //             vals: "last(numeric_data, updated_at) as value"}),
            //         columnPairs: {
            //             srcColumn: "id",
            //             dstColumn: "item_master_id",
            //         }
            //     }
            // }
            // dims = ["id"]
            // vals = ["heelHeightMm.value as heelHeightMm"]
            // cols = [`
            //     'FLAT' if heelHeightMm <= 25 else (
            //     'LOW' if heelHeightMm <= 40 else (
            //     'MID' if heelHeightMm <= 80 else (
            //     'HIGH' if heelHeightMm <= 105 else (
            //     'SHIGH')))) as heelHeightRefinement`]


            // dimension

            // widthIn
            // heightIn
            // depthIn

            // handlebars('{{widthIn}} W x {{heightIn}} H x {{depthIn}} D') if widthIn != 0 && heightIn != 0 && depthIn != 0 else (
            // handlebars('{{widthIn}} W x {{heightIn}} H') if widthIn != 0 && heightIn != 0 else (
            // handlebars('{{widthIn}} W x {{depthIn}} D') if widthIn != 0 && depthIn != 0 else (
            // handlebars('{{heightIn}} H x {{depthIn}} D') if heightIn != 0 && depthIn != 0 else (
            // handlebars('{{widthIn}} W') if widthIn != 0 else (
            // handlebars('{{heightIn}} H') if heightIn != 0 else (
            // handlebars('{{depthIn}} H') if depthIn != 0))))))








            // 'FLAT' if heelHeightMm <= 25 else (
            // 'LOW' if heelHeightMm <= 40 else (
            // 'MID' if heelHeightMm <= 80 else (
            // 'HIGH' if heelHeightMm <= 105 else (
            // 'SHIGH'))))

            // def get_heel_height_refinement_value(*obj_hash)
            //   item                   = obj_hash[0]
            //   company                = obj_hash[1]
            //   heel_height_smf        = company.get_preloaded_field('item', 'Heel Height (mm)')
            //   return nil if heel_height_smf.blank?
            //   heel_height_ref        = company.get_preloaded_field('item', 'Heel Height Refinement')
            //   heel_height_ref_lookup = heel_height_ref.try(:lookup)
            //   return nil if heel_height_ref_lookup.blank?
            //   heel_height_data       = item.item_attribute_datas.find_by(sku_master_field_id: heel_height_smf.id).try(:numeric_data)
            //   case heel_height_data
            //   when 0..25
            //     return heel_height_ref_lookup.lookup_options.find_by(code: 'FLAT').try(:id)
            //   when 26..40
            //     return heel_height_ref_lookup.lookup_options.find_by(code: 'LOW').try(:id)
            //   when 41..80
            //     return heel_height_ref_lookup.lookup_options.find_by(code: 'MID').try(:id)
            //   when 81..105
            //     return heel_height_ref_lookup.lookup_options.find_by(code: 'HIGH').try(:id)
            //   when 106..Float::INFINITY
            //     return heel_height_ref_lookup.lookup_options.find_by(code: 'SHIGH').try(:id)
            //   end
            //   nil
            // end            

            return {
                stream,
                source,
                filter1,
                filter2,
                filter3,
                dims,
                vals,
                cols,
                sort,
            }
        },

        dropdownConfig() {
            let stream = undefined
            let source = undefined
            let dims = undefined
            let vals = undefined
            let filter1 = undefined
            let filter2 = undefined
            let filter3 = "!is_deleted"

            vals = [
                { calc: "last(is_overridden, updated_at)",  name: "is_overridden",  },
                { calc: "last(is_deleted, updated_at)",     name: "is_deleted",     },
            ]

            if (this.entriesType == "item") {
                stream = "pim_flex_field_multi_dropdown_values"
                dims = ['sku_master_field_id', 'item_master_id', 'lookup_option.code']
                filter1 = `item_master_id in [${this.entries.map(({id}) => `'${id}'`).join(',')}]`
                filter2 = `sku_master_field_id in [${this.validFields.map(({id}) => `'${id}'`).join(',')}]`
            }

            if (this.entriesType == "variation") {
                stream = "variation_flex_field_dropdown_values"
                dims = ['sku_master_field_id', 'variation_master_id', 'lookup_option.code']
                filter1 = `variation_master_id in [${this.entries.map(({id}) => `'${id}'`).join(',')}]`
                filter2 = `sku_master_field_id in [${this.validFields.map(({id}) => `'${id}'`).join(',')}]`
                
            }

            if (this.entriesType == "sku") {
                stream = "sku_flex_field_dropdown_values"
                dims = ['sku_master_field_id', 'sku_master_id', 'lookup_option.code']
                filter1 = `sku_master_id in [${this.entries.map(({id}) => `'${id}'`).join(',')}]`
                filter2 = `sku_master_field_id in [${this.validFields.map(({id}) => `'${id}'`).join(',')}]`
            }

            let cols = []
            let sort = []
            return {
                stream,
                source,
                filter1,
                filter2,
                filter3,
                dims,
                vals,
                cols,
                sort,
            }
        },
        cursor() {
            if (this.entries) {
                let cursor = this.entries.indexOf(this.focus)
                return cursor != -1 ? cursor : 0
            }
            else return 0
        },
        fieldsFuse() {
            return new Fuse(this.validFields, this.search)
        },
        entriesType() {
            return this.entries[0].type
        },
        validFields() {
            let groups = this.fieldGroups(this.entriesType)
            return this.fields
                .filter(field =>
                    field.api_name == "en_allMaterials" ||
                    field.relation &&
                    field.relation.viewable &&
                    groups.includes(field.group_name) &&
                    field.name.indexOf("DO NOT USE") == -1)
        },
        attributesChanged() {
            return !_.isEmpty(this.changes)
        },
        visibleGroups() {
            let visibleGroups = _(this.visibleFields)
                .groupBy(({item:field}) => this.getFieldName(field.parent || field))
                .toPairs()
                .sortBy(([group_name, [field]]) => this.getFieldName(field.parent || field))
                .sortBy(([group_name, [field]]) => (field.parent || field).sequence || null)
                .fromPairs()
                .value()
            return Object.freeze(visibleGroups)
        },
    },
    methods: {
        hasAIResults(entry) {
            return this.classifyReport && this.classifyReport[entry.image]
        },
        hasAISuggestions(entry) {
            let getValue = api_name => {
                let field_id = this.getFieldByApiNameAndLevel(api_name, this.entriesType)?.id
                let edited = this.changes[field_id]
                if (edited !== undefined)
                    return edited
                let attribute = this.attributes[field_id]
                if (attribute !== undefined)
                    return attribute.vals[this.cursor]
                return null
            }
            return _(this.classifyReport && this.classifyReport[entry.image])
                .toPairs().some(([api_name, code]) => {
                    let value = getValue(api_name)
                    return value != code.name
                })
        },
        formatClassification(classification) {
            return ""
        },
        async runClassification() {
            let entries = this.entries
            let images = _(entries).map("image").filter().uniq().value()
            let classifyReport = {}
            this.classifyReport = classifyReport

            images = images.filter(image => {
                let result = this.classificationCache[image]
                if (result)
                    this.$set(classifyReport, image, result)
                else
                    return image
            })

            let {models} = await (await fetch("/classify/models")).json()


            // no more than 4 conections from classifier
            for (let batch of _.chunk(images, 40)) {
                let chunks = _.chunk(batch, 10)
                await Promise.all(chunks.map(images =>
                    fetch("/classify", {
                        method: "POST",
                        headers: {"content-type": "application/json"},
                        body: JSON.stringify({models, images})
                    })
                    .then(response => response.json())
                    .then(report => _(report)
                        .toPairs()
                        .forEach(([image, result]) => {
                            this.$set(classifyReport, image, result)
                            this.classificationCache[image] = result
                        }))
                ))
                if (this.entries != entries)
                    return
            }
        },
        async completeText(prompt) {
            return await (await fetch("/generate", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(prompt)
            })).json()
        },
        async discardRomanceCopyChoice(choice) {
            let entry = this.entry
            let choices = this.romanceCopyReport[entry.id].result.choices
            this.$delete(choices, choices.indexOf(choice))
            this.generateRomanceCopy(choices)
        },
        async generateRomanceCopy(choices) {
            let entry = this.entry
            let cursor = this.cursor
            if (this.romanceCopyReport[entry.id] && !choices)
                return

            try {
                let attributes = {}

                for (let field of this.fields) {
                    let attribute = this.attributes[field.id]
                    if (attribute) {
                        let value = attribute.vals[cursor]
                        if (value !== undefined)
                            attributes[field.api_name] = value
                    }
                }

                if (_.isEmpty(attributes))
                    return

                let romanceCopyPrompt = this.romanceCopyPrompt

                if (_.isString(romanceCopyPrompt)) {
                    try {
                        romanceCopyPrompt = eval(romanceCopyPrompt)
                    }
                    catch (ex) {
                        console.warn(romanceCopyPrompt)
                        console.warn(ex.message)
                    }
                }

                if (!_.isFunction(romanceCopyPrompt))
                    return null

                let prompt = romanceCopyPrompt(attributes)

                if (choices)
                    prompt.n = 1

                if (!choices)
                    this.$set(this.romanceCopyReport, entry.id, {pending: true})

                let result = await this.completeText(prompt)

                let report = { entry, result, prompt }
                if (!choices)
                    this.$set(this.romanceCopyReport, entry.id, report)
                else
                    choices.splice(choices.length, 0, result.choices[0])
            }
            catch (ex) {
                this.$delete(this.romanceCopyReport, entry.id)
            }
        },
        editorInit(field) {
            let spellchecker_language = "en"
            let match = field.name.match(/\(([a-z]{2}(-[A-Z]{2})?)\)$/)
            if (match) {
                spellchecker_language = match[1]
            }
            if (field.locale_id) {
                let locale = this.locales.find(locale => locale.id == field.locale_id)
                if (locale)
                    spellchecker_language = locale.name
            }
            if (spellchecker_language == "ca")
                spellchecker_language = "fr"
            return {
                inline: true,
                skin: "small",
                icons: "small",
                // plugins: "tinymcespellchecker",
                // spellchecker_language,
                // toolbar: "ai",
                // setup: editor => {
                //     editor.ui.registry.addButton('ai', {
                //         text: 'ai',
                //         onAction: async () => {
                //             let prompt = h2p(editor.getContent())   
                //             let {choices} = await (await fetch("/generate", {
                //                 method: "POST",
                //                 headers: { "Content-Type": "application/json" },
                //                 body: JSON.stringify({prompt})
                //             })).json()
                //             editor.insertContent(choices[0].text);
                //         }
                //     })
                // }
            }
        },
        isGroupOpen(group) {
            return this.groupsStatus[group]?.open === true
        },
        toggleGroup(group) {
            let groupStatus = this.groupsStatus[group]
            if (groupStatus === undefined) {
                groupStatus = {}
                this.$set(this.groupsStatus, group, groupStatus)
            }
            this.$set(groupStatus, "open", !this.isGroupOpen(group))
        },
        updateVisibleFields() {
            let visibleFields = _((this.searchString ?
                this.fieldsFuse
                    .search(this.searchString)
                    .map(entry => {
                        entry.formattedName =
                            utils.formatSearchItem(
                                entry.item.name,
                                entry.matches.filter(match => match.key == "name"))
                        return entry
                    }) : this.validFields.map(field => ({item: field})))
                .filter(({item:field}) =>
                        !this.showOnlyAiFields || 
                        field.api_name == this.romanceCopyField ||
                        (
                            this.entries[this.cursor] &&
                            this.entries[this.cursor].image &&
                            this.classifyReport[this.entries[this.cursor].image] &&
                            this.classifyReport[this.entries[this.cursor].image][field.api_name]
                        )
                    )
                .filter(({item:field}) =>
                    !this.locale || !field.has_locales || field.locale_id == this.locale)
                .filter(({item:field}) =>
                    !this.storefront || !field.has_storefronts || field.api_name.startsWith(this.storefront))
                .filter(({item:field}) =>
                    (!this.showOnlyEditable || !this.isFieldReadonly(field)) &&
                    (
                        this.showEmptyValues ||
                        this.attributes[field.id] && (
                            this.attributes[field.id].mixed ||
                            this.changes[field.id] !== undefined ||
                            this.attributes[field.id].vals[this.cursor])
                    )))
                .filter(({item:field}) => this.columns.length == 0 || this.columns.some(column => column.id == field.id))
                .sortBy(field => field.name)
                .sortBy(field => field.sequence || null)
                .value()
            this.visibleFields = Object.freeze(visibleFields)
        },
        getValue(field) {
            let edited = this.changes[field.id]
            if (edited !== undefined)
                return edited

            let attribute = this.attributes[field.id]
            if (attribute !== undefined) {
                let value = attribute.vals[this.cursor]
                if (field.field_type == "json" && !this.isComputed(field))
                    return JSON.stringify(value || null, undefined, 2)
                else
                    return value
            }
            return null
        },
        setValue(field, value) {
            this.$set(this.changes, field.id, value)
        },
        isRequired(field) {
            return field.mandatory
        },
        isMixed(field) {
            return !this.isEdited(field) && this.attributes[field.id]?.mixed
        },
        isDerived(field) {
            return field.is_derived == 1
        },
        isComputed(field) {
            return this.attributes[field.id]?.computed
        },
        isEdited(field) {
            return this.changes[field.id] !== undefined
        },
        isOverriden(field) {
            return this.isDerived(field) && (this.attributes[field.id]?.overridden || this.isEdited(field)) && this.changes[field.id] !== null
        },
        formatMixed(mixed) {
            let threshold = 20
            if (mixed.length <= threshold + 10)
                return mixed.join("\n")
            else {
                let text = mixed.slice(0, threshold).join("\n")
                text += `\nand ${Number(mixed.length - threshold)} more`
                return text
            }
        },
        isFieldReadonly(field) {
            return field.is_readonly ||
                field.is_derived && !field.overridable ||
                field.editable === false ||
                this.entries.length > 1 && this.mass_update === false
        },
        async submitChanges() {
            let changes = this.changes
            let createUser = this.username
            let createTime = new Date().toISOString().split(".")[0]
            let actions = []

            {
                let records = []

                let stream = undefined
                switch (this.entriesType) {
                    case "category":    stream = "metadata_flex_field_datas";   break
                    case "item":        stream = "item_attribute_data";         break
                    case "variation":   stream = "variation_flex_field_datas";  break
                    case "sku":         stream = "sku_flex_field_datas";        break
                }
                
                for (let [fieldId, value] of _.toPairs(changes)) {
                    let field = this.fieldsByFieldId[fieldId]

                    if (!field || field.field_type == "dropdown" || field.field_type == "multidropdown")
                        continue

                    for (let entry of this.entries) {
                        let record = {
                            company_id: "1",
                            sku_master_field_id: field.id,
                            creator_id: createUser,
                            updater_id: createUser,
                            created_at: createTime,
                            updated_at: createTime,
                        }

                        switch (entry.type) {
                            case "category":    record.metadata_id          = entry.id; break
                            case "item":        record.item_master_id       = entry.id; break
                            case "variation":   record.variation_master_id  = entry.id; break
                            case "sku":         record.sku_master_id        = entry.id; break
                        }

                        if (field.is_derived)
                            record.is_overridden = true

                        if (value == null) {
                            record.is_deleted = true
                        }
                        else {
                            switch (field.field_type) {
                                case "alphanumeric": record.alphanumeric_data   = value; break;
                                case "textarea":     record.textarea_data       = value; break;
                                case "numeric":      record.numeric_data        = value; break;
                                case "date":         record.date_data           = value; break;
                                case "datetime":     record.datetime_data       = value; break;
                                case "boolean":      record.boolean_data        = value; break;
                                case "decimal":      record.decimal_data        = value; break;
                                case "json":         record.json_data           = JSON.parse(value); break;
                            }
                        }

                        records.push(record)
                    }
                }

                if (records.length)
                    actions.push(`
                        regular:appendRecords(
                            stream: "${stream}",
                            format: "json",
                            records: ${utils.quote(JSON.stringify(records))})`)
            }

            {
                let records = []

                let stream = undefined
                switch (this.entriesType) {
                    case "item":        stream = "pim_flex_field_multi_dropdown_values"; break
                    case "variation":   stream = "variation_flex_field_dropdown_values"; break
                    case "sku":         stream = "sku_flex_field_dropdown_values";       break
                }

                let entriesMap = _(this.entries).map((entry, i) => [entry.id, i]).fromPairs().value()
                
                for (let [fieldId, value] of _.toPairs(changes)) {
                    let field = this.fieldsByFieldId[fieldId]

                    if (!field || field.field_type != "dropdown" && field.field_type != "multidropdown")
                        continue

                    let previous_values = this.attributes[fieldId]?.vals || []

                    for (let entry of this.entries) {
                        let record = {
                            company_id: "1",
                            sku_master_field_id: field.id,
                            creator_id: createUser,
                            updater_id: createUser,
                            created_at: createTime,
                            updated_at: createTime,
                        }

                        switch (entry.type) {
                            case "category":    record.metadata_id          = entry.id; break
                            case "item":        record.item_master_id       = entry.id; break
                            case "variation":   record.variation_master_id  = entry.id; break
                            case "sku":         record.sku_master_id        = entry.id; break
                        }

                        if (field.is_derived)
                            record.is_overridden = true

                        switch (field.field_type) {
                            case "dropdown": {
                                let options = this.dropdownOptions[field.id] || []
                                if (value == null) {
                                    let previous_value = previous_values[entriesMap[entry.id]]
                                    if (previous_value !== undefined) {
                                        let option = options.find(option => option.code == previous_value)
                                        if (option) {
                                            record.lookup_option_id = option.id
                                            record.is_deleted = true
                                            records.push(record)
                                        }
                                    }
                                }
                                else {
                                    let option = options.find(option => option.code == value)
                                    if (option) {
                                        record.lookup_option_id = option.id;
                                        records.push(record)
                                    }
                                }
                                break;
                            }

                            case "multidropdown": {
                                let options = this.dropdownOptions[field.id] || []
                                for (let code of value) {
                                    let option = options.find(option => option.code == code)
                                    if (option) {
                                        record.lookup_option_id = option.id;
                                        records.push(_.clone(record))
                                    }
                                }
                                for (let code of previous_values[entriesMap[entry.id]] || [])
                                    if (value.indexOf(code) == -1) {
                                        let option = options.find(option => option.code == code)
                                        if (option) {
                                            record.lookup_option_id = option.id
                                            record.is_deleted = true
                                            records.push(_.clone(record))
                                        }
                                    }
                                break;
                            }
                        }
                    }
                }

                if (records.length)
                    actions.push(`
                        dropdown:appendRecords(
                            stream: "${stream}",
                            format: "json",
                            records: ${utils.quote(JSON.stringify(records))})`)
            }



            let query = `
                mutation {
                    ${actions.join("\n")}
                }`

            await utils.fetch("/graphql", {
                method: "POST",
                body: JSON.stringify({query}),
                headers: {"Content-Type": "application/json"},
            })

            await Promise.all([
                this.$refs.valuesReport.requestData({forced:true}),
                this.$refs.derivedReport.requestData({forced:true}),
                this.$refs.dropdownReport.requestData({forced:true}),
            ])


            this.changes = {}

            /*let actions = []
            let createUser = this.username
            let createTime = new Date().toISOString().split(".")[0]
            for (let key of _.keys(changes)) {
                let field = this.fields.find(field => field.id == key)
                let stream = `attr_category_${key}`
                let value = changes[key]
                let records = []
                for (let entry of this.entries || [this.focus]) {
                    records.push([
                        "manual",
                        0,
                        entry.id,
                        changes[key],
                        createTime,
                        createUser,
                        createTime,
                        createUser
                    ])
                    let attr = entry.attrs.find(attr => attr.key == key)
                    if (attr)
                        this.$st(attr, "val", value)
                }
                actions.push(
                    `\t${key}:appendRecords(
                        stream: ${utils.quote(stream)},
                        format: "json",
                        records: ${utils.quote(JSON.stringify(records))})`)
            }
            if (_.isEmpty(actions))
                return
            let query = `mutation {\n${actions.join("\m")}}`
            await utils.fetch("/graphql", {
                method: "POST",
                body: JSON.stringify({query}),
                headers: {"Content-Type": "application/json"},
            })*/
        },
        discardChanges() {
            this.changes = {}
        },
    },
}
</script>
<style>
.pim-attributes input[type="search"] {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--gray);
    background: transparent;
    margin-bottom: 15px;
}
.pim-attributes input[type="search"]:focus {
    outline: none;
    box-shadow: none;
    /*color: white;*/
    background: transparent;
    border-bottom-color: var(--dark);
}
.my-dark-theme .pim-attributes input[type="search"]:focus {
  border-bottom-color: white;
}
.pim-attributes ::-webkit-search-cancel-button {
    position: relative;
    right: -10px;
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    cursor: pointer;
    background-size: 18px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="rgb(80,80,80)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>');
}
.pim-attributes .form-group {
    margin-bottom: 8px;
}
.pim-attributes .form-inline .form-group {
    display: flex;
    width: 100%;
}
.pim-attributes .form-group > label {
    margin-bottom: 5px;
    color: var(--dark);
}
.pim-attributes .form-group > label:first-child:last-child {
    display: block;
    margin-bottom: -10px;
}
.pim-attributes .form-inline label {
    flex-basis: 1px;
    flex-grow: 1;
    display: block;
}
.pim-attributes input,
.pim-attributes select,
.pim-attributes textarea {
    color: black;
}
.my-dark-theme .pim-attributes input,
.my-dark-theme .pim-attributes select,
.my-dark-theme .pim-attributes textarea,
.my-dark-theme .pim-attributes .chosen-container-single {
    background: transparent;
    color: white;
    border: none;
    border-bottom: 1px solid var(--light);
    border-radius: 0;
}
.my-dark-theme .pim-attributes .form-group > label  {
    color: var(--light);
}
.my-dark-theme ::-webkit-calendar-picker-indicator {
    filter: invert(0.8);
}
.pim-attributes .chosen-container{
    display: block;
}
.pim-attributes .chosen-container-single {
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
}
.pim-attributes .chosen-container-single .chosen-single {
  line-height: 30px;
  height: 30px;
}
.pim-attributes .chosen-container-single .chosen-drop {
  transform: translate(-1px, 0);
}
.pim-attributes .chosen-container-single .chosen-single b {
  transform: translate(0, 4px);
}
</style>