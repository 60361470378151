var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "gp-chart": true, "gp-chart-compact": _vm.compact } },
    [
      _c("date-filter", {
        attrs: {
          stream: _vm.stream,
          function: "date",
          notBefore: _vm.notBefore,
          notAfter: _vm.notAfter,
          groups: ["timerange"]
        }
      }),
      _vm._v(" "),
      _c("my-tooltip", {
        ref: "tooltip",
        attrs: {
          meta: _vm.meta,
          chart: _vm.chart,
          keepOnly: _vm.keepOnly,
          exclude: _vm.exclude,
          accessor: _vm.tooltipAccessor,
          actions: _vm.actions
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { ref: "base", staticClass: "my-chart" },
        [
          _c("gp-data", {
            ref: "data",
            attrs: {
              id: "gp-charts",
              controls: true,
              stream: _vm.stream,
              source: _vm.source,
              groups: _vm.groups,
              dims: _vm.dims,
              vals: _vm.vals,
              cols: _vm.cols,
              filter1: _vm.filter1,
              filter2: _vm.filter2,
              instant: _vm.instant,
              throttled: _vm.throttled
            },
            on: {
              report: function($event) {
                _vm.report = $event
              },
              reportId: function($event) {
                _vm.reportId = $event
              }
            }
          }),
          _vm._v(" "),
          _c("div", {
            ref: "chart",
            style: { opacity: _vm.reportId ? 0.3 : 1 }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: { "gp-chart-metrics": true, opened: _vm.metricsOpened } },
        [
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  _vm.metricsOpened = !_vm.metricsOpened
                }
              }
            },
            [
              _c("feather-icon", { attrs: { name: "chevrons-right" } }),
              _vm._v(" "),
              _c("l10n", { attrs: { value: "Metrics" } })
            ],
            1
          ),
          _vm._v(" "),
          _c("gp-stored", {
            attrs: { compact: true, family: "chart", username: _vm.username },
            model: {
              value: _vm.checked,
              callback: function($$v) {
                _vm.checked = $$v
              },
              expression: "checked"
            }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.metricsSearchString,
                expression: "metricsSearchString"
              }
            ],
            staticClass: "form-control form-control-sm",
            attrs: { placeholder: _vm.l10n("Search..."), type: "search" },
            domProps: { value: _vm.metricsSearchString },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.metricsSearchString = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "gp-chart-metrics-list" },
            [
              _vm._l(_vm.visibleMetrics, function(metric) {
                return _vm.checked[metric.formula] !== undefined
                  ? _c("li", { key: metric.formula }, [
                      _vm.checked[metric.formula] && _vm.nestedColors
                        ? _c("div", { staticClass: "gp-chart-marker" }, [
                            _c("input", {
                              attrs: { type: "color" },
                              domProps: { value: _vm.metricColor(metric.name) },
                              on: {
                                change: function($event) {
                                  _vm.$set(
                                    _vm.colorsOverride,
                                    metric.name,
                                    $event.target.value
                                  )
                                  _vm.chart.render()
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", {
                              style: {
                                "background-color": _vm.metricColor(metric.name)
                              }
                            })
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-check" }, [
                        _c("input", {
                          staticClass: "form-check-input",
                          attrs: {
                            type: "checkbox",
                            id: "metric-" + metric.formula
                          },
                          domProps: { checked: _vm.checked[metric.formula] },
                          on: {
                            click: function($event) {
                              return _vm.$set(
                                _vm.checked,
                                metric.formula,
                                !_vm.checked[metric.formula]
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: { for: "metric-" + metric.formula }
                          },
                          [
                            _c("l10n", { attrs: { value: metric.name } }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "gp-chart-remove",
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$delete(
                                      _vm.checked,
                                      metric.formula
                                    )
                                  }
                                }
                              },
                              [_c("feather-icon", { attrs: { name: "x" } })],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.visibleMetrics.find(function(metric) {
                return _vm.checked[metric.formula] !== undefined
              })
                ? _c("li", [_c("hr")])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.visibleMetrics, function(metric) {
                return _vm.checked[metric.formula] === undefined
                  ? _c("li", { key: metric.formula }, [
                      _c("div", { staticClass: "form-check" }, [
                        _c("input", {
                          staticClass: "form-check-input",
                          attrs: {
                            type: "checkbox",
                            id: "metric-" + metric.formula
                          },
                          domProps: { checked: _vm.checked[metric.formula] },
                          on: {
                            click: function($event) {
                              return _vm.$set(
                                _vm.checked,
                                metric.formula,
                                !_vm.checked[metric.formula]
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: { for: "metric-" + metric.formula }
                          },
                          [_c("l10n", { attrs: { value: metric.name } })],
                          1
                        )
                      ])
                    ])
                  : _vm._e()
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }