<template>
  <div class="policies">
    <header class="policies__header">
      <autoorder-tabs
        :staticTabs="tabs"
        :dynamicTabs="cardTabs"
        :activeTabId="currentTabId"
        @onSelect="selectTab"
        @onCloseTab="tabCloseInit"
      />
    </header>
    <main class="policies__main">
      <keep-alive :exclude="['policy-card']">
        <component
          v-if="currentComponent"
          :is="currentComponent"
        />
      </keep-alive>
    </main>

    <close-tab-modal v-model="closeTabModalVisible" @onTabCloseConfirm="tabCloseConfirm" />
  </div>
</template>

<script>
const {
  saveToStorageDynamicTabIds,
  getFromStorageDynamicTabIds,
  clearCardStatesCache,
} = require('../../utils/useStorage');

// async components https://v2.vuejs.org/v2/guide/components-dynamic-async#Async-Components
/* eslint-disable import/no-dynamic-require, global-require */
const PolicyCard = (resolve) => {
  require(['./policy-card.vue'], resolve);
};
const PoliciesCatalog = (resolve) => {
  require(['./policies-catalog.vue'], resolve);
};
// const PoliciesRegister = (resolve) => {
//   require(['./policies-register.vue'], resolve);
// };
const AutoorderTabs = (resolve) => {
  require(['../../components/autoorder-tabs.vue'], resolve);
};
const CloseTabModal = (resolve) => {
  require(['../../components/custom-modals/close-tab-modal.vue'], resolve);
};
/* eslint-enable import/no-dynamic-require, global-require */

const policyTabsStorageKey = 'policy-tabs';
const policyEntityStorageKey = 'policy-cards';
const catalogId = 'policy-catalog';
const registerId = 'policy-register';
const newPolicy = 'new-policy';
const initialCardTab = {
  id: null,
  component: PolicyCard,
};

module.exports = {
  components: {
    PoliciesCatalog,
    // PoliciesRegister,
    PolicyCard,
    AutoorderTabs,
    CloseTabModal,
  },

  provide() {
    return {
      newPolicy,
      policyEntityStorageKey,
      openPoliciesCatalog: this.openCatalog,
      openPolicyRegister: this.openRegister,
      createNewPolicyCard: this.createNewCard,
      openExistPolicyById: this.openExistCard,
      onSuccessfulCreatePolicy: this.onSuccessfulCreatePolicy,
      reactiveProvidedPolicyData: this.reactiveData, // https://ru.vuejs.org/v2/api/#provide-inject
    };
  },

  data() {
    return {
      tabs: [
        { id: catalogId, title: 'Справочник политик', component: PoliciesCatalog },
        // { id: registerId, title: 'Реестр привязок политик', component: PoliciesRegister },
      ],
      cardTabs: [],
      newCardCounter: 0,
      reactiveData: {
        currentTabView: null,
      },
      tabIdForClose: null,
      closeTabModalVisible: false,
    };
  },

  computed: {
    currentComponent() {
      return this.reactiveData.currentTabView?.component || null;
    },
    currentTabId() {
      return this.reactiveData.currentTabView?.id || null;
    },
    dynamicTabIds() {
      return this.cardTabs?.length ? this.cardTabs.map((item) => item?.id) : [];
    },
  },

  methods: {
    selectTab(id) {
      this.reactiveData.currentTabView = this.tabs.find((tab) => tab.id === id)
      || this.cardTabs.find((tab) => tab.id === id) || null;
    },
    removeTab(id) {
      const result = [];

      for (let i = 0; i < this.cardTabs.length; i += 1) {
        const card = this.cardTabs[i];
        if (card.id !== id) {
          result.push(card);
        }
      }

      this.cardTabs = result;

      if (this.currentTabId === id) {
        this.selectTab(catalogId);
      }
    },
    tabCloseConfirm() {
      this.removeTab(this.tabIdForClose);
      this.tabIdForClose = null;
      this.closeTabModalVisible = false;
    },
    tabCloseInit(tabId) {
      this.closeTabModalVisible = true;
      this.tabIdForClose = tabId;
    },
    openCatalog() {
      this.selectTab(catalogId);
    },
    openRegister() {
      this.selectTab(registerId);
    },
    openExistCard(id) {
      const newCardTab = {
        ...initialCardTab,
        id,
      };

      if (!this.cardTabs.find((card) => card.id === id)) {
        this.cardTabs.push(newCardTab);
        this.selectTab(id);
      } else {
        this.selectTab(id);
      }
    },
    createNewCard() {
      this.newCardCounter += 1;
      const newId = `${newPolicy}-${this.newCardCounter}`;
      this.openExistCard(newId);
    },
    onSuccessfulCreatePolicy(id) {
      if (id) {
        this.cardTabs = this.cardTabs.map((card) => (card.id === this.currentTabId ? {
          ...card,
          id,
        } : card));
        this.selectTab(id);
      }
    },
  },

  watch: {
    dynamicTabIds(newIds) {
      saveToStorageDynamicTabIds({ key: policyTabsStorageKey, tabIds: newIds });

      clearCardStatesCache({ entity: policyEntityStorageKey, tabIds: newIds });
    },
  },

  mounted() {
    const { tabs, counter } = getFromStorageDynamicTabIds({
      key: policyTabsStorageKey, newCardName: newPolicy, initialCardTab,
    });
    this.cardTabs = tabs;
    this.newCardCounter = counter;
  },
};
</script>

<style scoped>
.policies {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
  overflow-y: auto;
  padding: 12px;
}

.policies__main {
  flex: 1 0 auto;
}
</style>
