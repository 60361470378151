var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "hierarchy-table" }, [
    _c("thead", [
      _c(
        "tr",
        { staticClass: "hierarchy-table__row" },
        _vm._l(_vm.tableColumns, function(column) {
          return _c(
            "td",
            {
              key: column.title,
              staticClass: "hierarchy-table__cell head-cell"
            },
            [
              _c(
                "a",
                {
                  staticClass: "hierarchy-table__head-title",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [_vm._v("\n          " + _vm._s(column.title) + "\n        ")]
              )
            ]
          )
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.rows, function(row, rowIndex) {
        return _c(
          "tr",
          {
            key: row.nodeType + "_" + rowIndex + "_" + row.id,
            staticClass: "hierarchy-table__row",
            class: { "hierarchy-table__row_leaf": row.nodeType === "leaf" },
            on: {
              click: function($event) {
                row.nodeType === "leaf" && _vm.handleLeafClick(row.id)
              }
            }
          },
          [
            row.level
              ? _c("hierarchy-link", {
                  attrs: {
                    level: row.level,
                    isLastRowOnLevel: _vm.isLastRowOnLevel({
                      row: row,
                      rows: _vm.rows,
                      rowIndex: rowIndex
                    }),
                    isRestRowsHasNodes: _vm.isRestRowsHasNodes({
                      row: row,
                      rows: _vm.rows,
                      rowIndex: rowIndex
                    })
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.tableColumns, function(column, columnIndex) {
              return _c(
                "td",
                {
                  key: column.title,
                  staticClass: "hierarchy-table__cell",
                  style:
                    columnIndex === 0
                      ? {
                          "padding-left":
                            _vm.getRowLeftSpacing(row.level) + "px"
                        }
                      : null
                },
                [
                  !columnIndex && row.nodeType === "node"
                    ? _c("node-button", {
                        attrs: { isOpen: row.isOpen },
                        on: {
                          click: function($event) {
                            return _vm.handleNodeClick({
                              row: row,
                              rowIndex: rowIndex
                            })
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.getValueOfCell(column.field, row)) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            })
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }