<template>
    <div class="gp-report">
        <!--ul>
            <template v-for="spout in spouts">
                <li v-for="path in spout.loadedPaths">
                    {{path}}
                </li>
            </template>
        </ul-->
        <div class="gp-report-loading" v-if="loading">
            <feather-icon name="clock"/>
        </div>
        <!--gp-filter
            v-model="filter_"
            :report="report"
            :popup-portal="null"
            :attributes="
                columns
                    .filter(({indexed}) => indexed)
                    .map(({gqlName:calc, name}) => ({name: l10n(name), calc}))"
            /-->
        <div v-if="errors" class="alert alert-warning">
            <div v-for="error in errors">{{error.message}}</div>
        </div>
        <div
            v-if="pageCount > 1"
            tabindex="0"
            @keydown="handlePaginatorKeyDown"
            class="plain-table-paginator">

            <a
                href="javascript:void(0)"
                :class="{disabled: page === 0}"
                @click="setDesiredPage(page - 10)">
                <feather-icon name="chevrons-left"/></a><a
                href="javascript:void(0)"
                :class="{disabled: page === 0}"
                @click="setDesiredPage(page - 1)">
                <feather-icon name="chevron-left"/></a>

            <a href="javascript:void(0)" @click="promptPage">
                <l10n value="page {page}" :page="new Number(page+1).toLocaleString()"/></a>

            <a
                href="javascript:void(0)"
                :class="{disabled: page === pageCount-1}"
                @click="setDesiredPage(page + 1)">
                <feather-icon name="chevron-right"/></a><a
                href="javascript:void(0)"
                :class="{disabled: page === pageCount-1}"
                @click="setDesiredPage(page + 10)">
                <feather-icon name="chevrons-right"/></a>

            <l10n class="nowrap" :rows="new Number(size).toLocaleString()" value="{rows} rows,"/>
            <l10n class="nowrap" :pages="new Number(pageCount).toLocaleString()" value="{pages} pages,"/>

            <a href="javascript:void(0)" @click="promptPageSize">
                <l10n class="nowrap" :page-size="new Number(pageSize).toLocaleString()" value="{page-size} rows per page"/>
            </a>

            <inline-help
                text="Click and use use <Left> and <Right> arrow keys for pagination. Hold <Shift> for fast forward."
                :html="true"/>

        </div>

        <table
            class="table table-sm table-striped table-hover table-responsive"
            :style="{opacity: loading ? 0.8 : 1}"
            >
            <thead>
                <tr>
                    <th v-for="column, i in columns" :data-type="column.type">
                        <l10n :value="column.synonym || column.name"/>
                        <!--a
                            href="javascript:void(0)"
                            @click="toogleSort(i)">
                            <l10n :value="column.synonym || column.name"/>
                            <feather-icon v-if="sort[0] === i+1" name="arrow-up"/>
                            <feather-icon v-if="sort[0] === -i-1" name="arrow-down"/>
                        </a-->
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="record in records.rows">
                    <template v-for="column, i in columns">
                        <td :set="value = formatValue(record[i])" :data-type="column.type">
                            <template v-if="value.match(/^https?:/)">
                                <a :href="value" target="_blank">{{value}}</a>
                            </template>
                            <template v-else>
                                {{value}}
                            </template>
                        </td>
                    </template>
                </tr>
            </tbody>
        </table>
        <button
            class="btn btn-secondary"
            @click="exportData">
            <l10n value="Export data"/>
        </button>
    </div>
</template>
<script>
let utils = require("../my-utils")

module.exports = {
    props: {
        report: String,
        filter: Array,
    },
    data() {
        return {
            l10n: utils.l10n,
            records: { rows: [], size: 0 },
            selection: {},
            spouts: [],
            columns: [],
            pageSize: 20,
            desiredPage: 0,
            reportSize: 0,
            filter_: _.cloneDeep(this.filter) || [],
            sort: [],
            loading: null,
            errors: null,
        }
    },
    mounted() {
        if (this.report)
            this.loadReport(this.report)
    },
    beforeDestroy() {
    },
    watch: {
        columns() {
            this.$emit("columns", this.columns)
        },
        records() {
            this.$emit("records", this.records)
        },
        report() {
            this.columns = []
            this.records = { rows: [], size: 0 }
            this.selection = {}
            this.spouts = []
            this.reportSize = 0
            this.desiredPage = 0
            if (this.report)
                this.loadReport(this.report)
        },
        skip(a,b) {
            if (this.report && !_.isEqual(a, b))
                this.loadReport(this.report)
        },
        take(a,b) {
            if (this.report && !_.isEqual(a, b))
                this.loadReport(this.report)
        },
        sort(a,b) {
            if (this.report && !_.isEqual(a, b))
                this.loadReport(this.report)
        },
        filterString(a,b) {
            if (this.report && !_.isEqual(a, b))
                this.loadReport(this.report)
        },
    },
    computed: {
        size() {
            return this.records.size
        },
        page() {
            let desiredPage = this.desiredPage
            return Math.max(0, Math.min(this.pageCount - 1, desiredPage))
        },
        pageCount() {
            return Math.floor((this.size + this.pageSize - 1) / this.pageSize)
        },
        take() {
            return this.pageSize
        },
        skip() {
            return this.page * this.pageSize
        },
        allSelected() {
            return _.every(
                this.records.rows,
                ({__id:id}) => this.selection[id])
        },
        selectedCount() {
            return _(this.selection).values().sum()
        },
        filterString() {
            return this.makeFilter(this.filter_)
        }
    },
    methods: {
        reload() {
            if (this.report)
                this.loadReport(this.report)
        },
        toogleSort(i) {
            if (this.sort[0] === i+1)
                this.sort = [-i-1];
            else if (this.sort[0] === -i-1)
                this.sort = []
            else
                this.sort = [i+1]
        },
        async loadReport(report) {
            let data = await this.submitQuery(`
                query {
                    dataset {
                        report(name:"${report}") {
                            size
                            rows(
                                skip: ${this.skip}
                                take: ${this.take})
                            columns { name gqlName type synonym indexed }
                        }
                    }
                }`)
            this.records = {
                rows: data.dataset.report.rows,
                size: data.dataset.report.size,
            }
            this.columns = data.dataset.report.columns
        },
        setDesiredPage(page) {
            this.desiredPage = page
        },
        promptPageSize() {
            let pageSize = prompt("Rows per page:", this.pageSize)
            if (pageSize !== null) {
                pageSize = parseInt(pageSize)
                if (!_.isNaN(pageSize))
                    this.pageSize = pageSize
            }
        },
        handlePaginatorKeyDown: function(e) {
            switch (e.key) {
                case "ArrowLeft":
                    e.preventDefault()
                    e.stopPropagation()
                    this.setDesiredPage(this.page - (e.shiftKey ? 10 : 1))
                    break
                case "ArrowRight":
                    e.preventDefault()
                    e.stopPropagation();
                    this.setDesiredPage(this.page + (e.shiftKey ? 10 : 1))
                    break
            }
        },
        toogleSelectedStreamSelection(id) {
            if (id === undefined) {
                let state = !this.allSelected
                let selection = this.selection
                for (let record of this.records.rows)
                    this.$set(selection, record.__id, state)
            }
            else {
                let selection = this.selection
                this.$set(selection, id, !selection[id])
            }
        },
        async exportData() {
            let report = this.report
            let query = `
                query {
                  dataset {
                    report(name:"${report}") {
                      file(format:"csv") {
                        link
                      }
                    }
                  }
                }`

            let res = await Promise
                .resolve($.ajax({
                    url: '/graphql',
                    method: 'POST',
                    data: JSON.stringify({query}),
                    dataType: 'json',
                    contentType: 'application/json'
                }))

            let link = res.data.dataset.report.file.link
            let a = document.createElement("a")
            a.href = link
            a.setAttribute("download", `${report} ${moment().format('Y-M-D H:m:s')}.csv.gz`)
            a.style.display = "none"
            document.body.appendChild(a)
            a.click()
        },
        importData(e) {
            let report = this.report
            let file = e.target.files[0]
            e.target.value = ""
            if (!confirm(utils.l10n("Are you sure you want to import data from file {file} into report {report}?").replace("{file}", file.name).replace("{report}", this.report)))
                return
            let format = file.name.split(".").slice(1).concat("b64").join(".")
            let reader = new FileReader()
            reader.addEventListener("load", async () => {
                let records = reader.result.split(";base64,")[1]
                let query = `
                    mutation {
                        appendRecords(
                            skip: 1,
                            report: ${utils.quote(report)},
                            format: ${utils.quote(format)},
                            records: ${utils.quote(records)})
                    }`
                let res = await Promise
                    .resolve($.ajax({
                        url: '/graphql',
                        method: 'POST',
                        data: JSON.stringify({query}),
                        dataType: 'json',
                        contentType: 'application/json'
                    }))
                let rows = res.data.appendRecords.length
                alert(utils.l10n("{rows} rows have been imported into report {report}")
                    .replace("{rows}", new Number(rows).toLocaleString())
                    .replace("{report}", report))
            })
            reader.readAsDataURL(file)
        },
        deleteSelectedRows() {
            let report = this.report
            let selection = this.selection

            let ids = _(selection)
                .toPairs()
                .filter(([id, selected]) => selected)
                .map(([id, selected]) => id)
                .value()

            if (!window.confirm(`Are you sure you want to delete ${new Number(ids.length)} rows?`))
                return

            this.submitQuery(`
                mutation {
                    removeRecords(
                        report: ${utils.quote(report)}
                        ids: [${ids.join(",")}])
                }
            `).then(({removeRecords}) => {
                if (removeRecords && this.report == report) {
                    return this.loadReport(report)
                }
            }).then(() => {
                for (let id of ids)
                    this.$set(selection, id, false)
            })
        },
        async submitQuery(query) {
            let loading = utils.randomId()
            this.loading = loading
            try {
                let {errors, data} = await Promise
                    .resolve($.ajax({
                        url: '/graphql?wait',
                        method: 'POST',
                        data: JSON.stringify({query}),
                        dataType: 'json',
                        contentType: 'application/json'
                    }))
                if (errors) {
                    this.errors = errors
                    throw errors
                }
                else {
                    this.errors = null
                    return data
                }
            }
            finally {
                if (this.loading === loading)
                    this.loading = null
            }
        },
        formatValue(x) {
            if (_.isNumber(x))
                return new Number(x).toLocaleString()
            if (_.isPlainObject(x))
                return JSON.stringify(x)
            return `${x}`
        },
        promptPage() {
            let page = prompt("Go to page:", this.page+1)
            if (page !== null) {
                page = parseInt(page)
                if (!_.isNaN(page)) {
                    this.setDesiredPage(page-1)
                }
            }
        },
        setDesiredPage(page) {
            this.desiredPage = page
        },
        promptPageSize() {
            let pageSize = prompt("Rows per page:", this.pageSize)
            if (pageSize !== null) {
                pageSize = parseInt(pageSize)
                if (!_.isNaN(pageSize))
                    this.pageSize = pageSize
            }
        },
        makeFilter(filter) {
            return _(filter)
                    .map((condition) =>
                        _(condition)
                            .toPairs()
                            .map(([key, value]) => 
                                value.length == 1
                                    ? `${key} == ${utils.quote(value[0])}`
                                    : `${key} in ${utils.quote(value)}`)
                            .join(" && "))
                    .join(" || ")
        },
    }
}
</script>
<style>
.gp-report-loading {
    float: right
}
.gp-report-loading svg {
    width: 18px;
    height: 18px;
}
.gp-report .gp-filter {
    margin-bottom: 10px;
}
.gp-report .plain-table-paginator {
    float: none;
}
.gp-report .table {
    font-size: 0.9em;
    margin-top: 8px;
    margin-bottom: 15px;
    clear: both;
}
.gp-report .table td {
    white-space: nowrap;
}
.gp-report .table th {
    font-weight: normal;
    line-height: 15px;
}
.gp-report .table svg {
    width: 18px;
    height: 18px;
    margin: -1px;
}
.gp-report .table .form-check {
    display: inline-block;
    margin-right: -8px;
}
.gp-report .table th .form-check {
    vertical-align: bottom;
    margin-bottom: 4px;
}
.gp-report .table td .form-check {
    margin-top: -2px;
    vertical-align: top;
}
.gp-report p {
    padding-left: 6px;
    font-size: 0.9em;
}
.gp-report .feather-icon-clock {
    color: var(--green);
}
</style>