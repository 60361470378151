<template>
  <div class="display-settings-container">
    <h6>Параметры отображения</h6>
    <gp-check
      :checked="showNullOrder"
      @change="handleChange"
    >
      <l10n value="Показывать строки с нулевым заказом" />
    </gp-check>
    <gp-check
      :checked="showAlert"
      @change="handleChange"
    >
      <l10n value="Показывать алерты" />
    </gp-check>
    <gp-check
      :checked="allowEdit"
      @change="handleChange"
    >
      <l10n value="Разрешить редактирование заказа" />
    </gp-check>
  </div>
</template>

<script>
module.exports = {
  props: {

  },

  data() {
    return {
      showNullOrder: true,
      showAlert: true,
      allowEdit: true,
    };
  },

  methods: {
    handleChange() {

    },
  },
};
</script>

<style scoped>
.display-settings-container {
  width: 49.5%;
  padding: 0.65rem;
  border: 1px solid #ccc;
}
.accordion-title {
  margin-left: 0.75rem;
  color: var(--cyan);
}
</style>
