<template>
    <my-dialog
        title="Assembly"
        @close="onClose"
        >
        <ul class="nav nav-tabs my-assembly-tabs">
            <li v-for="(code, name) of assembly" class="nav-item">
                <a
                    href="javascript:void(0)"
                    :class="{'nav-link': true, active: name === assemblyTab}"
                    @click="assemblyTab = name"
                    style="line-height:1"
                    >
                    {{name}}
                </a>
                <a
                    v-if="name === 'Query'"
                    href="/graphiql"
                    target="_blank"
                    @click="openQueryInGraphiQL"
                    >
                    <feather-icon name="external-link"/>
                </a>
            </li>
        </ul>
        <ace-editor
            :value="assembly[assemblyTab]||''"
            :read-only="true"
            :mode="mode"
            height="400px"
            class="my-assembly-code"
        />
    </my-dialog>
</template>
<script>
module.exports = {
    props: {
        assembly: { type: Object }
    },
    data: function() {
        return {
            assemblyTab: "Dims"
        }
    },
    computed: {
        mode: function() {
            return this.assemblyTab === "Query" ? "yaml" : "assembly_x86"
        }
    },
    methods: {
        onClose: function() {
            this.$emit("close")
        },
        openQueryInGraphiQL: function() {
            localStorage["graphiql:query"] = this.assembly.Query
        },
    },
}
</script>
<style>
.my-assembly-tabs .nav-item {
    position: relative;
}
.my-assembly-tabs .nav-link {
    padding: 8px 15px!important;
}
.my-assembly-tabs .nav-link:not(:last-child) {
    padding-right: 30px!important;
}
.my-assembly-tabs .nav-link + a {
    position: absolute;
    top: 3px;
    right: 6px;
}
.my-assembly-tabs .nav-link + a svg {
    width: 18px;
    height: 18px;
}
.my-dark-theme .my-assembly-code {
    border: 1px solid rgb(68, 68, 68);
    border-top: none;
}
.my-light-theme .my-assembly-code {
    border: 1px solid rgb(222, 226, 230);
    border-top: none;
}
</style>