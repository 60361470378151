var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    {
      class: _vm.classes,
      style: _vm.style,
      attrs: {
        title: _vm.title,
        "data-column": _vm.column.i,
        "data-type": _vm.column.type,
        "data-name": _vm.column.name,
        tabindex: _vm.tabindex
      },
      on: { mousedown: _vm.handleMouseDown }
    },
    [
      _vm.column.actionable && _vm.actionicon && !_vm.noAction
        ? _c(
            "a",
            {
              staticClass: "my-colum-action",
              attrs: { href: "javascript:void(0)" },
              on: { click: _vm.handleAction }
            },
            [_c("feather-icon", { attrs: { name: _vm.actionicon } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.editing
        ? [
            _vm.column.tagname
              ? _c("RenderProxy", {
                  attrs: {
                    tagname: _vm.column.tagname,
                    column: _vm.column,
                    meta: _vm.meta,
                    row: _vm.row,
                    config: _vm.column.config
                  }
                })
              : _vm.column.options
              ? _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editingValue,
                        expression: "editingValue"
                      }
                    ],
                    on: {
                      blur: _vm.handleBlur,
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.editingValue = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.handleSelectChange
                      ],
                      keydown: _vm.handleKeyDown
                    }
                  },
                  _vm._l(_vm.column.options, function(option) {
                    return _c("option", { domProps: { value: option } }, [
                      _vm._v(
                        "\r\n                " +
                          _vm._s(option) +
                          "\r\n            "
                      )
                    ])
                  }),
                  0
                )
              : _vm.column.type == "html"
              ? _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editingValue,
                      expression: "editingValue"
                    }
                  ],
                  domProps: { value: _vm.editingValue },
                  on: {
                    blur: _vm.handleBlur,
                    change: _vm.handleChange,
                    keydown: _vm.handleKeyDown,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.editingValue = $event.target.value
                    }
                  }
                })
              : (_vm.column.type === "date" ? "date" : "text") === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editingValue,
                      expression: "editingValue"
                    }
                  ],
                  attrs: {
                    list: _vm.options ? _vm.column.id + "-options" : null,
                    type: "checkbox"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.editingValue)
                      ? _vm._i(_vm.editingValue, null) > -1
                      : _vm.editingValue
                  },
                  on: {
                    blur: _vm.handleBlur,
                    change: [
                      function($event) {
                        var $$a = _vm.editingValue,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.editingValue = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.editingValue = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.editingValue = $$c
                        }
                      },
                      _vm.handleChange
                    ],
                    keydown: _vm.handleKeyDown
                  }
                })
              : (_vm.column.type === "date" ? "date" : "text") === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editingValue,
                      expression: "editingValue"
                    }
                  ],
                  attrs: {
                    list: _vm.options ? _vm.column.id + "-options" : null,
                    type: "radio"
                  },
                  domProps: { checked: _vm._q(_vm.editingValue, null) },
                  on: {
                    blur: _vm.handleBlur,
                    change: [
                      function($event) {
                        _vm.editingValue = null
                      },
                      _vm.handleChange
                    ],
                    keydown: _vm.handleKeyDown
                  }
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editingValue,
                      expression: "editingValue"
                    }
                  ],
                  attrs: {
                    list: _vm.options ? _vm.column.id + "-options" : null,
                    type: _vm.column.type === "date" ? "date" : "text"
                  },
                  domProps: { value: _vm.editingValue },
                  on: {
                    blur: _vm.handleBlur,
                    change: _vm.handleChange,
                    keydown: _vm.handleKeyDown,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.editingValue = $event.target.value
                    }
                  }
                }),
            _vm._v(" "),
            _vm.column.type !== "date"
              ? _c("span", [
                  _vm._v(_vm._s(_vm.editingValue)),
                  _vm.editingValue && _vm.editingValue.endsWith("\n")
                    ? _c("br")
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.editingValue
              ? _c("span", [_vm._v(_vm._s(_vm.formattedValue))])
              : _vm._e(),
            _vm._v(" "),
            _vm.options
              ? _c(
                  "datalist",
                  { attrs: { id: _vm.column.id + "-options" } },
                  _vm._l(_vm.options, function(option) {
                    return _c("option", { domProps: { value: option } })
                  }),
                  0
                )
              : _vm._e()
          ]
        : _vm.column.actionable && !_vm.actionicon && !_vm.noAction
        ? _c("span", [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: { click: _vm.handleAction }
              },
              [
                _vm._v(
                  "\r\n            " +
                    _vm._s(_vm.formattedValue) +
                    "\r\n        "
                )
              ]
            )
          ])
        : _vm.actionlink
        ? _c("span", [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.actionlink,
                  target: _vm.actionlink.startsWith("http") ? "_blank" : ""
                },
                on: { click: _vm.handleNavigate }
              },
              [
                _vm._v(
                  "\r\n            " +
                    _vm._s(_vm.formattedValue) +
                    "\r\n        "
                )
              ]
            )
          ])
        : _vm.isLink && !_vm.noLink
        ? _c("span", [
            _c("a", { attrs: { href: _vm.value, target: "_blank" } }, [
              _vm._v(
                "\r\n            " + _vm._s(_vm.formattedValue) + "\r\n        "
              )
            ])
          ])
        : _c("span", [_vm._v(_vm._s(_vm.formattedValue))])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }