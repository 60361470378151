var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "22",
        height: "21",
        viewBox: "0 0 22 21",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M1 8.5C1 8.5 1.12132 7.65076 4.63604 4.13604C8.15076 0.62132 13.8492 0.62132 17.364 4.13604C18.6092 5.38131 19.4133 6.90072 19.7762 8.5M1 8.5V2.5M1 8.5H7M21 12.5C21 12.5 20.8787 13.3492 17.364 16.864C13.8492 20.3787 8.15076 20.3787 4.63604 16.864C3.39076 15.6187 2.58669 14.0993 2.22383 12.5M21 12.5V18.5M21 12.5H15",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }