const component = require('../../input/calendar/calendar.vue').default;

module.exports = ({
  mode,
}) => {
  const valueToInput = (x) => x
    .map((day) => ({
      ...day,
      date: new Date(day.date),
    }));
  const inputToValue = (x) => x
    .map((day) => ({
      ...day,
      date: Number(day.date),
    }));
  const display = (x) => x
    .sort((a, b) => a.date - b.date)
    .map(({ date }) => new Date(date).toLocaleDateString())
    .join(', ');

  return {
    component,
    valueToInput,
    inputToValue,
    display,
    mode,
  };
};
