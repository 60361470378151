const component = require('../../input/text.vue').default;

module.exports = () => {
  const valueToInput = (x) => x.join(',');
  const inputToValue = (x) => x.split(/[^0-9]+/).filter((i) => i);
  const display = (x) => x.join(', ');

  return {
    component,
    valueToInput,
    inputToValue,
    display,
  };
};
