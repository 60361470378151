<template>
    <tr>
        <td>
            <div
                class="pim-tree-multiselect"
                ref="multiselect"
                :class="{focused: entry.focused}"
                :style="{'margin-left': `${level * 20 + 26}px`}"
                @change="handleChange"
                >
                <gp-check :checked="checked" :indeterminate="indeterminate">
                    <template v-if="selected == 0">
                        select all {{pluralize(entry.entry.childrenType)}}
                    </template>
                    <template v-else-if="selected == 1">
                        {{l10n(selected)}} {{entry.entry.childrenType}} selected
                    </template>
                    <template v-else>
                        {{l10n(selected)}} {{pluralize(entry.entry.childrenType)}} selected
                    </template>
                </gp-check>
            </div>
        </td>
    </tr>
</template>
<script>
let utils = require("../my-utils")
module.exports = {
    props: {
        entry: { type: Object },
        classify: { type: Function },
        pluralize: { type: Function },
    },
    methods: {
        focus() {
        },
        l10n(x) {
            if (_.isNumber(x))
                return Number(x).toLocaleString()
            return utils.l10n(x)
        },
        handleChange(e) {
            if (e.target.checked) {
                for (let child of this.children)
                    if (!child.selected)
                        this.$set(child, "selected", true)
            }
            else if (this.indeterminate) {
                e.target.checked = true
                for (let child of this.children)
                    if (!child.selected)
                        this.$set(child, "selected", true)
            }
            else {
                for (let child of this.children)
                    if (child.selected)
                        this.$delete(child, "selected")
            }
        },
    },
    computed: {
        children() {
            return this.entry.entry.children || []
        },
        checked() {
            return this.selected > 0
        },
        indeterminate() {
            return this.selected > 0 && this.selected < this.children.length
        },
        selected() {
            let selected = 0
            for (let child of this.children)
                if (child.selected)
                    selected += 1
            return selected
        },
        level() {
            let level = 0
            let entry = this.entry.entry
            while (entry) {
                level += 1
                entry = entry.parent
            }
            return level
        },
    }
}
</script>
<style>
.pim-tree-multiselect {
  color: var(--dark);
  font-style: italic;
  font-size: 0.95em;
}
.pim-tree-multiselect input {
  transform: translate(0, -1px);
}
.my-dark-theme .pim-tree-multiselect {
  color: var(--light);
}
</style>