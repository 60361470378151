var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-card" }, [
    _c(
      "button",
      {
        staticClass: "error-card__reload-btn",
        attrs: { type: "button" },
        on: { click: _vm.reloadCurrentPage }
      },
      [_c("retry-icon")],
      1
    ),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", { staticClass: "error-card__text" }, [
        _vm._v("Ошибка загрузки.")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "error-card__text" }, [
        _vm._v("Пожалуйста, перезагрузите страницу.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }