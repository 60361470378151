var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-sitemap" }, [
    _c(
      "ul",
      _vm._l(_vm.flat, function(node) {
        return _c(
          "li",
          { style: { "--level": node.level } },
          [
            !node.children
              ? _c("feather-icon", { attrs: { size: "18px", name: "empty" } })
              : node.children.length > 0
              ? _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        return _vm.$set(node, "open", !node.open)
                      }
                    }
                  },
                  [
                    _c("feather-icon", {
                      attrs: {
                        size: "18px",
                        name: node.open ? "book-open" : "book"
                      }
                    })
                  ],
                  1
                )
              : _c("feather-icon", { attrs: { size: "18px", name: "empty" } }),
            _vm._v(" "),
            _c("a", { attrs: { href: node.path, nofollow: "" } }, [
              _vm._v(
                "\r\n                " + _vm._s(node.name) + "\r\n            "
              )
            ]),
            _vm._v(" "),
            node.title
              ? _c("span", { staticClass: "text-muted" }, [
                  _vm._v(
                    "\r\n                – " +
                      _vm._s(node.title) +
                      "\r\n            "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            node.path == _vm.path
              ? _c(
                  "span",
                  { staticClass: "my-sitemap-here" },
                  [
                    _c("feather-icon", {
                      attrs: { size: "18px", name: "arrow-left" }
                    }),
                    _vm._v(
                      "\r\n                you are here\r\n                "
                    ),
                    node.level != 0
                      ? _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.trash(node)
                              }
                            }
                          },
                          [
                            _c("feather-icon", {
                              attrs: { size: "18px", name: "trash" }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }