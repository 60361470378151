<template>
  <v-jstree
    class="gp-treeview"
    :no-dots="noDots"
    :data="data"
    :multiple="multiple"
    :show-checkbox="showCheckbox"
    :allow-transition="false"
    @item-click="onItemClick"
  >
    <template slot-scope="_">
      <feather-icon
        v-if="!_.model.loading"
        class="gp-treeview-icon"
        role="presentation"
        :name="_.model.icon || 'folder'"
        size="16px"
      />
      <span
        class="gp-treeview-item"
        v-html="_.model.text"
      />
    </template>
  </v-jstree>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    noDots: {
      type: Boolean,
      default: true,
    },

    showCheckbox: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onItemClick({ model }) {
      if (model.children.length) {
        this.toggle(model);
      } else {
        this.$emit('item-click', model);
      }
    },

    toggle({ opened, openChildren, closeChildren }) {
      if (opened) {
        closeChildren();
      } else {
        openChildren();
      }
    }
  }
};
</script>

<style scoped>
.gp-treeview >>> svg {
  vertical-align: initial;
}

.gp-treeview >>> .tree-anchor {
  display: inline-flex;
  align-items: center;
  padding: 14px 5px;
}

.gp-treeview >>> .feather-icon {
  margin-right: 4px;
}

.gp-treeview >>> .tree-selected {
  background: transparent;
}

.gp-treeview >>> .tree-hovered {
  background: #3498db10;
}

.gp-treeview >>> .gp-treeview-icon {
  color: var(--cyan);
  flex: 0 0 16px;
}

.gp-treeview >>> .gp-treeview-item {
  flex: 0 0 calc(100% - 16px);
}

.gp-treeview >>> .tree-anchor {
  width: 100%;
}

.gp-treeview >>> .tree-ocl {
  display: none;
}
</style>
