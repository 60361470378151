var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gp-bounds" }, [
    _c(
      "div",
      {
        staticClass: "progress",
        style: { visibility: _vm.loading ? "visible" : "hidden" }
      },
      [
        _vm.loading
          ? _c("div", {
              staticClass:
                "progress-bar progress-bar-striped progress-bar-animated bg-info",
              style: { width: _vm.progress * 100 + "%" }
            })
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _vm.report
      ? _c(
          "div",
          { class: { loading: _vm.loading } },
          [
            _c(
              "ul",
              { staticClass: "gp-bounds-runs" },
              _vm._l(_vm.report.runs, function(run) {
                return _c(
                  "li",
                  { key: run.id },
                  [
                    _c("l10n", {
                      attrs: {
                        value: "{strategy}, optimized by {user} at {time}",
                        strategy: run.strategy.name,
                        user: run.user,
                        time: _vm.formatTime(run.time)
                      }
                    })
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "gp-bounds-legend" }, [
              _vm.report.result.currentPrice
                ? _c(
                    "div",
                    { staticClass: "gp-bounds-legend-current-price" },
                    [
                      _c("l10n", { attrs: { value: "Current price" } }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.formatPrice(_vm.report.result.currentPrice)
                          ) +
                          "\n      "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.report.result.modifiedCurrentPrice
                ? _c(
                    "div",
                    { staticClass: "gp-bounds-legend-modified-current-price" },
                    [
                      _c("l10n", {
                        attrs: { value: "Modified current price" }
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.formatPrice(
                              _vm.report.result.modifiedCurrentPrice
                            )
                          ) +
                          "\n      "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.report.result.optimalPrice
                ? _c(
                    "div",
                    { staticClass: "gp-bounds-legend-optimal-price" },
                    [
                      _c("l10n", { attrs: { value: "Optimal price" } }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.formatPrice(_vm.report.result.optimalPrice)
                          ) +
                          "\n      "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.report.result.finalPrice
                ? _c(
                    "div",
                    { staticClass: "gp-bounds-legend-final-price" },
                    [
                      _c("l10n", { attrs: { value: "Final price" } }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.formatPrice(_vm.report.result.finalPrice)
                          ) +
                          "\n      "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.report.result.markdown
                ? _c(
                    "div",
                    { staticClass: "gp-bounds-legend-markdown-price" },
                    [_c("l10n", { attrs: { value: "Markdown price" } })],
                    1
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.report.result.markdown
              ? _c(
                  "div",
                  { staticClass: "gp-bounds-markdown" },
                  [
                    _c("series-chart", {
                      ref: "markdownChart1",
                      attrs: {
                        stream: _vm.stream,
                        groups: _vm.groups,
                        dims: ["type", "date"],
                        vals: ["price"],
                        throttled: false,
                        elasticX: true,
                        elasticY: true,
                        marginTop: 0,
                        marginLeft: 60,
                        marginRight: 50,
                        marginBottom: 60,
                        yAxisPadding: "10%",
                        height: 200,
                        yTicks: 5,
                        xTicks: 10,
                        rightYTicks: 5,
                        xTickAngle: -90,
                        x: "d3.scaleTime()",
                        yTickFormat: "d3.format('$,.2f')",
                        brushOn: false,
                        title: "(d) => d.value[0]",
                        renderVerticalGridLines: true,
                        renderHorizontalGridLines: true,
                        clipPadding: 10,
                        valueAccessor: "(d) => d.value[0]",
                        transitionDuration: 0,
                        renderLegend: false,
                        curve: "d3.curveStepAfter",
                        renderDataPoints: {
                          radius: 2,
                          fillOpacity: 1,
                          strokeOpacity: 0
                        },
                        provider: _vm.markdownChart1Provider,
                        colors: _vm.markdownChart1Colors,
                        seriesSort: _vm.markdownChart1SeriesSort,
                        id: "markdownChart1"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "composite-chart",
                      {
                        attrs: {
                          stream: _vm.stream,
                          groups: _vm.groups,
                          dims: ["type", "date"],
                          vals: ["value"],
                          throttled: false,
                          elasticX: true,
                          elasticY: true,
                          marginTop: 60,
                          marginLeft: 60,
                          marginRight: 50,
                          marginBottom: 60,
                          yAxisPadding: "10%",
                          height: 260,
                          yTicks: 5,
                          xTicks: 10,
                          rightYTicks: 5,
                          xTickAngle: -90,
                          x: "d3.scaleTime()",
                          yTickFormat: "d3.format('$,.0f')",
                          rightYTickFormat: "d3.format(',.0f')",
                          brushOn: false,
                          title: "(d) => d.value[0]",
                          renderVerticalGridLines: true,
                          renderHorizontalGridLines: true,
                          clipPadding: 10,
                          valueAccessor: "(d) => d.value[0]",
                          transitionDuration: 0,
                          renderLegend: true,
                          curve_: "d3.curveStepAfter",
                          renderDataPoints: {
                            radius: 1,
                            fillOpacity: 1,
                            strokeOpacity: 0
                          },
                          id: "markdownChart2"
                        }
                      },
                      [
                        _c("line-chart", {
                          attrs: {
                            stream: _vm.stream,
                            groups: _vm.groups,
                            dims: ["date"],
                            vals: ["Inventory"],
                            throttled: false,
                            transitionDuration: 0,
                            renderDataPoints: {
                              radius: 1,
                              fillOpacity: 1,
                              strokeOpacity: 0
                            },
                            provider: _vm.markdownChart2ProviderInventory,
                            colors: "() => 'var(--orange)'",
                            brushOn: false,
                            useRightYAxis: true,
                            renderTitle: true,
                            xyTipsOn: true
                          }
                        }),
                        _vm._v(" "),
                        _c("line-chart", {
                          attrs: {
                            stream: _vm.stream,
                            groups: _vm.groups,
                            dims: ["date"],
                            vals: ["Revenue"],
                            throttled: false,
                            transitionDuration: 0,
                            renderDataPoints: {
                              radius: 1,
                              fillOpacity: 1,
                              strokeOpacity: 0
                            },
                            provider: _vm.markdownChart2ProviderRevenue,
                            colors: "() => 'var(--teal)'",
                            brushOn: false,
                            renderTitle: true,
                            xyTipsOn: true
                          }
                        }),
                        _vm._v(" "),
                        _c("line-chart", {
                          attrs: {
                            stream: _vm.stream,
                            groups: _vm.groups,
                            dims: ["date"],
                            vals: ["Profit"],
                            throttled: false,
                            transitionDuration: 0,
                            renderDataPoints: {
                              radius: 1,
                              fillOpacity: 1,
                              strokeOpacity: 0
                            },
                            provider: _vm.markdownChart2ProviderProfit,
                            colors: "() => 'var(--red)'",
                            brushOn: false,
                            renderTitle: true,
                            xyTipsOn: true
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "gp-check",
              {
                attrs: { checked: _vm.selectedStage == "currentPrice" },
                on: {
                  click: function($event) {
                    _vm.selectedStage =
                      _vm.selectedStage == "currentPrice"
                        ? "finalPrice"
                        : "currentPrice"
                  }
                }
              },
              [
                _c("l10n", {
                  attrs: { value: "Show price bounds for current price" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.opendedRelationRule
              ? _c(
                  "my-dialog",
                  {
                    attrs: { title: "Price rule relations", large: true },
                    on: {
                      close: function($event) {
                        _vm.opendedRelationRule = null
                      }
                    }
                  },
                  [
                    _c("gp-bounds-related", {
                      attrs: {
                        stream: _vm.stream,
                        groups: _vm.groups,
                        rule: _vm.opendedRelationRule,
                        formats: _vm.formats,
                        formulas: _vm.formulas,
                        attributes: _vm.attributes,
                        metrics: _vm.metrics,
                        timeframes: _vm.timeframes,
                        "related-metrics": _vm.relatedMetrics
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("table", [
              _c(
                "tbody",
                [
                  _vm._l(_vm.report.rows, function(row) {
                    return row.stage === _vm.selectedStage && row.status
                      ? [
                          _c(
                            "tr",
                            {
                              key: "" + row.rule_id,
                              class: { strict: row.rule.strict }
                            },
                            [
                              _c(
                                "td",
                                {
                                  attrs: {
                                    title: _vm.formatRule(
                                      row.rule,
                                      row.leftBound,
                                      row.rightBound,
                                      row.centroid
                                    )
                                  }
                                },
                                [
                                  row.error !== undefined
                                    ? [
                                        row.error < 0.009999
                                          ? _c("feather-icon", {
                                              attrs: { name: "check" }
                                            })
                                          : _c("feather-icon", {
                                              attrs: { name: "alert-circle" }
                                            })
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$set(
                                            _vm.expandedRules,
                                            row.rule.id,
                                            !_vm.expandedRules[row.rule.id]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(row.rule.name) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _c("div", { staticClass: "gp-bounds-rule" }, [
                                  row.leftBound || row.rightBound
                                    ? _c("div", {
                                        staticClass: "gp-bounds-bounds",
                                        style: {
                                          left:
                                            "calc(" +
                                            _vm.scale(row.leftBound) * 100 +
                                            "%",
                                          width:
                                            "calc(" +
                                            (_vm.scale(row.rightBound) -
                                              _vm.scale(row.leftBound)) *
                                              100 +
                                            "%"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.finalPrice
                                    ? _c("div", {
                                        staticClass: "gp-bounds-final-price",
                                        style: {
                                          left:
                                            _vm.scale(row.finalPrice) * 100 +
                                            "%"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.optimalPrice
                                    ? _c("div", {
                                        staticClass: "gp-bounds-optimal-price",
                                        style: {
                                          left:
                                            _vm.scale(row.optimalPrice) * 100 +
                                            "%"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.modifiedCurrentPrice
                                    ? _c("div", {
                                        staticClass:
                                          "gp-bounds-modified-current-price",
                                        style: {
                                          left:
                                            _vm.scale(
                                              row.modifiedCurrentPrice
                                            ) *
                                              100 +
                                            "%"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.currentPrice
                                    ? _c("div", {
                                        staticClass: "gp-bounds-current-price",
                                        style: {
                                          left:
                                            _vm.scale(row.currentPrice) * 100 +
                                            "%"
                                        }
                                      })
                                    : _vm._e()
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm.expandedRules[row.rule.id]
                            ? _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: "2" } },
                                  [
                                    row.rule.weight !== undefined &&
                                    row.rule.type !== "rounding" &&
                                    row.rule.type !== "allowable_percent"
                                      ? _c(
                                          "span",
                                          { staticClass: "gp-bounds-weight" },
                                          [
                                            _c("l10n", {
                                              attrs: { value: "rule weight" }
                                            }),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.formatPercent(
                                                    row.rule.weight * 100
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.rule.filter.length > 0
                                      ? [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatRuleFilter(row.rule)
                                              )
                                            )
                                          ])
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.formatRule(
                                              row.rule,
                                              row.leftBound,
                                              row.rightBound,
                                              row.centroid
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    row.error >= 0.01
                                      ? _c(
                                          "p",
                                          [
                                            _c("l10n", {
                                              attrs: {
                                                value:
                                                  "price rule violation {error}",
                                                error: _vm.formatPrice(
                                                  row.error
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.rule.type === "same_price" ||
                                    row.rule.type === "relations"
                                      ? _c("p", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                "aria-label":
                                                  "Show price relations",
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.opendedRelationRule =
                                                    row.rule
                                                }
                                              }
                                            },
                                            [
                                              _c("l10n", {
                                                attrs: {
                                                  value: "Show price relations"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ])
                            : _vm._e()
                        ]
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm.modelSeries
                    ? _vm._l(["margin", "value", "units"], function(metric) {
                        return _c("tr", [
                          _c(
                            "td",
                            [
                              _c("l10n", {
                                attrs: { value: metric + " profile" }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "svg",
                              {
                                staticClass: "gp-bounds-series",
                                attrs: { viewBox: "0 0 104 30", width: "100%" }
                              },
                              [
                                _c("path", {
                                  attrs: { d: _vm.modelSeries[metric].axis }
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: { d: _vm.modelSeries[metric].fill }
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: { d: _vm.modelSeries[metric].line }
                                }),
                                _vm._v(" "),
                                _vm.modelSeries[metric].best
                                  ? _c("svg-title", [
                                      _c("circle", {
                                        attrs: {
                                          cx: _vm.modelSeries[metric].best.x,
                                          cy: _vm.modelSeries[metric].best.y,
                                          title:
                                            _vm.modelSeries[metric].best.title,
                                          r: "3"
                                        }
                                      })
                                    ])
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "gp-bounds-rule" }, [
                              _vm.report.result.finalPrice
                                ? _c("div", {
                                    staticClass: "gp-bounds-final-price",
                                    style: {
                                      left:
                                        _vm.scale(
                                          _vm.report.result.finalPrice
                                        ) *
                                          100 +
                                        "%"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.report.result.optimalPrice
                                ? _c("div", {
                                    staticClass: "gp-bounds-optimal-price",
                                    style: {
                                      left:
                                        _vm.scale(
                                          _vm.report.result.optimalPrice
                                        ) *
                                          100 +
                                        "%"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.report.result.modifiedCurrentPrice
                                ? _c("div", {
                                    staticClass:
                                      "gp-bounds-modified-current-price",
                                    style: {
                                      left:
                                        _vm.scale(
                                          _vm.report.result.modifiedCurrentPrice
                                        ) *
                                          100 +
                                        "%"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.report.result.currentPrice
                                ? _c("div", {
                                    staticClass: "gp-bounds-current-price",
                                    style: {
                                      left:
                                        _vm.scale(
                                          _vm.report.result.currentPrice
                                        ) *
                                          100 +
                                        "%"
                                    }
                                  })
                                : _vm._e()
                            ])
                          ])
                        ])
                      })
                    : _vm._e()
                ],
                2
              )
            ]),
            _vm._v(" "),
            _vm.report.elast
              ? _c(
                  "p",
                  [
                    _c("l10n", { attrs: { value: "Elasticity:" } }),
                    _vm._v(
                      "\n      " +
                        _vm._s(new Number(_vm.report.elast).toLocaleString()) +
                        "\n    "
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }