const utils = require('../../my-utils');

const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

const dateParser = (key, value) => {
// first, just make sure the property is a string:
  if (typeof value === 'string') {
    // then, use regex to see if it's an ISO-formatted string
    let a = reISO.exec(value);
    if (a) {
    // if so, Date() can parse it:
      return new Date(value);
    }
    // otherwise, see if it's a wacky Microsoft-format string:
    a = reMsAjax.exec(value);
    if (a) {
    // and perform some jujitsu to make use of it:
      const b = a[1].split(/[-+,.]/);
      return new Date(b[0] ? +b[0] : 0 - +b[1]);
    }
    // here, you could insert any additional tests and parse instructions you like, for other date syntaxes...
  }

  // important: you need to return any values you're not parsing, or they die...
  return value;
};

module.exports.dateReplacer = (key, value) => {
  if (key === 'md_dates') {
    return value.map((x) => utils.formatDate(x));
  }

  return value;
};

module.exports.loadConfig = (localStorageKey) => {
  const rawConfig = window.localStorage.getItem(localStorageKey) || '';

  try {
    return JSON.parse(rawConfig, dateParser) || { sections: [], filter: [] };
  } catch (e) {
    window.localStorage.setItem(localStorageKey, '');
  }

  return { sections: [], filter: [] };
};

module.exports.saveConfig = (localStorageKey, config) => {
  window.localStorage.setItem(localStorageKey, JSON.stringify(config));
};
