<template>
  <div class="empty-card">
    <button
      class="empty-card__retry-btn"
      @click="redirectToDirectory"
      type="button">
      <add-icon />
    </button>
    <p class="empty-card__text">
      {{ localizeValue(text) }}
    </p>
  </div>
</template>

<script>
const utils = require('../../my-utils');
const addIcon = require('../../icons/product-add-icon.vue').default;

module.exports = {
  components: {
    'add-icon': addIcon,
  },
  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    redirectToDirectory() {
      this.$emit('on-redirect');
    },
    localizeValue(value) {
      return utils.l10n(value);
    },
  },
};
</script>

<style scoped>
.empty-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
}
.empty-card__retry-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 28px;
  border-radius: 50%;
  background-color: #F7F7F7;
  outline: none;
  border: none;
  color:#AFAFAF;
}
.empty-card__retry-btn:hover {
  background-color: #E9E9E9;
}
.empty-card__text {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #9B9B9B;
}
</style>
