<template>
  <div class="timeframe-select">
    <gp-treeview
      :key="key"
      :data="treeData"
      @item-click="onItemClick"
    />
  </div>
</template>

<script>
const utils = require('../my-utils');

module.exports = {
  mixins: [
    utils.referenceDateHelper,
  ],

  props: {
    username: {
      type: String,
      default: null,
    },

    selectedTimeframe: {
      type: String,
      default: '',
    },

    timeframes: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      key: 0,
    };
  },

  computed: {
    treeData() {
      const tfs = _(this.timeframes)
        .toPairs()
        .map(([, { id, name, calc, group }]) => ({ id, text: name, calc, group }))
        .value();

      const result = tfs.reduce((acc, curr) => {
        const { id, text, calc, group } = curr;
        const icon = id === this.selectedTimeframe ? 'check' : 'none';

        if (!calc) {
          return acc;
        }

        try {
          const refDate = new Date(this.referenceDate);
          const [startDate, endDate] = eval(calc)(refDate);
          const el = { id, text, calc, icon, startDate, endDate, group };

          el.text = `<span>${utils.l10n(text)}</span><span>${startDate.toLocaleDateString()}`;
          if (Number(startDate) !== Number(endDate)) {
            el.text += ` - ${endDate.toLocaleDateString()}`;
          }
          el.text += '</span>';


          if (group) {
            const accGroup = acc.find((i) => i.text === group) || { text: group, children: []};
            const isNewGroup = accGroup.children.length === 0;
            accGroup.children.push(el);

            if (el.icon === 'check') {
              accGroup.icon = 'check';
            }

            if (isNewGroup) {
              acc.push(accGroup);
            }
          } else {
            acc.push(el);
          }

          return acc;
        } catch(e) {
          return acc;
        }
      }, []);

      this.key++;

      return result.sort((a, b) => (b?.children?.length || 0) - (a?.children?.length || 0));
    },
  },

  methods: {
    onItemClick({ id }) {
      this.$emit('select-timeframe', id);
    },
  },
};
</script>

<style scoped>
.timeframe-select {
  padding: 12px 12px 12px 2px;
  overflow-y: scroll;
  max-height: 400px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.timeframe-select::-webkit-scrollbar {
    width: 3px;
    background-color: #ffffff;
}
.timeframe-select::-webkit-scrollbar-thumb {
    width: 3px;
    border-radius: 50%;
    background-color: #E9E9E9;
}

.my-light-theme .timeframe-select {
  background: #fff;
  border: none;
  border-radius: 8px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.my-dark-theme .timeframe-select {
  background: #222;
  border: none;
  border-radius: 8px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.timeframe-select >>> .gp-treeview-item {
  display: flex;
  justify-content: space-between;
}

.timeframe-select >>> .gp-treeview-item > span:nth-child(1) {
  margin-right: 20px;
}
</style>
