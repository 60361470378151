var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "ao-chart": true, "ao-chart-compact": _vm.compact } },
    [
      _c("date-filter", {
        attrs: {
          stream: _vm.stream,
          function: "date",
          notBefore: _vm.notBefore,
          notAfter: _vm.notAfter,
          groups: ["timerange"]
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "multiselect-container" },
        [
          _c("my-multiselect", {
            staticStyle: { width: "300px" },
            attrs: {
              options: _vm.suppliers,
              selectedOption: _vm.suppliers[0],
              searchable: "",
              placeholder: "Выберите поставщика"
            }
          }),
          _vm._v(" "),
          _c("my-multiselect", {
            staticStyle: { width: "535px" },
            attrs: {
              options: _vm.products,
              selectedOption: _vm.products[0],
              searchable: "",
              placeholder: "Выберите товар"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("my-tooltip", {
        ref: "tooltip",
        attrs: {
          meta: _vm.meta,
          chart: _vm.chart,
          accessor: _vm.tooltipAccessor,
          actions: _vm.actions
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { ref: "base", staticClass: "my-chart" },
        [
          _c("gp-data", {
            ref: "data",
            attrs: {
              id: "gp-charts",
              controls: false,
              stream: _vm.stream,
              source: _vm.source,
              groups: _vm.groups,
              dims: _vm.dims,
              vals: _vm.vals,
              cols: _vm.cols,
              filter1: _vm.filter1,
              filter2: _vm.filter2,
              instant: _vm.instant,
              throttled: _vm.throttled
            },
            on: {
              report: function($event) {
                _vm.report = $event
              },
              reportId: function($event) {
                _vm.reportId = $event
              }
            }
          }),
          _vm._v(" "),
          _c("div", {
            ref: "chart",
            style: { opacity: _vm.reportId ? 0.3 : 1 }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }