/* eslint-disable consistent-return */
const initialRow = {
  nodeType: null, // 'node' | 'leaf'
  id: null,
  level: null,
  isOpen: false,
};

const getRowFromNode = (node, { parentRowIds = [] }) => {
  const row = { ...initialRow, ...node };

  row.nodeType = 'node';
  row.parentIds = parentRowIds.length && node?.parent_id ? parentRowIds : [];
  row.name = node.category?.name;
  row.id = node.id;
  return row;
};

const getRowFromLeaf = (leaf, { parentRowIds, parentRowLevel }) => {
  const row = { ...initialRow, ...leaf };
  delete row.isOpen;

  row.nodeType = 'leaf';
  row.id = leaf.id;
  row.name = leaf.name;
  row.code = leaf.code;
  row.parentIds = parentRowIds;
  row.level = parentRowLevel + 1;

  return row;
};

const getTableRows = (collection, options) => {
  const { parentRowIds, parentRowLevel, leafsFieldName } = options || {};
  const nodes = collection?.nodes || [];
  const leafs = collection?.[leafsFieldName] || [];
  const result = [];

  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];

    result.push(getRowFromNode(node, { parentRowIds }));
  }

  for (let i = 0; i < leafs.length; i += 1) {
    const leaf = leafs[i];

    result.push(getRowFromLeaf(leaf, { parentRowIds, parentRowLevel }));
  }

  return result;
};

const toggleOpenNode = ({ rows, rowIndex }) => {
  const result = [...rows];
  result[rowIndex].isOpen = !result[rowIndex].isOpen;
  return result;
};

const insertRows = ({ rows, childRows, rowIndex }) => {
  const sourceRows = [...rows];
  sourceRows.splice(rowIndex + 1, 0, ...childRows);
  return sourceRows;
};

const removeRows = ({ rows, row, rowIndex }) => {
  const rowId = row?.id;
  const firstHalf = [...rows].slice(0, rowIndex + 1);
  const secondHalf = [];

  for (let i = rowIndex + 1; i < rows.length; i += 1) {
    const target = rows[i];

    if (target.level >= row?.level && target.parentIds.includes(rowId)) {
      // eslint-disable-next-line no-continue
      continue;
    } else if (target.id === rowId && target.nodeType === 'node') {
      const rest = rows.slice(i);
      return [...firstHalf, ...secondHalf, ...rest];
    }

    secondHalf.push(target);
  }

  return [...firstHalf, ...secondHalf];
};

const getFullPath = ({ path, id }) => {
  let URL = path;

  if (id) {
    URL += `/${id}`;
  }

  return URL;
};

const isLastRowOnLevel = ({ row, rows, rowIndex }) => {
  const nextRow = rows[rowIndex + 1];

  if (!nextRow) {
    return true;
  }

  if (row.nodeType === 'node') {
    const hasNodesOnSameLevel = rows.slice(rowIndex + 1).filter((restRow) => (restRow.nodeType === 'node' && restRow.level <= row.level)).length;
    return !hasNodesOnSameLevel;
  }

  if (row.nodeType === 'leaf') {
    if (nextRow?.nodeType === 'node') {
      return true;
    }
    return false;
  }
};

const isRestRowsHasNodes = ({ rows, rowIndex }) => {
  const restRowsHasNodes = rows.slice(rowIndex + 1).filter((restRow) => restRow.nodeType === 'node').length;
  return !restRowsHasNodes;
};

module.exports = {
  getTableRows,
  insertRows,
  removeRows,
  toggleOpenNode,
  getFullPath,
  isLastRowOnLevel,
  isRestRowsHasNodes,
};
