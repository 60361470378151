import { render, staticRenderFns } from "./autoorder-empty-card.vue?vue&type=template&id=3b79644f&scoped=true&"
import script from "./autoorder-empty-card.vue?vue&type=script&lang=js&"
export * from "./autoorder-empty-card.vue?vue&type=script&lang=js&"
import style0 from "./autoorder-empty-card.vue?vue&type=style&index=0&id=3b79644f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b79644f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\aazat\\OneDrive\\Documents\\Repositories\\Goalprofit\\gloriajeans_frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3b79644f')) {
      api.createRecord('3b79644f', component.options)
    } else {
      api.reload('3b79644f', component.options)
    }
    module.hot.accept("./autoorder-empty-card.vue?vue&type=template&id=3b79644f&scoped=true&", function () {
      api.rerender('3b79644f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "public/autoorder/components/autoorder-empty-card.vue"
export default component.exports