var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rules-card" }, [
    _c("header", { staticClass: "header" }, [
      _c("div", { staticClass: "header__title-block" }, [
        _c("button", {
          staticClass: "button-return",
          attrs: { type: "button" },
          on: { click: _vm.redirectToDirectory }
        }),
        _vm._v(" "),
        _vm.cardExist
          ? _c("p", { staticClass: "header-title" }, [
              _vm._v(_vm._s(_vm.cardTitle))
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "header__search-form" },
        [_c("autoorder-search")],
        1
      )
    ]),
    _vm._v(" "),
    _vm.isCardEmpty
      ? _c(
          "main",
          { staticClass: "empty-card-container" },
          [
            _c("autoorder-empty-card", {
              attrs: { text: "Select a card in the registry" },
              on: { "on-redirect": _vm.redirectToDirectory }
            })
          ],
          1
        )
      : _vm.hasError
      ? _c(
          "main",
          { staticClass: "error-card-container" },
          [
            _c("autoorder-error-card", {
              on: { "on-reload": _vm.reloadWindow }
            })
          ],
          1
        )
      : _c("main", { staticClass: "main" }, [
          _c("h3", { staticClass: "main__title" }, [
            _vm._v("Атрибуты документа")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "main__sections" }, [
            _c("section", { staticClass: "main__section" }, [
              !_vm.isNewRules && !_vm.isRulesDuplicated
                ? _c("div", { staticClass: "inputblock" }, [
                    _c(
                      "label",
                      {
                        staticClass: "inputblock__label",
                        attrs: { for: "rulesId" }
                      },
                      [_vm._v("\n            ID документа\n          ")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.rulesId,
                          expression: "rulesId"
                        }
                      ],
                      staticClass: "inputblock__input input",
                      attrs: { id: "rulesId", disabled: "" },
                      domProps: { value: _vm.rulesId },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.rulesId = $event.target.value
                        }
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "inputblock" }, [
                _c(
                  "label",
                  {
                    staticClass: "inputblock__label",
                    attrs: { for: "dateFrom" }
                  },
                  [_c("l10n", { attrs: { value: "Дата действия с" } })],
                  1
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.dateFrom,
                      expression: "dateFrom"
                    }
                  ],
                  staticClass: "inputblock__input input",
                  attrs: {
                    type: "date",
                    id: "dateFrom",
                    placeholder: _vm.localizeValue("Select date")
                  },
                  domProps: { value: _vm.dateFrom },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.dateFrom = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "inputblock" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "inputblock__label",
                      attrs: { for: "linksTo" }
                    },
                    [_vm._v("Ссылка на параметр")]
                  ),
                  _vm._v(" "),
                  _c("my-multiselect", {
                    class: {
                      invalid: _vm.isLinksToNotChecked && _vm.tryToSend
                    },
                    attrs: {
                      id: "linksTo",
                      options: _vm.allLinksTo,
                      closeOnSelect: false,
                      loading: _vm.selectLoading === "linksTo",
                      label: "name",
                      "track-by": "id",
                      placeholder: "Выберите ссылку на параметр"
                    },
                    on: {
                      open: _vm.getLinksTo,
                      select: function($event) {
                        _vm.formWasEdited = true
                      }
                    },
                    model: {
                      value: _vm.rulesLinksTo,
                      callback: function($$v) {
                        _vm.rulesLinksTo = $$v
                      },
                      expression: "rulesLinksTo"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "inputblock" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "inputblock__label",
                      attrs: { for: "products" }
                    },
                    [_vm._v("Выбор товара(ов)")]
                  ),
                  _vm._v(" "),
                  _c("my-multiselect", {
                    class: {
                      invalid: _vm.isProductsNotChecked && _vm.tryToSend
                    },
                    attrs: {
                      id: "products",
                      options: _vm.allProducts,
                      closeOnSelect: false,
                      loading: _vm.selectLoading === "products",
                      label: "name",
                      "track-by": "id",
                      placeholder: "Выберите товар(ы)"
                    },
                    on: {
                      open: _vm.getProducts,
                      select: function($event) {
                        _vm.formWasEdited = true
                      }
                    },
                    model: {
                      value: _vm.rulesProducts,
                      callback: function($$v) {
                        _vm.rulesProducts = $$v
                      },
                      expression: "rulesProducts"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "inputblock" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "inputblock__label",
                      attrs: { for: "warehouses" }
                    },
                    [_vm._v("Выбор объекта(ов)")]
                  ),
                  _vm._v(" "),
                  _c("my-multiselect", {
                    class: {
                      invalid: _vm.isWarehousesNotChecked && _vm.tryToSend
                    },
                    attrs: {
                      id: "warehouses",
                      options: _vm.allWarehouses,
                      closeOnSelect: false,
                      loading: _vm.selectLoading === "warehouses",
                      label: "name",
                      "track-by": "id",
                      placeholder: "Выберите объект(ы)"
                    },
                    on: {
                      open: _vm.getWarehouses,
                      select: function($event) {
                        _vm.formWasEdited = true
                      }
                    },
                    model: {
                      value: _vm.rulesWarehouses,
                      callback: function($$v) {
                        _vm.rulesWarehouses = $$v
                      },
                      expression: "rulesWarehouses"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "main__section" }, [
              _c("div", { staticClass: "inputblock" }, [
                _c(
                  "label",
                  {
                    staticClass: "inputblock__label",
                    attrs: { for: "rulesNote" }
                  },
                  [_vm._v("\n            Описание\n          ")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.rulesNote,
                      expression: "rulesNote"
                    }
                  ],
                  staticClass: "inputblock__input input",
                  attrs: {
                    id: "rulesNote",
                    placeholder: _vm.localizeValue("Enter the description")
                  },
                  domProps: { value: _vm.rulesNote },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.rulesNote = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "inputblock" }, [
                _c(
                  "label",
                  {
                    staticClass: "inputblock__label",
                    attrs: { for: "dateTo" }
                  },
                  [_c("l10n", { attrs: { value: "Дата действия по" } })],
                  1
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.dateTo,
                      expression: "dateTo"
                    }
                  ],
                  staticClass: "inputblock__input input",
                  attrs: {
                    type: "date",
                    id: "dateTo",
                    placeholder: _vm.localizeValue("Select date")
                  },
                  domProps: { value: _vm.dateTo },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.dateTo = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              !_vm.isNewRules && !_vm.isRulesDuplicated
                ? _c("div", { staticClass: "inputblock" }, [
                    _c(
                      "label",
                      {
                        staticClass: "inputblock__label",
                        attrs: { for: "dateCreate" }
                      },
                      [_c("l10n", { attrs: { value: "Дата создания" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.dateCreate,
                          expression: "dateCreate"
                        }
                      ],
                      staticClass: "inputblock__input input",
                      attrs: {
                        type: "date",
                        id: "dateCreate",
                        placeholder: _vm.localizeValue("Select date"),
                        disabled: ""
                      },
                      domProps: { value: _vm.dateCreate },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.dateCreate = $event.target.value
                        }
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNewRules && !_vm.isRulesDuplicated
                ? _c("div", { staticClass: "inputblock" }, [
                    _c(
                      "label",
                      {
                        staticClass: "inputblock__label",
                        attrs: { for: "rulesAuthor" }
                      },
                      [_vm._v("\n            Автор\n          ")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.rulesAuthor,
                          expression: "rulesAuthor"
                        }
                      ],
                      staticClass: "inputblock__input input",
                      attrs: { id: "rulesAuthor", disabled: "" },
                      domProps: { value: _vm.rulesAuthor },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.rulesAuthor = $event.target.value
                        }
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "inputblock" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "inputblock__label",
                      attrs: { for: "linkToPolitic" }
                    },
                    [_vm._v("Ссылка на политику")]
                  ),
                  _vm._v(" "),
                  _c("my-multiselect", {
                    class: {
                      invalid: _vm.islinkToPoliticNotChecked && _vm.tryToSend
                    },
                    attrs: {
                      id: "linkToPolitic",
                      options: _vm.allLinkToPolitic,
                      closeOnSelect: false,
                      loading: _vm.selectLoading === "linkToPolitic",
                      label: "name",
                      "track-by": "id",
                      placeholder: "Выберите политику"
                    },
                    on: {
                      open: _vm.getlinkToPolitic,
                      select: function($event) {
                        _vm.formWasEdited = true
                      }
                    },
                    model: {
                      value: _vm.ruleslinkToPolitic,
                      callback: function($$v) {
                        _vm.ruleslinkToPolitic = $$v
                      },
                      expression: "ruleslinkToPolitic"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]),
    _vm._v(" "),
    _vm.cardExist
      ? _c("footer", { staticClass: "footer" }, [
          _c(
            "div",
            { staticClass: "footer__checkbox" },
            [
              _c(
                "gp-check",
                {
                  attrs: { checked: _vm.isActiveCard },
                  on: { change: _vm.handleChangeActivity }
                },
                [
                  _vm._v("\n            \n        "),
                  _c("l10n", { attrs: { value: "Активный документ" } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "footer__buttons-block" }, [
            _c(
              "button",
              {
                staticClass: "button button_secondary",
                class: { button_disabled: _vm.isLoading },
                attrs: { type: "button", disabled: _vm.isLoading },
                on: { click: _vm.handleSecondaryButtonAction }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.secondaryButtonTitle) + "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button button_primary",
                class: { button_disabled: _vm.isLoading },
                attrs: { type: "button", disabled: _vm.isSubmitButtonDisabled },
                on: { click: _vm.submitRulesForm }
              },
              [_vm._v("\n        Сохранить\n      ")]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }