let utils = require("./my-utils")
require("./markdown.css")
require("./highlight.css")


module.exports = {
    props: {
        text:        { type: String, default: "" },
        data:        { type: Object, default: () => ({}) },
        hash:        { type: String },
        base:        { type: String },
        path:        { type: String },
        darkTheme:   { type: Boolean, default: false },
        bounds:      { type: String },
        config:      { type: Object, default: () => ({}) },
        editable:    { type: Boolean, default: false },
        autoHeight:  { type: Boolean, default: false },
        username:    { type: String, default: null },
        storageKey:  { type: String },

        html:        { type: Boolean,  default: true },
        xhtmlOut:    { type: Boolean,  default: false },
        breaks:      { type: Boolean,  default: true },
        langPrefix:  { type: String,   default: "language-" },
        linkify:     { type: Boolean,  default: true },
        typographer: { type: Boolean,  default: true },
        quotes:      { type: String,   default: '“”‘’' },
        highlight:   { type: Function, default: undefined },

        emoji:       { type: Boolean,  default: false },
        taskLists:   { type: Boolean,  default: false },

        storage:     { type: Object,   default: () => ({}) },
    },
    data() {
        return {
            props: {_:{}},
            hljs: null,
            hljsPromise: null,
            editing: false,
            editingText: "",
        }
    },
    mounted() {
        if (this.editingKey && localStorage[this.editingKey]) {
            this.editing = true
            this.editingText = localStorage[this.editingKey]
        }
    },
    watch: {
        props: {
            deep: true,
            handler() {
                utils.bridge.trigger("setInclude", this, this.props)
            }
        },
        editing() {
            if (this.editing) {
                this.$el.classList.add("editing")
                if (this.editingKey && !localStorage[this.editingKey])
                    localStorage[this.editingKey] = this.text
                this.$emit("editing")
            }
            else {
                this.$el.classList.remove("editing")
                if (this.editingKey)
                    delete localStorage[this.editingKey]
            }
        },
    },
    beforeDestroy() {
        utils.bridge.trigger("unsetInclude", this)
    },
    computed: {
        editingKey() {
            return this.path ? `markdown_editing_${this.path.replaceAll("/", "_")}` : null
        },
        ast() {
            if (this.editing) {
                return [
                    [{
                        tag: "div",
                        attrs: {
                            class: "markdown-actions",
                        },
                    }, [
                        [{
                            tag: "button",
                            attrs: { class: "btn btn-sm btn-primary" },
                            content: "OK (Cmd-Enter)",
                            on: { click: this.submitChanges },
                        }, []],
                        [{
                            tag: "button",
                            attrs: { class: "btn btn-sm btn-secondary" },
                            content: "Cancel (Esc)",
                            on: { click: this.discardChanges },
                        }, []]
                    ]],
                    [{
                        tag: "ace-editor",
                        ref: "editor",
                        on: {
                            loaded: editor => {
                                editor.focus()
                                editor.gotoLine(0,0)
                            },
                            change: text => {
                                if (this.editingKey)
                                    localStorage[this.editingKey] = text
                            }
                        },
                        props: {
                            mode: "markdown",
                            value: this.editingText,
                            darkTheme: this.darkTheme,
                            autoHeight: this.autoHeight,
                            commands: [{
                                name: "save",
                                exec: this.submitChanges,
                                bindKey: { win: "ctrl-s", mac: "cmd-s" },
                            }, {
                                name: "save",
                                exec: this.submitChanges,
                                bindKey: { win: "ctrl-enter", mac: "cmd-enter" },
                            }, {
                                name: "exit",
                                exec: this.discardChanges,
                                bindKey: { win: "esc", mac: "esc" },
                            }]
                        }
                    }, []]
                ]
            }   
            let ast = this.parseMarkdown(this.text || "")
            if (this.editable)
                ast = 
                [[{
                    tag: "a",
                    on: {
                        click: (e) => {
                            this.editing = true
                            this.editingText = this.text
                        }
                    },
                    attrs: {
                        href: "javascript:void(0)",
                        style: "position:absolute;z-index:10;right:20px;",
                    },
                }, [
                    [{
                        tag: "feather-icon",
                        props: { name: "edit", size: "20px" }
                    }, []]
                ]]].concat(ast)
            return ast
        }
    },
    methods: {
        submitChanges() {
            let text = this.$refs.editor.editor.getValue()
            if (text != this.text)
                this.$emit("edited", text)
            this.editing = false
        },
        discardChanges() {
            let text = this.$refs.editor.editor.getValue()
            if (this.text != text && window.confirm("Do you want to save changes?"))
                this.$emit("edited", text)
            this.editing = false
        },
        parseMarkdown: _.memoize(function(markdown) {
            let loop = (tokens) => {
                let stack = [[{tag:"div"},[]]]
                let openTag = null
                for (let token of tokens) {
                    switch (token.nesting) {
                        case 1:
                            stack.push([token,[]])
                            break
                        case 0:
                            if (token.type === "inline")
                                for (let node of loop(token.children))
                                    stack[stack.length-1][1].push(node)
                            else {
                                if (token.type == "html_block") {
                                    token.content = this.parseHTML(token.content)
                                    stack[stack.length-1][1].push([token,[]])
                                }
                                else if (token.type == "html_inline") {
                                    let content = this.parseHTML(token.content)
                                    let tag = content[0] ? content[0].tag : null
                                    if (tag) {
                                        if ([ // self closing html tags
                                            "area",
                                            "base",
                                            "br",
                                            "col",
                                            "embed",
                                            "hr",
                                            "img",
                                            "input",
                                            "link",
                                            "meta",
                                            "param",
                                            "source",
                                            "track",
                                            "wbr"].includes(tag))
                                        {
                                            stack[stack.length-1][1].push([content[0],[]])
                                        }
                                        else {
                                            openTag = tag
                                            stack.push([content[0],[]])
                                        }
                                    }
                                    else if (openTag) { 
                                        stack[stack.length-2][1].push(stack.pop())
                                        openTag = null
                                    }
                                    else {
                                        token.content = [token.content]
                                        stack[stack.length-1][1].push([token,[]])
                                    }
                                }
                                else {
                                    stack[stack.length-1][1].push([token,[]])
                                }
                            }
                            break
                        case -1:
                            stack[stack.length-2][1].push(stack.pop())
                            break
                    }
                }
                return stack.pop()[1]
            }
            let md = new MarkdownIt({
                html:        this.html,
                xhtmlOut:    this.xhtmlOut,
                breaks:      this.breaks,
                langPrefix:  this.langPrefix,
                linkify:     this.linkify,
                typographer: this.typographer,
                quotes:      this.quotes,
                highlight:   (str, lang) => {
                    return ""
                },
                emoji:       this.emoji,
                taskLists:   this.taskLists,
            })
            let env = {}
            let tokens = md.parse(markdown, env)
            let result = loop(tokens)
            return result
        }),
        parseHTML: _.memoize(function(html) {
            let stack = [{children:[]}]
            let parser = new htmlparser2.Parser({
                onopentag(tag, attrs) {
                    let node = {tag, attrs, children:[]}
                    stack[stack.length-1].children.push(node)
                    stack.push(node)
                },
                ontext(text) {
                    stack[stack.length-1].children.push(text)
                },
                onclosetag(tag) {
                    stack.pop()
                }
            }, {
                recognizeSelfClosing: true,
            })
            parser.write(html)
            parser.end()
            let {children} = stack.pop()
            return children
        }),
        eval: _.memoize(function(text) {
            return eval(text)
        }),
        renderHTML(h, children) {
            let loop = (node) => {
                if (_.isString(node))
                    return node
                if (!node.tag.match(/^[-_a-zA-Z0-9]+$/))
                    return ""
                let attrs = node.attrs
                let props = undefined
                let on = {}

                if (attrs.id)
                    props = _.clone(this.data[attrs.id]) || {}

                if (node.tag == "script" && _.isEmpty(attrs)) {
                    if (!this.scriptKeys)
                        this.scriptKeys = {}
                    let content = node.children.join()
                    let key = this.scriptKeys[content]
                    if (key === undefined)
                        key = this.scriptKeys[content] = utils.randomId()
                    attrs = _.clone(attrs)
                    attrs.key = key
                }

                if (_.isPlainObject(props)) {
                    let component = Vue.component(node.tag)
                    if (component !== undefined) {
                        props = _.assign({config:this.config}, this.config, props)
                        if (_.isArray(props.mixins)) {
                            let mixedProps = {}
                            for (let mixinName of props.mixins) {
                                let mixin = this.data[mixinName]
                                if (_.isPlainObject(mixin))
                                    _.assign(mixedProps, mixin)
                            }
                            _.assign(mixedProps, props)
                            props = mixedProps
                        }
                        props.darkTheme = this.darkTheme
                        props.username = this.username
                        props.hash = this.hash
                        props.bounds = this.bounds

                        if (this.storageKey) {
                            if (props.storageKey)
                                props.storageKey = `${this.storageKey}.${props.storageKey}`
                            else
                                props.storageKey = this.storageKey
                        }

                        for (let key of _.keys(component.options.props)) {
                            let prop = component.options.props[key]
                            if (component.options.model && component.options.model.prop == key) {
                                on[component.options.model.event] = (event) => {
                                    if (!this.storage[attrs.id])
                                        this.$set(this.storage, attrs.id, {})
                                    if (!_.isEqual(this.storage[attrs.id][key], event))
                                        this.$set(this.storage[attrs.id], key, event)
                                    // this.$forceUpdate()
                                }
                            }
                            if (this.storage[attrs.id]) {
                                let value = this.storage[attrs.id][key]
                                if (value !== undefined)
                                    props[key] = value
                            }
                            let value = props[key]
                            if (value != null) {
                                if (_.isArray(prop.type) ? !_.includes(prop.type, value.constructor) : value.constructor !== prop.type) {
                                    console.warn(`property ${key} ignored: expected ${[].concat(prop.type).map((type) => type.name).join(" or ")} got ${value.constructor.name}:`, value)
                                    delete props[key]
                                }
                            }
                            else {
                                delete props[key]
                            }
                        }
                    }
                }
                // if (!_.isEmpty(props))

                _(attrs)
                    .toPairs()
                    .filter(([name]) => name.match(/^@[-_a-zA-Z0-9]+$/))
                    .forEach(([name, func]) => {
                        try {
                            on[name.slice(1)] = (a,b,c,d,e) => {
                                if (_.isString(func))
                                    func = eval(func)
                                if (_.isFunction(func))
                                    func(a,b,c,d,e)
                            }
                        }
                        catch (ex) {
                            console.warn(value)
                            console.warn(ex)
                        }
                    })

                attrs = _(attrs)
                    .toPairs()
                    .filter(([name]) => name.match(/^[:]?[-_a-zA-Z0-9]+$/))
                    .map(([name, value]) => {
                        if (name.match(/^[:].+/)) {
                            try {
                                value = eval(value)
                                return [name.slice(1), value]
                            }
                            catch (ex) {
                                console.warn("failed to evalue attribute", name, value, ex.message)
                            }
                        }
                        else if (name == "v-model") {
                            let component = Vue.component(node.tag)
                            if (component && component.options.model) {
                                if (props === undefined)
                                    props = {}
                                props[component.options.model.prop] = _.get(this.data, value)
                                let prevHandler = on[component.options.model.event]
                                on[component.options.model.event] = event => {
                                    if (_.isFunction(prevHandler))
                                        prevHandler(event)
                                    if (!_.isEqual(_.get(this.props, value), event)) {
                                        let dest = this.props
                                        let steps = value.split(".")
                                        for (let i=0; i<steps.length; ++i) {
                                            let step = steps[i]
                                            if (i == steps.length-1)
                                                this.$set(dest, step, event)
                                            else {
                                                if (!_.isObject(dest[step]))
                                                    this.$set(dest, step, {})
                                                dest = dest[step]    
                                            }
                                        }
                                    }
                                }
                            }
                            if (node.tag == "input" ||
                                node.tag == "select" ||
                                node.tag == "textarea")
                            {
                                if (props === undefined)
                                    props = {}
                                let prop = "value"
                                props[prop] = _.get(this.data, value) || ""
                                on["change"] = event => {
                                    if (!_.isEqual(_.get(this.props, value), event.target[prop])) {
                                        let dest = this.props
                                        let steps = value.split(".")
                                        for (let i=0; i<steps.length; ++i) {
                                            let step = steps[i]
                                            if (i == steps.length-1)
                                                this.$set(dest, step, event.target[prop])
                                            else {
                                                if (!_.isObject(dest[step]))
                                                    this.$set(dest, step, {})
                                                dest = dest[step]    
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        else
                            return [name, value]
                    })
                    .filter()
                    .fromPairs()
                    .value()

                if (node.tag == "input" && props) {
                    if (attrs["type"] == "date" && _.isDate(props.value))
                        props.value = utils.formatDate(props.value)
                    if (attrs["type"] == "datetime-local" && _.isDate(props.value))
                        props.value = `${utils.formatDate(props.value)}T${utils.formatTime(props.value)}`
                    attrs.value = props.value
                }

                let children = node.children.map(loop)
                if (node.tag == "textarea" && props?.value)
                    children = [props.value]
                if (node.tag == "textarea" && attrs?.value)
                    children = [attrs.value]

                return h(
                    node.tag,
                    {attrs, props, on, key:attrs.key},
                    children)
            }
            return children.map(loop)
        },
    },
    render(h) {
        let loop = ([token, children]) => {
            switch (token.type) {
                case "text":
                    return token.content

                case "html_block":
                    return this.renderHTML(h, token.content)

                default:
                    if (!this.breaks && token.tag == "br" && token.type == "softbreak")
                        return

                    if (token.tag !== "") {
                        let attrs = token.attrs
                        let props = token.props
                        let on = token.on
                        let key = token.key || attrs?.key
                        let ref = token.ref
                        if (_.isArray(attrs))
                            attrs = _.fromPairs(attrs)
                        children = children.map(loop)
                        if (token.content)
                            children.push(token.content)
                        let domProps = undefined
                        if (token.type == "fence") {
                            if (!attrs)
                                attrs = {}
                            attrs["data-type"] = "fence"
                            if (token.tag == "code") {
                                attrs["class"] = "hljs"
                                if (token.info) {
                                    if (this.hljs) {
                                        if (this.hljs.getLanguage(token.info)) {
                                            let html = this.hljs.highlight(token.content, { language: token.info }).value
                                            domProps = { innerHTML: html }
                                            children = undefined
                                        }
                                    }
                                    else {
                                        if (!this.hljsPromise)
                                            this.hljsPromise = import("highlight.js").then(hljs => this.hljs = hljs)
                                    }
                                }
                            }
                        }
                        if (this.base && attrs?.href) {
                            if (!attrs.href.match(/^([a-z]+:\/\/|\/|javascript:|#)/))
                                attrs.href = `${this.base.replace(/\/[^/]*$/, "")}/${attrs.href}`
                        }
                        return h(token.tag, {props, attrs, key, ref, on, domProps}, children)
                    }
                    else
                        console.warn("unexpected token", {token})
            }
        }
        return h("div", this.ast.map(loop))
    },
}
