<template>
  <div :class="rootClass">
    <div
      ref="contentPanel"
      class="my-content-panel"
    >
      <div class="my-container">
        <markdown
          class="my-content"
          :dark-theme="darkTheme"
          :config="config"
          :username="username"
          :bounds="bounds"
          :data="dataThrottled"
          :text="textThrottled"
          :hash="hash"
          :storage="storage || {}"
          :storage-key="storageKey"
        />
      </div>
    </div>

    <div class="my-settings">
      <span
        v-if="pageWarning"
        id="my-page-warning"
      >
        <feather-icon name="alert-triangle" />
        <span>{{ pageWarning }}</span>
      </span>

      <feather-icon name="menu" />

      <div class="form-group">
        <div class="form-check">
          <input
            id="editMode"
            v-model="editMode"
            class="form-check-input"
            type="checkbox"
          >

          <label
            class="form-check-label"
            for="editMode"
          >
            <l10n value="design mode" />
          </label>
        </div>

        <div class="form-check">
          <input
            id="darkTheme"
            v-model="darkTheme"
            class="form-check-input"
            type="checkbox"
          >

          <label
            class="form-check-label"
            for="darkTheme"
          >
            <l10n value="dark theme" />
          </label>
        </div>

        <div class="form-check">
          <input
            id="l10nEnable"
            v-model="l10nEnable"
            class="form-check-input"
            type="checkbox"
          >
          <label
            class="form-check-label"
            for="l10nEnable"
          >
            <l10n value="translation" />
          </label>
        </div>

        <div
          style="line-height: 1"
          class="text-muted"
        >
          <small v-if="username !== ''">
            <l10n
              value="Logged in as: {username}"
              :username="username"
            />
          </small>
        </div>

        <a
          v-if="username !== ''"
          href="javascript::void(0)"
          @click="logout"
        >
          <l10n value="logout" />
        </a>
      </div>
    </div>

    <dev
      v-if="editModeActivated"
      v-show="editMode"
      :path="path"
      :hash="hash"
      :text="text"
      :data="data"
      :original="original"
      :dark-theme="darkTheme"
      :text-warning="textWarning"
      :data-warning="dataWarning"
      :page-warning="pageWarning"
      :text-throttled="textThrottled"
      :data-throttled="dataThrottled"
      @change:text="handleTextChange"
      @change:data="handleDataChange"
      @change:asset="handleAssetChange"
    />

    <gp-toast-queue :notifications="notifications" v-if="notifications.length" />

    <portal-target name="modal" />
    <portal-target name="popup" />
    <portal-target name="tooltip" />
    <portal-target name="hover" />
  </div>
</template>

<script>
const utils = require('./my-utils');
const includes = require('./includes');
const GpToastQueue = require('./gp/gp-toast-queue.vue').default;

module.exports = {
  mixins: [
    includes,
  ],

  components: {
    'gp-toast-queue': GpToastQueue,
  },

  data() {
    let username = '';
    let groups = [];
    let users = localStorage.users ? JSON.parse(localStorage.users) : [];
    let path = location.pathname;
    let text = __CONTENT.template || '';
    let data = __CONTENT.elements || '';
    let hash = location.hash.replace(/^#/, '');
    let original = __CONTENT.original === 'yes';
    let editMode = localStorage.editMode === 'true';
    let editModeActivated = editMode;
    let textThrottled = '';
    let dataThrottled = {};
    let pendingChanges = {};
    let pagesCache = {};
    let textWarning = null;
    let dataWarning = null;
    let pageWarning = null;
    let config = {};
    let session = utils.randomId();
    let l10nEnable = utils.l10nEnable;
    let notifications = this.$notifications;

    if (localStorage.l10nEnable !== undefined) {
      l10nEnable = utils.l10nEnable = localStorage.l10nEnable === 'true';
    }

    const browser = localStorage.browser || utils.randomId();
    localStorage.browser = browser;
    let darkTheme = _.get(dataThrottled, '_.darkTheme');

    if (localStorage.darkTheme != null) {
      darkTheme = localStorage.darkTheme === 'true';
    } else {
      localStorage.darkTheme = darkTheme;
    }

    if (window.location.hash === '#dark') {
      darkTheme = true;
    }

    try {
      _.assign(utils.l10n_table, _.fromPairs(JSON.parse(__CONTENT.translation)));
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.warn(ex);
    }

    if (localStorage.config) {
      try {
        config = JSON.parse(localStorage.config);
      }
      catch (ex) {
        console.warn(ex);
      }
    }
    try {
      dataThrottled = this.parseData(data, {config});
    }
    catch (ex) {
      console.warn(ex.message);
      dataWarning = ex.message;
    }
    try {
      let vars = _.assign({config}, dataThrottled);
      textThrottled = this.parseText(text, vars);
    }
    catch (ex) {
      console.warn(ex.message);
      textWarning = ex.message;
    }
    let locale = _.get(dataThrottled, '_.locale');

    if (_.isString(locale)) {
      moment.locale(locale);
      locale = utils.locales[locale];
    }
    if (locale) {
      d3.formatDefaultLocale(locale);
      d3.timeFormatDefaultLocale(locale);
    }
    let maxActiveRequests = _.get(dataThrottled, '_.maxActiveRequests');
    if (maxActiveRequests !== undefined)
      utils.maxActiveRequests = maxActiveRequests;

    let maxBatchRequests = _.get(dataThrottled, '_.maxBatchRequests');
    if (maxBatchRequests !== undefined)
      utils.maxBatchRequests = maxBatchRequests;

    return {
      l10n: utils.l10n,
      l10nEnable,
      users,
      config,
      socket: null,
      username,
      groups,
      search: '',
      editMode,
      editModeActivated,
      darkTheme,
      path,
      text,
      data,
      hash,
      original,
      schema: null,
      textThrottled,
      dataThrottled,
      textWarning,
      dataWarning,
      pageWarning,
      pendingChanges,
      pagesCache,
      showLoginForm: false,
      session,
      browser,
      storage: null,
      streams: [],
      reports: [],
      notifications,
    };
  },

  watch: {
    notifications() { this.notifications = this.$notifications; },
  },

  computed: {
    variables() {
      try {
        let variables = (this.variablesRoot ? _.get(this.dataThrottled, this.variablesRoot) : this.dataThrottled) || null;

        if (this.variablesSearch) {
          let test = (text) => text.indexOf(this.variablesSearch) !== -1;

          try {
            const regex = new RegExp(this.variablesSearch);

            test = (text) => text.match(regex);
          } catch (ex) {
            // eslint-disable-next-line no-console
            console.warn(ex);
          }

          const trim = (node) => {
            if (_.isString(node)) {
              return test(node) ? node : undefined;
            }

            if (_.isArray(node)) {
              node = node.map(trim).filter((x) => x !== undefined);

              return node.length ? node : undefined;
            }

            if (_.isPlainObject(node)) {
              node = _(node)
                .toPairs()
                .map(([k, v]) => (test(k) ? [k, v] : [k, trim(v)]))
                .filter(([, v]) => v !== undefined)
                .fromPairs()
                .value();

              return !_.isEmpty(node) ? node : undefined;
            }

            return undefined;
          };

          variables = trim(variables);
        }

        // ???
        const replacer = (key, value) => (typeof value === Function ? undefined : value);

        return jsyaml.dump(variables, { replacer });
      } catch (ex) {
        return ex.message;
      }
    },

    themeStylesheet() {
      return this.darkTheme
        ? '/static/bootstrap-dark.css'
        : '/static/bootstrap-light.css';
    },

    bounds() {
      return _.get(this.dataThrottled, '_.bounds');
    },

    storageKey() {
      return _.get(this.dataThrottled, '_.storageKey');
    },

    title() {
      return _.get(this.dataThrottled, '_.title');
    },

    includes() {
      return _.get(this.dataThrottled, '_.includes') || [];
    },

    rootClass() {
      const rootClass = {
        'my-root': true,
        'my-dark-theme': this.darkTheme,
        'my-light-theme': !this.darkTheme,
      };

      if (this.username) {
        rootClass[`my-user-${this.username}`] = true;

        if (this.isCategoryManager(this.username)) {
          rootClass['my-group-category-manager'] = true;
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const group of this.groups || []) {
        rootClass[`my-group-${group}`] = true;
      }

      return rootClass;
    },

    state() {
      return {
        path: this.path,
        text: this.text,
        data: this.data,
      };
    },

    types() {
      if (this.schema !== null) {
        return _(this.schema.types)
          .sortBy((type) => type.name)
          .map((type) => [type.name, type])
          .fromPairs()
          .value();
      }

      return {};
    },

    keywords() {
      const score = 0;
      let keywords = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const stream of this.streams) {
        keywords.push({ value: stream.name, score, meta: 'stream' });

        // eslint-disable-next-line no-restricted-syntax
        for (const column of stream.columns) {
          keywords.push({ value: column.name, score, meta: stream.name });
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const link of stream.links) {
          keywords.push({ value: link.linkName, score, meta: `@${stream.name}` });
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const func of stream.funcs) {
          keywords.push({ value: func.name, score, meta: stream.name });
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const report of this.reports) {
        keywords.push({ value: report.name, score, meta: 'report' });

        // eslint-disable-next-line no-restricted-syntax
        for (const link of report.links) {
          keywords.push({ value: link.linkName, score, meta: `@${report.name}` });
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const func of report.funcs) {
          keywords.push({ value: func.name, score, meta: report.name });
        }
      }

      keywords = _(keywords)
        .groupBy('value')
        .values()
        .map((keywords) => _.assign({}, keywords[0], { meta: _(keywords).map('meta').join(', ') }))
        .value();

      return keywords;
    },
  },

  methods: {
    async loadUsers() {
      const query = `
        {
          dataset {
            users {
              id
              meta
              groups
            }
          }
        }`;
      const response = await fetch('/graphql?id=users', {
        method: 'POST',
        body: JSON.stringify({ query }),
      });

      this.users = (await response.json()).data.dataset.users;
    },

    handleHashChange() {
      this.hash = window.location.hash.replace(/^#/, '');
    },

    parseData(text, vars = {}) {
      const now = new Date();
      const today = utils.formatDate(now);
      const yesterday = utils.formatDate(utils.prevDate(now));
      const tomorrow = utils.formatDate(utils.nextDate(now));
      const ctx = {
        path: this.path,
        hash: this.hash,
        username: this.username,
        today,
        yesterday,
        tomorrow,
      };

      try {
        const schema = utils.jsyamlSchema;
        let data = window.Handlebars.compile(text)(_.assign({}, ctx, vars));
        data = jsyaml.safeLoad(data, { schema });

        if (!_.isPlainObject(data)) {
          data = {};
        }

        this.mergeIncludes(data);
        data = window.Handlebars.compile(text)(_.assign({}, ctx, vars, data));
        data = jsyaml.safeLoad(data, { schema });

        if (!_.isPlainObject(data)) {
          data = {};
        }

        this.mergeIncludes(data);

        return data;
      } catch (ex) {
        if (ex.mark) {
          const context = 10;
          const start = Math.max(0, ex.mark.line - context);
          const end = ex.mark.line + context + 1;
          const lines = ex.mark.buffer.split('\n');
          const snippet = lines
            .slice(start, end)
            .map((line, i) => {
              let newLine = line;
              const prefix = `${start + i + 1}: `;

              if (i + start === ex.mark.line) {
                const pads = ' '.repeat(Math.max(0, prefix.length + ex.mark.column));
                newLine = `${line}\n%c${pads}^\n  ${ex.reason}%c`;
              }

              return prefix + newLine;
            });

          if (start > 0) {
            snippet.splice(0, 0, '...');
          }

          if (end < lines.length) {
            snippet.push('...');
          }
        } else {
          // eslint-disable-next-line no-console
          console.warn(ex.message);
        }

        throw ex;
      }
    },

    parseText(text, vars = {}) {
      return window.Handlebars.compile(text)(vars);
    },

    isCategoryManager(username) {
      const user = this.users.find((u) => u.id === username);

      return user
        ? user.groups.includes('category-manager')
        : true;
    },

    logout() {
      Promise
        .resolve($.ajax({
          url: '/logout',
          method: 'POST',
        }))
        .then(() => {
          Object.assign(this.$data, {
            text: '<login-form/>',
            data: '',
            original: false,
            username: '',
            schema: null,
          });
        });
    },

    updateText() {
      try {
        const vars = _.assign({ config: this.config }, this.dataThrottled);

        this.textThrottled = this.parseText(this.text, vars);
        this.textWarning = null;
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.warn(ex.message);
        this.textWarning = ex.message;
      }
    },

    updateData() {
      try {
        const dataThrottled = this.parseData(this.data, { config: this.config });
        const dataSynced = utils.reuseData(this.dataThrottled, dataThrottled);

        this.dataThrottled = dataSynced;
        this.dataWarning = null;
        this.loadIncludes();
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.warn(ex.message);
        this.dataWarning = ex.message;
      }
    },

    filtersChanged(uidOrElement, config) {
      const uid = this.resolveUid(uidOrElement);

      if (!_.isEqual(this.$crossFilters[uid], config)) {
        Vue.set(this.$crossFilters, uid, config);
      }
    },

    tryOpenWebSocket() {
      let protocol = 'ws:';

      if (window.location.protocol === 'https:') {
        protocol = 'wss:';
      }

      const socket = new WebSocket(`${protocol}//${document.location.host}/ws`);

      socket.onmessage = (e) => {
        const message = JSON.parse(e.data);
        utils.bridge.trigger('ws-message', message);
      };

      socket.onerror = () => {
        socket.close();
      };

      socket.onopen = () => {
        this.socket = socket;
      };

      socket.onclose = () => {
        this.socket = null;
        _.defer(() => this.tryOpenWebSocket(), 1000);
      };
    },

    savePage(instant) {
      if (instant) {
        if (_.isEmpty(this.pendingChanges)) {
          return;
        }
        
        const changes = this.pendingChanges;
        this.pendingChanges = {};

        const mutation = _(changes)
          .toPairs()
          .map(([path, { text, data }], i) => {
            const fields = {};

            if (text !== undefined) {
              fields.template = text;
            }

            if (data !== undefined) {
              fields.elements = data;
            }

            const queryPath = utils.quote_string(path.slice(7));
            const queryPage = _(fields).toPairs().map(([k, v]) => `${k}:${utils.quote_string(v)}`).join(',');

            return `
              page${i}: updatePage(
                path:${queryPath},
                page:{${queryPage}}
              )
            `;
          })
          .join('\n');

        const query = `
          mutation {
            ${mutation}
          }`;

        utils.fetchWithAjaxOpts({
          url: '/graphql?id=page',
          method: 'POST',
          data: JSON.stringify({ query }),
          dataType: 'json',
          contentType: 'application/json',
        })
          .then(({ errors }) => {
            if (errors != null) {
              this.pageWarning = errors[0].message;
            } else {
              this.pageWarning = null;
            }
          })
          .catch(() => {
            this.pendingChanges = _.merge(changes, this.pendingChanges);
          });
      } else {
        clearTimeout(this.saveTimeout);
        this.saveTimeout = setTimeout(() => this.savePage(true), 1000);
      }
    },

    loadPage(path) {
      if (this.path === path && this.pagesCache[path] !== undefined) {
        Object.assign(this.$data, this.pagesCache[path]);
        this.updateData();
        this.updateText();
        window.history.replaceState(this.state, document.title, window.location.pathname + window.location.hash);
      }

      const query = `
        {
          page(path:${utils.quote_string(path.slice(7))}) {
            template
            elements
          }
        }`;

      utils.fetchWithAjaxOpts({
        url: '/graphql?id=page',
        method: 'POST',
        data: JSON.stringify({ query }),
        dataType: 'json',
        contentType: 'application/json',
      })
        .then(({ data: { page: { template: text, elements: data } } }) => {
          if (text == null) {
            text = '';
          }

          if (data == null) {
            data = '';
          }

          this.pagesCache[path] = { text, data };

          if (path === this.path) {
            Object.assign(this.$data, { text, data, original: true });
            this.updateData();
            this.updateText();
            window.history.replaceState(this.state, document.title, window.location.pathname + window.location.hash);
          }
        });
    },

    handlePopState(e) {
      if (e.state !== null) {
        window.history.replaceState(e.state, document.title, window.location.pathname + window.location.hash);
        Object.assign(this.$data, e.state);
        this.updateData();
        this.updateText();
        this.loadPage(this.path);
      }
    },

    handleStorageEvent(e) {
      if (e.key === 'ping') {
        this.checkSession();
      }
    },

    checkSession() {
      fetch('/session', { method: 'GET', headers: utils.nocahe() })
        .then((res) => res.json())
        .then(({ username, groups }) => {
          this.username = username;
          this.groups = groups;
        });
    },

    checkReports() {
      if (localStorage.activeReports) {
        let activeReports = JSON.parse(localStorage.activeReports);
        const canceledReportIds = [];
        const now = _.now();

        // eslint-disable-next-line no-restricted-syntax
        for (const id of _.keys(activeReports)) {
          if (now - activeReports[id] > 10000) {
            this.cancelReport(id, 'abandoned');
            canceledReportIds.push(id);
          }
        }

        if (!_.isEmpty(canceledReportIds)) {
          activeReports = JSON.parse(localStorage.activeReports);

          // eslint-disable-next-line no-restricted-syntax
          for (const id of canceledReportIds) {
            delete activeReports[id];
          }

          localStorage.activeReports = JSON.stringify(activeReports);
        }
      }
    },

    async updateConfig() {
      const configRes = await fetch('/storage/_/config', { method: 'GET', headers: utils.nocahe() });
      const customTimeframesRes = await fetch('/storage/timeframes', { method: 'GET', headers: utils.nocahe() });

      if (configRes.status !== 200) {
        return;
      }

      const config = (await configRes.json()) || {};
      const customTimeframes = (await customTimeframesRes.json()) || {};

      if (!_.isEqual(this.config, config)) {
        config.attributes = Object.entries(config?.attributes || {}).map((x) => x[1]);
        config.metrics = Object.entries(config?.metrics || {}).map((x) => x[1]);
        config.calc_columns = Object.entries(config?.calc_columns || {}).map((x) => x[1]);
        config.timeframes = {
          ...config.timeframes,
          ...customTimeframes,
        };
        this.config = config;
      }
    },

    cancelReport(id) {
      if (this.socket) {
        this.socket.send(JSON.stringify({ CancelReport: { id } }));
      }
    },

    interceptClickEvent(e) {
      if (e.target.tagName === 'A'
        && e.target.target !== '_blank'
        && e.target.origin === window.location.origin
        && e.target.pathname.startsWith('/pages/')
        && e.target.pathname !== window.location.pathname
      ) {
        e.preventDefault();
        e.stopPropagation();

        if (this.saveTimeout != null) {
          this.savePage(true);
        }

        window.history.replaceState(this.state, document.title, window.location.pathname + window.location.hash);

        Object.assign(this.$data, {
          path: e.target.pathname,
          text: '',
          data: '',
        });

        window.history.pushState(this.state, '', e.target.pathname);

        this.updateData();
        this.updateText();
        this.loadPage(this.path);
      }
    },

    handleTextChange(text) {
      if (!this.original) {
        return;
      }

      this.text = text;

      if (this.pendingChanges[this.path] === undefined) {
        this.pendingChanges[this.path] = {};
      }

      this.pendingChanges[this.path].text = this.text;
      this.savePage();
    },

    handleDataChange(data) {
      if (!this.original) {
        return;
      }

      this.data = data;

      if (this.pendingChanges[this.path] === undefined) {
        this.pendingChanges[this.path] = {};
      }

      this.pendingChanges[this.path].data = this.data;
      this.savePage();
    },

    handleAssetChange(asset) {
      // eslint-disable-next-line no-restricted-syntax
      for (const include of this.includes) {
        if (include.fetch === asset.link || include.fetch?.url === asset.link) {
          this.loadInclude(include);
        }
      }

      if (asset.link === `${this.path}elements.yml`) {
        delete this.pagesCache[this.path];
        delete this.pendingChanges[this.path];
        this.data = asset.data;
      }

      if (asset.link === `${this.path}template.md`) {
        delete this.pagesCache[this.path];
        delete this.pendingChanges[this.path];
        this.text = asset.data;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const el of $(`[href^='${asset.link}']`)) {
        el.href = `${asset.link}?rnd=${utils.randomId()}`;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const el of $(`[src^='${asset.link}']`)) {
        el.src = `${asset.link}?rnd=${utils.randomId()}`;
      }
    },

    prefetchPageLinks() {
      // eslint-disable-next-line no-restricted-syntax
      for (const link of $('a')) {
        if (link.hasAttribute('nofollow')) {
          continue;
        }

        const { origin, pathname: path } = link;

        if (origin === window.location.origin
          && path.startsWith('/pages/')
          && path.split('/').slice(-1)[0].indexOf('.') === -1
        ) {
          if (this.pagesCache[path] === undefined) {
            this.loadPage(path);
          }
        }
      }
    },

    handleBeforeUnload(e) {
      utils.bridge.trigger('handleBeforeUnload');
      e.preventDefault();
    },

    sendConsoleMessage(type, args) {
      try {
        const user = this.username;
        const time = Date.now();
        let stack;

        // eslint-disable-next-line no-restricted-syntax
        for (const arg of args) {
          if (_.isError(arg)) {
            stack = arg.stack;
          }
        }

        const cache = new Set();

        try {
          const parser = (key, value) => {
            if (_.isPlainObject(value)
              || _.isArray(value)
              || _.isNumber(value)
              || _.isBoolean(value)
              || _.isString(value)
            ) {
              if (typeof value === 'object' && value !== null) {
                if (cache.has(value)) {
                  return undefined;
                }

                return cache.add(value) && value;
              }

              return value;
            }

            return null;
          };

          const message = JSON.stringify({
            ConsoleMessage: {
              type,
              args,
              user,
              time,
              stack,
              session: this.session,
              browser: this.browser,
            },
          }, parser);

          if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            try {
              this.socket.send(message);
            } catch (ex) {
              this.pendingMessages.push(message);
            }
          } else {
            this.pendingMessages.push(message);
          }
        } catch (ex) {
          this.defaultWarn.apply(console, ['failed to serialize console message', ex]);
        }
      } catch (ex) {
        this.defaultError.apply(console, ['failed to send console message', ex]);
      }
    },

    handleConsoleLog() {
      this.defaultLog.apply(console, arguments);
      this.sendConsoleMessage('log', Array.from(arguments));
    },

    handleConsoleWarn() {
      this.defaultWarn.apply(console, arguments);
      this.sendConsoleMessage('warn', Array.from(arguments));
    },

    handleConsoleError() {
      this.defaultError.apply(console, arguments);
      this.sendConsoleMessage('error', Array.from(arguments));
    },

    fetchStorage() {
      if (this.storageKey) {
        fetch(`/storage/storage/${this.storageKey}`, { headers: utils.nocahe() })
          .then((res) => {
            switch (res.status) {
              case 200: return res.json();
              case 404: return {};
              default: return {};
            }
          })
          .then((storage) => {
            this.storage = storage;
          });
      }
    },
  },

  created() {
    window.app = this;

    utils.bridge.bind('filtersChanged', this.filtersChanged);
    utils.bridge.bind('checkSession', this.checkSession);
    utils.bridge.bind('cancelReport', this.cancelReport);

    window.addEventListener('popstate', this.handlePopState);
    window.addEventListener('storage', this.handleStorageEvent);
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    window.addEventListener('hashchange', this.handleHashChange);

    document.addEventListener('click', this.interceptClickEvent);
  },

  mounted() {
    document.getElementById('theme-stylesheet').setAttribute('href', this.themeStylesheet);
    document.body.style.setProperty('--scrollbar-size', `${utils.getScrollbarSize()}px`);

    $('title').text(this.title);
    $('#preview').remove();

    this.tryOpenWebSocket();
    this.checkSessionTimer = setInterval(this.checkSession, 1000 * 60);
    this.updateConfigTimer = setInterval(this.updateConfig, 1000 * 60);
    this.checkReportsTimer = setInterval(this.checkReports, 1000 * 10);
    this.checkSession();
    this.updateConfig();

    this.pendingMessages = [];

    this.defaultLog = console.log.bind(console);
    this.defaultWarn = console.warn.bind(console);
    this.defaultError = console.error.bind(console);

    // if (window.location.hostname !== 'localhost') {
    //   console.log = this.handleConsoleLog;
    //   console.warn = this.handleConsoleWarn;
    //   console.error = this.handleConsoleError;
    // }

    this.socketPingTimer = setInterval(() => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ Ping: { now: _.now() } }));
      }
    }, 10000);

    this.fetchStorage();
    this.loadUsers();

    this.loadUsersTimer = setInterval(() => {
      this.loadUsers();
    }, 60000);

    _.delay(() => this.prefetchPageLinks(), 2000);
  },

  beforeDestroy() {
    utils.bridge.unbind('filtersChanged', this.filtersChanged);
    utils.bridge.unbind('checkSession', this.checkSession);
    utils.bridge.unbind('cancelReport', this.cancelReport);

    window.removeEventListener('popstate', this.handlePopState);
    window.removeEventListener('storage', this.handleStorageEvent);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    window.removeEventListener('hashchange', this.handleHashChange);
    document.removeEventListener('click', this.interceptClickEvent);

    clearInterval(this.checkSessionTimer);
    clearInterval(this.updateConfigTimer);
    clearInterval(this.checkReportsTimer);

    // console.log = this.defaultLog;
    // console.warn = this.defaultWarn;
    // console.error = this.defaultError;

    clearInterval(this.socketPingTimer);
    clearInterval(this.loadUsersTimer);
  },

  watch: {
    themeStylesheet() {
      document.getElementById('theme-stylesheet').setAttribute('href', this.themeStylesheet);
    },

    users() {
      localStorage.users = JSON.stringify(this.users);
    },

    storage: {
      deep: true,
      async handler(storage, prevStorage) {
        if (this.storageKey && prevStorage != null) {
          await fetch(`/storage/storage/${this.storageKey}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(storage),
          });
        }
      },
    },

    l10nEnable() {
      utils.l10nEnable = this.l10nEnable;
      localStorage.l10nEnable = this.l10nEnable;
      utils.bridge.trigger('l10n');
    },

    loadedIncludes: {
      deep: true,
      handler() {
        this.updateData();
        this.updateText();
      },
    },

    customIncludes: {
      deep: true,
      handler() {
        this.updateData();
        this.updateText();
      },
    },

    socket(socket) {
      if (socket) {
        try {
          // eslint-disable-next-line no-restricted-syntax
          for (const message of this.pendingMessages) {
            socket.send(message);
          }
        } catch (ex) {
          this.defaultError.apply(console, ['failed to send pending logs', ex]);
        }

        this.pendingMessages = [];
      }
    },

    config(config) {
      if (config) {
        localStorage.config = JSON.stringify(config);
        this.updateData();
        this.updateText();
      }
    },

    editMode(editMode) {
      this.editModeActivated = true;
      localStorage.editMode = editMode;
      utils.bridge.trigger('modeChanged');
    },

    darkTheme(darkTheme) {
      localStorage.darkTheme = darkTheme;
      utils.bridge.trigger('themeChanged');
    },

    editTab() {
      localStorage.editTab = this.editTab;
      this.$set(this.visitedTabs, this.editTab, true);

      switch (this.editTab) {
        case 'template':
          Vue.nextTick(() => this.$refs.templateEditor.editor?.focus());
          break;
        case 'elements':
          Vue.nextTick(() => this.$refs.elementsEditor.editor?.focus());
          break;
        default: break;
      }
    },

    text() {
      localStorage.text = this.text;
      clearTimeout(this.textTimeout);

      this.textTimeout = setTimeout(() => {
        this.updateText();
      }, 300);
    },

    data() {
      localStorage.data = this.data;
      clearTimeout(this.dataTimeout);

      this.dataTimeout = setTimeout(() => {
        this.updateData();
        this.updateText();
      }, 300);
    },

    path() {
      this.updateData();
      this.updateText();
    },

    hash() {
      this.updateData();
      this.updateText();
    },

    title() {
      $('title').text(this.title);
    },

    textThrottled() {
      _.delay(() => this.prefetchPageLinks(), 1000);
    },

    storageKey() {
      this.fetchStorage();
    },

    username() {
      if (this.username !== '') {
        this.loadPage(this.path);
        this.updateConfig();
        this.fetchStorage();
        this.loadUsers();
      }

      Vue.prototype.$username = this.username;
      utils.bridge.trigger('usernameChanged');
    },
  },
};
</script>

<style>
#my-page-warning {
  padding: 4px;
  padding-left: 0;
  line-height: 24px;
  color: var(--orange);
}
#my-text-warning,
#my-data-warning {
  position: absolute;
  right: 4px;
  top: 4px;
  padding: 4px;
  color: var(--orange);
}
#my-text-warning svg,
#my-data-warning svg {
  width: 20px!important;
  height: 20px!important;
}
*:fullscreen {
  padding: 10px 10px;
  background-color: white;
  overflow-y: auto;
}
.my-dark-theme *:fullscreen {
  background-color: rgb(34,34,34);
}
</style>
