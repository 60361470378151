module.exports.props = {
    layout: { type: String, default: "relaxed" },
    stacking: { type: String, default: "hierarchical" },
    descriptionGroup: { type: String, default: "auto" },
    descriptionGroupType: { type: String, default: "stab" },
    descriptionGroupSize: { type: Number, default: 0.125 },
    descriptionGroupMinHeight: { type: Number, default: 35 },
    descriptionGroupMaxHeight: { type: Number, default: 0.5 },
    descriptionGroupPosition: { type: Number, default: 225 },
    descriptionGroupDistanceFromCenter: { type: Number, default: 1 },
    groupMinDiameter: { type: Number, default: 10 },
    maxGroups: { type: Number, default: 50000 },
    rectangleAspectRatioPreference: { type: Number, default: -1 },
    relaxationInitializer: { type: String, default: "fisheye" },
    relaxationMaxDuration: { type: Number, default: 3000 },
    relaxationQualityThreshold: { type: Number, default: 1 },
    groupGrowingDuration: { type: Number, default: 0 },
    groupGrowingEasing: { type: String, default: "bounce" },
    groupGrowingDrag: { type: Number, default: 0 },
    groupResizingBudget: { type: Number, default: 2 },
    groupBorderRadius: { type: Number, default: 0.15 },
    groupBorderWidth: { type: Number, default: 4 },
    groupBorderWidthScaling: { type: Number, default: 0.6 },
    groupInsetWidth: { type: Number, default: 6 },
    groupBorderRadiusCorrection: { type: Number, default: 1 },
    groupFillType: { type: String, default: "gradient" },
    groupFillGradientRadius: { type: Number, default: 1 },
    groupFillGradientCenterHueShift: { type: Number, default: 0 },
    groupFillGradientCenterSaturationShift: { type: Number, default: 0 },
    groupFillGradientCenterLightnessShift: { type: Number, default: 20 },
    groupFillGradientRimHueShift: { type: Number, default: 0 },
    groupFillGradientRimSaturationShift: { type: Number, default: 0 },
    groupFillGradientRimLightnessShift: { type: Number, default: -5 },
    groupStrokeType: { type: String, default: "plain" },
    groupStrokeWidth: { type: Number, default: 1.5 },
    groupStrokePlainHueShift: { type: Number, default: 0 },
    groupStrokePlainSaturationShift: { type: Number, default: 0 },
    groupStrokePlainLightnessShift: { type: Number, default: -10 },
    groupStrokeGradientRadius: { type: Number, default: 1 },
    groupStrokeGradientAngle: { type: Number, default: 45 },
    groupStrokeGradientUpperHueShift: { type: Number, default: 0 },
    groupStrokeGradientUpperSaturationShift: { type: Number, default: 0 },
    groupStrokeGradientUpperLightnessShift: { type: Number, default: 20 },
    groupStrokeGradientLowerHueShift: { type: Number, default: 0 },
    groupStrokeGradientLowerSaturationShift: { type: Number, default: 0 },
    groupStrokeGradientLowerLightnessShift: { type: Number, default: -20 },
    groupSelectionOutlineColor: { type: String, default: "#222" },
    groupSelectionOutlineWidth: { type: Number, default: 5 },
    groupSelectionOutlineShadowSize: { type: Number, default: 0 },
    groupSelectionOutlineShadowColor: { type: String, default: "#fff" },
    groupSelectionFillHueShift: { type: Number, default: 0 },
    groupSelectionFillSaturationShift: { type: Number, default: 0 },
    groupSelectionFillLightnessShift: { type: Number, default: 0 },
    groupSelectionStrokeHueShift: { type: Number, default: 0 },
    groupSelectionStrokeSaturationShift: { type: Number, default: 0 },
    groupSelectionStrokeLightnessShift: { type: Number, default: -10 },
    groupHoverFillHueShift: { type: Number, default: 0 },
    groupHoverFillSaturationShift: { type: Number, default: 0 },
    groupHoverFillLightnessShift: { type: Number, default: 20 },
    groupHoverStrokeHueShift: { type: Number, default: 0 },
    groupHoverStrokeSaturationShift: { type: Number, default: 0 },
    groupHoverStrokeLightnessShift: { type: Number, default: -10 },
    groupLabelFontFamily: { type: String, default: "sans-serif" },
    groupLabelFontStyle: { type: String, default: "normal" },
    groupLabelFontWeight: { type: String, default: "normal" },
    groupLabelFontVariant: { type: String, default: "normal" },
    groupLabelMinFontSize: { type: Number, default: 6 },
    groupLabelMaxFontSize: { type: Number, default: 160 },
    groupLabelLineHeight: { type: Number, default: 1.05 },
    groupLabelHorizontalPadding: { type: Number, default: 1 },
    groupLabelVerticalPadding: { type: Number, default: 1 },
    groupLabelMaxTotalHeight: { type: Number, default: 0.9 },
    groupLabelUpdateThreshold: { type: Number, default: 0.05 },
    groupLabelColorThreshold: { type: Number, default: 0.35 },
    groupLabelDarkColor: { type: String, default: "#000" },
    groupLabelLightColor: { type: String, default: "#fff" },
    groupExposureScale: { type: Number, default: 1.15 },
    groupExposureShadowColor: { type: String, default: "rgba(0, 0, 0, 0.5)" },
    groupExposureShadowSize: { type: Number, default: 50 },
    groupExposureZoomMargin: { type: Number, default: 0.1 },
    groupUnexposureLightnessShift: { type: Number, default: 65 },
    groupUnexposureSaturationShift: { type: Number, default: -65 },
    groupUnexposureLabelColorThreshold: { type: Number, default: 0.35 },
    exposeDuration: { type: Number, default: 700 },
    exposeEasing: { type: String, default: "squareInOut" },
    openCloseDuration: { type: Number, default: 500 },
    parentFillOpacity: { type: Number, default: 0.7 },
    parentStrokeOpacity: { type: Number, default: 1 },
    parentLabelOpacity: { type: Number, default: 1 },
    rainbowStartColor: { type: String, default: "hsla(0, 100%, 55%, 1)" },
    rainbowEndColor: { type: String, default: "hsla(359, 100%, 55%, 1)" },
    rainbowColorDistribution: { type: String, default: "radial" },
    rainbowColorDistributionAngle: { type: Number, default: -45 },
    rainbowLightnessDistributionAngle: { type: Number, default: 45 },
    rainbowSaturationCorrection: { type: Number, default: 0.1 },
    rainbowLightnessCorrection: { type: Number, default: 0.4 },
    rainbowLightnessShift: { type: Number, default: 30 },
    rainbowLightnessShiftCenter: { type: Number, default: 0.4 },
    rolloutStartPoint: { type: String, default: "center" },
    rolloutMethod: { type: String, default: "groups" },
    rolloutDuration: { type: Number, default: 2000 },
    rolloutEasing: { type: String, default: "squareOut" },
    rolloutScalingStrength: { type: Number, default: -0.7 },
    rolloutTranslationXStrength: { type: Number, default: 0 },
    rolloutTranslationYStrength: { type: Number, default: 0 },
    rolloutRotationStrength: { type: Number, default: -0.7 },
    rolloutTransformationCenter: { type: Number, default: 0.7 },
    rolloutPolygonDrag: { type: Number, default: 0.1 },
    rolloutPolygonDuration: { type: Number, default: 0.5 },
    rolloutLabelDelay: { type: Number, default: 0.8 },
    rolloutLabelDrag: { type: Number, default: 0.1 },
    rolloutLabelDuration: { type: Number, default: 0.5 },
    rolloutChildGroupsDelay: { type: Number, default: 0.2 },
    rolloutChildGroupsDrag: { type: Number, default: 0.1 },
    pullbackStartPoint: { type: String, default: "center" },
    pullbackMethod: { type: String, default: "groups" },
    pullbackDuration: { type: Number, default: 1500 },
    pullbackEasing: { type: String, default: "squareIn" },
    pullbackScalingStrength: { type: Number, default: -0.7 },
    pullbackTranslationXStrength: { type: Number, default: 0 },
    pullbackTranslationYStrength: { type: Number, default: 0 },
    pullbackRotationStrength: { type: Number, default: -0.7 },
    pullbackTransformationCenter: { type: Number, default: 0.7 },
    pullbackPolygonDelay: { type: Number, default: 0.3 },
    pullbackPolygonDrag: { type: Number, default: 0.1 },
    pullbackPolygonDuration: { type: Number, default: 0.8 },
    pullbackLabelDelay: { type: Number, default: 0 },
    pullbackLabelDrag: { type: Number, default: 0.1 },
    pullbackLabelDuration: { type: Number, default: 0.3 },
    pullbackChildGroupsDuration: { type: Number, default: 0.3 },
    pullbackChildGroupsDelay: { type: Number, default: 0.1 },
    pullbackChildGroupsDrag: { type: Number, default: 0.1 },
    fadeDuration: { type: Number, default: 700 },
    fadeEasing: { type: String, default: "cubicInOut" },
    zoomMouseWheelFactor: { type: Number, default: 1.5 },
    zoomMouseWheelDuration: { type: Number, default: 500 },
    zoomMouseWheelEasing: { type: String, default: "squareOut" },
    titleBarFontFamily: { type: String, default: null },
    titleBarFontStyle: { type: String, default: "normal" },
    titleBarFontWeight: { type: String, default: "normal" },
    titleBarFontVariant: { type: String, default: "normal" },
    titleBarMinFontSize: { type: Number, default: 10 },
    titleBarMaxFontSize: { type: Number, default: 40 },
    titleBarBackgroundColor: { type: String, default: "rgba(0, 0, 0, 0.5)" },
    titleBarTextColor: { type: String, default: "rgba(255, 255, 255, 1)" },
    titleBarTextPaddingLeftRight: { type: Number, default: 20 },
    titleBarTextPaddingTopBottom: { type: Number, default: 15 },
    maxLabelSizeForTitleBar: { type: Number, default: 8 },
    attributionText: { type: String, default: null },
    attributionLogo: { type: String, default: null },
    attributionLogoScale: { type: Number, default: 0.5 },
    attributionUrl: { type: String, default: "http://carrotsearch.com/foamtree" },
    attributionWeight: { type: Number, default: 0.025 },
    attributionPosition: { type: [String, Number], default: "bottomright" },
    attributionDistanceFromCenter: { type: Number, default: 1 },
    attributionTheme: { type: String, default: "light" },
    pixelRatio: { type: Number, default: 1 },
    wireframePixelRatio: { type: Number, default: 1 },
    groupContentDecoratorTriggering: { type: String, default: "onLayoutDirty" },
    wireframeLabelDrawing: { type: String, default: "auto" },
    wireframeContentDecorationDrawing: { type: String, default: "auto" },
    maxGroupLevelsDrawn: { type: Number, default: 4 },
    maxGroupLabelLevelsDrawn: { type: Number, default: 3 },
    maxGroupLevelsAttached: { type: Number, default: 4 },
    incrementalDraw: { type: String, default: "fast" },
    wireframeToFinalFadeDelay: { type: Number, default: 300 },
    wireframeToFinalFadeDuration: { type: Number, default: 500 },
    finalToWireframeFadeDuration: { type: Number, default: 200 },
    wireframeDrawMaxDuration: { type: Number, default: 15 },
    finalCompleteDrawMaxDuration: { type: Number, default: 80 },
    finalIncrementalDrawMaxDuration: { type: Number, default: 100 },
    imageData: { type: String, default: null },
    interactionHandler: { type: String, default: "builtin" },
    groupColorDecorator: { type: [Function, String] },
}

module.exports.watch = {}

_.forEach(_.keys(module.exports.props), (name) => {
    module.exports.watch[name] = function(value) {
        if (this.foamtree != null) {
            let prop = Vue.component(this.$options._componentTag).options.props[name]
            if (_.isString(value) && _.isArray(prop.type) && _.includes(prop.type, Function)) {
                value = eval(value)
            }
            this.foamtree.set(name, value)
            this.foamtree.redraw()
        }
    }
})

module.exports.computed = {}
module.exports.computed.config = function() {
    let config = {}
    for (name of _.keys(module.exports.props)) {
        let value = this[name]
        let prop = Vue.component(this.$options._componentTag).options.props[name]
        if (_.isString(value) && _.isArray(prop.type) && _.includes(prop.type, Function)) {
            value = eval(value)
        }
        config[name] = value
    }
    return config
}

