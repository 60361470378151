<template>
    <div class="pim-hierarchies">
        <div class="pim-hierarchies-body">
            <div class="pim-hierarchies-main" ref="main" tabindex="0">
                <gp-news id="news" :darkTheme="darkTheme"/>
                <div class="pim-hierarchies-move">
                    <div>
                        <span></span>
                        <a tabindex="-1" href="javascript:void(0)" @click="moveUp" :class="{disabled: !canMoveUp}" title="Shift+ArrowUp">
                            <feather-icon name="arrow-up"/>
                        </a>
                        <span></span>
                    </div>
                    <div>
                        <a tabindex="-1" href="javascript:void(0)" @click="moveLeft" :class="{disabled: !canMoveLeft}" title="Shift+ArrowLeft">
                            <feather-icon name="arrow-left"/>
                        </a>
                        <a tabindex="-1" href="javascript:void(0)" @click="showMenu = true" :class="{disabled: !focus}">
                            <feather-icon name="menu" ref="menu"/>
                        </a>
                        <a tabindex="-1" href="javascript:void(0)" @click="moveRight" :class="{disabled: !canMoveRight}" title="Shift+ArrowRight">
                            <feather-icon name="arrow-right"/>
                        </a>
                    </div>
                    <div>
                        <span></span>
                        <a tabindex="-1" href="javascript:void(0)" @click="moveDown" :class="{disabled: !canMoveDown}" title="Shift+ArrowDown">
                            <feather-icon name="arrow-down"/>
                        </a>
                        <span></span>
                    </div>
                </div>
                <div class="pim-hierarchies-head">
                    <div class="pim-hierarchies-search">
                        <my-search v-model="searchString" ref="search"/>
                        <p>
                            Use arrows to navigate through the hierarchy.<br>
                            Use Shift + arrows to move categories around.
                        </p>
                        <gp-check v-model="multiselect">Enable multiselect mode</gp-check>
                    </div>
                </div>
                <div class="pim-hierarchies-entries">
                    <table>
                        <thead v-if="entriesReport && entriesReport.meta.vals.length > 5">
                            <tr>
                                <th></th>
                                <th v-for="{name} in entriesReport.meta.vals.slice(5)">
                                    {{name}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <pim-hierarchies-entry
                                ref="entries"
                                :class="{focused: entry == focus}"
                                v-for="entry in visibleEntries"
                                :key="entry.id"
                                :entry="entry"
                                @focus="focus = $event"
                                :multiselect="multiselect"
                                :formatColumn="formatColumn"
                                />
                        </tbody>
                    </table>
                </div>
                <my-popup
                    v-if="focus && showMenu"
                    :draggable="true"
                    placement="bottom"
                    @clickoutside="showMenu = false"
                    :anchor="$refs.menu.$el">
                    <div class="popover pim-hierarchies-menu">
                        <div class="popover-body">
                            <label>{{focus.name}}</label>
                            <ul>
                                <template v-if="focus.parent.parent">
                                    <li>
                                        <a href="javascript:void(0)" @click="showMenu = false; createSubCategory()">
                                            <feather-icon name="file-plus"/>Create subcategory
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" @click="showMenu = false; createCategoryAfter()">
                                            <feather-icon name="file-plus"/>Create category right after
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" @click="showMenu = false; removeThisCategory()">
                                            <feather-icon name="trash"/>Remove this category
                                        </a>
                                    </li>
                                </template>
                                <template v-else>
                                    <li>
                                        <a href="javascript:void(0)" @click="showMenu = false; createSubCategory()">
                                            <feather-icon name="file-plus"/>Create category
                                        </a>
                                    </li>
                                </template>
                                <li>
                                    <a href="javascript:void(0)" @click="showMenu = false; exportAttributes();">
                                        <feather-icon name="download"/>Export attributes
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" @click="showMenu = false; importAttributes();">
                                        <feather-icon name="upload"/>Import attributes
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" @click="showMenu = false; showColumns = true;">
                                        <feather-icon name="list"/>Manage columns
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </my-popup>
            </div>
            <pim-hierarchies-side
                ref="side"
                :entry="focus"
                :entries="multiselect ? selectedEntries : undefined"
                :username="username"
                :config="config"
                :fields="fields"
                :search="search"/>
        </div>
        <pim-hierarchies-foot
            ref="foot"
            :entry="focus"
            :config="config"
            :username="username"
            :metrics="config.metrics"
            :formats="config.formats"
            :formulas="config.formulas"
            :attributes="config.attributes"
            :timeframes="config.timeframes"
            />
        <gp-data
            stream="fields"
            filter2="api_group_name == 'Category Flex Field'"
            :dims="['api_name']"
            :vals="['field_name', 'field_type']"
            v-model="fieldsReport"
            />
        <gp-data
            :stream="stream"
            :dims="['id']"
            :vals="[
                'level',
                'brand.name',
                'name',
                'sequence_number',
                'ancestry'].concat(columns)"
            :initialSort="[2,1]"
            filter2="is_deleted == '0'"
            v-model="entriesReport"
            />
        <my-popup
            v-if="showColumns"
            :draggable="true"
            placement="bottom"
            @clickoutside="showColumns = false"
            :anchor="$refs.menu.$el">
            <div class="popover pim-hierarchies-columns">
                <div class="popover-body">
                    <gp-section-columns
                        :autofocus="true"
                        :attributes="columnsConfig.attributes"
                        :metrics="columnsConfig.metrics"
                        :columns="columns"
                        @submit="showColumns = false; columns = $event.columns;"
                        @cancel="showColumns = false;"
                        />
                </div>
            </div>
        </my-popup>
        <form style="display: none" ref="uploadForm">
            <input type="file" ref="uploadInput" @change="handleFileUpload">
        </form>
    </div>
</template>
<script>
let utils = require("../my-utils")

module.exports = {
    props: {
        stream: { type: String, default: "categories" },
        search: { type: Object, default: () => ({
            threshold: 0,
            minMatchCharLength: 2,
            isCaseSensitive: false,
            includeMatches: true,
            ignoreLocation: true,
            useExtendedSearch: true,
            findAllMatches: true,
            shouldSort: false,
            includeScore: true,
            keys: ["name"],
        })},
        username: { type: String },
        config: { type: Object },
        darkTheme: { type: Boolean },
    },
    data() {
        return {
            focus: null,
            fieldsReport: null,
            entriesReport: null,
            searchString: "",
            catalog: [],
            entries: {},
            showMenu: false,
            showColumns: false,
            multiselect: false,
            columns: JSON.parse(localStorage['pim-hierarchies-main-columns'] || '[]'),
        }
    },
    mounted() {
        window.pim = this
        document.addEventListener("keydown", this.handleKeyDown)
        this.$refs.search.$el.focus()
    },
    beforeDestroy() {
        document.removeEventListener("keydown", this.handleKeyDown)
    },
    methods: {
        async exportAttributes() {
            let XLSX = await import("xlsx")
            let workbook = XLSX.utils.book_new()
            let rows = []
            let header = ["Storefront", "Level", "ID", "Name", "Parent ID", "Parent Name"]
                .concat(this.entriesReport.meta.vals.slice(5).map(({name}) => name))
                .concat(["Deleted"])
            rows.push(header)
            let getLevel = entry => {
                let level = -2
                while (entry) {
                    level += 1
                    entry = entry.parent
                }
                return level
            }
            let entries = []
            let loop = (entry) => {
                entries.push(entry)
                _.forEach(entry.children, loop)
            }
            _.forEach(this.catalog.children, loop)
            for (let entry of entries) {
                if (entry.type == 'category')
                    rows.push([
                        entry.brand,
                        getLevel(entry),
                        entry.id,
                        entry.name,
                        entry.parent?.id,
                        entry.parent?.name
                    ]
                    .concat(entry.attrs.map(({key, val}) => val))
                    .concat([""]))
            }
            let worksheet = XLSX.utils.aoa_to_sheet(rows)
            XLSX.utils.book_append_sheet(workbook, worksheet, "categories")
            XLSX.writeFile(workbook, "categories.xlsx")
        },
        importAttributes() {
            $(this.$refs.uploadInput).trigger("click")
        },
        handleFileUpload(e) {
            let file = e.target.files[0]
            this.$refs.uploadForm.reset()
            this.uploadFile(file);
        },
        async uploadFile(file) {
            var formData = new FormData()
            formData.append("file", file)

            let {rows} = await Promise.resolve($.ajax({
                url: "/import2",
                type: "POST",
                data: formData,
                processData: false,
                contentType: false
            }))

            let columns = {
                id: rows[0].indexOf("ID"),
                name: rows[0].indexOf("Name"),
            }
            for (let field of this.fields)
                columns[field.key] = rows[0].indexOf(field.name)

            let count = 0

            for (let row of rows) {
                let entry = this.entries.find(entry => entry.id == row[columns.id])
                if (entry) {
                    for (let key of _.keys(columns)) {
                        let value = row[columns[key]]
                        if (value !== undefined) {
                            if (value == null)
                                value = ""
                            if (key == "name" && entry.name != value) {
                                this.$set(entry, "name", value)
                                count += 1
                            }
                            else if (key != "id") {
                                let attr = entry.attrs.find(attr => attr.key == key)
                                if (attr !== undefined && attr.val != value) {
                                    this.$set(attr, "val", value)
                                    count += 1
                                }
                            }
                        }
                    }
                }
            }
            if (count == 0)
                window.alert(`No changes detected.`)
            else    
                window.alert(`Applied ${count} changes.`)
        },
        handleKeyDown(e) {
            let focus = this.focus
            if (focus && $(document.activeElement).closest(this.$refs.main).length) {
                if (e.shiftKey) {
                    if (e.key == "ArrowUp" && this.canMoveUp)
                        this.moveUp()
                    if (e.key == "ArrowDown" && this.canMoveDown)
                        this.moveDown()
                    if (e.key == "ArrowLeft" && this.canMoveLeft)
                        this.moveLeft()
                    if (e.key == "ArrowRight" && this.canMoveRight)
                        this.moveRight()
                }
                else {
                    if (e.key == "ArrowUp" && this.prevEntry) {
                        this.focusEntry(this.prevEntry)
                        e.preventDefault()
                    }
                    if (e.key == "ArrowDown" && this.nextEntry) {
                        this.focusEntry(this.nextEntry)
                        e.preventDefault()
                    }
                    if (e.key == "ArrowLeft")
                        this.$set(focus, "opened", false)
                    if (e.key == "ArrowRight")
                        this.$set(focus, "opened", true)
                }
            }
        },
        moveUp() {
            let focus = this.focus
            let i = focus.parent.children.indexOf(focus)
            focus.parent.children.splice(i, 1)
            focus.parent.children.splice(i-1, 0, focus)
            Vue.nextTick(() => this.focusEntry(focus))
        },
        moveDown() {
            let focus = this.focus
            let i = focus.parent.children.indexOf(focus)
            focus.parent.children.splice(i, 1)
            focus.parent.children.splice(i+1, 0, focus)
            Vue.nextTick(() => this.focusEntry(focus))
        },
        moveLeft() {
            let focus = this.focus
            let i = focus.parent.children.indexOf(focus)
            focus.parent.children.splice(i, 1)
            let j = focus.parent.parent.children.indexOf(focus.parent)
            focus.parent.parent.children.splice(j+1, 0, focus)
            focus.parent = focus.parent.parent
            Vue.nextTick(() => this.focusEntry(focus))
        },
        moveRight() {
            let focus = this.focus
            let i = focus.parent.children.indexOf(focus)
            let parent = focus.parent.children[i-1]
            focus.parent.children.splice(i, 1)
            parent.children.push(focus)
            focus.parent = parent
            this.$set(parent, "opened", true)
            Vue.nextTick(() => this.focusEntry(focus))
        },
        focusEntry(entry) {
            for (let component of this.$refs.entries)
                if (component.entry == entry)
                    component.$refs.name.focus()
        },
        ensureVisible(entry) {
            entry.component.$refs.name.scrollIntoView({
                alignToTop: false,
                scrollIntoViewOptions: {
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                }
            })
        },
        createSubCategory() {
            let focus = this.focus
            let name = window.prompt("Please enter a category name:")
            if (name) {
                let entry = {
                    id: this.nextId,
                    name,
                    rules: [],
                    parent: focus,
                    children: [],
                }
                focus.children.push(entry)
                this.$set(focus, "opened", true)
                Vue.nextTick(() => this.focusEntry(entry))
            }
        },
        createCategoryAfter() {
            let focus = this.focus
            let name = window.prompt("Please enter a category name:")
            if (name) {
                let entry = {
                    id: this.nextId,
                    name,
                    rules: [],
                    parent: focus.parent,
                    children: [],
                }
                let i = focus.parent.children.indexOf(focus)
                focus.parent.children.splice(i+1, 0, entry)
                Vue.nextTick(() => this.focusEntry(entry))
            }
        },
        removeThisCategory() {
            let focus = this.focus
            if (window.confirm(`Are you sure you want to remove category ${focus.name}?`)) {
                let i = focus.parent.children.indexOf(focus)
                focus.parent.children.splice(i, 1)
                if (focus.children.length) {
                    if (window.confirm(`Do you want to move its subcategories to the  ${focus.parent.parent ? "parent category" : "storefront"}?`)) {
                        for (let entry of focus.children) {
                            focus.parent.children.push(entry)
                        }
                    }
                }
            }
        },
        formatColumn(key, val) {
            if (val == null)
                return null
            if (_.isBoolean(val))
                return val ? "yes" : "no"
            if (_.isNumber(val))
                return Number(val).toLocaleString()
            let type = this.types[key]
            if (type == "date")
                return moment(new Date(val)).format("MM-DD-YYYY")
            if (type == "datetime")
                return moment(new Date(val)).format("MM-DD-YYYY HH:mm:ss")
            return val
        }
    },
    computed: {
        fields() {
            return _(this.fieldsReport?.rows)
                .map(([key, name, type]) => ({key, name, type}))
                .value()
        },
        types() {
            return _(this.fields).map(field => [field.key, field.type]).fromPairs().value()
        },
        columnsConfig() {
            let attributes = [{
                name: "Description",
                calc: "description",
            }]
            for (let field of this.fields) {
                attributes.push({
                    name: field.name,
                    calc: field.key,
                })
            }
            return { attributes, metrics: [] }
        },
        selectedEntries() {
            return this.entries.filter(entry => entry.selected)
        },
        nextId() {
            return _(this.entries).map(({id}) => parseInt(id)).max() + 1
        },
        fuse() {
            return new Fuse(this.entries, this.search)
        },
        canMoveUp() {
            return this.focus &&
                this.focus.parent &&
                this.focus.parent.children.indexOf(this.focus) > 0
        },
        canMoveDown() {
            return this.focus &&
                this.focus.parent &&
                this.focus.parent.children.indexOf(this.focus) < this.focus.parent.children.length - 1
        },
        canMoveLeft() {
            return this.focus &&
                this.focus.parent &&
                this.focus.parent.parent &&
                this.focus.parent.parent.parent
        },
        canMoveRight() {
            return this.canMoveUp && this.focus.parent.parent
        },
        visibleEntries() {
            let visibleEntries = []
            let loop = (entry) => {
                visibleEntries.push(entry)
                if (entry.search && entry.search.matched ||
                    entry.children.length && entry.opened)
                    for (let child of entry.children)
                        if (!child.search || child.search.matched)
                            loop(child)
            }
            _.forEach(this.catalog.children, loop)
            return visibleEntries
        },
        prevEntry() {
            let i = this.visibleEntries.indexOf(this.focus)
            return i > 0 ? this.visibleEntries[i-1] : null
        },
        nextEntry() {
            let i = this.visibleEntries.indexOf(this.focus)
            return i < this.visibleEntries.length - 1 ? this.visibleEntries[i+1] : null
        }
    },
    watch: {
        multiselect() {
            if (!this.multiselect)
                for (let entry of this.entries)
                    if (entry.selected)
                        this.$delete(entry, "selected")
        },
        focus(newFocus, oldFocus) {
            if (newFocus == oldFocus)
                return

            Vue.nextTick(() => {
                if (oldFocus)
                    this.$delete(oldFocus, "focused")
                if (newFocus)
                    this.$set(newFocus, "focused", true)
            })
            localStorage["pim-hierarchies-focus"] = newFocus?.id
        },
        columns() {
            localStorage["pim-hierarchies-main-columns"] = JSON.stringify(this.columns)
        },
        entriesReport() {
            let catalog = {}
            let entries = {}
            let meta = this.entriesReport?.meta
            for (let row of this.entriesReport?.rows || []) {
                let [id, level, brand, name, seqn, ancestry] = row
                let attrs = row.slice(6).map((val, i) => ({key: meta.vals[i+6-1].calc, val}))
                let entry = {
                    id,
                    type: "category",
                    brand,
                    level,
                    name,
                    seqn,
                    attrs,
                    rules: [],
                    children: []
                }
                entries[id] = entry
                if (ancestry) {
                    parent = _.last(ancestry.split('/'))
                    if (entries[parent]) {
                        entry.parent = entries[parent]
                        entry.parent.children.push(entry)
                    }
                }
                else {
                    parent = catalog[brand]
                    if (!parent) {
                        parent = {
                            id: `_${brand}`,
                            type: "storefront",
                            name: brand,
                            children: []
                        }
                        catalog[brand] = parent
                    }
                    parent.children.push(entry)
                    entry.parent = parent
                }
            }
            for (let entry of _.values(catalog))
                entries[entry.id] = entry
            catalog = {
                children: _(catalog).values().sortBy("name").value()
            }
            for (let entry of catalog.children)
                entry.parent = catalog
            entries = _(entries).values().sortBy("seqn").value()
            let focus = entries.find(entry => entry.id == localStorage["pim-hierarchies-focus"]) || null
            if (focus) {
                let entry = focus.parent
                while (entry) {
                    entry.opened = true
                    entry = entry.parent
                }
            }
            this.focus = focus
            this.catalog = catalog
            this.entries = entries
        },
        searchString() {
            for (let entry of this.entries) {
                if (entry.search)
                    this.$delete(entry, "search")
            }
            if (this.searchString) {
                let matchedEntries = this.fuse.search(this.searchString)

                for (let matchedEntry of matchedEntries) {
                    let matches = matchedEntry.matches
                    for (let match of matches) {
                        if (match.indices.length > 1)
                            match.indices = match.indices.filter(([s,e]) => !(s == 0 && e == match.value.length - 1))
                    }
                    this.$set(matchedEntry.item, "search", {matched: true, matches: matches})
                    let parentEntry = matchedEntry.item.parent
                    while (parentEntry) {
                        if (!parentEntry.search)
                            this.$set(parentEntry, "search", {matched: true})
                        parentEntry = parentEntry.parent
                    }
                }
                for (let entry of this.entries) {
                    if (!entry.search)
                        this.$set(entry, "search", {matched: false})
                }
            }
        }
    },
}
</script>