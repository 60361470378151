<template>
    <div class="number-display" :style="{opacity: reportId ? 0.5:1}">
        <my-errors :errors="errors" @retry="requestData"/>

        <my-caption :caption="caption"/>

        <my-progress
            :key="reportId"
            :progress="progress"
            :processRows="processRows"
            :processTime="processTime"
            :meta="meta"
            :exportToFile="exportToFile"
            :assembly="assembly"/>

        <my-filters v-model="filters" :chart="chart"/>

        <div class="my-chart" :style="computedStyle" ref="base">
            <slot/>
            <div ref="chart"></div>
        </div>
    </div>
</template>

<script>
module.exports = {
    mixins: [
        require("../data.js"),
        require("./base.js"),
        require("./number-display.js"),
    ],
}
</script>

<style>
.number-display .my-chart {
    position: initial;
    clear: both;
}
.number-display .my-chart .dc-chart {
    position: initial;
}

</style>